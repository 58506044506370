
import {Component, Mixins, Watch} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {
    ACTION_UPDATE_USER_WEB_APP_SETTINGS
} from "@/store/store-user-web-app-settings";
import {MUTATION_FORCE_SEARCH} from "@/store/store-search";
import SidebarRightUserConfigFilterRadioGroup
    from "@/components/SidebarRightUserConfigFilterRadioGroup.vue";
import {
    FrontendUserWebAppSettings
} from "@/models/frontend-user-web-app-settings";
import {ACTION_FETCH_MONITORS} from "@/store/store-monitor";

/**
 * This component presents the settings for the web app, which are available to the user.
 */
@Component({
    components: {
        ErrorMessage,
        SidebarRightCloseButton,
        SidebarRightUserConfigFilterRadioGroup
    },
})
export default class SidebarRightUserWebAppSettings extends Mixins(StateHelper, Utils) {
    
    errorMessage: string = "";
    
    requestInProgress = false;


    @Watch("$store.state.sidebarRightStatus")
    onSwitchSidebar() {
        this.errorMessage = "";
    }

    handleToggleFilter(event: any, reloadSearchAndMonitors: boolean = false) : void {
        if(this.requestInProgress) {
            return;
        }
        this.errorMessage = "";
        this.requestInProgress = true;
        let config: any = new FrontendUserWebAppSettings(null);
        config[event.filter] = event.value;
        this.$store.dispatch(ACTION_UPDATE_USER_WEB_APP_SETTINGS,config).then(() => {
            if(reloadSearchAndMonitors) {
              /*
                Because we change which entities are visible, we must reload the search results (if any),
                as well as the monitor list, as companies or persons may have been added / removed from
                the list (consider the case of a sole proprietorship deregistered more than five years ago).
               */
              this.$store.dispatch(ACTION_FETCH_MONITORS);
            	this.$store.commit("search/" + MUTATION_FORCE_SEARCH);
            }
            this.requestInProgress = false;
        }).catch((response) => {
            this.errorMessage = this.extractErrorMessage(response, "Något gick fel. Försök igen senare.");
            this.requestInProgress = false;
        });
    }
    
    isActive(key: string) : boolean {
        let settings: any = this.userWebAppSettings;
        if(typeof(settings) === 'undefined' || settings == null) {
            return false;
        }
        return settings[key];
    }
}
