
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import Utils from "@/mixins/utils";
    import {FrontendMonitorPriorityConfig} from '@/models/frontend-monitor-priority-config';
    import {FrontendMonitorConfig} from '@/models/frontend-monitor-config';
    import SvgConstants from "@/mixins/svg-constants";
    import ErrorMessage from "@/components/ErrorMessage.vue";
    import StateHelper from "@/mixins/state-helper";
    import SearchPageEntityInfoHeader from "@/components/SearchPageEntityInfoHeader.vue";
    import SidebarRightUserConfigFilterRadioGroup from "@/components/SidebarRightUserConfigFilterRadioGroup.vue";
    
    /**
     * This component represents a monitor user config.
     */
    @Component({
        components: {ErrorMessage, SearchPageEntityInfoHeader,SidebarRightUserConfigFilterRadioGroup}
    })
    export default class SidebarRightMonitorUserConfig extends Mixins(StateHelper, SvgConstants, Utils) {
        @Prop()
        config: FrontendMonitorPriorityConfig;
        
        @Prop()
        priority: string;

        showDetails: boolean = false;
        
        errorMessage: string = "";
        
        requestInProgress: boolean = false;
        
        get catalogDeliveryWarning(): string {
        	if(this.config.incomeCatalogUpdateDeliveryMethods.length === 0) {
                return "Uppdateringar i inkomstkatalogen kommer inte meddelas";
        	} else {
        		return "";
        	}
        }
        get deliveryWarning(): string {
        	if(this.config.deliveryMethods.length === 0) {
                return "Bevakningshändelser kommer inte meddelas";
        	} else {
        		return "";
        	}
        }
        
        handleToggleAutoMonitorsOnCompany(evt: any) {
            if(this.requestInProgress) {
                return;
            }
            this.requestInProgress=true;
            
        	this.updateUserMonitorConfig((config: FrontendMonitorConfig) => {
                let priorityConfig: FrontendMonitorPriorityConfig = this.getPriorityConfigFromConfig(config);
                priorityConfig.autoMonitorsOnIndividualsOnCompany = evt.value;
            }).then(() => {
                this.requestInProgress=false;
            }).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response, "Något gick fel. Försök igen senare.");
                this.requestInProgress = false;
            });
        }
        handleSetIncomeCatalogDelivery(deliveryMethod: string) {
            if(this.requestInProgress) {
                return;
            }
            this.requestInProgress=true;
            
        	this.updateUserMonitorConfig((config: FrontendMonitorConfig) => {
                let priorityConfig: FrontendMonitorPriorityConfig = this.getPriorityConfigFromConfig(config);
                if(priorityConfig.incomeCatalogUpdateDeliveryMethods.includes(deliveryMethod)) {
                    let idx: number = priorityConfig.incomeCatalogUpdateDeliveryMethods.indexOf(deliveryMethod);
                    priorityConfig.incomeCatalogUpdateDeliveryMethods.splice(idx,1);
                } else {
                    priorityConfig.incomeCatalogUpdateDeliveryMethods.push(deliveryMethod);
                }
            }).then(() => {
                this.requestInProgress=false;
            }).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response, "Något gick fel. Försök igen senare.");
                this.requestInProgress = false;
            });
        }
        
        handleToggleDeliveryMethod(deliveryMethod: string): void {
            if(this.requestInProgress) {
                return;
            }
            this.requestInProgress=true;
            
            this.updateUserMonitorConfig((config: FrontendMonitorConfig) => {
                let priorityConfig: FrontendMonitorPriorityConfig = this.getPriorityConfigFromConfig(config);
                if(priorityConfig.deliveryMethods.includes(deliveryMethod)) {
                    let idx: number = priorityConfig.deliveryMethods.indexOf(deliveryMethod);
                    priorityConfig.deliveryMethods.splice(idx,1);
                } else {
                    priorityConfig.deliveryMethods.push(deliveryMethod);
                }
            }).then(() => {
                this.requestInProgress=false;
            }).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response, "Något gick fel. Försök igen senare.");
                this.requestInProgress = false;
            });
        }
        
        handleToggleFilter(event: any): void {
            if(this.requestInProgress) {
                return;
            }
            this.requestInProgress=true;
            
        	let newValue = event.value;
        	let filterName = event.filter;
        	this.updateUserMonitorConfig((config: FrontendMonitorConfig) => {
                let priorityConfig: FrontendMonitorPriorityConfig = this.getPriorityConfigFromConfig(config);
        	    
        	    if(newValue) {
        	    	if(!priorityConfig.eventGroupFilters.includes(filterName)) {
        	    		priorityConfig.eventGroupFilters.push(filterName);
        	    	}
        	    } else {
        	    	if(priorityConfig.eventGroupFilters.includes(filterName)) {
                        let idx: number = priorityConfig.eventGroupFilters.indexOf(filterName);
                        priorityConfig.eventGroupFilters.splice(idx,1);
                    }
        	    }
            }).then(() => {
                this.requestInProgress=false;
            }).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response, "Något gick fel. Försök igen senare.");
                this.requestInProgress = false;
            });
        }
        
        getPriorityConfigFromConfig(config: FrontendMonitorConfig): FrontendMonitorPriorityConfig {
        	if(this.priority==="HIGH") {
        		return config.high;
        	}
        	else if(this.priority==="NORMAL") {
                return config.normal;
            }
        	else if(this.priority==="LOW") {
                return config.low;
            }
        	return null;
        }
        get formatHeader(): string {
        	return "Prioritet " + this.$options.filters.monitorPriority(this.priority)
        }
        
        get headingIconText(): string {
          if (this.showDetails) {
              return "Dölj" + " &#9661;";
          } else {
              return "Visa" + " &#9655;";
          }
        }
    }
