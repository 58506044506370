
    import {Component, Prop, Vue} from 'vue-property-decorator';


    /**
     * This component represents an error message that will be visible when the error
     * message property is truthy.
     */
    @Component
    export default class ErrorMessage extends Vue {
        /**
         * The error message to display.
         */
        @Prop()
        errorMessage: string;
        
        /**
         * Whether the message is a warning.
         */
        @Prop()
        isWarning: boolean;
    }
