
import {Component, Mixins} from 'vue-property-decorator';
import HomePage from "@/views/HomePage.vue";
import {
    MUTATION_POPUP_MESSAGE_STATUS,
    MUTATION_POPUP_REGISTRATION_STATE,
    PopupRegistrationState
} from "@/store/store";
import Utils from "@/mixins/utils";
import {HTTP} from "@/services/http-provider";
import {
    EndUserSubscriptionParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";

/**
 * This component represents the page where a user is directed when clicking on
 * a link in an invite email.
 */
@Component({
    components: {
        HomePage
    },

    /**
     * Make sure we close the popup whenever we navigate from the invite page.
     *
     * @param to The new route.
     * @param from The old route.
     * @param next Must call this in order to complete navigation.
     */
    beforeRouteLeave(to, from, next) {
        this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, "");
        next();
    }
})
export default class UserInvitePage extends Mixins(Utils) {
    created() {
        let userInviteLinkId: string = this.$route.params["userInviteLinkId"];

        // Sign out is handled by the App component.
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success) {
                HTTP.get<SubscriptionParameters>("/api/subscription/invite/" + userInviteLinkId).then((subscription: SubscriptionParameters) => {
                    /*
                      Store the necessary properties of the invite in a json object to be
                      parsed by the PopupRegistrationForm.
                     */
                    let inviteProperties = new EndUserSubscriptionParameters();
                    inviteProperties.subscriptionParameters = subscription;
                    this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, new PopupRegistrationState("invite:" + JSON.stringify(inviteProperties), subscription));
                }).catch((errorResponse) => {
                    let errorMessage: string = this.extractErrorMessage(errorResponse, "Den angivna inbjudningslänken är inte korrekt.");
                    this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=" + errorMessage);
                });
            }
        });
    }
}
