
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import {EntityStatus} from "@/models/entity-status";
import StatusIcon from "@/components/StatusIcon.vue";

/**
 * This component represents a row in a "table_multiline_entries" table,
 * potentially containing a status icon and a describing text.
 */
@Component({
    components: {StatusIcon}
})
export default class StatusIconRow extends Mixins(SvgConstants) {
    /**
     * The status.
     */
    @Prop()
    status: EntityStatus;

    @Prop({default: "icon_gray"})
    infoClass: string;

    @Prop({default: true})
    light: boolean;
}
