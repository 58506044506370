
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import {AuthRequest} from "@/models/auth-request.model";
import {ACTION_AGREE_TO_SAFENODE_TERMS} from "@/store/store";
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {UserErrorMessage} from "@/models/error-message";
import {SafenodeQuestionerParameters} from "@/models/safenode-questioner-parameters";
import {ACTION_FETCH_QUESTIONERS} from "@/store/store-questioner";
import BankIdAuthentication from "@/components/BankIdAuthentication.vue";

/**
 * This component handles accepting terms, without bankID.
 */
@Component({
    components: {BankIdAuthentication, ErrorMessage}
})
export default class AgreeToSafenodeTerms extends Mixins(StateHelper, SvgConstants, Utils) {
    /**
     * The text to be displayed to the user. Not needed if questioner is
     * present.
     */
    @Prop()
    text: string;

    /**
     * Set to true if the button should be clickable.
     */
    @Prop({default: true})
    enableButton: boolean;

    /**
     * Questioner parameters if this operation is for signing the terms for
     * a new questioner.
     */
    @Prop()
    questioner: SafenodeQuestionerParameters;

    @Prop()
    cancelable: boolean;

    /**
     * True if we should show the spinner.
     */
    showSpinner: boolean = false;

    errorMessage: string = "";

    private authRequest: AuthRequest;

    /**
     * Accept terms.
     */
    acceptTermsNoQuestioner(): void {
    	this.errorMessage = "";
        this.showSpinner = true;
        let refNoOrZero: string = this.activeSubscriptionRefNo || "ZERO";

        /*
           Note that we sign for and add questioners through the
           BankIDAuthentication component.
         */
        this.authRequest = new AuthRequest(refNoOrZero, undefined);
        this.$store.dispatch(ACTION_AGREE_TO_SAFENODE_TERMS, this.authRequest)
            .catch((response: UserErrorMessage | any) => {
                this.errorMessage = this.extractErrorMessage(response);
            })
            .finally(() => {
                /*
                  The process is done, let us reload the list of
                  questioners to ensure that they are updated.

                  Not really necessary since we didn't add any new questioner
                  if we reached here but better safe than sorry.
                */
                this.complete();
                this.ignoreError(this.$store.dispatch(ACTION_FETCH_QUESTIONERS));
            });
    }

    complete(): void {
        this.showSpinner = false;
        this.$emit("agreed-to-terms");
    }

    /**
     * Aborts an ongoing terms process.
     */
    abort(): void {
        this.showSpinner = false;
        this.$emit("agree-aborted");
    }
}
