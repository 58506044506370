
import {Component, Mixins, Watch} from 'vue-property-decorator';
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import {
  EndUserParameters,
  SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import VisibleAware from "@/mixins/visible-aware";
import Utils from "@/mixins/utils";
import {ACTION_FETCH_QUESTIONERS} from "@/store/store-questioner";
import {DocumentEntity} from "@/models/document-entity";
import {ACTION_FETCH_DOCUMENT_ENTITIES} from '@/store/store-documents';
import SidebarRightDocumentEntityListEntry
  from "@/components/SidebarRightDocumentEntityListEntry.vue";

/**
 * This component represents the list of credit reports in the right sidebar.
 */
@Component({
    components: {
      SidebarRightDocumentEntityListEntry,
        SidebarRightCloseButton
    },
})
export default class SidebarRightDocumentEntityList extends Mixins(StateHelper, SvgConstants, Utils, VisibleAware) {
    mounted() {
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success) {
                this.onActiveUserChange(this.activeUser, null);
                if (this.documentEntities.length === 0) {
                    this.updateDocumentEntities(this.activeSubscription, null);
                }
            }
        });
    }

    /**
     * Getter for the document entity list in the store.
     */
    get documentEntities(): DocumentEntity[] {
      return this.$store.state.documents.entities;
    }

    /**
     * Getter for checking if we have anything in the credit report list in
     * the store.
     */
    get hasDocumentEntities(): boolean {
        return this.documentEntities.length > 0;
    }

    /**
     * Make sure we update the credit report list whenever the active subscription
     * changes.
     *
     * @param val The new active subscription. May be undefined.
     * @param oldVal The old active subscription. May be undefined.
     */
    @Watch("$store.state.activeSubscription")
    onActiveSubscriptionChange(val: SubscriptionParameters, oldVal: SubscriptionParameters) {
        this.updateDocumentEntities(val, oldVal);
    }
    /**
     * Make sure we update the questioner list whenever the active user changes.
     *
     * @param val The new active user. May be undefined.
     * @param oldVal The old active user. May be undefined.
     */
    @Watch("$store.state.activeUser")
    onActiveUserChange(val: EndUserParameters, oldVal: EndUserParameters) {
        if (this.differentRefNo(val, oldVal)) {
            this.ignoreError(this.$store.dispatch(ACTION_FETCH_QUESTIONERS));
        }
    }

    updateDocumentEntities(val: SubscriptionParameters, oldVal: SubscriptionParameters): void {
        if (this.differentRefNo(val, oldVal)) {
            this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES));
            this.ignoreError(this.$store.dispatch(ACTION_FETCH_QUESTIONERS));
        }
    }
}
