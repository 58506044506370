
import {Component, Prop, Mixins} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import SidebarRightUserConfigFilterRadioGroup
    from "@/components/SidebarRightUserConfigFilterRadioGroup.vue";
import {ACTION_UPDATE_ENTITY_FILTER} from "@/store/store-search";
import {EntityType} from "@/models/entity-type";


@Component({
    components: {SidebarRightUserConfigFilterRadioGroup}
})
export default class SearchPageEventFilterSettings extends Mixins(SvgConstants, StateHelper, Utils) {
    @Prop()
    entityId: string;

    @Prop()
    filters: string[];

    @Prop()
    numHidden: number;

    expand: boolean = false;
    
    requestInProgress: boolean = false;

    /**
     * Gets the entity type that we're rendering event filter settings for.
     */
    get entityType(): EntityTypeWithEventFilter {
        switch (this.entityId.charAt(0)) {
            case "1":
                return EntityType.INDIVIDUAL;
            case "2":
                return EntityType.ORGANISATION;
            case "3":
                return EntityType.ADDRESS_LOCATION;
            default:
                throw new Error(`Unhandled entity id: ${this.entityId.charAt(0)}`);
        }
    }

    /**
     * Selects the filter options depending on what type of entity this
     * component is working with.
     */
    get eventFilterOptions(): Record<string, string> {
        switch (this.entityType) {
            case EntityType.ADDRESS_LOCATION:
                return this.addressLocationEventFilters;
            case EntityType.INDIVIDUAL:
                return this.individualEventFilters;
            case EntityType.ORGANISATION:
                return this.companyEventFilters;
            default:
                // This is not be needed if ts is run with strict null checks
                const exhaustiveCheck: never = this.entityType;
                throw new Error(`Unhandled entity case: ${exhaustiveCheck}`);
        }
    }

    handleToggleFilter(event: any): void {
        if(this.requestInProgress) {
            return;
        }
        this.requestInProgress=true;
        
        const updated: string[] = [];
        for (let f of this.filters) {
            updated.push(f);
        }

        if (event.value) {
            if (!updated.includes(event.filter)) {
                updated.push(event.filter);
            }
        } else {
            if (updated.includes(event.filter)) {
                let idx: number = updated.indexOf(event.filter);
                updated.splice(idx, 1);
            }
        }
        this.$store.dispatch(ACTION_UPDATE_ENTITY_FILTER, {
            "filters": updated,
            "publicId": null
        }).then(() => {
            this.requestInProgress = false;
        }).catch(() => {
            this.requestInProgress = false;
        });
    }

    handleSelectAll(): void {
        if(this.requestInProgress) {
            return;
        }
        this.requestInProgress=true;
        
        let allFiltersForOurEntityType: string[];
        switch (this.entityType) {
            case EntityType.INDIVIDUAL:
                allFiltersForOurEntityType = Object.keys(this.individualEventFilters);
                break;
            case EntityType.ORGANISATION:
                allFiltersForOurEntityType = Object.keys(this.companyEventFilters);
                break;
            case EntityType.ADDRESS_LOCATION:
                allFiltersForOurEntityType = Object.keys(this.addressLocationEventFilters);
                break;
            default:
                const exhaustiveCheck: never = this.entityType;
                throw new Error(`Unhandled entity case: ${exhaustiveCheck}`);
        }

        let updated: string[] = [];

        // Add current filters for entity types we're not dealing with here.
        for (let filter of this.filters) {
            if (!allFiltersForOurEntityType.includes(filter)) {
                updated.push(filter);
            }
        }

        /*
          We want the "select all" to unselect all if all filters are
          already selected for the entity type we're dealing with, so we
          only add allFiltersForOurEntityType if that is not the case.
         */
        if (!this.aContainsAllInB(this.filters, allFiltersForOurEntityType)) {
            for (let filter of allFiltersForOurEntityType) {
                updated.push(filter);
            }
        }
        this.$store.dispatch(ACTION_UPDATE_ENTITY_FILTER, {
            "filters": updated,
            "publicId": null
        }).then(() => {
            this.requestInProgress = false;
        }).catch(() => {
            this.requestInProgress = false;
        });
    }

    private aContainsAllInB(a: string[], b: string[]): boolean {
        for (let elemInB of b) {
            if (!a.includes(elemInB)) {
                return false;
            }
        }
        return true;
    }
}

/**
 * Should cover all entity types that have individual event filter settings.
 */
type EntityTypeWithEventFilter =
    EntityType.INDIVIDUAL |
    EntityType.ORGANISATION |
    EntityType.ADDRESS_LOCATION

