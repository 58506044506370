
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import Utils, {UTILS} from "@/mixins/utils";
import SearchPageEntityEvent from "@/components/SearchPageEntityEvent.vue";
import {DisplayableEvent} from "@/models/displayable-event";


/**
 * This component represents a list of events.
 */
@Component({
    components: {
        SearchPageEntityEvent,
        SearchPageEntityInfoHeader, SearchPageEntityLink}
})
export default class SearchPageEntityEventList extends Mixins(StateHelper, Utils) {
    /**
     * The list of events.
     */
    @Prop()
    events: DisplayableEvent[];

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is
     * used when determining if the link is active or not by checking if the id
     * in the detailIds array on the level to the right of this one, is the same
     * as our id.
     */
    @Prop()
    level: number;

    numToShow: number = UTILS.CHUNK_SIZE;


    /**
     * Gets the entries currently visible in the list. For large lists, we
     * don't show all entries since that may consume too much resources in
     * the browser.
     */
    get visibleEntries(): DisplayableEvent[] {
        return this.events.slice(0, this.numToShow);
    }
}
