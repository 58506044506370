
    import {Component, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import PopupMessageCloseButton
        from "@/components/PopupMessageCloseButton.vue";

    /**
     * This component represents the popup telling the user that they have been logged
     * out because they are currently using the service from another device.
     */
    @Component({
        components: {PopupMessageCloseButton}
    })
    export default class PopupMessageLogout extends Mixins(SvgConstants) {
    }
