import {Component, Vue} from "vue-property-decorator";
import {AuthInfoResponse} from "@/models/auth-info-response";
import store from "@/store/store";
import {Authority} from "@/models/Authority";

/**
 * This class contains authentication related utility methods.
 */
@Component
export default class Auth extends Vue {

    /**
     * Gets the list of authentication roles from the authInfo field from the
     * store. Returns an empty list if we have no authInfo.
     */
    getRoles(): string[] {
        let authInfo: AuthInfoResponse | undefined = store.getters.authorityInfo;
        if (authInfo && authInfo.authorities) {
            return authInfo.authorities;
        }
        return [];
    }

    /**
     * Checks if the current authentication token has a given role.
     *
     * @param roleName The role name.
     */
    hasRole(roleName: string): boolean {
        let roles = this.getRoles();
        return roles.indexOf(roleName) != -1;
    }

    /**
     * Checks if the current authentication token has the BankID role.
     *
     * @return True if the token seems to have the bank id role
     */
    hasBankIdRole(): boolean {
        return this.hasRole(Authority.AUTHENTICATED_BANK_ID);
    }
}

export const AUTH: Auth = new Auth();