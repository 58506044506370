
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {
    SearchResultEntryCompanyDetails
} from "@/models/search-result-entry-company-details";
import VisibleAware from "@/mixins/visible-aware";
import SearchPageEntityCompanyDocumentsAnnualReportList
    from "@/components/SearchPageEntityCompanyDocumentsAnnualReportList.vue";
import StateHelper from "@/mixins/state-helper";
import {HTTP} from "@/services/http-provider";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {DocumentAvailability} from "@/models/document-availability";
import {AnnualReportEntry} from "@/models/annual-report-entry";
import Utils from "@/mixins/utils";
import {DisplayableDocument} from "@/models/displayable-document";
import SearchPageEntityCommonCredit
    from "@/components/SearchPageEntityCommonCredit.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import {
    ACTION_FETCH_DOCUMENT_ENTITIES,
    ACTION_FLASH_NEW_DOCUMENT
} from "@/store/store-documents";
import SearchPageEntityDocumentList
    from "@/components/SearchPageEntityDocumentList.vue";
import SvgConstants from "@/mixins/svg-constants";
import SearchPageEntitySimpleExpandable
    from "@/components/SearchPageEntitySimpleExpandable.vue";
import {MUTATION_ACTIVE_INLINE_MODAL} from "@/store/store";
import {CompanyDocumentInfo} from "@/models/company-document-info";
import SearchPageEntityCompanyDocumentsProtocolList
    from "@/components/SearchPageEntityCompanyDocumentsProtocolList.vue";
import {
    FrontendLegalDocumentListing
} from "@/models/frontend-legal-document-listing";
import SearchPageEntityLegalDocumentListing
    from "@/components/SearchPageEntityLegalDocumentListing.vue";
import SearchPageEntityBvCaseListing
    from "@/components/SearchPageEntityBvCaseListing.vue";
import {FrontendBvCaseListing} from "@/models/frontend-bv-case-listing";

/**
 * This component represents the documents tab in a search result details view.
 */
@Component({
    components: {
        SearchPageEntityBvCaseListing,
        SearchPageEntityLegalDocumentListing,
        SearchPageEntityCompanyDocumentsProtocolList,
        SearchPageEntitySimpleExpandable,
        SearchPageEntityDocumentList,
        SearchPageEntityExpandable,
        SearchPageEntityCommonCredit,
        SearchPageEntityCompanyDocumentsAnnualReportList,
        ErrorMessage
    }
})
export default class SearchPageEntityCompanyDocuments extends Mixins(StateHelper, VisibleAware, Utils, SvgConstants) {
    private firstLoadDone: boolean = false;

    @Prop()
    entity: SearchResultEntryCompanyDetails;

    loading: boolean = true;

    documentAvailability: DocumentAvailability = new DocumentAvailability();

    annualReports: AnnualReportEntry[] = [];

    errorMessage: string = "";

    displayableDocuments: DisplayableDocument[] = [];

    protocols: DisplayableDocument[] = [];

    companyDataMinDate: string = "";

    companyDataMaxDate: string = "";

    vehicleDataMinDate: string = "";

    vehicleDataMaxDate: string = "";

    legalDocumentListing: FrontendLegalDocumentListing = null;

    bvCaseListing: FrontendBvCaseListing = null;

    /**
     * Reload documents if we're mounted while being visible.
     */
    mounted() {
        if (this.isVisible) {
            this.onVisibleChange(true);
        }
    }

    /**
     * Reload documents when we become visible.
     *
     * @param val The new visible value.
     */
    @Watch("isVisible")
    onVisibleChange(val: boolean): void {
        if (val) {
            if (this.firstLoadDone) {
                return;
            }
            this.errorMessage = "";
            this.annualReports = [];
            this.displayableDocuments = [];

            if (this.entity.secretIdentity) {
                this.loading = false;
                this.firstLoadDone = true;
            } else {
                this.loadDocumentInfo().then(() => {
                    this.firstLoadDone = true;
                });
            }
        }
    }

    get creditReportDocuments(): DisplayableDocument[] {
        return this.doFilter("CREDIT_REPORT");
    }

    get articleOfAssociationDocuments(): DisplayableDocument[] {
        return this.doFilter("ARTICLES_OF_ASSOCIATION");
    }

    get statutesDocuments(): DisplayableDocument[] {
        return this.doFilter("STATUTES");
    }

    get financialPlanDocuments(): DisplayableDocument[] {
        return this.doFilter("FINANCIAL_PLAN");
    }

    get caseListDocuments(): DisplayableDocument[] {
        return this.doFilter("CASE_LIST");
    }

    get certificateOfRegistrationDocuments(): DisplayableDocument[] {
        return this.doFilter("CERTIFICATE_OF_REGISTRATION");
    }

    get certificateOfRegistrationEnglishDocuments(): DisplayableDocument[] {
        return this.doFilter("CERTIFICATE_OF_REGISTRATION_ENGLISH");
    }

    get companyReportDocuments(): DisplayableDocument[] {
        return this.doFilter("COMPANY_REPORT");
    }

    get companyMortgageReportDocuments(): DisplayableDocument[] {
        return this.doFilter("COMPANY_MORTGAGE_REPORT");
    }

    get companyVehicleReportDocuments(): DisplayableDocument[] {
        return this.doFilter("COMPANY_VEHICLE_REPORT");
    }

    get companyCapitalReportDocuments(): DisplayableDocument[] {
        return this.doFilter("COMPANY_CAPITAL_REPORT");
    }

    get vehicleDataEarliestDate(): string {
        if (this.companyDataMinDate && this.companyDataMinDate > this.vehicleDataMinDate) {
            return this.companyDataMinDate;
        } else {
            return this.vehicleDataMinDate;
        }
    }

    private doFilter(type: string): DisplayableDocument[] {
        return this.displayableDocuments.filter(document => type === document.type);
    }

    loadDocumentInfo(): Promise<void> {
        return new Promise<void>(resolve => {
            let timeout = setTimeout(() => this.loading = true, 500);
            let url: string = "/sapi/company-document-info/" + this.entity.id + "/" + this.activeSubscriptionRefNo;
            HTTP.get<CompanyDocumentInfo>(url).then((docInfo: CompanyDocumentInfo) => {
                if (docInfo.error) {
                    this.errorMessage = docInfo.error;
                }

                // Annual reports.
                this.annualReports = docInfo.annualReports;

                // Document availability
                this.documentAvailability.articlesOfAssociationAvailable = docInfo.articlesOfAssociationAvailable;
                this.documentAvailability.statutesAvailable = docInfo.statutesAvailable;
                this.documentAvailability.financialPlanAvailable = docInfo.financialPlanAvailable;
                this.documentAvailability.certificateOfRegistrationAvailable = docInfo.certificateOfRegistrationAvailable;

                // Company time span data.
                if (docInfo.companyDataTimeSpan) {
                    this.companyDataMinDate = docInfo.companyDataTimeSpan.earliest;
                    this.companyDataMaxDate = docInfo.companyDataTimeSpan.latest;
                }

                // Vehicle time span data.
                if (docInfo.vehicleDataTimeSpan) {
                    this.vehicleDataMinDate = docInfo.vehicleDataTimeSpan.earliest;
                    this.vehicleDataMaxDate = docInfo.vehicleDataTimeSpan.latest;
                }

                // Displayable documents.
                if (Array.isArray(docInfo.displayableDocuments)) {
                    this.displayableDocuments = docInfo.displayableDocuments;
                } else {
                    this.displayableDocuments = [];
                }

                // Protocols.
                this.protocols = docInfo.protocols;

                // Legal documents
                this.legalDocumentListing = docInfo.legalDocumentListing;

                // BV case listing.
                this.bvCaseListing = docInfo.bvCaseListing;
            }).catch(response => {
                if (!this.errorMessage) {
                    this.errorMessage = this.extractErrorMessage(response);
                }
            }).finally(() => {
                clearTimeout(timeout);
                this.loading = false;
                resolve();
            });
        });
    }

    addNewDocument(doc: DisplayableDocument) {
        // Add document and re-sort according to sort key (reversed).
        this.displayableDocuments.unshift(doc);
        this.displayableDocuments.sort((a, b) => b.sortKey.localeCompare(a.sortKey));

        let refName: string = SearchPageEntityCompanyDocuments.typeToRefName(doc.type);
        if (refName) {
            /*
               Since we use a component and not an actual DOM element
               that we want to scroll to we do it in a bit ugly way by
               using the ref as type "any" so we can access the $el
               property and scroll to that.
             */
            let scrollToMe: any = this.$refs[refName] as any;
            scrollToMe.$el.scrollIntoView({behavior: "smooth"});
        }
        this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES));
        this.$store.dispatch(ACTION_FLASH_NEW_DOCUMENT, doc);
    }

    /**
     * Handle event triggered by the user requesting a new legal document
     * listing by simply replacing the one we currently have.
     *
     * @param legalDocumentListing The new listing.
     */
    newLegalDocumentListing(legalDocumentListing: FrontendLegalDocumentListing) {
        this.legalDocumentListing = legalDocumentListing;
    }

    /**
     * Handle event triggered by the user requesting a new bv case listing by
     * simply replacing the one we currently have.
     *
     * @param bvCaseListing The new listing.
     */
    newBvCaseListing(bvCaseListing: FrontendBvCaseListing) {
        this.bvCaseListing = bvCaseListing;
    }

    private static typeToRefName(type: string): string {
        switch (type) {
            case "CREDIT_REPORT":
                return "creditReports";
            case "ARTICLES_OF_ASSOCIATION":
                return "articlesOfAssociation";
            case "STATUTES":
                return "statutes";
            case "FINANCIAL_PLAN":
                return "financialPlan";
            case "CASE_LIST":
                return "caseList";
            case "CERTIFICATE_OF_REGISTRATION":
                return "certificateOfRegistration";
            case "COMPANY_REPORT":
                return "companyReports";
            case "COMPANY_MORTGAGE_REPORT":
                return "companyMortgageReports";
            case "COMPANY_VEHICLE_REPORT":
                return "companyVehicleReports";
            default:
                return null;
        }
    }

    deleteDisplayableDocument(type: string, documentId: string): void {
        this.removeDisplayableDocument(this.displayableDocuments, documentId);
        this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES, this.entity.id));
    }

    removeDisplayableDocument(arr: DisplayableDocument[], documentId: string) {
        arr.forEach((doc: DisplayableDocument, index: number) => {
            if (doc.id === documentId) {
                arr.splice(index, 1);
            }
        });
    }

    get showAnnualReports(): boolean {
        let fetchAnnualReportsPermission = this.entity.aktiebolag ? this.allowFetchAnnualReportAb : this.allowFetchAnnualReportNonAb;
        return this.signedIn && this.hasActiveSubscription && fetchAnnualReportsPermission && !this.entity.soleProprietorship;
    }

    get showCompanyReport(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCompanyReport;
    }

    get showCertificateOfRegistration(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCertificateOfRegistration;
    }

    get showCertificateOfRegistrationEnglish(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCertificateOfRegistrationEnglish;
    }

    get showArticlesOfAssociation(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchArticlesOfAssociation;
    }

    get showStatutes(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchStatutes && this.isAssociation(this.entity);
    }

    get showFinancialPlan(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchFinancialPlan && this.isHousingSociety(this.entity);
    }

    get showCaseList(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCaseList;
    }

    get showCaseInfo(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCaseInfo;
    }

    get showProtocols(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchProtocol;
    }

    get showCreditReports(): boolean {
        return this.signedIn && this.allowCreditReport;
    }

    get showCompanyMortgageReports(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCompanyMortgageReport;
    }

    get showCompanyVehicleReports(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCompanyVehicleReport && !this.entity.soleProprietorship;
    }

    get showCompanyCapitalReports(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowFetchCompanyCapitalReport && !this.entity.soleProprietorship;
    }

    get showLegalDocuments(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowLegalDocuments;
    }

    get showNoDocuments(): boolean {
        return this.numberOfShownDocuments() === 0;
    }

    get showSingleDocumentType(): boolean {
        return this.numberOfShownDocuments() === 1;
    }

    private numberOfShownDocuments(): number {
        let numberOfShownDocuments: number = 0;
        if (this.showAnnualReports) {
            numberOfShownDocuments++;
        }
        if (this.showCompanyReport) {
            numberOfShownDocuments++;
        }
        if (this.showCertificateOfRegistration) {
            numberOfShownDocuments++;
        }
        if (this.showCertificateOfRegistrationEnglish) {
            numberOfShownDocuments++;
        }
        if (this.showLegalDocuments) {
            numberOfShownDocuments++;
        }
        if (this.showArticlesOfAssociation) {
            numberOfShownDocuments++;
        }
        if (this.showCaseList) {
            numberOfShownDocuments++;
        }
        if (this.showCaseInfo) {
            numberOfShownDocuments++;
        }
        if (this.showCreditReports) {
            numberOfShownDocuments++;
        }
        if (this.showCompanyMortgageReports) {
            numberOfShownDocuments++;
        }
        if (this.showCompanyVehicleReports) {
            numberOfShownDocuments++;
        }
        return numberOfShownDocuments;
    }

    get tagForCreditReport(): string {
        if (this.entity.aktiebolag) {
            // We always create a real credit report for aktiebolag.
            return '';
        } else {
            // For other company forms, there will be a demo report if the
            // subscription is set to demo.
            return this.isDemoSubscription ? 'Demo' : '';
        }
    }

    /**
     * Closes the active inline modal.
     */
    closeActiveInlineModal(val: boolean): void {
        this.$store.commit(MUTATION_ACTIVE_INLINE_MODAL, "");
    }
}
