
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {MonitoredEntity} from "@/models/monitored-entity";
import {ACTION_DELETE_MONITOR, MonitorRequest} from "@/store/store-monitor";
import {MUTATION_SIDEBAR_RIGHT_STATUS} from "@/store/store";
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import {EntityViewRef} from "@/store/store-search";
import ErrorMessage from "@/components/ErrorMessage.vue";
import StateHelper from "@/mixins/state-helper";

    /**
     * This component represents a single entry in the list of monitors in the right
     * sidebar.
     */
    @Component({
        components: {ErrorMessage}
    })
    export default class SidebarRightMonitorListEntry extends Mixins(StateHelper, SvgConstants, Utils) {
        @Prop()
        monitor: MonitoredEntity;

        @Prop()
        removable: boolean;

        showConfirmRemove: boolean = false;

        showRemoveAnimation: boolean = false;

        errorMessage: string = "";

        get buttonText(): string {
            return this.entityTypeText(this.monitor);
        }

        get isCompany(): boolean {
            return !!this.monitor.companyName;
        }

        /**
         * True if this entry refers to an address location.
         */
        get isAddressLocation(): boolean {
            return !!this.monitor.streetAddressAndCity;
        }

        get isRealProperty(): boolean {
            return !!this.monitor.realPropertyName;
        }

        get isVehicle(): boolean {
        	return !!this.monitor.vehicleRegistrationNumber;
        }

        /**
         * Show the confirm dialog before removing the monitor.
         */
        doShowConfirmRemove() {
            this.showConfirmRemove = true;
            this.errorMessage = "";
        }

        updateMonitor() {
        	this.errorMessage = "";

            
            let request: MonitorRequest = new MonitorRequest();
            request.publicId=this.monitor.id;
            request.priority= this.getNextMonitorPriorityValue(this.monitor.priority);
            request.excludeFromAuto=this.monitor.excludedFromAuto;

            this.createMonitorFetchUpdates(request).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response);
            });
        }
        
        /**
         * Delete the monitor.
         */
        deleteMonitor() {
            this.showRemoveAnimation = true;
            this.errorMessage = "";
            setTimeout(() => {
                this.$store.dispatch(ACTION_DELETE_MONITOR, this.monitor.id).catch((response) => {
                    this.errorMessage= this.extractErrorMessage(response);
                }).finally(() => {
                    this.showConfirmRemove = false;
                    this.showRemoveAnimation = false;
                })
            }, 500)
        }

        /**
         * Show the entity the monitor refers to.
         */
        showEntity(): void {
            this.$router.push({
                path: "sok",
                query: {
                    vad: this.monitor.id,
                    detaljer: Utils.encode(new EntityViewRef(this.monitor.id)),
                    filter: ""
                }
            });
            if (!Utils.isMobile()) {
                this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "monitor-list");
            }
        }
    }
