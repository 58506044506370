/**
 * See the corresponding java class for documentation.
 */
import {EndUserSubscriptionParameters} from "@/models/end-user-subscription-parameters";

export class AuthRequest {
    id: string;
    secret?: string;

    constructor(id: string, secret?: string) {
        this.id = id;
        this.secret = secret;
    }
}

/**
 * This extension of the AuthRequest class makes it possible to set an abortion
 * flag indicating that an ongoing request should be aborted.
 */
export class AbortableAuthRequest extends AuthRequest {
    /**
     * Set to true when a request should be aborted.
     */
    abort?: boolean;

    constructor(id: string, secret?: string) {
        super(id, secret);
    }
}

/**
 * See the corresponding java class for documentation.
 */
export class OneTimeCodeAuthRequest extends AbortableAuthRequest {
    type: string = "EMAIL";

    constructor(id: string, secret?: string, type?: string) {
        super(id, secret);
        this.type = type;
    }
}

/**
 * A wrapper around an AbortableAuthRequest that can specify an action,
 * depending on if we want to authenticate or sign.
 */
export class BankIDAuthRequest extends AbortableAuthRequest {
    action: string;

    /**
     * The BankID order reference. This is set by the authentication process as
     * soon as we now it, but will be undefined until then.
     */
    orderRef: string;


    /**
     * Constructor.
     *
     * @param id The id must be set to the refNo of the active subscription
     *     when signing or the name of the questioner when signing for registering
     *     a new questioner.
     * @param action The action. Either "authenticate" or "sign".
     * @param secret The secret must be set to the organization number of the new
     *      questioner when signing the terms for it.
     */
    constructor(id: string, action: string, secret?: string) {
        super(id, secret);
        this.action = action;
    }
}

/**
 * A wrapper around a BankIDAuthRequest that can specify parameters for a new
 * self-service subscription to be created if signing succeeds.
 */
export class CreateSelfServiceSubscriptionBankIDAuthRequest extends BankIDAuthRequest {
    creation: EndUserSubscriptionParameters;

    /**
     * Constructor.
     *
     * @param creation The parameters to use when creating the subscription.
     */
    constructor(creation: EndUserSubscriptionParameters) {
        super(undefined, "create-self-service-subscription");
        this.creation = creation;
    }
}

/**
 * See the corresponding java class for documentation.
 */
export class BankIDCollectRequest {
    orderRef: string;

    subscriptionRefNo: string;


    constructor(orderRef: string, subscriptionRefNo?: string) {
        this.orderRef = orderRef;
        this.subscriptionRefNo = subscriptionRefNo;
    }
}