import { ActionTree, Module } from "vuex";
import { HTTP } from "@/services/http-provider";
import { FrontendUserWebAppSettings } from "@/models/frontend-user-web-app-settings";
import { resetState, RootState } from "@/store/store";
import { Global } from "@/global";

export const MUTATION_USER_SETTINGS = "userSettings";

export const ACTION_GET_USER_WEB_APP_SETTINGS = "userWebAppSettings/fetchUserWebAppSettings";
export const ACTION_UPDATE_USER_WEB_APP_SETTINGS = "userWebAppSettings/updateUserWebAppSettings";


export interface UserWebAppSettingsState {
    userWebAppSettings: FrontendUserWebAppSettings;
}

export const actions: ActionTree<UserWebAppSettingsState, RootState> = {
    fetchUserWebAppSettings(context): Promise<FrontendUserWebAppSettings> {
        return new Promise((resolve, reject) => {
            if (context.rootGetters.signedIn && context.rootState.activeUser) {
                HTTP.get<FrontendUserWebAppSettings>("/sapi/user-config/get-user-web-app-settings").then((response: FrontendUserWebAppSettings) => {
                    context.commit(MUTATION_USER_SETTINGS, response);
                    resolve(response);
                }).catch((error) => {
                    context.commit(MUTATION_USER_SETTINGS, undefined);
                    reject(error);
                });
            } else {
                resolve(undefined)
            }
        })
    },

    updateUserWebAppSettings(context, settings: FrontendUserWebAppSettings): Promise<FrontendUserWebAppSettings> {
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;
            if (context.rootGetters.signedIn && as) {
                HTTP.post<FrontendUserWebAppSettings>("/sapi/user-config/update-user-web-app-settings", settings).then((response: FrontendUserWebAppSettings) => {
                    context.commit(MUTATION_USER_SETTINGS, response);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(undefined)
            }
        })
    },
};

/**
 * Returns the initial state of this store.
 */
function initialState(): UserWebAppSettingsState {
    return {
        userWebAppSettings: undefined,
    };
}

/**
 * This module handles the user web app configuration as well as the actions that are
 * possible to perform upon it.
 */
export const userWebAppSettingsModule: Module<UserWebAppSettingsState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {

        /**
         * Sets the state with the new config.
         *
         * @param state The state.
         * @param freeriders The new set of notifications.
         */
        [MUTATION_USER_SETTINGS]: (state: UserWebAppSettingsState, response: FrontendUserWebAppSettings) => {
            state.userWebAppSettings = response;
        },

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: UserWebAppSettingsState) => {
            resetState(state, initialState());
        },
    },
    actions: actions
};