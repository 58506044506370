
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "../mixins/state-helper";
import SvgConstants from "../mixins/svg-constants";
import PreflightLink from "@/components/PreflightLink.vue";
import {Global} from "@/global";
import {FrontendBvCase} from "@/models/frontend-bv-case";


/**
 * This component represents a single BV case in a case listing.
 */
@Component({
    components: {
        PreflightLink
    }
})
export default class SearchPageEntityBvCaseListingCase extends Mixins(StateHelper, SvgConstants) {
    @Prop()
    bvCase: FrontendBvCase;

    @Prop()
    bvCaseListingId: number;

    expanded: boolean = false;


    /**
     * Gets the url where a given case pdf can be downloaded.
     *
     * @param documentId The id of the document.
     */
    documentUrl(documentId: string): string {
        return Global.url("/sapi/bv-case-info/open/" + this.bvCaseListingId + "/" + documentId + this.subRefNoParam);
    }

    /**
     * Render the display text for a case in the list.
     */
    get renderHeader(): string {
        return this.bvCase.date + " Ärende " + this.bvCase.diaryNo + "/" + this.bvCase.diaryYear;
    }

    /**
     * Render the rubricering.
     */
    get rubricering(): string {
        return this.bvCase.rubricering.map(r => r.text).join(". ");
    }

    /**
     * Convenience method for creating a parameter "subscription" with the refNo
     * of the active subscription to be added to an url.
     */
    get subRefNoParam(): string {
        return "?subscription=" + this.activeSubscriptionRefNo;
    }
}
