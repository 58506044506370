
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import Utils from "@/mixins/utils";
    import SearchPageEntityInfoMap, {
        MapAddress,
        MapPosition,
        MapState
    } from "@/components/SearchPageEntityInfoMap.vue";

    /**
     * This component represents the expandable map in the info tabs for individuals and
     * companies. In order to handle resizing of the map properly, we use two separate
     * maps with a key that changes whenever the map is expanded or contracted. The reason
     * for this is that Leaflet seems to have difficulties finding its new size properly
     * if the div it resides in changes size, even when using the invalidateSize() method.
     * We also seem to get problems when using only v-if on the expandedMap variable so
     * that's why both the small and large maps are present in the DOM and only shown or
     * hidden depending on the expandedMap state.
     */
    @Component({
        components: {SearchPageEntityInfoMap}
    })
    export default class SearchPageEntityIndividualInfo extends Mixins(Utils) {
        @Prop()
        addresses: MapAddress[];

        /**
         * True when showing the expanded map and false when showing the default map.
         */
        expandedMap: boolean = false;

        /**
         * Store the state of the map here whenever the user clicks the expand button in
         * one of the maps.
         */
        mapPosition: MapPosition = null;


        /**
         * Getter for the map state to hand over to the sub maps.
         */
        get mapState(): MapState {
            return new MapState(this.mapPosition, this.addresses);
        }

        /**
         * A unique id that ensures the same map is not loaded more than once.
         */
        uniqueId(): string {
            return "id-" + Math.random() + "-" + new Date().getMilliseconds();
        }

        /**
         * In order to keep the location and zoom level when changing between the small
         * and large map, we receive an event with the current center and zoom level
         * whenever the user clicks the expand button in one of the maps. These parameters
         * are then stored in the map state given to the map to be shown. Since we have
         * given both maps a :key attribute that changes with the expandedMap variable,
         * they are rerendered and thus adapts to the given parameters.
         *
         * @param mapPosition The state of the map in which the user clicked the expand
         * button. This state will be passed on to the other map so it looks like the
         * state is preserved.
         */
        handleSizeChange(mapPosition: MapPosition): void {
            this.mapPosition = mapPosition;
            this.expandedMap = !this.expandedMap;
        }
    }
