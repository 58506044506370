
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultCompanyGroupTree
} from "@/models/search-result-company-group-tree";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import FMillions from "@/components/FMillions.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import FNum from "@/components/FNum.vue";

/**
 * This entity represents a row in the company group tree, containing
 * information about the company and a clickable link to open that company.
 * The code that handles checking if the link is active is similar to the
 * code in SearchPageEntityLink.
 */
@Component({
    name: 'SearchPageEntityCompanyGroupTree',
    components: {
        FNum,
        StatusIcon,
        FMillions,
        SelectOnClick,
        SearchPageEntityCompanyGroupTree,
        SearchPageEntityLink,
    }
})
export default class SearchPageEntityCompanyGroupTree extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    root: SearchResultCompanyGroupTree;

    @Prop()
    current: string;

    @Prop()
    level: number;

    @Prop()
    view: 'HIDE' | 'SIMPLE' | 'FULL';

    readonly GROUP: string = "companyGroup";

    collapsed: boolean = true;

    mounted() {
        // Setup the initial view
        this.handleViewChange(this.view);
    }

    @Watch("view")
    handleViewChange(newView: 'HIDE' | 'SIMPLE' | 'FULL') {
        switch (newView) {
        case 'HIDE':
            // No action neccessary, since we hide the whole tree anyway for this view.
            break;
        case 'SIMPLE':
            this.collapsed = !this.root.ancestorOfTarget;
            break;
        case 'FULL':
            this.collapsed = false;
            break;
        }
    }

    get showUncollapseButton() {
        return this.view === 'SIMPLE' && this.root.children.length !== 0 && !this.root.ancestorOfTarget;
    }

    /**
     * True if this link should be grayed out and not clickable. This should
     * happen if the company does not exist in our database, or the company
     * is the one already displayed.
     */
    get isNoLink(): boolean {
        return !this.root.id || this.root.id === this.current;
    }

    /**
     * True if this link should be marked as the current company.
     */
    get isCurrentCompany(): boolean {
        return this.root.id === this.current;
    }

    /**
     * True if this link is active. A link is active if its public id is shown in the
     * view to the right (that is - level + 1) and its unique id is stored at that
     * level in the details array.
     */
    get isActive(): boolean {
        let nextLevel = this.level + 1;
        return this.details.length > nextLevel
            && this.details[nextLevel].publicId === this.root.id
            && this.details[nextLevel].linkId === this.linkId;
    }

    /**
     * Gets a unique id for this link so we make sure we only get one ref. This is
     * also the id used in order to track if this is the clicked link when showing the
     * entity with this link's id in the view to the right.
     */
    get linkId(): string {
        return String(this.hashCode(this.GROUP + "-" + this.level + "-" + this.root.id));
    }
}
