
import {Component, Mixins} from 'vue-property-decorator';
import {HTTP} from "@/services/http-provider";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Utils from "@/mixins/utils";

/**
 * This component shows the terms of use bullet list, fetched from the server.
 */
@Component({
    components: {ErrorMessage}
})
export default class TermsOfUseBulletList extends Mixins(Utils) {
    termsOfUse: string[] = [];

    errorMessage: string = "";

    mounted() {
        HTTP.get<string[]>("/api/subscription/terms-of-use").then((terms: string[]) => {
            this.termsOfUse = terms;
        }).catch(response => {
            this.errorMessage = this.extractErrorMessage(response);
        });
    }
}
