
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import {DisplayableEvent} from "@/models/displayable-event";
import {
    ALLOW_LINE_BREAK_AFTER_DASH_FILTER,
    DATE_FILTER,
    GIVEN_NAME_FILTER,
    ID_NUMBER_FILTER,
    NUMBER_FILTER
} from "@/services/filters";
import Utils from "@/mixins/utils";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import {ClickEventData} from "@/views/SearchPage.vue";
import FNum from "@/components/FNum.vue";
import FormattedText from "@/components/FormattedText.vue";
import Fraction from "@/components/Fraction.vue";

/**
 * This component represents a single event in an event list for individuals or
 * companies.
 */
@Component({
    components: {
        FormattedText,
        FNum,
        SearchPageEntityLink,
        Fraction
    }
})
export default class SearchPageEntityEvent extends Mixins(StateHelper, Utils) {
    /**
     * The level at which this event occur, where 0 is the leftmost view etc.
     */
    @Prop()
    level: number;

    /**
     * The event.
     */
    @Prop()
    evt: DisplayableEvent;

    /**
     * A group identifying this event so that we can be able to track if it is this
     * link that has been clicked when the entity with this event's id is shown in the
     * view to the right.
     */
    @Prop()
    group: string;


    /**
     * Gets a unique id for this link in order to track if this is the clicked event
     * when showing the entity with this event's id in the view to the right.
     */
    get linkId(): string {
        return String(this.hashCode(this.group + "-" + this.evt.date + "-" + this.evt.type));
    }

    /**
     * Checks if the publicId in the payload of the event refers to an entity that is
     * currently viewed at the level to the right of the level where this event
     * resides. Notice that such clickable events must have a field "publicId" in
     * their payload, containing the public id of the entity to link to.
     */
    isActive(): boolean {
        let nextLevel = this.level + 1;
        return this.details.length > nextLevel && this.details[nextLevel].publicId === this.evt.payload.publicId && this.details[nextLevel].linkId === this.linkId;
    }

    /**
     * Checks if the publicId (or any of the publicId:s in the publicIds array)
     * in the payload of the event refers to the parent entity that spawned
     * this current entity. This is used in order to highlight all events
     * referring to the entity to the left.
     */
    isReferenced(): boolean {
        // If the parent of the current entity is the same as this event references to, then return true.
        if (this.details.length > this.level) {
            const parentLevel = this.details[this.level].parentLevel;
            if (parentLevel !== -1 && parentLevel !== null) {
                const parentPublicId = this.details[parentLevel].publicId;
                if(this.evt.renderableText) {
                    return this.evt.publicIds.indexOf(parentPublicId) !== -1;                    
                } else {
                    return parentPublicId === this.evt.payload.publicId || (this.evt.payload.publicIds && this.evt.payload.publicIds.indexOf(parentPublicId) !== -1);
                }
            }
        }
        return false;
    }

    /**
     * Replace the link's linkId with the linkId of this event, since it is the
     * event div that should be marked as active. 
     */
    emitOpenEntityView(event: ClickEventData<OpenEntityRequest>): void {
        event.data.linkId = this.linkId;
        this.$emit('open-entity-view', event);
    }

    /**
     * All events that do not require special templates are rendered in this
     * method, that returns pure html.
     */
    renderEvent(): string {
        return ALLOW_LINE_BREAK_AFTER_DASH_FILTER(this.renderEventInternal());
    }

    /**
     * Internal method only to be called by renderEvent().
     */
    private renderEventInternal(): string {
        return this.evt.displayText;
    }
}
