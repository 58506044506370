
    import {Component, Mixins} from 'vue-property-decorator';
    import SidebarRightCloseButton
        from "@/components/SidebarRightCloseButton.vue";
    import SvgConstants from "@/mixins/svg-constants";
    import CustomInput from "@/components/CustomInput.vue";
    import {SubscriptionParameters} from "@/models/end-user-subscription-parameters";
    import {
        MUTATION_ACTIVE_SUBSCRIPTION,
        MUTATION_SIDEBAR_RIGHT_STATUS
    } from "@/store/store";
    import Validation, {
        ValidationFunction,
        ValidationStatus
    } from "@/mixins/validation";
    import {HTTP} from "@/services/http-provider";
    import {EndUserSubscriptionOperationResponse} from "@/models/end-user-subscription-operation-response";
    import {AxiosResponse} from "axios";
    import StateHelper from "@/mixins/state-helper";
    import ErrorMessage from "@/components/ErrorMessage.vue";

    /**
     * This component represents where a user can edit company details in the
     * right sidebar.
     */
    @Component({
        components: {ErrorMessage, CustomInput, SidebarRightCloseButton},
    })
    export default class SidebarRightCompanyDetailsForm extends Mixins(SvgConstants, StateHelper, Validation) {
        /**
         * A copy of the currently active subscription. Will be transferred to the real
         * active subscription on successful update.
         */
        subscription: SubscriptionParameters = new SubscriptionParameters();

        /**
         * Set to true when we should show the success message after successful saving.
         */
        showSuccess: boolean = false;

        /**
         * Mapping from the name of the field in the SubscriptionParameters
         * object to the validation function for that field.
         */
        validation: any = {
            companyName: this.vAllOf(this.vAllowEmpty(this.vLength(1, 250)), this.externalValidation("companyName")),
            organisationNumber: this.vAllOf(this.vAllowEmpty(this.vModulo10CheckSum()), this.externalValidation("organisationNumber")),
            paymentEmail: this.vAllOf(this.vEmail(), this.vMaxLength(250), this.externalValidation("paymentEmail")),
            address1: this.vAllOf(this.vAllowEmpty(this.vLength(1, 250)), this.externalValidation("address1")),
            zip: this.vAllOf(this.vAllowEmpty(this.vZipCode()), this.externalValidation("zip")),
            city: this.vAllOf(this.vAllowEmpty(this.vLength(1, 250)), this.externalValidation("city")),
        };

        /**
         * Mapping from the name of the field to a human readable string. See
         * Validation#runValidationFunctions for more info.
         */
        private fieldName: any = {
            companyName: "Företagsnamnet",
            organisationNumber: "Organisationsnumret",
            paymentEmail: "E-postadress för betalning",
            address1: "Adressen",
            zip: "Postnumret",
            city: "Postorten",
        };

        /**
         * Mapping from the name of the field to an error message for that field. These
         * can be set both during frontend validation but also when receiving a validation
         * error message from the server. We also have a 'generic' mapping to which other
         * types of errors can be mapped. The error message shown below the fields is
         * composed of these messages. Notice that it is important to initialize all
         * mappings since Vue otherwise can't track when they change.
         */
        private complaints: any = {
            generic: "",
            companyName: "",
            organisationNumber: "",
            paymentEmail: "",
            address1: "",
            zip: "",
            city: "",
        };


        mounted() {
            this.updateModel(this.activeSubscription);
        }

        /**
         * True if we're not allowed to edit company details.
         */
        get disableEdit(): boolean {
            return !this.activeSubscription.owner;
        }

        /**
         * Compile an error message from our complaints map. Use the field names in the
         * fieldName map to produce a human readable text.
         */
        get errorMessage(): string {
            return this.createErrorMessage(this.complaints);
        }

        /**
         * Called when the user clicks the save button. Runs all validation functions and
         * if anyone fails, we store error messages in the complaints mapping mapped to
         * the name of the field.
         */
        save(): void {
            this.showSuccess = false;

            // Run all validation functions and store error messages for those that fail.
            this.runValidationFunctions(this.validation, this.complaints, this.fieldName, this.subscription);

            if (!this.errorMessage) {
                HTTP.post<EndUserSubscriptionOperationResponse>("/sapi/subscription/update-subscription", this.subscription).then((response: EndUserSubscriptionOperationResponse) => {
                    this.updateModel(response.subscriptionParameters);
                    this.$store.commit(MUTATION_ACTIVE_SUBSCRIPTION, response.subscriptionParameters);
                    this.showSuccess = true;
                    setTimeout(() => {
                        this.showSuccess = false;
                        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "menu");
                    }, 1500);
                }).catch((response: AxiosResponse) => {
                    this.handleErrorMessage(this.complaints, response.data);
                });
            }
        }

        /**
         * This method returns a validation function for the given field name that will
         * fail if there is a complaint for that field in the complaints map. Thus, when
         * we store complaints from the backend in that map, the corresponding field will
         * be marked as invalid as well.
         *
         * @param field The name of the field in the EndUserParameters object.
         */
        externalValidation(field: string): ValidationFunction {
            return () => this.complaints[field] ? ValidationStatus.FORCE_INVALID : ValidationStatus.VALID;
        }

        /**
         * Remove the error message for the given field (if it is still valid), as well as
         * any generic error message. This will be triggered on the change event for an
         * input field.
         *
         * @param fieldName The field in the SubscriptionParameters object.
         */
        fieldChange(fieldName: string) {
            this.onFieldChange(this.validation, this.complaints, fieldName, this.subscription);
        }

        private updateModel(subscription: SubscriptionParameters) {
            if (subscription) {
                this.subscription = JSON.parse(JSON.stringify(subscription));
            } else {
                this.subscription = new SubscriptionParameters();
            }
        }
    }
