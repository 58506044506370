/**
 * See the corresponding java class for more info.
 */
export abstract class AbstractErrorMessage {
    type: string;

    constructor(type: string) {
        this.type = type;
    }
}


/**
 * See the corresponding java class for more info.
 */
export class UserErrorMessage extends AbstractErrorMessage {
    errorType: string;

    errorHeader: string;

    errorMessage: string;


    constructor(errorType: string, errorMessage: string) {
        super("USER_MESSAGE");
        this.errorType = errorType;
        this.errorMessage = errorMessage;
    }
}

/**
 * This class represents a validation error message. These may be received from the server
 * but also created manually.
 */
export class ValidationErrorMessage extends AbstractErrorMessage {
    message: string;

    complaints: ValidationComplaint[];
}

/**
 * This class represents a validation error message. These may be received from the server
 * but also created manually.
 */
export class ValidationComplaint {
    /**
     * The name of the field the validation refers to.
     */
    path: string;

    /**
     * The actual error message to display to the user.
     */
    message: string;

    /**
     * Flags that may mark other types of characteristics for the complaint.
     */
    flags: string[];

}
