
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import {GIVEN_NAME_FILTER} from "@/services/filters";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import {
    SearchResultHouseholdMember
} from "@/models/search-result-household-member";
import SvgConstants from "@/mixins/svg-constants";
import StatusIcon from "@/components/StatusIcon.vue";
import StatusIconCell from "@/components/StatusIconCell.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";

/**
 * This component represents a list of named individuals.
 */
@Component({
    components: {
        StatusIconRow,
      StatusIconCell,
      StatusIcon, SearchPageEntityInfoHeader, SearchPageEntityLink}
})
export default class SearchPageEntityHouseholdMemberList extends Mixins(SvgConstants, Utils) {
    @Prop()
    individuals: SearchResultHouseholdMember[];

    @Prop()
    level: number;


    // noinspection JSMethodCanBeStatic
    /**
     * Helper for extracting the complete name of a household member.
     *
     * @param member The member.
     */
    hhMemberName(member: SearchResultHouseholdMember): string {
        if (member.secretIdentity) {
            return "Person med skyddad identitet";
        }
        let firstNames = GIVEN_NAME_FILTER(member.firstNames, member.givenNameCode, "extract-with-fallback");
        let middleAndLastNames = this.getMiddleAndLastNamesFromStrings(member.middleNames, member.lastNames);
        return `${firstNames} ${middleAndLastNames}`;
    }
}
