
import {Vue, Component, Prop} from 'vue-property-decorator';

/**
 * Creates an expandable dropdown header.
 */
@Component
export default class Dropdown extends Vue {
    @Prop()
    header: string;

    expand: boolean = false;

    get expandHeadingIcon(): string {
        if (this.expand) {
            return "Dölj" + " &#9661;";
        } else {
            return "Visa" + " &#9655;";
        }
    }
}
