import Vue from 'vue'
import App from './App.vue'
import router, {
    PATH_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_IN_KEEP_STATE_QUERY_NAME,
    KEEP_SIDEBAR_OPEN_QUERY_NAME
} from './router'
import store, {MUTATION_SIDEBAR_RIGHT_STATUS} from './store/store'
import './services/filters'
import {Global} from "@/global";
import {Route} from "vue-router";
import {EmailLoginApplicationState} from "@/models/email-login-application-state";
import ApplicationStateSerializer from "@/mixins/application-state-serializer";
import Utils from "@/mixins/utils";
/**
 * Some browsers don't support Promise.finally, so we use this shim.
 */
let promiseFinally = require('promise.prototype.finally');
promiseFinally.shim();


Vue.config.productionTip = false;

/*
  Load site specific css and set various meta tags and other site dependent
  attributes.
 */
Global.loadCSS(Global.choose(
    "/app/css/faktakontroll.css?v=" + Global.BUILD_HASH,
    "/app/css/kreditupplysning.css?v=" + Global.BUILD_HASH
));
Global.setBodyId(Global.choose(
    "faktakontroll",
    "kreditupplysning"
));
Global.setMetaTagContent("meta[name='description']", Global.choose(
    "Sökresultat",
    "Digitala kreditupplysningar, precis som de borde vara"
));


/**
 * Make sure we close the right sidebar when we navigate to a new url, except
 * when performing a new search when we're already on the search page.
 *
 * @param to The new route.
 * @param from The old route.
 * @param next Must call this in order to complete navigation.
 */
router.beforeEach((to: Route, from: Route, next: any) => {
    if(to.query[KEEP_SIDEBAR_OPEN_QUERY_NAME] !== "true") {
        store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "");
    }
    next();
});

/**
 * Navigate to the sign in page if we are going to the redirect sign in path.
 * If [REDIRECT_SIGN_IN_KEEP_STATE_QUERY_NAME]=true is a query parameter then
 * the current route state will be passed along to the login page
 * enabling the user to come back to current page after login.
 *
 * @param to The new route.
 * @param from The old route.
 * @param next Must call this in order to complete navigation.
 */
router.beforeEach((to: Route, from: Route, next: any) => {
    if (to.path === PATH_REDIRECT_SIGN_IN) {
        const keepState = REDIRECT_SIGN_IN_KEEP_STATE_QUERY_NAME;
        let stateString = "";
        if (keepState in to.query && to.query[keepState] === "true") {
            let state: EmailLoginApplicationState = ApplicationStateSerializer.createApplicationState(from);
            stateString = ApplicationStateSerializer.serializeState(state);
        }

        next({
            name: "home",
            params: {
                state: stateString
            }
        });
    } else {
        next();
    }
});

/**
 * Make sure we can't navigate to the /prova-gratis page if we're on
 * Kreditupplysning.se
 *
 * @param to The new route.
 * @param from The old route.
 * @param next Must call this in order to complete navigation.
 */
router.beforeEach((to: Route, from: Route, next: any) => {
    if (to.name === 'free-trial' && Global.isKreditupplysning()) {
        next("/");
    } else {
        next();
    }
});

Vue.directive('safeHtml', {
    bind: function (el, binding, vnode) {
        el.innerHTML= Utils.cleanStringFromHtml(binding.value);
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
