
import {Component, Mixins, Prop, Emit} from "vue-property-decorator";
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import {
    GIVEN_NAME_FILTER,
    ID_NUMBER_FILTER,
    TRUNCATE_FILTER
} from "@/services/filters";
import {ClickEventData, EntityContext} from "@/views/SearchPage.vue";
import Auth from "@/mixins/auth";
import MountAware from "@/mixins/mount-aware";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import {
    SearchResultEntryCompanyDetails
} from "@/models/search-result-entry-company-details";
import {
    SearchResultEntryRealPropertyDetails
} from "@/models/search-result-entry-real-property-details";
import {
    SearchResultEntryVehicleDetails
} from "@/models/search-result-entry-vehicle-details";
import {SearchResultBoardMember} from "@/models/search-result-board-member";
import SearchPageEntityIndividualInfo
    from "@/components/SearchPageEntityIndividualInfo.vue";
import SearchPageEntityCompanyInfo
    from "@/components/SearchPageEntityCompanyInfo.vue";
import SearchPageEntityIndividualIncome
    from "@/components/SearchPageEntityIndividualIncome.vue";
import SearchPageEntityCompanyRemarks
    from "@/components/SearchPageEntityCompanyRemarks.vue";
import SearchPageEntityCompanyDocuments
    from "@/components/SearchPageEntityCompanyDocuments.vue";
import SearchPageEntityCommonCredit
    from "@/components/SearchPageEntityCommonCredit.vue";
import SearchPageEntityCommonMonitor
    from "@/components/SearchPageEntityCommonMonitor.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import SearchPageEntityIndividualDocuments
    from "@/components/SearchPageEntityIndividualDocuments.vue";
import {
    SearchResultEntryAddressLocationDetails
} from "@/models/search-result-entry-address-location-details";
import SearchPageEntityAddressLocationInfo
    from "@/components/SearchPageEntityAddressLocationInfo.vue";
import SearchPageEntityRealPropertyInfo
    from "@/components/SearchPageEntityRealPropertyInfo.vue";
import SearchPageEntityVehicleInfo
    from "@/components/SearchPageEntityVehicleInfo.vue";
import SearchPageEntityRealPropertyDocuments
    from "@/components/SearchPageEntityRealPropertyDocuments.vue";
import Tooltip from "@/components/Tooltip.vue";
import {StyleValue} from "vue/types/jsx";
import ErrorMessage from "@/components/ErrorMessage.vue";

/**
 * A reference to an entity to be opened. Possible to specify which tab that should
 * be active after it has been opened.
 */
export class OpenEntityRequest {


    /**
     * The public id of the entity.
     */
    id: string;

    /**
     * The tab to show, for example "info" for the info tab etc.
     */
    tab?: string;

    /**
     * Level the parent entity is on or -1 if it should have no parent. Level must be level>=-1.
     */
    parentLevel: number;

    /**
     * Level that represent having no parent.
     */
    static get noParentLevel(): number {
        return -1;
    }

    /**
     * Boolean to represent if the parent should be replaced with this entity or not. Default is false.
     */
    replaceParent: boolean;

    /**
     * The id of the link that was clicked when this entity was opened. Used in order
     * to be able to keep track of which links that should be active.
     */
    linkId?: string;

    /**
     * The address location filter. If defined, it will be either an
     * apartment number any of the special values defined in
     * SearchPageEntityAddressLocationInfo.
     */
    addressLocationFilter?: number;


    constructor(id: string, parentLevel: number, linkId?: string) {
        this.id = id;
        this.linkId = linkId ? linkId : String(Utils.sHashCode(id));
        this.parentLevel = parentLevel;
        this.replaceParent = false;
    }
}

/**
 * This component represents the an entire search result details view.
 */
@Component({
    components: {
        ErrorMessage,
        Tooltip,
        SearchPageEntityRealPropertyDocuments,
        SearchPageEntityAddressLocationInfo,
        SearchPageEntityIndividualDocuments,
        SearchPageEntityCommonMonitor,
        SearchPageEntityCommonCredit,
        SearchPageEntityCompanyDocuments,
        SearchPageEntityIndividualIncome,
        SearchPageEntityCompanyRemarks,
        SearchPageEntityCompanyInfo,
        SearchPageEntityIndividualInfo,
        SearchPageEntityRealPropertyInfo,
        SearchPageEntityVehicleInfo,
        SelectOnClick
    }
})
export default class SearchPageEntity extends Mixins(Auth, MountAware, SvgConstants, Utils, StateHelper) {
    @Prop()
    level: number;

    @Prop()
    levelCount: number;

    @Prop()
    activeLevel: number;

    @Prop()
    maxLevelCount: number | null;

    @Prop()
    context: EntityContext;

    showCopyToolTip: boolean = false;

    /**
     * Gets the class name defining which tab to show.
     */
    get activeTabClass(): string {
        return "show_object_" + this.context.activeTab;
    }

    /**
     * Convenience getter for the entity's id.
     */
    get entryId(): string {
        return this.context.entity ? this.context.entity.id : null;
    }

    /**
     * Convenience getter for the individual.
     */
    get individual(): SearchResultEntryIndividualDetails {
        return this.context.entity.individual;
    }

    /**
     * Convenience getter for the company.
     */
    get company(): SearchResultEntryCompanyDetails {
        return this.context.entity.company;
    }

    /**
     * Convenience getter for the address location.
     */
    get addressLocation(): SearchResultEntryAddressLocationDetails {
        return this.context.entity.addressLocation;
    }

    /**
     * Convenience getter for the real property.
     */
    get realProperty(): SearchResultEntryRealPropertyDetails {
        return this.context.entity.realProperty;
    }

    /**
     * Convenience getter for the vehicle.
     */
    get vehicle(): SearchResultEntryVehicleDetails {
        return this.context.entity.vehicle;
    }

    /**
     * Returns the type of entity as a string.
     */
    get entityType(): string {
        if (this.individual) {
            return "Person";
        } else if (this.company) {
            return "Företag";
        } else if (this.addressLocation) {
            return "Adressplats";
        } else if (this.realProperty) {
            return "Fastighet";
        } else if (this.vehicle) {
            return "Fordon";
        } else {
            return "";
        }
    }

    /**
     * Returns true if we should show the income tab.
     */
    get showIncomeTab(): boolean {
        return this.individual && this.allowViewIncome;
    }

    /**
     * Returns true if we should show the "Anmärkning" tab.
     */
    get showRemarkCatalogTab(): boolean {
        return this.signedIn && this.allowViewRemarks && this.isCompanyInRemarkCatalog(this.company);
    }

    /**
     * Returns true if we should show the "Dokument" tab.
     */
    get showDocumentsTab(): boolean {
        if (this.individual) {
            return this.hasAnyIndividualDocumentPermissions;
        } else if (this.company) {
            return this.hasAnyCompanyDocumentPermissions;
        } else if (this.realProperty) {
            return this.hasAnyRealPropertyDocumentPermissions;
        } else {
            return false;
        }
    }

    /**
     * Returns true if we should show the "Dokument" tab for individuals.
     */
    get hasAnyIndividualDocumentPermissions(): boolean {
        return this.signedIn && (this.allowCreditReport || this.allowFetchIndividualReport || this.allowFetchIndividualCommitmentReport);
    }

    /**
     * Returns true if we should show the "Dokument" tab for companies.
     */
    get hasAnyCompanyDocumentPermissions(): boolean {
        let anyCompanySubscriptionDocuments = false;
        if (this.hasActiveSubscription) {
            let fetchAnnualReportsPermission = this.company.aktiebolag ? this.allowFetchAnnualReportAb : this.allowFetchAnnualReportNonAb;
            anyCompanySubscriptionDocuments =
                fetchAnnualReportsPermission ||
                this.allowFetchCompanyReport ||
                this.allowFetchCertificateOfRegistration ||
                this.allowFetchCertificateOfRegistrationEnglish ||
                this.allowFetchArticlesOfAssociation ||
                this.allowFetchCaseList ||
                this.allowFetchProtocol ||
                this.allowFetchCompanyMortgageReport ||
                this.allowFetchCompanyVehicleReport;
        }
        return this.signedIn && (this.allowCreditReport || anyCompanySubscriptionDocuments);
    }

    /**
     * Returns true if we should show the "Dokument" tab for real
     * properties.
     */
    get hasAnyRealPropertyDocumentPermissions(): boolean {
        return this.signedIn && (this.allowFetchRealPropertyReport || this.allowFetchRealPropertyTaxationReport || this.allowFetchTitleDeedAndHistoricalOwnerReport);
    }

    /**
     * Convenience getter for the board member to display in the header.
     * We take the first one with isPrincipal = true.
     */
    get headerBoardMember(): SearchResultBoardMember {
        return this.company.boardMembers.find(b => b.isPrincipal);
    }

    /**
     * Getter for the header board member's function, if any.
     */
    get headerFunction(): string {
        if (this.headerBoardMember && this.headerBoardMember.mainFunction) {
            return ", " + this.headerBoardMember.mainFunction;
        } else {
            return "";
        }
    }

    /**
     * Getter for the org. no. in the header.
     */
    get headerOrgNo(): string {
        return ID_NUMBER_FILTER(this.company.orgNo);
    }

    /**
     * Getter for the type and acreage in the header.
     */
    get formatRealPropertyTypeHeader(): string {
        let ret: string = this.realProperty.propertyType != "" ? this.realProperty.propertyType : "Okänt";

        if (this.realProperty.acreage != 0) {
            ret += ", " + this.formatNumber(this.realProperty.acreage) + "&nbsp;kvm";
        }

        return ret;
    }

    get formatVehicleHeader(): string {
        let yearString: string = this.vehicle.year ? ", " + this.vehicle.year : "";
        if (this.vehicle.color.toLowerCase() != "okänd") {
            return this.vehicle.color + " " + this.vehicle.make + yearString
        }
        return this.vehicle.make + yearString;
    }

    /**
     * Getter for orgNo and function, if any.
     */
    get orgNoAndFunction(): string {
        let ret: string = this.headerOrgNo;
        ret += this.headerFunction;
        return ret;
    }

    get staircaseStyle(): StyleValue {
        // If we have reached too many levels, then make a staircase
        if (this.maxLevelCount && this.levelCount > this.maxLevelCount) {
            const leftMarginPercent = (this.levelCount - this.maxLevelCount) / this.maxLevelCount / (this.levelCount - 1);
            return {
                'margin-left': this.level != 0 ? (-100 * leftMarginPercent) + '%' : null,
                'border-left': this.level != 0 ? '1px solid #e5e5e5' : null,
                'box-shadow': this.level == this.activeLevel ? '0 0 10px rgba(0,0,0,.5)' : null,
                'z-index': this.levelCount - Math.abs(this.activeLevel - this.level),
                'position': 'relative',
                'background-color': 'white',
            };
        } else {
            return null;
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Helper for producing the tab icon and text.
     */
    tabIconAndText(svgIcon: string, text: string): string {
        return "<div class='object_menu_button_active_marker'></div>" + svgIcon + "<span>" + text + "</span>";
    }

    /**
     * Helper for producing the tab icon and text for a tab that may have
     * alternate text depending on how many tabs we show.
     *
     * @param svgIcon The tab icon.
     * @param text The default text.
     * @param altText The alternate text.
     * @param useAltText True if we should use the alternate text.
     */
    tabIconAndTextWithAlternateText(svgIcon: string, text: string, altText: string, useAltText: boolean) {
        let textToUse = useAltText ? altText : text;
        return this.tabIconAndText(svgIcon, textToUse);
    }

    /**
     * Helper for producing the tab icon and text for the monitor tab.
     */
    monitorTabIconAndText(): string {
        if (this.signedIn && this.allowManageMonitor && this.hasAnyMonitorOn(this.context.entity.id)) {
            return this.tabIconAndText(this.svg.checkCircle, 'Bevakas');
        } else {
            return this.tabIconAndText(this.svg.bell, 'Bevaka');
        }
    }

    /**
     * Emits that the left close button was clicked.
     */
    @Emit()
    leftCloseClicked(level: number, event: PointerEvent): {
        level: number,
        event: PointerEvent
    } {
        return {level: level, event: event};
    }

    /**
     * Emits that the "close all to the right" button was clicked.
     */
    @Emit("close-all-to-the-right")
    closeAllToTheRight(level: number, event: PointerEvent): {
        level: number,
        event: PointerEvent
    } {
        return {level: level, event: event};
    }

    /**
     * Emits that the "move tab left" button was clicked.
     */
    @Emit("move-tab-left")
    moveTabLeft(level: number): number {
        return level;
    }

    /**
     * Emits that the "move tab right" button was clicked.
     */
    @Emit("move-tab-right")
    moveTabRight(level: number): number {
        return level;
    }

    /**
     * Emits that the center button was clicked.
     * @param level
     */
    @Emit()
    centerClicked(level: number): number {
        return level;
    }

    /**
     * Emits the active level.
     */
    @Emit("active-level")
    emitActiveLevel(level: number): number {
        return level;
    }

    /**
     * Change the active tab.
     */
    @Emit("active-tab")
    setActiveTab(newTab: string): string {
        return newTab;
    }

    /**
     * Closes the search details view by emitting a close-clicked event.
     */
    @Emit()
    closeClicked(event: PointerEvent): PointerEvent {
        return event;
    }

    /**
     * Creates the heading text depending on if the entity is a company or an
     * individual. For companies, we simply return the name and let css handle
     * possible truncation. For individuals, we try to fit in a decent
     * representation of the complete name in an approximate width, by for
     * example using initials in the first name and truncating the last name.
     *
     * @return The string containing a representation of first, middle and last names that
     * should fit nicely in the details heading.
     */
    createHeadingText(): string {
        if (this.company) {
            return this.company.name;
        } else if (this.addressLocation) {
            return this.addressLocation.streetAddress;
        } else if (this.realProperty) {
            return this.realProperty.municipality + " " + this.realProperty.propertyDesignation;
        }

        let i = this.individual;

        if (i.secretIdentity) {
            return "Person med skyddad identitet";
        }

        let maxLen: number = 45;

        let firstOrGiven: string = GIVEN_NAME_FILTER(i.firstNames, i.givenNameCode, "extract-with-fallback");
        let firstOrGivenWidth: number = this.approximateWidth(firstOrGiven);

        let middleAndLast = this.getMiddleAndLastNames(i);
        let middleAndLastWidth = this.approximateWidth(middleAndLast);

        // If the full name is too wide, we use initials instead.
        let useInitials: boolean = firstOrGivenWidth + middleAndLastWidth > maxLen - 1;
        if (useInitials) {
            firstOrGiven = GIVEN_NAME_FILTER(this.initials(i.firstNames), i.givenNameCode, "extract-with-fallback");
            firstOrGivenWidth = this.approximateWidth(firstOrGiven);
        }

        let widthLeft = maxLen - firstOrGivenWidth - 1;

        /*
          If the initials are too wide as well, we truncate them to 20 characters, a
          number chosen pretty randomly since this should really not happen.
         */
        if (widthLeft < 0) {
            firstOrGiven = TRUNCATE_FILTER(firstOrGiven, 20);
        }
        return firstOrGiven + " " + middleAndLast;
    }

    /**
     * Formats the personal number for the heading info text.
     */
    get headerPnr(): string {
        return ID_NUMBER_FILTER(this.individual.personalNumber, false);
    }

    get eventFilter(): string[] {
        return this.eventFilterForEntity(this.entryId);
    }

    get isNotOnlyTab(): boolean {
        return !(this.level === 0 && this.details.length === 1);
    }

    get hasTabToTheLeft(): boolean {
        return this.level > 0;
    }

    get hasTabToTheRight(): boolean {
        return this.level < this.details.length - 1;
    }

    /**
     * Creates the heading info text for individuals, except the pnr.
     */
    createHeadingInfoIndividual(): string {
        let i = this.individual;
        if (!i.id) {
            return "";
        }
        let pnrComma: string = i.personalNumber ? ", " : "";
        let gender: string = this.getGender(i.gender);
        if (i.deceased && i.ageWhenDeceased) {
            return pnrComma + gender + " avliden vid " + i.ageWhenDeceased + " års ålder";
        } else if (i.deceased) {
            return pnrComma + "Avliden " + gender.toLowerCase();
        } else if (i.deregistered) {
            return pnrComma + "Avregistrerad " + gender.toLowerCase();
        } else {
            return pnrComma + gender + " " + i.age + " år";
        }
    }

    /**
     * Gets the name of the board member to display in the header.
     */
    formatBoardMemberForHeader(): string {
        let member: SearchResultBoardMember = this.headerBoardMember;
        if (member) {
            // Truncate the member name if the total length is too long.
            let ret: string = this.getDisplayNameForBoardMember(member);
            let numChars: number = this.numCharsForApproximateWidth(ret, 46 - this.orgNoAndFunction.length);
            if (numChars < ret.length) {
                return TRUNCATE_FILTER(ret, numChars);
            }
            return ret;
        } else {
            return ""
        }
    }

    /**
     * Handles the case where the user clicks the individual in the company
     * header in order to open a new entity view.
     *
     * @param event The click event.
     * @param publicId The public id of the entity to open.
     */
    handleCompanyHeaderClick(event: PointerEvent, publicId: string): void {
        let entityReference: OpenEntityRequest = new OpenEntityRequest(publicId, this.level, String(this.hashCode("company-" + this.level)));
        this.emitOpenEntity({data: entityReference, pEvent: event});
    }

    /**
     * Emits an event with the id of the entity to open along with the tab to show.
     *
     * @param event The reference to open alongside with the event that triggered the opening.
     * @param tab The tab to show. Defaults to "info".
     */
    @Emit("open-entity-view")
    emitOpenEntity(event: ClickEventData<OpenEntityRequest>, tab: string = "info"): ClickEventData<OpenEntityRequest> {
        event.data.tab = tab;
        return event;
    }

    /**
     * Handles sharing of a link when clicking the share button.
     */
    handleShare(useShareApi: boolean): void {
        let nav: any = navigator;
        let link: string = document.location.origin + "/p/" + this.context.entity.encodedId;
        if (useShareApi) {
            /*
              Try to find the best share type available by using the canShare
              method if it is defined on the navigator object. Prefer url to
              text, but don't specify both since that seems to trigger a bug on
              iOS where the link is copied twice. If none is supported - just
              copy to the clipboard.
             */
            if (typeof nav.canShare !== "function") {
                nav.clipboard.writeText(link);
            } else if (nav.canShare({url: link})) {
                nav.share({url: link});
            } else if (nav.canShare({text: link})) {
                nav.share({text: link});
            } else {
                nav.clipboard.writeText(link);
            }
        } else {
            nav.clipboard.writeText(link).then(() => {
                this.showCopyToolTip = true;
                setTimeout(() => this.showCopyToolTip = false, 2000);
            });
        }
    }

    /**
     * True if we should show the spinner. That is - for slow results.
     */
    get showSpinner(): boolean {
        return this.isMounted && this.context.status === "slow";
    }
}
