import {SearchResultLineOfBusiness} from "@/models/search-result-line-of-business";
import {SearchResultSimpleCompany} from "@/models/search-result-simple-company";

/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
export class SearchResultEntryCompany extends SearchResultSimpleCompany {
    orgNo: string;

    form: string = "";

    formText: string = "";

    careOf: string;

    streetAddress: string;

    zip: string;

    city: string;

    secretIdentity: boolean;

    sanction: boolean;

    lineOfBusiness: SearchResultLineOfBusiness[] = [];
}
