
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import VueQrcode from 'vue-qrcode'
/**
 * This component renders a qr code based on the string passed.
 */
@Component({
    components: {VueQrcode}
})
export default class SimpleQr extends Mixins(Utils) {


    
    @Prop()
    qrString: string;
    
    @Prop()
    statusMessage: string;
    
    @Prop({default: false})
    forceSpinner: boolean;

    size: number = 150;
    
    color: any = { dark: '#000000ff', light: '#ffffffff' };
    
    type: string = "image/png";
    
    version: number = 10;
    
    imgSrc: string = "";
    
    quality: number = 1.0;
    
    margin: number = 2;
    
    onDataUrlChange(dataUrl: string) {
        this.imgSrc=dataUrl;
    }
    
    get containerStyle() {
        return "width:"+this.size+"px;height:"+this.size+"px;"
    }

    get paragraphStyle() {
        return "flex: 0 0 "+this.size+"px;"
    }
    
    get showMessage() {
        return !this.forceSpinner && !!this.statusMessage;
    }
    
    get showQr() {
        return !this.forceSpinner &&!!this.qrString && !this.showMessage;
    }
    
    get showSpinner() {
        return this.forceSpinner || (!this.qrString && !this.showMessage);
    }
}
