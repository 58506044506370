
    import {Component, Mixins} from 'vue-property-decorator';
    import StateHelper from "@/mixins/state-helper";

    /**
     * This component represents a mailto link to the customer support address
     * for respective site.
     */
    @Component
    export default class CustomerSupportEmailLink extends Mixins(StateHelper) {
    }
