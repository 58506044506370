
    import {Component, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";

    /**
     * This component represents the top right close button in a popup modal.
     */
    @Component({
        components: {}
    })
    export default class PopupRegistrationFormCloseButton extends Mixins(SvgConstants) {
    }
