
import {Component, Mixins} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";

/**
 * This component represents the footer text.
 */
@Component
export default class FooterText extends Mixins(StateHelper) {
}
