
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

/**
 * This component represents a progress bar. If the "loading" property has been
 * true for more than "delayMs" milliseconds, we show a progress bar. When the
 * "loading" property turns false, we do one of two things.
 *
 * 1) If we have loaded longer than delayMs, and hence are showing the progress
 *    bar, we wait for 500 ms before showing the contents, in order to let the
 *    progress bar finish.
 * 2) If we have not loaded for longer than delayMs, we show the content
 *    immediately.
 */
@Component
export default class ProgressBar extends Vue {
    /**
     * The text to the left of the progress.
     */
    @Prop({default: "Laddar"})
    text: string;

    /**
     * The time in ms before we show the progress bar.
     */
    @Prop({default: 300})
    delayMs: number;

    /**
     * True while loading.
     */
    @Prop()
    loading: boolean;

    /**
     * Will be set to true when we have been in the loading state long enough.
     */
    hasLongLoadTime: boolean = false;

    /**
     * Will be set to true when the content should be faded in.
     */
    showContent: boolean = false;


    mounted(): void {
        if (this.loading) {
            // Show the progress bar after the specified delay.
            setTimeout(() => this.hasLongLoadTime = true, this.delayMs);
        } else {
            this.showContent = true;
        }
    }

    @Watch("loading")
    onVisibleChange(val: boolean): void {
        if (!val) {
            if (this.hasLongLoadTime) {
                /*
                  Wait for a while before we show the content, so the progress
                  bar has time to finish.
                 */
                setTimeout(() => this.showContent = true, 500);
            } else {
                // Show content immediately.
                this.showContent = true;
            }
        }
    }
}
