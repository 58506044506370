import {EntityStatus} from "@/models/entity-status";

/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
export class SearchResultSimpleCompany {
    id: string;

    name: string;

    active: boolean;

    status: EntityStatus;

    statusDate: string;

    turnover: number;

    earningsAfterNetInterestIncome: number;

    numEmployees: number;
}
