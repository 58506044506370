import Vue from "vue"
import {UTILS} from "@/mixins/utils";


/**
 * Enumeration of various date and time formats.
 */
export enum TimestampFormat {
    /**
     * yyyy-MM-dd HH:mm:ss
     */
    DATE_TIME_SECONDS,

    /**
     * yyyy-MM-dd HH:mm
     */
    DATE_TIME_MINUTES,

    /**
     * yyyy-MM-dd
     */
    DATE,
}

let formatTimestamp = (value: string, format: TimestampFormat): string => {
    if (!value) {
        return "";
    }
    // Parse the date
    let year: string;
    let month: string;
    let date: string;
    if (value.length === 8) {
        year = value.substr(0, 4);
        month = value.substr(4, 2);
        date = value.substr(6, 2);
    } else {
        year = value.substr(0, 4);
        month = value.substr(5, 2);
        date = value.substr(8, 2);
    }
    // Default values
    let hours = "00";
    let minutes = "00";
    let seconds = "00";
    if (value.length >= 19) {
        hours = value.substr(11, 2);
        minutes = value.substr(14, 2);
        seconds = value.substr(17, 2);
    }

    switch (format) {
        case TimestampFormat.DATE_TIME_SECONDS:
            return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
        case TimestampFormat.DATE_TIME_MINUTES:
            return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes;
        case TimestampFormat.DATE:
            return year + '-' + month + '-' + date;
        default:
            return value;
    }
};

/**
 * This filter makes it possible to format a date. It expects input on the form
 * yyyy-MM-dd HH:mm:ss, yyyy-MM-dd or yyyyMMdd and produces a date on the format
 * yyyy-MM-dd.
 *
 * Usage:
 *   value | date
 * Example:
 *   "2016-04-27 11:45:03" | date
 * Formats to:
 *    2016-04-27
 */
export const DATE_FILTER = (value: string): string => {
    return formatTimestamp(value, TimestampFormat.DATE);
};
Vue.filter("date", DATE_FILTER);

/**
 * This filter makes it possible to format a timestamp. It expects input on the
 * form yyyy-MM-dd HH:mm:ss.SSS and produces a timestamp on the chosen format.
 *
 * Usage:
 *   value | timestamp
 * Example:
 *   "2016-04-27 11:45:03.123" | timestamp
 * Formats to:
 *    2016-04-27 11:45:03
 * And
 *   "2016-04-27 11:45:03.123" | timestamp(TimestmpFormat.DATE_TIME_MINUTES)
 * Formats to:
 *    2016-04-27 11:45
 */
export const TIMESTAMP_FILTER = (value: string, format: TimestampFormat = TimestampFormat.DATE_TIME_SECONDS): string => {
    return formatTimestamp(value, format);
};
Vue.filter("timestamp", TIMESTAMP_FILTER);

/**
 * This filter formats id numbers to the format YYMMDD-XXXX or YYYYMMDD-XXXX if
 * specified and the number is 12 digits.
 *
 * If the given string is not 10 or 12 digits, no formatting will be performed.
 *
 * Usage:
 *   value | idNumber(longForm?)
 * Example:
 *   '197001010011' | idNumber
 * Formats to:
 *   '700101-0011'
 * Example:
 *   '197001010011' | idNumber(true)
 * Formats to:
 *   '19700101-0011'
 */
export const ID_NUMBER_FILTER = (value: string | number, longForm: boolean = false): string => {
    // Check if it's even worth trying to do anything
    if (!value) {
        return "";
    }
    let strVal: string = String(value);

    if (strVal.length == 10) {
        return strVal.substr(0, 6) + "-" + strVal.substr(6, 4);
    } else if (strVal.length == 12) {
        if (longForm) {
            return strVal.substr(0, 8) + "-" + strVal.substr(8, 4);
        }
        return strVal.substr(2, 6) + "-" + strVal.substr(8, 4);
    }
    return strVal;
};
Vue.filter("idNumber", ID_NUMBER_FILTER);


/**
 * This filter truncates strings or parts of strings and appends an ellipsis
 * character at the end of all truncated parts. String parts are separated by
 * space character. Leading and trailing spaces will be removed. It is also
 * possible to ignore the ellipsis.
 *
 * Usage:
 *   value | truncate(<num chars>, <consider parts - defaults to false>,
 * <ignore ellipsis - defaults to false>) Example:
 *   "abcdefg abcd" | truncate(4, true)
 * Formats to:
 *   "abcd… abcd"
 * Example:
 *   "abcdefg abcd" | truncate(4)
 * Formats to:
 *   "abcd…"
 * Example:
 *   "abcdefg abcd" | truncate(4, false, true)
 * Formats to:
 *   "abcd"
 */

export const TRUNCATE_FILTER = (value: string, numChars: number = -1, parts: boolean = false, ignoreEllipsis: boolean = false): string => {
    if (!value) {
        return value;
    }

    let ellipsisOrNot: string = ignoreEllipsis ? "" : "…";

    // Tread each part of the string as a separate string.
    if (parts) {
        let result: string = "";
        value.split(" ").forEach((part: string) => {
            if (part.length > numChars) {
                part = part.substr(0, numChars) + ellipsisOrNot;
            }
            result += part + " ";
        });

        return result.trim();
    } else {
        return value.length <= numChars ? value : value.trim().substr(0, numChars) + ellipsisOrNot;
    }
};
Vue.filter("truncate", TRUNCATE_FILTER);

/**
 * This filter makes it possible to generate formatted html strings that
 * contain given names. It also opens up the possibility to simply extract the
 * given name from a list of first names as well as extract the given name with
 * a fallback to the list of first names.
 *
 * No formatting will be done if the givenNameCode is not a string that
 * contains exactly 2 numbers.
 *
 * When formatting, the given names will be made bold with the <strong>-tag.
 * You need to use it with the v-html property to get proper formatting of the
 * html. A special case is when there is exactly one name and given name code
 * "00", in which case we consider it a given name and thus formats it
 * accordingly.
 *
 * I.E: <span v-html=" 'firstNames' | givenName('givenNameCode') "></span>
 *
 * Usage:
 *   value | givenName(<givenNameCode as string>[,"format"|"extract"|"extract-with-fallback"])
 * Example:
 *   'Svenne Lars Rune' | givenName:'20'
 * Formats to:
 *   'Svenne <strong>Lars</strong> Rune'
 * and
 *   'Svenne Lars Rune' | givenName('23')
 * Formats to:
 *   'Svenne <strong>Lars-Rune</strong>'
 * while:
 *   'Svenne Lars Rune' | givenName('23', 'extract')
 * returns:
 *   'Lars-Rune'
 * and:
 *   'Svenne Lars Rune' | givenName('00', 'extract')
 * returns the empty string. However:
 *   'Svenne Lars Rune' | givenName('00', 'extract-with-fallback')
 * returns:
 *   'Svenne Lars-Rune'
 */
const TWO_DIGITS_ONLY_REGEX: RegExp = new RegExp("^[0-9][0-9]$");
export const GIVEN_NAME_FILTER = (value: string, givenNameCode: string, action: string = "format"): string => {
    // Check if it's even worth trying to do anything
    if (!value) {
        return "";
    }
    if (!givenNameCode || givenNameCode.length != 2 || !TWO_DIGITS_ONLY_REGEX.test(givenNameCode)) {
        return value;
    }

    let tokens = value.split(/[ -]/);
    let first = Number(givenNameCode.charAt(0));
    let second = Number(givenNameCode.charAt(1));

    let toJoin = [];
    let toReturn = [];
    let skip = false;
    let firstHandled = false;
    let secondHandled = false;
    for (let i = 0; i < tokens.length; i++) {
        if (skip) {
            skip = false;
            continue;
        }
        if (!firstHandled && first === (i + 1)) {
            let attemptDoubleName = `${tokens[first - 1]}-${tokens[second - 1]}`;
            if (second !== 0 && value.indexOf(attemptDoubleName) >= 0) {
                toJoin.push(`<b>${attemptDoubleName}</b>`);
                toReturn.push(attemptDoubleName);
                secondHandled = true;
                // We want to skip the next iteration, since we have already handled it
                skip = true;
            } else {
                toJoin.push(`<b>${tokens[i]}</b>`);
                toReturn.push(`${tokens[i]}`);
            }
            firstHandled = true;
        } else if (!secondHandled && second === (i + 1)) {
            toJoin.push(`<b>${tokens[i]}</b>`);
            toReturn.push(`${tokens[i]}`);
            secondHandled = true;
        } else {
            toJoin.push(tokens[i]);
        }
    }
    if (action === "format") {
        if (toJoin.length === 1 && givenNameCode === "00") {
            /*
              If we only have one name but no given name code we consider it a
              given name anyway.
             */
            return `<b>${toJoin[0]}</b>`
        } else if (givenNameCode == "00") {
            /*
              If the given name can't be deduced we return the original string,
              to preserve all names as well as hyphens in double names.
             */
            return value;
        }
        return toJoin.join(" ");
    } else if (action === "extract") {
        return toReturn.join(" ");
    } else if (action === "extract-with-fallback") {
        return toReturn.length > 0 ? toReturn.join(" ") : value;
    }
    return value;
};
Vue.filter("givenName", GIVEN_NAME_FILTER);

/**
 * This filter formats the given number according to Swedish locale. It is
 * possible to specify a fixed number of decimals, should it be required.
 */
export const NUMBER_FILTER = (value: string | number, numFixedDecimals?: number, maxValueForDecimals?: number): string => {
    if (!value && value !== 0) {
        return "";
    }
    value = Number(value);
    if (maxValueForDecimals && Math.abs(value) >= maxValueForDecimals) {
        value = Math.round(Number(value));
        numFixedDecimals = 0;
    }

	return FORMAT_NUMBER(Number(value), numFixedDecimals);
};
Vue.filter("number", NUMBER_FILTER);

/**
 * This filter scales the given number to millions and formats it with
 * an appropriate number of decimals (zero if larger than 1000 millions,
 * one if larger than 100 thousand and two otherwise).  
 */
export const MILLIONS_FILTER = (value: string | number): string => {
    if (!value && value !== 0) {
        return "";
    }
    value = Number(value);
    let absAmount: number = Math.abs(value);
    let numFixedDecimals: number;
    if (absAmount >= 1000000000) {
        numFixedDecimals = 0;
    } else if (absAmount >= 100000) {
        numFixedDecimals = 1;
    } else if (absAmount > 0) {
        numFixedDecimals = 2;
    } else {
        numFixedDecimals = 1;
    }
    return NUMBER_FILTER(value / 1000000, numFixedDecimals);
};
Vue.filter("millions", MILLIONS_FILTER);

/**
 * This filter formats the given amount of money. It uses Swedish locale as base
 * but scales the amount so that the number does not get too large. It also adds
 * a unit to the end of the string, such as kkr, mkr etc.
 *
 * It is possible to specify a fixed number of decimals, should it be required.
 * If the number of decimals is not specified it is chosen based on the final
 * number, with rules similar to that of MILLIONS_FILTER.
 */
export const MONEY_FILTER = (value: string | number, numFixedDecimals?: number): string => {
    if (!value && value !== 0) {
        return "";
    }
    let number: number = Number(value);
    let unit: string;

    if (number > 500000) {
        number = number / 1000000;
        unit = "mkr";
    } else if (number > 100000) {
        number = number / 1000;
        unit = "tkr";
    } else if (number > -100000) {
        unit = "kr";
        // No amounts less than one SEK.
        numFixedDecimals = 0;
    } else if (number > -500000) {
        number = number / 1000;
        unit = "tkr";
    } else {
        number = number / 1000000;
        unit = "mkr";
    }
    
    if (!numFixedDecimals) {
        let absNumber: number = Math.abs(number);
        if (absNumber >= 1000) {
            numFixedDecimals = 0;
        } else if (absNumber >= 0.1) {
            numFixedDecimals = 1;
        } else if (absNumber > 0) {
            numFixedDecimals = 0;
        } else {
            numFixedDecimals = 1;
        }
    }

	return FORMAT_NUMBER(number, numFixedDecimals)+ "\u00A0" + unit;
};
Vue.filter("money", MONEY_FILTER);


/**
 * This filter converts a falsy argument to an em dash. All other values are
 * left untouched.
 */
export const EM_DASH_ON_FALSY_FILTER = (value: any): string => {
    return value ? value : UTILS.EM_DASH;
};
Vue.filter("emDashOnFalsy", EM_DASH_ON_FALSY_FILTER);


/**
 * This filter handles financial periods. It expects a date on the format
 * yyyy-MM-dd and returns yyyy-mm unless the month is 12, in which case yyyy is
 * returned. It is also possible to force returning only the year, in cases
 * where we don't have space for more.
 */
export const PERIOD_FILTER = (value: any, forceYear: boolean = false): string => {
    let val: string = String(value).replace(/-/g, "");

    // We only handle dates of correct length.
    if (val.length != 8) {
        return val;
    }

    if (forceYear || val.substr(4, 2) === "12") {
        return val.substr(0, 4);
    } else {
        return val.substr(0, 4) + "-" + val.substr(4, 2);
    }
};
Vue.filter("period", PERIOD_FILTER);

export const MONITOR_PRIORITY_FILTER = (value: string, allowOff: boolean = false): string => {
    switch(value) {
        case "HIGH":
            return "Hög";
        case "NORMAL":
            return "Normal";
        case "LOW":
            return "Låg";
        case "OFF":
            return allowOff ? "Av" : "";
        default:
            return "";
    }
};
Vue.filter("monitorPriority", MONITOR_PRIORITY_FILTER);

export const DISPLAY_MAX_NUMBER_FILTER = (num: number, max: number = 99): string => {
    if(num >= max) {
        return max + "+";
    }
    return num.toString();
};
Vue.filter("displayMaxNumber", DISPLAY_MAX_NUMBER_FILTER);

/**
 * Tries to shorten the given company form, using a given set of rules.
 */
export const COMPANY_FORM_ABBREV_FILTER = (value: string): string => {
    if (!value) {
        return value;
    }
    
    return value.replace(/Enskild näringsidkare/gi, "Enskild firma")
}

const THIN_SPACE: string = "\u2009";
const NON_BREAKING_SPACE: string = "\u00A0";
const FORMAT_NUMBER = (num: number, numFixedDecimals: number=0): string => {
	return num.toLocaleString("SV", {
        minimumFractionDigits: numFixedDecimals,
        maximumFractionDigits: numFixedDecimals
    }).split(NON_BREAKING_SPACE).join(THIN_SPACE);
}

/**
 * This filter adds a zero-width-space after each '/' character, in order to
 * allow line breaks after '/'. Notice that we have don't want to do this for
 * html end tags, and since this filter may be run on html we have to take care
 * of that case as well.
 */
export const ALLOW_LINE_BREAK_AFTER_DASH_FILTER = (value: any): string => {
    return value ? value.replace(/([^<])\//g, "$1/" + UTILS.ZERO_WIDTH_SPACE) : value;
};
Vue.filter("allowLineBreakAfterDash", EM_DASH_ON_FALSY_FILTER);
