
    import {Component, Vue} from 'vue-property-decorator';
    import {Global} from "@/global";

    /**
     * This component represents a tel link to the customer service phone
     * number.
     */
    @Component
    export default class CustomerSupportPhoneNumber extends Vue {
        /**
         * Gets the site dependent phone number.
         */
        get phoneNumber(): string {
            return Global.choose("010-585 15 00", "010-585 54 00");
        }

        /**
         * Gets the site dependent phone number link content.
         */
        get phoneNumberLink(): string {
            return "tel:" + this.phoneNumber.replace(/[^0-9]/g, "");
        }
    }
