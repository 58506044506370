/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
import {SimpleIndividual} from "@/models/simple-individual";
import {SearchResultHousingInfo} from "@/models/search-result-housing-info";

export class SearchResultIndividual extends SimpleIndividual {
    id: string = "";
    birthDate: string = "";
    fbfCareOf: string = "";
    fbfAddressPrefix: string = "";
    fbfStreetAddress: string = "";
    fbfZip: string = "";
    fbfCity: string = "";
    fbfRegion: string = "";
    fbfARegionDescription: string = "";
    spCareOf: string = "";
    spAddressPrefix: string = "";
    spStreetAddress: string = "";
    spZip: string = "";
    spCity: string = "";
    foreignAddress1: string = "";
    foreignAddress2: string = "";
    foreignAddress3: string = "";
    foreignCountry: string = "";
    age: number;
    deceased: boolean;
    ageWhenDeceased: number;
    deregistered: boolean;
    deregisteredDate: string = "";
    householdHousingType: string = "";
    householdCompositionType: string = "";
    housingInfo: SearchResultHousingInfo;
    pepType: string;
    hasSanction: boolean;
    hasTradingProhibition: boolean;
}
