
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "../mixins/svg-constants";
import {DisplayableDocument} from "@/models/displayable-document";
import {Global} from "@/global";
import Auth from '@/mixins/auth';
import StateHelper from '@/mixins/state-helper';
import PreflightLink from "@/components/PreflightLink.vue";
import Utils from "@/mixins/utils";

/**
 * This component represents the list of documents of a given type.
 */
@Component({
    components: {PreflightLink}
})
export default class SearchPageEntityDocumentListEntry extends Mixins(Auth, StateHelper, SvgConstants, Utils) {
    @Prop()
    doc: DisplayableDocument;

    showConfirmRemove: boolean = false;


    buildUrl() {
        let path: string = "/sapi/documents/open/" + this.doc.id;
        if (this.activeSubscriptionRefNo) {
            path = path + "?subscription=" + this.activeSubscriptionRefNo;
        }
        return Global.url(path);
    }

    doShowConfirmRemove(): void {
        if (this.showConfirmRemove) {
            return;
        }
        this.showConfirmRemove = true;
        this.$emit("show-confirm");
    }

    doHideConfirmRemove(): void {
        if (!this.showConfirmRemove) {
            return;
        }
        this.showConfirmRemove = false;
        this.$emit("hide-confirm")
    }

    deleteDocument(): void {
        this.showConfirmRemove = false;
        this.$emit("delete-document");
    }
}
