
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";


/**
 * This component represents a custom radio button.
 */
@Component
export default class CustomRadioButton extends Mixins(SvgConstants) {
    /**
     * The label.
     */
    @Prop()
    label: string;

    /**
     * The label.
     */
    @Prop()
    checked: boolean;


    /**
     * The svg to display.
     */
    get buttonSvg(): string {
        return this.checked ? this.svg.radioChecked : this.svg.radioUnchecked;
    }
}
