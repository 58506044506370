
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from '@/mixins/state-helper';
import {HTTP} from "@/services/http-provider";

/**
 * This component wraps a link and performs an extra preflight request before
 * handling the original click, if the auth token is about to expire. This can
 * be useful in order to properly refresh invalid tokens before following the
 * link, since link clicks are not handled by our Http component, and hence are
 * not subject to ordinary refresh functionality.
 * <p>
 * If for some reason the preflight request fails a "preflight-failed" event is
 * emitted, with the response as argument.
 */
@Component
export default class PreflightLink extends Mixins(StateHelper) {
    static idCounter: number = 1;

    @Prop()
    url: string;

    @Prop()
    target: string;

    reference: string = new Date().getTime() + "_" + PreflightLink.idCounter++;


    /**
     * The first click should have a target different from our hidden link. If
     * that is the case, we perform the preflight request, and if it succeeds,
     * we trigger a click on the hidden "real" link.
     *
     * @param event The event.
     */
    preflightAndClick(event: PointerEvent): void {
        let target: HTMLElement = event.target as HTMLElement;
        if (target.id !== this.reference) {
            // The click is not on our hidden link - let's preflight!
            event.preventDefault();

            // Find our hidden link.
            let element: HTMLElement = this.$refs[this.reference] as HTMLElement;

            // We're only allowed to download anything if we have some authInfo.
            if (this.expiresAt) {
                /*
                  Only do a preflight if it's less than 2 seconds until our
                  access token expires. If our token is still valid, but it is
                  less than 2 seconds until it expires, we wait the remaining
                  time before doing a preflight, so that we're sure the token is
                  really expired when the preflight fires.
                 */
                let timeLeftUntilTokenExpiresInMs = Math.max(this.expiresAt.getTime() - new Date().getTime(), 0);
                if (timeLeftUntilTokenExpiresInMs < 2000) {
                    setTimeout(() => {
                        HTTP.get<void>("/sapi/preflight").then(() => {
                            /*
                              Successful preflight means we now we have tokens,
                              so let's trigger a click on the link.
                             */
                            element.click();
                        }).catch(response => {
                            this.$emit("preflight-failed", response);
                        });
                    }, timeLeftUntilTokenExpiresInMs);
                } else {
                    // We have an auth token - no need to preflight.
                    element.click();
                }
            }
        }
    }
}
