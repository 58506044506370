
import {Component, Mixins} from 'vue-property-decorator';
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import {MUTATION_SIDEBAR_RIGHT_STATUS} from "@/store/store";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import {
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {ID_NUMBER_FILTER} from "@/services/filters";
import {signOutAllRedirect, signOutRedirect} from "@/services/auth-service";
import ApplicationStateSerializer from "@/mixins/application-state-serializer";

/**
 * This is the component that handles the top level menu in the right sidebar.
 */
@Component({
    components: {SidebarRightCloseButton},
})
export default class SidebarRightMenu extends Mixins(StateHelper, SvgConstants, Utils, ApplicationStateSerializer) {
    /**
     * Gets calculated user complete name.
     */
    get userCompleteName(): string {
        if (!this.activeUser) {
            return "";
        }
        return this.activeUser.firstName + " " + this.activeUser.lastName;
    }

    /**
     * Gets name and possibly orgNo.
     */
    get nameAndOrgNo(): string {
        let ret: string = this.activeSubscription.companyName;
        if (this.activeSubscription.organisationNumber) {
            ret += ", " + ID_NUMBER_FILTER(this.activeSubscription.organisationNumber);
        }
        return ret;
    }

    /**
     * Renders the name of the user. If there is a pnr, it is rendered as well.
     */
    get nameAndPnrIfAny(): string {
        let ret: string = this.userCompleteName;
        if (this.hasPnr) {
            ret += ", " + ID_NUMBER_FILTER(this.activeUser.personalNumber);
        }
        return ret;
    }

    close(): void {
        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "");
    }

    /**
     * Sign out the user. Dont save current state if they sign in again.
     */
    signOut(): void {
        // Close the right sidebar.
        this.close();
        signOutRedirect();
    };


    /**
     * Sign out all sessions for this user. Don't save current state if they
     * sign in again.
     */
    signOutAll(): void {
        // Close the right sidebar.
        this.close();
        signOutAllRedirect();
    }

    /**
     * Returns true if we should show the freerider list. Don't show it if the
     * user is not the owner of the subscription, or if the package does not
     * allow extra users.
     */
    get showFreeriderList(): boolean {
        let as: SubscriptionParameters = this.activeSubscription;
        return as && as.owner && as.numAllowedUsers > 1;
    }

    changeRightSidebarStatus(newStatus: string): void {
        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, newStatus);
    }

    /**
     * Closes the sidebar, removes the user cookie and navigates to the given
     * path of the outer app, that is - without the /app.
     *
     * @param path The path.
     */
    closeAndNavigateTo(path: string): void {
        this.close();
        this.removeUserCookieAndRedirectToOuterApp(path);
    }
}
