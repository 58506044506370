
import {Component, Mixins} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import {Global} from "@/global";
import SignIn from "@/components_v2/SignIn.vue";
import Subscribe from "@/components_v2/Subscribe.vue";
import Utils from "@/mixins/utils";
import FooterText from "@/components/FooterText.vue";
import InactiveSubscription from "@/components_v2/InactiveSubscription.vue";

@Component({
    components: {InactiveSubscription, Subscribe, SignIn, FooterText}
})
export default class HomePage extends Mixins(StateHelper, Utils) {
    // noinspection JSMethodCanBeStatic
    mounted(): void {
        Global.setPrefixedTitle("");
    }

    /**
     * True if we're actually on the home page. Necessary since we don't
     * want the "new" start page for the message page where we show error
     * messages and messages like 'Inloggad i annan flik'.
     */
    get isPureHomePage(): boolean {
        return this.popupMessageStatus === "" && !this.startsWithAnyOf(this.$route.path, ["/inbjudan"]);
    }

    /**
     * Only show the inactive subscription message if we don't have an active
     * subscription AND we're not showing another full screen message.
     */
    get showInactiveSubscription(): boolean {
        return !this.hasActiveSubscription && this.popupMessageStatus === "";
    }
}
