
    import {Component, Mixins} from 'vue-property-decorator';
    import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
    import VisibleAware from "@/mixins/visible-aware";
    import SidebarRightCompanyDetailsForm
        from "@/components/SidebarRightCompanyDetailsForm.vue";

    /**
     * This component represents the list of monitors in the right sidebar.
     */
    @Component({
        components: {
            SidebarRightCompanyDetailsForm,
            SidebarRightCloseButton
        },
    })
    export default class SidebarRightCompanyDetails extends Mixins(VisibleAware) {
    }
