/**
 * This class corresponds to the java class with the same name. See that class for more
 * documentation.
 */
export class SearchResultFinancial {
    periodStart: string;

    periodEnd: string;

    omsattning: number;

    cashFlowAffectingEBDTA: number;

    aretsResultat: number;

    summaTillgangar: number;

    egetKapital: number;

    kassaOchBank: number;

    langfristigaSkulder: number;

    kortfristigaSkulder: number;

    rorelseresultat: number;

    resultatEfterFinansnetto: number;

    utdelning: number;

    antalAnstallda: number;

    personalKostnader: number
}
