
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from '@/mixins/state-helper';
import {
    RenderableText, RenderableTextPart
} from "@/models/renderable-text";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import Fraction from "@/components/Fraction.vue";
import {ClickEventData} from "@/views/SearchPage.vue";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";

/**
 * This component represents a text consisting of separate parts, where each
 * part may carry extra information about how to be rendered.
 */
@Component({
    components: {SearchPageEntityLink, Fraction}
})
export default class FormattedText extends Mixins(StateHelper) {
    static idCounter: number = 1;

    @Prop()
    renderableText: RenderableText;

    @Prop()
    level: number;

    @Prop()
    group: string;


    classMap(text: RenderableTextPart): any {
        let ret: any = {};
        if (!text.props) {
            return ret;
        }
        if (text.props.includes("b")) {
            ret["bold"] = true;
        }
        if (text.props.includes("i")) {
            ret["italic"] = true;
        }
        return ret;
    }

    styleMap(text: RenderableTextPart): any {
        let ret: any = {};
        if (!text.props) {
            return ret;
        }
        if (text.props.includes("nb")) {
            ret["white-space"] = "nowrap";
        }
        return ret;
    }
    
    get asFrac(): boolean {
        return this.realPropertyOwnershipAsFraction;
    }
    
    /**
     * Adds the apartment number as the address location filter, then emits a new event.
     */
    addApartment(event: ClickEventData<OpenEntityRequest>, apartment: string = undefined): void {
        event.data.addressLocationFilter = apartment ? Number(apartment) : undefined;
        this.$emit('link-clicked', event);
    }
}
