
import {Component, Mixins, Watch} from 'vue-property-decorator';
import SearchPageResultListEntry
    from "@/components/SearchPageResultListEntry.vue";
import {Route} from "vue-router";
import {
    ACTION_GET_ENTITY_FILTER,
    ACTION_PERFORM_SEARCH,
    EntityViewRef,
    MUTATION_DETAILS,
    MUTATION_UPDATE_DETAIL_VIEW_CONFIG
} from "@/store/store-search";
import {SearchFilter, SearchRequest} from "@/models/search-request";
import {SearchResultResponse} from "@/models/search-response";
import SvgConstants from "@/mixins/svg-constants";
import SearchPageEntity, {
    OpenEntityRequest
} from "@/components/SearchPageEntity.vue";
import {HTTP} from "@/services/http-provider";
import {Dictionary} from "vue-router/types/router";
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import {SearchResultEntryDetails} from "@/models/search-result-entry-details";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import {Global} from "@/global";
import {EntityType} from "@/models/entity-type";
import Routing from "@/mixins/routing";
import {MUTATION_LAST_SEARCH_STRING} from "@/store/store";
import {StyleValue} from 'vue/types/jsx';


export class EntityContext {
    static idCounter: number = 1;


    entity: SearchResultEntryDetails;

    linkId: string;

    status: string = "show";

    activeTab: string = "";

    generatedId: string;

    errorMessage: string;

    /**
     * Set to true in order to hide this entity. May be useful when an error
     * occurs, for example when a user has changed application settings so that
     * outdated entities no longer is viewable, but such an entity is among the
     * currently viewed.
     */
    hidden: boolean = false;

    constructor(entity: SearchResultEntryDetails, linkId: string, activeTab: string) {
        this.entity = entity;
        this.linkId = linkId;
        this.activeTab = activeTab;
        this.generatedId = String(EntityContext.idCounter++);
    }

    /**
     * Convenience getter for the id.
     */
    get id(): string {
        return this.entity && this.entity.id;
    }

    /**
     * This key makes sure we can reuse contexts during Vue rendering.
     */
    get vueKey(): string {
        return this.generatedId;
    }

    /**
     * Gets a key that identifies this context as uniquely as possible. Notice
     * that it is possible we get the same unique key for references opened from
     * the same link, but currently this is good enough for our purposes.
     */
    get uniqueKey(): string {
        return this.id + "|" + this.linkId;
    }
}

/**
 * Type to represent that an entity on a certain level and a certain tab should be focused.
 */
type FocusedEntity = { level: number, tab: string };

/**
 * Type to carry some data alongside an optional PointerEvent that triggered the sending of the data.
 */
export type ClickEventData<T> = { data: T, pEvent?: PointerEvent };

@Component({
    components: {SearchPageEntity, SearchPageResultListEntry}
})
export default class SearchPage extends Mixins(StateHelper, SvgConstants, Utils) {
    /**
     * Set this to slightly above 500 ms since there have been timing issues
     * with exactly 500 ms.
     *
     * @private
     */
    private readonly ANIMATION_DELAY_MS: number = 510;


    /**
     * The current search string. Will be kept updates on page reload and url change.
     */
    currentSearchString: string = "";

    /**
     * The search filter determining how we filter the result list.
     */
    filter: SearchFilter = new SearchFilter();

    /**
     * Used to keep track of changes to the search filter, in order to correctly
     * trigger new searches.
     */
    currentFilterString: string = "";

    /**
     * If a search has failed, we store an error message here.
     */
    searchErrorMessage: string = null;

    /**
     * The list of open entity detail views.
     */
    contexts: EntityContext[] = [];

    /**
     * The index in the contexts array of the currently active breadcrumb tab.
     */
    activeBreadcrumbIndex = -1;

    /**
     * The level of the details box which is currently highest up in the
     * "staircase" display, if we are using the "staircase"
     */
    activeLevel: number = 0;

    /**
     * Set to true when we begin a new search and to false when the search either
     * completes or fails.
     */
    searching: boolean = false;

    /**
     * Set to true 500 ms after a search is initiated, which shows the spinner, and
     * to false when the search completes or fails.
     */
    showSpinner: boolean = false;

    /**
     * A mapping from level to a timer that controls animations for the detail view
     * on that level.
     */
    private levelToTimer: Map<number, number> = new Map();

    /**
     * A mapping from level to a timestamp when we sent a request for data on that
     * level. If this has changed when we receive the result, we should abort that
     * result since we're waiting for a newer result.
     */
    private levelToTimestampForLatestFetch: Map<number, number> = new Map();

    windowResizeHandler: () => void = null;

    recomputeWindowSizeTimeout: number | null = null;

    maxLevelCount: number | null = null;

    /**
     * Gets the current search result from the root store.
     */
    get searchResult(): SearchResultResponse {
        return this.$store.state.search.searchResultResponse;
    }

    /**
     * True if a search generated no hits.
     */
    get noHits(): boolean {
        return this.searchResult.hits && this.searchResult.hits.length === 0 && !this.searchResult.tooManyHits;
    }

    /**
     * True if we should show any of the "no results" boxes, and thus not the result
     * list.
     */
    get noResult(): boolean {
        return this.noHits || this.searchResult.tooManyHits || this.searchError;
    }

    /**
     * This number is passed into the SearchPageEntities and controls their
     * styling so that they end up in the correct position depending on how many
     * views we have open. If we have more views than fits on the screen, they
     * get stacked. The reason why we don't use the length of this.contexts is
     * that when performing hierarchical close, the closing views have to be
     * visible until the animation is finished, so we can't remove them from the
     * contexts array until then. When the animation has finished, other views
     * have already scrolled into positions based on the current length of the
     * contexts array, positions which are no longer correct. That would lead to
     * the views getting positioned too far left if we start with stacked views.
     * But, by only passing in the number of views that are currently not in the
     * process of being closed, we get the correct positions for all views
     * visible after the closing has completed.
     */
    get numLevelsControllingEntityViewPositions(): number {
        return this.contexts.filter((context: EntityContext) => context.status !== "closing" && !context.hidden).length;
    }

    /**
     * Returns true if a search resulted in an error.
     */
    get searchError(): boolean {
        return this.searchErrorMessage !== null;
    }

    // noinspection JSMethodCanBeStatic
    mounted(): void {
        Global.setPrefixedTitle("");
        let filterString: string = this.getSingleString(this.$route.query.filter) || "";
        if (filterString) {
            this.filter = new SearchFilter(filterString);
            this.currentFilterString = filterString;
        }
        this.ignoreError(this.$store.dispatch(ACTION_GET_ENTITY_FILTER, null));

        this.windowResizeHandler = () => {
            // Recompute the max level count on resize, after a 100 ms
            // timeout so we don't cause too much lag when dragging
            this.recomputeWindowSizeTimeout && clearTimeout(this.recomputeWindowSizeTimeout);
            this.recomputeWindowSizeTimeout = setTimeout(() => {
                this.recomputeWindowSizeTimeout = null;
                this.maxLevelCount = this.computeMaxLevelCount();
            }, 100);
        };
        window.addEventListener('resize', this.windowResizeHandler);
        // Also compute the max level count now
        this.windowResizeHandler();
    }

    beforeDestroy() {
        // Prevent resource leaks
        window.removeEventListener('resize', this.windowResizeHandler);
    }

    /**
     * Compute the maximum number of panels that fit on the screen, given
     * the size.
     */
    private computeMaxLevelCount(): number | null {
        // Using matchMedia instead of simply comparing window sizes directly,
        // should be more robust, but probably slower
        let mediaQueryToLevelCount: { [key: string]: number } = {
            '(min-width:768px)  and (max-width:1019px)': 2, // special case because we only have one details box, see specialCaseListStyle
            '(min-width:1020px) and (max-width:1359px)': 2,
            '(min-width:1360px) and (max-width:1699px)': 3,
            '(min-width:1700px) and (max-width:2039px)': 4,
            '(min-width:2040px) and (max-width:2379px)': 5,
            '(min-width:2380px) and (max-width:2719px)': 6,
            '(min-width:2720px) and (max-width:3059px)': 7,
            '(min-width:3060px) and (max-width:3399px)': 8,
            '(min-width:3400px)': 9,
        };
        for (let mq in mediaQueryToLevelCount) {
            if (matchMedia(mq).matches) {
                return mediaQueryToLevelCount[mq];
            }
        }
        return null;
    }

    /**
     * Quite hacky solution, if we can only fit two panels, then show the search results until the
     * user brings up two panels side by side, then show only the SearchPageEntity panels as a
     * staircase display with width 2.
     */
    get specialCaseListStyle(): StyleValue {
        if (this.numLevelsControllingEntityViewPositions >= 2) {
            if (matchMedia('(min-width:768px) and (max-width:1024px)').matches) {
                return {'max-width': 0};
            }
        }
        return null;
    }

    /**
     * The text to display in a breadcrumb tab
     */
    breadcrumbTitle(context: EntityContext): string {
        if (context.errorMessage) {
            return "Något gick fel";
        } else if (context.entity.individual) {
            return this.getGivenOrFirstOrPnrWithTruncation(context.entity.individual, 20) + " " + this.getMiddleAndLastNames(context.entity.individual);
        } else if (context.entity.company) {
            return context.entity.company.name;
        } else if (context.entity.addressLocation) {
            return this.formatAddressLocation(context.entity.addressLocation);
        } else if (context.entity.realProperty) {
            return context.entity.realProperty.municipality + " " + context.entity.realProperty.propertyDesignation;
        } else if (context.entity.vehicle) {
            return context.entity.vehicle.type + " " + this.formatRegistrationNumberNoWrap(context.entity.vehicle.registrationNumber);
        }
    }

    /**
     * Watches when the url changes and performs the search the url requires.
     *
     * @param to The new route.
     */
    @Watch("$route", {immediate: true, deep: true})
    onUrlChange(to: Route) {
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success) {
                if (!this.hasActiveSubscription) {
                    this.$router.push("/");
                } else {
                    if (Routing.getPreserveEntities(to.params)) {
                        this.performSearch(to, false, true);
                    } else {
                        this.performSearch(to, false, false);
                    }
                }
            }
        });
    }

    /**
     * Watches the value indicating if we should force a search for the same search
     * query as we currently have searched for. Necessary due to how Vue router works.
     */
    @Watch("$store.state.search.forceSearch")
    onForceSearch() {
        this.performSearch(this.$router.currentRoute, true, false);
    }

    /**
     * Triggered each time the search filter changes. Causes navigation to the
     * same search but with the new filter in the query.
     */
    changeFilter() {
        let filterString: string = this.filter.toString();

        let query: any = {vad: this.currentSearchString};
        if (filterString) {
            query.filter = filterString;
        }
        let params = {};
        // Keep the details
        Routing.preserveEntities(params, query, this.details);

        this.$router.push({
            name: "search",
            query: query,
            params
        });
    }

    /**
     * Performs a search based on the given route. Also opens the correct detail
     * views if necessary.
     *
     * @param route The route.
     * @param forceNewSearch Set to true if we should force a new search even
     * though the new search string is the same as the old one.
     * @param preserveEntities Set to false if the current open entities should be closed upon searching.
     * If we are not performing a new search then this argument does not make any difference. Default is true.
     */
    performSearch(route: Route, forceNewSearch: boolean = false, preserveEntities: boolean = true) {
        // Only perform search if the app is loaded, and we're signed in.
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success && this.signedIn) {
                let what: string = this.getSingleString(route.query["vad"]);
                this.$store.commit(MUTATION_LAST_SEARCH_STRING, what);

                let newFilterString: string = this.getSingleString(route.query.filter) || "";

                // Check if the search string has changed, or the filter type has changed.
                let newSearch: boolean = what !== this.currentSearchString
                    || newFilterString !== this.currentFilterString
                    || forceNewSearch;
                this.currentSearchString = what;
                this.currentFilterString = newFilterString;
                this.filter = new SearchFilter(newFilterString);

                /*
                  The "detaljer" string is a comma separated list of
                  EntityViewRef objects encoded with the encode() method.
                  Thus, it contains the public id of all entities that are
                  currently viewed, as well as information about which link that
                  was clicked in order to open each view. It also contains info
                  about the address location filter, if any.
                 */
                let detailsString: string = this.getSingleString(route.query["detaljer"]);

                let parsedDetails: EntityViewRef[] = detailsString ? detailsString.split(",").map((encoded: string) => this.decode(encoded)) : [];

                // Store our detail ids and link ids globally.
                this.$store.commit("search/" + MUTATION_DETAILS, this.copy(parsedDetails));

                if (newSearch || this.searchError) {
                    if (!preserveEntities) {
                        this.contexts = [];
                    }
                    let request = new SearchRequest();
                    request.searchString = what;
                    request.subscriptionRefNo = this.signedIn && this.activeSubscription ? this.activeSubscription.refNo : null;
                    if (this.currentFilterString) {
                        request.filter = this.filter;
                    }
                    this.searchErrorMessage = null;

                    // Start a timer that shows the spinner after 500 ms.
                    this.searching = true;
                    let spinnerTimeout = setTimeout(() => {
                        if (this.searching) {
                            this.showSpinner = true;
                        }
                    }, 500);

                    // Perform the actual search and handle potential detail ids afterwards.
                    this.$store.dispatch(ACTION_PERFORM_SEARCH, request).then((response: SearchResultResponse) => {
                        /*
                          Open entity directly if we only got one hit, or if we
                          got a specified main hit, provided the hit is not
                          already opened.
                         */
                        let singleHitId = this.getSingleHit(response, parsedDetails);
                        if (singleHitId && parsedDetails.length === 0) {
                            this.navigateToEntityCloseRight(new OpenEntityRequest(singleHitId, OpenEntityRequest.noParentLevel), parsedDetails.length - 1);
                        } else {
                            this.handleEntitiesOnNavigation(parsedDetails, route.params, forceNewSearch, preserveEntities);
                        }
                    }).catch((e) => {
                        this.searchErrorMessage = this.extractErrorMessage(e);
                    }).finally(() => {
                        this.searching = false;
                        this.showSpinner = false;
                        clearTimeout(spinnerTimeout);
                    });
                } else {
                    // Handle potential detail ids.
                    this.handleEntitiesOnNavigation(parsedDetails, route.params, forceNewSearch, preserveEntities);
                }
            }
        });
    }

    private getSingleHit(response: SearchResultResponse, details: EntityViewRef[]): string {
        let publicIdsInOpenDetails: string[] = details.map(detail => detail.publicId);
        if (response.mainHitId || response.totalNumHits === 1) {
            let hitId: string = response.mainHitId ? response.mainHitId : response.hits[0].id;
            if (!publicIdsInOpenDetails.includes(hitId)) {
                return hitId;
            }
        }
        return null;
    }

    /**
     * Opens a new entity. The entity placement will either be to the right of the parent of the new entity or to the
     * furthest right of all the entities in the case that a modifier key was held down during when the opening action.
     * @param event
     */
    openEntity(event: ClickEventData<OpenEntityRequest>) {
        const entity: OpenEntityRequest = event.data;
        if (event.pEvent && (Utils.modifierKeyPressed(event.pEvent) || this.permanentExpertMode)) {
            // Open the reference furthest to the right
            this.navigateToEntityCloseRight(entity, this.details.length - 1, true);
        } else {
            // Close all entries to the right of the parent and open the reference to the right of the parent.
            this.navigateToEntityCloseRight(entity, entity.parentLevel);
        }
    }

    /**
     * Open the detail view for an id on the given level. This involves handling
     * the closing of views to the right of the given level before opening the
     * new result.
     *
     * @param reference The entity reference to open.
     * @param closeToRightOf All entries to the right of this level will be
     * closed. By default, all entries will be closed before the new reference
     * is opened. If set to the index of the last entry then no entries will be
     * closed.
     * @param decouple Set to true if the opened entity should be decoupled from
     * its parent.
     */
    navigateToEntityCloseRight(reference: string | OpenEntityRequest, closeToRightOf: number = -1, decouple: boolean = false) {
        let entityReference: OpenEntityRequest;
        if (typeof reference == "string") {
            entityReference = new OpenEntityRequest(reference, closeToRightOf);
        } else {
            entityReference = reference;
        }
        let parentLevel = decouple ? OpenEntityRequest.noParentLevel : entityReference.parentLevel;
        let levelForNewRef: number = closeToRightOf + 1;

        /*
          In cases such as with the address location filter we want to replace the parent instead of creating a
          new entity.
         */
        if (entityReference.replaceParent) {
            levelForNewRef = entityReference.parentLevel;
        }

        /*
          In order to avoid having to keep track of the linkId in a component
          triggering a navigation event, it is possible to specify the "same"
          linkId in order to ensure we don't change the linkId.
         */
        if (entityReference.linkId === "same" && this.details.length > levelForNewRef) {
            entityReference.linkId = this.details[levelForNewRef].linkId;
        }

        /*
          Check if we're already showing this id on the given level, that the
          level is the currently rightmost, and that the current linkId is the
          same as the clicked link. This means that if we click a link referring
          to the same entity but with a different id, we reopen that entity.
         */
        let refersToEntityOnThisLevel: boolean = this.contexts.length > levelForNewRef && this.contexts[levelForNewRef].id === entityReference.id;
        let referencedBySameLink: boolean = this.details[levelForNewRef] && this.details[levelForNewRef].linkId === entityReference.linkId;
        let hasSameAddressLocationFilter: boolean = this.details[levelForNewRef] && this.details[levelForNewRef].addressLocationFilter == entityReference.addressLocationFilter;
        if (refersToEntityOnThisLevel && referencedBySameLink && hasSameAddressLocationFilter) {
            return;
        }

        if (refersToEntityOnThisLevel && referencedBySameLink) {
            /*
              Here we stay on the same entity view, so we don't want to change
              the contexts array, and we want to keep the details array as
              intact as possible.
             */
            this.details[levelForNewRef].addressLocationFilter = entityReference.addressLocationFilter;

            this.navigate(this.details, {
                level: levelForNewRef,
                tab: entityReference.tab
            }, true);
        } else {
            /*
              Handle potential close animations and perform navigation when we
              know the animations are done. We only need to close something if
              the new level is somewhere among our current levels.
             */
            let shouldClose = levelForNewRef < this.details.length && SearchPage.findDescendants(this.details, closeToRightOf).length > 0;
            let closeCompleted: Promise<EntityViewRef[]> = shouldClose ? this.closeDescendants(closeToRightOf) : Promise.resolve(this.details);
            closeCompleted.then((newDetails: EntityViewRef[]) => {
                let newRef: EntityViewRef = new EntityViewRef(entityReference.id, parentLevel, String(entityReference.linkId), entityReference.addressLocationFilter);
                newDetails.splice(levelForNewRef, 0, newRef);

                // Update the parent level of refs to the right of the new one.
                for (let i: number = levelForNewRef + 1; i < newDetails.length; i++) {
                    let refAtI: EntityViewRef = newDetails[i];
                    if (refAtI.parentLevel >= levelForNewRef) {
                        refAtI.parentLevel = refAtI.parentLevel + 1;
                    }
                }

                // Perform the actual navigation.
                this.navigate(newDetails, {
                    level: levelForNewRef,
                    tab: entityReference.tab
                });
            });
        }
    }

    /**
     * Close a detail view on a given level.
     */
    closeEntityOnLevel(level: number): void {
        this.closeSingleEntityNavigate(level);
    }

    private closeDescendants(level: number): Promise<EntityViewRef[]> {
        let toClose: number[] = SearchPage.findDescendants(this.details, level);
        return this.closeLevels(new Set(toClose));
    }

    /**
     * Closes the given entity without closing any of its children and navigates properly to the new state of open
     * entities.
     */
    private closeSingleEntityNavigate(level: number): void {
        this.closeLevelsNavigate(new Set([level]));
    }

    /**
     * Closes the given entities and navigates properly to the new state of open entities.
     */
    private closeLevelsNavigate(levelsToCLose: Set<number>): void {
        this.closeLevels(levelsToCLose).then((details) => this.navigate(details));
    }

    /**
     * Closes the given levels. This will trigger closing animations which when done will resolve into a list of
     * the details that are left open.
     *
     * @param levelsToClose
     */
    private closeLevels(levelsToClose: Set<number>): Promise<EntityViewRef[]> {
        const newDetails = this.updateParents(levelsToClose);
        return this.closeLevelsAnimate(levelsToClose).then(() => newDetails);
    }

    /**
     * Iterates through all current details and updates their parent fields to reflect the soon-to-be removed given
     * levels. Returns the list of references that should be kept.
     *
     * @param toClose levels that will be removed.
     * @private
     */
    private updateParents(toClose: Set<number>): EntityViewRef[] {
        if (toClose.size === 0) {
            return this.details;
        }

        // Store entity views that still will be open here.
        let newDetails: EntityViewRef[] = [];
        // When entities are closed to left of the entity, then that entity's level will be shifted down. That new level
        // is stored here
        let newLevel: Map<number, number> = new Map<number, number>();
        let nrOfClosedToLeft = 0;

        // Update the parentLevels of the children to the elements to be removed
        for (let entityLevel: number = 0; entityLevel < this.details.length; entityLevel++) {
            let refAtLevel: EntityViewRef = this.details[entityLevel];
            if (toClose.has(entityLevel)) {
                nrOfClosedToLeft += 1;
            } else {
                newLevel.set(entityLevel, entityLevel - nrOfClosedToLeft);
                // If its parent is one of the elements that will be removed. Then set parent to the grandparent instead.
                // The grandparent however could also be part of entities to be removed so we have to loop until we reach
                // an element that won't be removed or reached the top parent.
                let checkedParents = new Set();
                let parentLevel = refAtLevel.parentLevel;
                while (toClose.has(parentLevel) && parentLevel != -1) {
                    checkedParents.add(parentLevel);
                    parentLevel = this.details[parentLevel].parentLevel;

                    if (checkedParents.has(parentLevel)) {
                        // Invalid state. Reroute to search page.
                        console.error("Circular relationship among the detail entities. The tree of relationships should not contain any cycles.");
                        this.$router.push('sok');
                        break;
                    }
                }
                refAtLevel.parentLevel = parentLevel;
                newDetails.push(refAtLevel);
            }
        }

        // Take account the left shift that will happen when removing entities.
        for (const newDetail of newDetails) {
            if (newDetail.parentLevel != -1) {
                newDetail.parentLevel = newLevel.get(newDetail.parentLevel);
            }
        }

        return newDetails;
    }

    /**
     * Finds the levels of all entity views descending from the given view.
     *
     * @param refs All entity views.
     * @param subTreeRoot The root of the tree we're about to create.
     * @private
     */
    private static findDescendants(refs: EntityViewRef[], subTreeRoot: number): number[] {
        // If root is -1 then all nodes are seen as children
        if (subTreeRoot == -1) {
            // Return range from 0 to refs.length-1
            return Array.from({length: refs.length}, (_, k) => k);
        }
        let levelToDescendants: Map<number, number[]> = new Map<number, number[]>();
        levelToDescendants.set(-1, [0]);

        for (let level: number = 0; level < refs.length; level++) {
            levelToDescendants.set(level, []);
            let ref = refs[level];
            if (ref.parentLevel !== -1) {
                levelToDescendants.get(ref.parentLevel).push(level);
            }
        }

        let ret: number[] = [];
        this.findDescendantsInternal(levelToDescendants, subTreeRoot, ret, false);
        return ret;
    }

    /**
     * Recurse down the tree.
     *
     * @param levelToDecendants Mapping from the level to the children of the
     * view on that level
     * @param subTreeRoot The root of the current sub tree.
     * @param allDecendants Store decendants in this array.
     * @param doAdd Set to true if the given subTreeRoot should be added.
     * @private
     */
    private static findDescendantsInternal(levelToDecendants: Map<number, number[]>, subTreeRoot: number, allDecendants: number[], doAdd: boolean = true) {
        if (doAdd) {
            allDecendants.push(subTreeRoot);
        }
        let children: number[] = levelToDecendants.get(subTreeRoot);
        if (children) {
            for (let child of children) {
                this.findDescendantsInternal(levelToDecendants, child, allDecendants);
            }
        }
    }

    /**
     * Closes all levels except the given one and then navigates.
     *
     * @param level
     */
    centerLevelNavigate(level: number): void {
        let levelsToClose: Set<number> = new Set();
        for (let i = 0; i < this.details.length; i++) {
            if (i != level) {
                levelsToClose.add(i);
            }
        }
        this.closeLevelsNavigate(levelsToClose);
    }

    /**
     * Close all views to the left of the given level and navigate afterwards.
     * Level should be >= 0.
     */
    closeLeftEntitiesNavigate(level: number): void {
        let levelsToClose: Set<number> = new Set();
        for (let i = 0; i < level; i++) {
            levelsToClose.add(i);
        }
        this.closeLevelsNavigate(levelsToClose);
    }

    /**
     * Close all views to the right of the given level and navigate afterwards.
     * Level should be >= 0.
     */
    closeRightEntitiesNavigate(level: number): void {
        let levelsToClose: Set<number> = new Set();
        for (let i = level + 1; i < this.details.length; i++) {
            levelsToClose.add(i);
        }
        this.closeLevelsNavigate(levelsToClose);
    }

    /**
     * Moves the tab at the given level one level to the left.
     *
     * @param level The level of the tab to move.
     */
    moveTabLeft(level: number): void {
        if (this.details.length <= level) {
            return;
        }
        let newLevel: number = level - 1;
        this.swapTabsAndNavigate(level, newLevel);
    }

    /**
     * Moves the tab at the given level one level to the right.
     *
     * @param level The level of the tab to move.
     */
    moveTabRight(level: number): void {
        if (this.details.length <= level + 1) {
            return;
        }
        let newLevel: number = level + 1;
        this.swapTabsAndNavigate(level, newLevel);
    }

    private swapTabsAndNavigate(level: number, newLevel: number): void {
        /*
          Decouple the swapped tabs and all their descendants from the detail
          hierarchy, since it's really no longer relevant.
         */
        let allDecendants: Set<number> = new Set<number>([level, newLevel]);
        SearchPage.findDescendants(this.details, level).forEach(descendant => allDecendants.add(descendant));
        SearchPage.findDescendants(this.details, newLevel).forEach(descendant => allDecendants.add(descendant));
        allDecendants.forEach(descendant => this.details[descendant].parentLevel = OpenEntityRequest.noParentLevel);

        // Decoupling performed - now do the swap.
        let newDetails: EntityViewRef[] = [...this.details];
        newDetails[level] = this.details[newLevel];
        newDetails[newLevel] = this.details[level];

        /*
          And navigate. Do it in a way that makes the current active tab still
          the active one after the swap.
         */
        this.navigate(newDetails, {
            level: newLevel,
            tab: this.contexts[level].activeTab
        });
        this.navigate(newDetails)
    }

    /**
     * Make sure all requested detail views are properly opened.
     *
     *                 handle the case where the first detail id is not among
     *                 the search hits.
     * @param refsToOpen The list of EntityViewRefs to open.
     * @param params The parameters from the route. May contain info about which
     *               tab to show for an opened entity.
     * @param forceNewSearch True if we should force reload of entities.
     * @param preserveEntities
     */
    private handleEntitiesOnNavigation(refsToOpen: EntityViewRef[],
                                       params: Dictionary<string> = {},
                                       forceNewSearch: boolean = false,
                                       preserveEntities: boolean = true): void {
        if (refsToOpen.length == 0) {
            if (!preserveEntities) {
                this.contexts = [];
            }
            return;
        }

        let newContexts: EntityContext[] = Array(refsToOpen.length);

        /*
          Go through existing entity views and see if we have any of the new
          ones already open. In that case, we don't need to fetch data from the
          server for them.
         */
        let usedContexts: string[] = [];
        for (let i: number = 0; i < refsToOpen.length; i++) {
            let refToOpen: EntityViewRef = refsToOpen[i];

            // Use a placeholder dummy context if we can't find anything else.
            newContexts[i] = SearchPage.generateDummyContext();
            for (let j: number = 0; j < this.contexts.length; j++) {
                /*
                  We may have more than one entity view with the same unique id
                  if they were opened from the same link. Hence, we check the
                  status as well since that should be "closing" for all views
                  that have been closed.
                 */
                let existingContextAtLevelJ: EntityContext = this.contexts[j];
                if (existingContextAtLevelJ.uniqueKey === refToOpen.uniqueKey && existingContextAtLevelJ.status !== "closing" && usedContexts.indexOf(existingContextAtLevelJ.vueKey) === -1) {
                    /*
                      Setting the status to "keeping" avoids hiding the view
                      when it has been closed, which in turn makes it look like
                      a potential view to the right, that slides into this
                      view's place, stays there instead of disappearing and then
                      scrolling into view again.
                     */
                    existingContextAtLevelJ.status = "keeping";
                    newContexts[i] = existingContextAtLevelJ;
                    usedContexts.push(existingContextAtLevelJ.vueKey);
                    break;
                }
            }
        }

        /*
          Closing animations should have been handled here so we can safely
          replace the list of open detail views.
         */
        this.contexts = newContexts;


        let promises: Promise<SearchResultEntryDetails>[] = [];
        refsToOpen.forEach((ref: EntityViewRef, index: number) => {
            /*
              If we have a specified active tab for this level, it should be
              present in the params map, keyed on id and level.
             */
            promises.push(this.showEntity(ref, index, params[this.createPublicIdAndLevelKey(ref.publicId, index)], forceNewSearch));
        });

        /*
          We set the active level directly to get an eventual loading spinner
          as the active details box. Note that this requires .capture on the
          click event on SearchPageEntity.
         */
        this.setActiveLevel(refsToOpen.length - 1);

        /*
          We have to wait until all details have been fetched before setting
          the active breadcrumb index.
         */
        Promise.all(promises).then(() => {
            /*
              If we navigate "to the same" entity view (for example when
              changing the address location filter) we want to have the same
              active level after navigation as before.
             */
            if (params.activeLevel) {
                this.setActiveLevel(Number(params.activeLevel));
            }

            this.setActiveBreadcrumbIndex(refsToOpen.length - 1);
        });
    }

    /**
     * Shows the entity with the provided id on the given level. Here we assume
     * that the contexts array are of the correct size and thus contains at
     * least "level + 1" elements, and that all old views are properly closed.
     *
     * @param entity The entity to show.
     * @param level The zero based level.
     * @param activeTab The active tab. May be undefined in which case the info tab
     * will be shown.
     * @param forceNewSearch True if we should force reload of entities.
     */
    private showEntity(entity: EntityViewRef,
                       level: number,
                       activeTab: string,
                       forceNewSearch: boolean = false): Promise<SearchResultEntryDetails> {
        return new Promise((resolve) => {
            // Check if we're already showing this entity on the given level.
            if (!forceNewSearch && this.contexts[level] && this.contexts[level].uniqueKey === entity.uniqueKey) {
                if (activeTab) {
                    this.contexts[level].activeTab = activeTab;
                }
                resolve(this.contexts[level].entity);
                return;
            }

            /*
              Fetch filter when fetching entity, even though we know we the
              filter is the same for all entities with the current business
              logic.
             */
            this.ignoreError(this.$store.dispatch(ACTION_GET_ENTITY_FILTER, entity.publicId));

            /*
              Clear existing timer for this level and start a new one that shows
              the loading spinner if it takes more than 500 ms to fetch the
              result. Notice that the timer is always cleared in a finally
              clause, so that the spinner disappears when we get the result.
             */
            clearTimeout(this.levelToTimer.get(level));
            this.levelToTimer.set(level, setTimeout(() => {
                /*
                  In Chrome on Windows the spinner is not animated properly, but
                  it often rather shows up after the animation time has passed.
                  Explicitly setting the class a short while after adding the
                  context (and thus rendering the detail view) seem to somewhat
                  remedy this problem, even if it does on entirely disappear.
                 */
                let contextAtLevel = this.contexts[level];
                if (SearchPage.isDummy(contextAtLevel)) {
                    this.levelToTimer.set(level, setTimeout(() => contextAtLevel.status = "slow", 10));
                }
            }, 490));

            /*
              Store a timestamp for our request keyed on its level, so we can
              determine if it is still up-to-date when we receive the response.
             */
            let timeForRequest = new Date().getTime();
            this.levelToTimestampForLatestFetch.set(level, timeForRequest);

            // Fetch the entity and show it as soon as possible.
            let entityPromise: Promise<SearchResultEntryDetails> = HTTP.get<SearchResultEntryDetails>("/sapi/search/entity/" + entity.publicId, {subscriptionRefNo: this.activeSubscriptionRefNo});
            entityPromise.then((resultEntity: SearchResultEntryDetails) => {
                /*
                  If the current latest request time for this level does not
                  match the time of this request, we know the user has started a
                  new request for this level. Thus, we should ignore the result.
                  Notice that we use the same method in all of our asynchronous
                  list fetching requests.
                 */
                if (this.levelToTimestampForLatestFetch.get(level) !== timeForRequest) {
                    resolve(null);
                    return;
                }

                /*
                  The contexts array must have the correct length when this
                  method is called, so we can simply use the $set operator here.
                  Notice that we can't use the [] operator since Vue can't
                  detect such changes.
                 */
                let newContext = new EntityContext(resultEntity, entity.linkId, activeTab ? activeTab : "info");
                // TODO Sometimes currentContext ends up being undefined which should not happen but it does not seem
                // to cause any noticeable problems yet
                let currentContext = this.contexts[level];

                /*
                  We have to reuse the generatedId from the placeholder dummy
                  context so that Vue does not try to remove and add the
                  corresponding html parts for this context again.
                 */
                if (SearchPage.isDummy(currentContext)) {
                    newContext.generatedId = currentContext.generatedId;
                }

                // Update our config object.
                this.$store.commit("search/" + MUTATION_UPDATE_DETAIL_VIEW_CONFIG, resultEntity.detailViewConfig);

                /*
                  Notice that historicalCommitments may be null initially,
                  indicating that there are too many to fetch synchronously, and
                  that they hence should be fetched asynchronously.
                 */
                if (resultEntity.individual && resultEntity.individual.historicalCommitments === null) {
                    this.getAsynchronously("/sapi/search/historical-commitments/", entity.publicId, entityPromise, resolve, level, timeForRequest, "historicalCommitments");
                }

                this.$set(this.contexts, level, newContext);
                resolve(currentContext.entity);
            }).catch((e) => {
                let newContext: EntityContext;
                if (e && e.status === 429) {
                    newContext = new EntityContext(null, null, null);
                    newContext.errorMessage = this.extractErrorMessage(e);
                } else {
                    /*
                      Create a context for the missing entity but make it hidden so
                      that it won't show up. This may happen when a user changes
                      application settings so that she no longer sees outdated
                      entities. However, when switching back again, the hidden
                      entity will show up again.
                     */
                    newContext = new EntityContext(null, entity.linkId, activeTab ? activeTab : "info");
                    newContext.hidden = true;
                    let currentContext = this.contexts[level];
                    if (SearchPage.isDummy(currentContext)) {
                        newContext.generatedId = currentContext.generatedId;
                    }
                }
                this.$set(this.contexts, level, newContext);
                resolve(null);
            }).finally(() => clearTimeout(this.levelToTimer.get(level)));

            /*
              Fetch events - same request for all types of entities.
             */
            this.getAsynchronously("/sapi/search/events/", entity.publicId, entityPromise, resolve, level, timeForRequest, "events");

            let entityType: EntityType = this.getEntityType(entity.publicId);

            /*
              For individuals and companies - fetch real properties and vehicles
              asynchronously.
             */
            if (entityType === EntityType.INDIVIDUAL || entityType === EntityType.ORGANISATION) {
                this.getAsynchronously("/sapi/search/real-property-ownerships/", entity.publicId, entityPromise, resolve, level, timeForRequest, "realPropertyOwnerships");
                this.getAsynchronously("/sapi/search/vehicle-ownerships/", entity.publicId, entityPromise, resolve, level, timeForRequest, "vehicleOwnerships");
            }

            /*
              For companies - fetch historical board info asynchronously.
             */
            if (entityType === EntityType.ORGANISATION && this.allowBoardHistory) {
                this.getAsynchronously("/sapi/search/historical-board-info/", entity.publicId, entityPromise, resolve, level, timeForRequest, "historicalBoards");
            }

            /*
              For address locations - fetch companies asynchronously.
             */
            if (entityType === EntityType.ADDRESS_LOCATION) {
                this.getAsynchronously("/sapi/search/companies/", entity.publicId, entityPromise, resolve, level, timeForRequest, "companies");
            }

            return entityPromise;
        });
    }

    private getAsynchronously(path: string, publicId: string, entityPromise: Promise<SearchResultEntryDetails>, resolve: Function, level: number, timeForRequest: number, fieldName: string) {
        HTTP.get<any>(path + publicId, {subscriptionRefNo: this.activeSubscriptionRefNo}).then((value: any) => {
            this.handleAsynchronousField(entityPromise, resolve, level, timeForRequest, fieldName, value);
        }).catch((response: any) => {
            this.handleAsynchronousFieldError(entityPromise, resolve, level, timeForRequest, fieldName, response);
        });
    }

    private handleAsynchronousField(entityPromise: Promise<SearchResultEntryDetails>, resolve: Function, level: number, timeForRequest: number, fieldName: string, value: any) {
        entityPromise.then((resultEntity: SearchResultEntryDetails) => {
            if (this.levelToTimestampForLatestFetch.get(level) !== timeForRequest) {
                resolve(null);
                return;
            }
            this.$set(this.getEntity(resultEntity), fieldName, value);
        }).catch(() => { /* Ignore */ });
    }

    private handleAsynchronousFieldError(entityPromise: Promise<SearchResultEntryDetails>, resolve: Function, level: number, timeForRequest: number, fieldName: string, response: any) {
        entityPromise.then((resultEntity: SearchResultEntryDetails) => {
            if (this.levelToTimestampForLatestFetch.get(level) !== timeForRequest) {
                resolve(null);
                return;
            }
            this.$set(this.getEntity(resultEntity), "errors", {});
            this.$set(this.getEntity(resultEntity).errors, fieldName, this.extractErrorMessage(response));
        }).catch(() => { /* Ignore */ });
    }

    /**
     * Closes all detail views for the given levels. This includes handling of
     * closing animations. Does not perform any managing of the entity references or the url.
     *
     * @param levelsToClose The zero based levels to close.
     */
    private closeLevelsAnimate(levelsToClose: Set<number>): Promise<void> {
        // noinspection TypeScriptValidateTypes
        return new Promise((resolve) => {
            levelsToClose.forEach((level: number) => {
                if (level < this.contexts.length) {
                    clearTimeout(this.levelToTimer.get(level));
                    this.contexts[level].status = "closing";
                }
            });
            setTimeout(() => {
                resolve();
            }, this.ANIMATION_DELAY_MS);
        });
    }

    /**
     * Perform navigation. This refers to updating the url and adding to the browser history the new state.
     *
     * @param newDetails The (possibly empty) list of EntityViewRefs to show
     * detail views for.
     * @param focusedEntity Used to focus a specific entity, and what tab that entity should be on, after navigation.
     * Optional.
     * @param preserveActiveLevel Set to true if the active level should be
     * preserved after navigation.
     */
    navigate(newDetails: EntityViewRef[], focusedEntity: FocusedEntity = undefined, preserveActiveLevel: boolean = false) {
        let query: Dictionary<string | string[]> = {
            vad: this.currentSearchString,
            filter: this.currentFilterString
        };

        Utils.addDetailsToQuery(query, newDetails);

        /*
          If we have an EntityReference with an explicitly specified tab to show
          when the entity is loaded (as is the case when opening an entity from
          the income tab), we pass a mapping from concatenated level and id to
          the name of the tab.
         */
        let params: Dictionary<string> = {};
        if (focusedEntity) {
            // The access to this.details[] returns the old id.
            params[this.createPublicIdAndLevelKey(newDetails[focusedEntity.level].publicId, focusedEntity.level)] = focusedEntity.tab;
        }

        /*
          If we navigate to a different url but "from within" an entitiy view,
          for example when changing the address location filter, we want to
          preserve the active level.
         */
        if (preserveActiveLevel) {
            params.activeLevel = String(this.activeLevel);
        } else if (focusedEntity) {
            params.activeLevel = String(focusedEntity.level);
        }

        this.$router.push({
            name: "search",
            query: query,
            params: params
        })
    }

    /**
     * Change the active level. The active level is the one shown highest up
     * in the "staircase", if we are using the "staircase".
     */
    setActiveLevel(level: number): void {
        this.activeLevel = level;
    }

    /**
     * Change the active tab.
     */
    setActiveTab(newTab: string, level: number): void {
        if (this.contexts[level]) {
            this.contexts[level].activeTab = newTab;
        }
    }

    /**
     * Sets which breadcrumb that should be active and scrolls it into view.
     *
     * @param newIndex The index of the new active breadcrumb tab.
     */
    setActiveBreadcrumbIndex(newIndex: number) {
        this.activeBreadcrumbIndex = newIndex;
        // TODO Revisit: This version of scrolling into view did not work in Firefox.
        // let elements: any = this.$refs["breadcrumbObject_" + newIndex];
        // if (elements && elements[0]) {
        //     setTimeout(() => elements[0].scrollIntoView(), 0);
        // }
    }

    totNumHitsStr(): string {
        if (this.searchResult.hits) {
            return this.searchResult.totalNumHits > 100 ? "100+" : String(this.searchResult.hits.length);
        }
        return "0";
    }

    /**
     * Generate a placeholder dummy context to show before the real result
     * returns.
     *
     * @private
     */
    private static generateDummyContext(): EntityContext {
        return new EntityContext(<SearchResultEntryDetails>{
            id: "",
            individual: new SearchResultEntryIndividualDetails(),
            company: null
        }, "", "");
    }

    /**
     * Checks if the given context is a dummy context.
     * @param context
     * @private
     */
    private static isDummy(context: EntityContext): boolean {
        return context.id === "";
    }
}
