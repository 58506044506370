/**
 * This class contains various static global methods.
 */
export class Global {
    /**
     * The global build hash that is unique to this build.
     */
    public static BUILD_HASH = process.env.VUE_APP_BUILD_HASH;

    /**
     * The base url without trailing "/".
     */
    public static BASE_URL: string = process.env.BASE_URL.replace(/\/$/, "");

    /**
     * The name of the reset method that all Vuex stores must implement.
     */
    public static readonly MUTATION_RESET_STORE = "resetStore";

    /**
     * Prepends the application base url to the given url. The same behavior
     * applies to both relative and absolute urls. This method should always be
     * used when using urls to within the application that does NOT pass via
     * the Http class.
     */
    public static url(url: string): string {
        if (url.length === 0 || url.charAt(0) !== "/") {
            url = "/" + url;
        }
        return Global.BASE_URL + url;
    }

    /**
     * Returns true if we're on the faktakontroll.se site.
     */
    public static isFaktakontroll(): boolean {
        return Global.isSite("faktakontroll");
    }

    /**
     * Returns true if we're on the kreditupplysning.se site.
     */
    public static isKreditupplysning(): boolean {
        return Global.isSite("kreditupplysning");
    }

    /**
     * Loads the given css resource.
     *
     * @param filename The path to the css resource.
     */
    public static loadCSS(filename: string): void {
        let fileRef = document.createElement("link");
        fileRef.setAttribute("rel", "stylesheet");
        fileRef.setAttribute("type", "text/css");
        fileRef.setAttribute("href", filename);
        document.getElementsByTagName("head")[0].appendChild(fileRef);
    }

    /**
     * Sets the id attribute of the body tag to the given string.
     *
     * @param id The id;
     */
    public static setBodyId(id: string): void {
        document.getElementsByTagName("body")[0].setAttribute("id", id);
    }

    /**
     * Sets the content attribute of the meta tag matching the given selector.
     *
     * @param selector The selector, such as meta[name='description'].
     * @param content The content to set.
     */
    public static setMetaTagContent(selector: string, content: string): void {
        document.querySelector(selector).setAttribute("content", content);
    }

    /**
     * Returns the correct object depending on which site we're on.
     *
     * @param faktakontroll The object to return for Svensk Faktakontroll.
     * @param kreditupplysning The object to return for Kreditupplysning.se.
     */
    public static choose<T>(faktakontroll: T, kreditupplysning: T): any {
        if (Global.isFaktakontroll()) {
            return faktakontroll;
        } else {
            return kreditupplysning;
        }
    }

    /**
     * Returns the base url of the site.
     */
    public static baseUrl(): string {
        return window.location.hostname;
    }

    /**
     * Returns the name of the product we're using based on the url.
     */
    public static productName(): string {
        return Global.choose("Svensk Faktakontroll", "Kreditupplysning.se");
    }

    /**
     * Returns the name of the site we're on (in the production environment).
     */
    static siteName() {
        return Global.choose("Faktakontroll.se", "Kreditupplysning.se");
    }

    /**
     * Returns the url for the site we're on (in the production environment).
     */
    static siteUrl() {
        return Global.choose("www.faktakontroll.se", "www.kreditupplysning.se");
    }

    /**
     * Returns the customer support email for the site we're on.
     */
    static siteCustomerSupport() {
        return Global.choose("kundservice@faktakontroll.se", "kundservice@kreditupplysning.se");
    }

    /**
     * Returns the customer support phone for the site we're on.
     */
    static siteCustomerSupportPhone() {
        return Global.choose("010&#8209;585&nbsp;15&nbsp;00", "010&#8209;585&nbsp;54&nbsp;00");
    }

    /**
     * Returns the tag for the site we're on.
     */
    static siteTag() {
        return Global.choose("faktakontroll", "kreditupplysning");
    }

    /**
     * Sets the correct document title depending on which site we're on.
     */
    public static setTitle(): void {
        document.title = Global.productName()
    }

    /**
     * Sets the correct title of the document depending on which site we're on.
     * The title will be the given prefixed concatenated with a suffix based on
     * the site.
     *
     * @param prefix The prefix. Null or empty string will generate the "basic"
     * title for the site we're on.
     */
    public static setPrefixedTitle(prefix: string): void {
        let actualPrefix = prefix === null || prefix.length == 0 ? "" : prefix + " | ";
        document.title = actualPrefix + Global.productName();
    }

    /**
     * Returns true if the script is executed by the prerenderer. The checked
     * property is injected in vue.config.js.
     */
    public static isPrerendering(): boolean {
        return this.getPrerenderProperty("prerendering");
    }

    /**
     * Gets the value of a property defined in the prerendering config, see
     * vue.config.js.
     *
     * @param key The property name.
     */
    public static getPrerenderProperty(key: string): any {
        let injectedProperties = (<any>window).__PRERENDER_INJECTED;
        return injectedProperties && injectedProperties[key];
    }

    private static isSite(site: string): boolean {
        return window.location.hostname.toLowerCase().includes(site) || Global.getPrerenderProperty("site") === site;
    }
}