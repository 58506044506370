
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "../mixins/state-helper";
import SvgConstants from "../mixins/svg-constants";
import Utils, {UTILS} from "../mixins/utils";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import Expandable from "@/components/Expandable.vue";
import {HTTP} from "@/services/http-provider";
import {TIMESTAMP_FILTER, TimestampFormat} from "@/services/filters";
import {FrontendBvCaseListing} from "@/models/frontend-bv-case-listing";
import {FrontendBvCase} from "@/models/frontend-bv-case";
import SearchPageEntityBvCaseListingCase
    from "@/components/SearchPageEntityBvCaseListingCase.vue";


/**
 * This component represents a BV case listing and the possibility to request
 * new listings, as well as delete old ones.
 */
@Component({
    components: {
        SearchPageEntityBvCaseListingCase,
        Expandable,
        ProgressBar,
        ErrorMessage
    }
})
export default class SearchPageEntityBvCaseListing extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    entityId: string;

    @Prop()
    bvCaseListing: FrontendBvCaseListing;

    showProgressBar: boolean = false;

    orderInProgress: boolean = false;

    numToShow: number = UTILS.CHUNK_SIZE;

    showConfirmDelete: boolean = false;

    errorMessage: string = "";


    /**
     * Gets the "cost" string.
     */
    get renderCost(): string {
        if (!this.activeSubscription) {
            return "";
        } else {
            return "ingår i abonnemanget";
        }
    }

    /**
     * Gets the header for the document list.
     */
    get listingHeader(): string {
        return this.bvCaseListing === null ? "Inga tidigare slagningar" : "Senaste slagning " + TIMESTAMP_FILTER(this.bvCaseListing.created, TimestampFormat.DATE_TIME_MINUTES);
    }

    /**
     * Gets the list of currently visible cases.
     */
    get visibleCases(): FrontendBvCase[] {
        return this.bvCaseListing.cases.slice(0, this.numToShow);
    }

    /**
     * Order a new legal document listing, replacing the current one.
     */
    orderNewListing(): void {
        let timeout = setTimeout(() => {
            this.showProgressBar = true;
            this.orderInProgress = true;
        }, 300);
        this.errorMessage = "";
        HTTP.post<FrontendBvCaseListing>("/sapi/documents/bv-case-listing/" + this.entityId + this.subRefNoParam).then((newListing: FrontendBvCaseListing) => {
            if (this.orderInProgress) {
                setTimeout(() => {
                    this.notifyAboutNewListing(newListing);
                }, 500);
            } else {
                this.notifyAboutNewListing(newListing);
            }
        }).catch((error) => {
            this.errorMessage = this.extractErrorMessage(error);
            this.showProgressBar = false;
        }).finally(() => {
            clearTimeout(timeout);
            this.orderInProgress = false;
        });
    }

    /**
     * Deletes the current listing by marking it as superseded.
     */
    deleteListing(): void {
        this.setShowConfirmDelete(false);
        this.errorMessage = "";
        HTTP.post<void>("/sapi/documents/bv-case-listing/delete/" + this.bvCaseListing.id + this.subRefNoParam).then(() => {
            this.notifyAboutNewListing(null);
        }).catch((error) => {
            this.errorMessage = this.extractErrorMessage(error);
            this.showProgressBar = false;
        });
    }

    /**
     * Receive a new listing, replacing our current one.
     *
     * @param newListing The new listing.
     */
    notifyAboutNewListing(newListing: FrontendBvCaseListing): void {
        this.$emit("new-listing", newListing);
        this.showProgressBar = false;
    }

    /**
     * Convenience method for creating a parameter "subscription" with the refNo
     * of the active subscription to be added to an url.
     */
    get subRefNoParam(): string {
        return "?subscription=" + this.activeSubscriptionRefNo;
    }

    /**
     * Setter for the showConfirmDelete value.
     *
     * @param newValue The new value.
     */
    setShowConfirmDelete(newValue: boolean): void {
        this.showConfirmDelete = newValue;
    }
}
