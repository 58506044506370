
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SelectOnClick from "@/components/SelectOnClick.vue";
import FNum from "@/components/FNum.vue";
/**
 * This component formats a fraction.
 */
@Component({
    components: {SelectOnClick,FNum}
})
export default class Fraction extends Mixins(Utils) {


    /**
     * The numerator.
     */
    @Prop()
    numerator: number;
    
    /**
     * The denominator.
     */
    @Prop()
    denominator: number;
    
    /**
     * The max denominator before breaking into two rows. If -1 we never break.
     */
    @Prop({default: 999})
    maxDenominator: number | string;
    
    /**
     * The font size to use if the fraction is considered large.
     */
    @Prop({default: null})
    fontSize: number;
    
    /**
     * Whether to format the numbers or not.
     */
    @Prop({default: false})
    doFormat: boolean;

    /**
     * Whether to display the fraction as a percentage or not.
     */
    @Prop({default: false})
    asPercent: boolean;
    
    /**
     * Whether to allow small fractions to be broken across two lines.
     */
    @Prop({default: false})
    allowBreak: boolean;
    
    /**
     * The max number of decimals
     */
    @Prop({default: 2})
    maxDecimals: number;
    
    get isLarge(): boolean {
    	return this.maxDenominator != -1 && this.denominator>this.maxDenominator;
    }
    
    get doShow(): boolean {
    	return this.denominator > 0;
    }
    
    get fractionClass(): string {
    	
    	let clazz = "";
        if(this.isLarge) {
            clazz += "fraction_large";
        } else {
            clazz += "fraction_small";
            if(this.allowBreak) {
            	clazz += " break_span"
            }
        }
        return clazz
    }
    
    get fontStyle(): string {
    	if(this.fontSize && this.isLarge) {
    		return "font-size: " + this.fontSize +"px;line-height: " + (this.fontSize*1.1)+"px;"
    	}
    	return "";
    }
    
    get percent(): string {
   
    
    // get the percentage and find the length of the string before the decimal point.
    let percentage: number = (100*this.numerator / this.denominator);
    let numStr: string = percentage.toString();
    let preDecimalLength: number = numStr.split(".")[0].length;

    // Get the max number of decimals
    let locMaxDecimals= preDecimalLength==1 ? this.maxDecimals : Math.max(this.maxDecimals-1,0);
    
    // Find the first non zero decimal, if one exists.
    let decimalString: string = numStr.split(".")[1];
    let numDecimals = this.findFirstNonZero(decimalString)+locMaxDecimals;
    

    // Perform rounding to the correct amount of decimals.
    

    let decimalsMulti = Math.pow(10,numDecimals);
    
    let retNum: number = Math.round(decimalsMulti*percentage)/decimalsMulti;
    
    
    // Pad with zero decimals if needed. 
    let ret: string = retNum.toString();
    decimalString = ret.split(".")[1];

    // Pad the decimals part with zeros if whole part is of length 1
    // and decimal part is shorter than the maximum allowed.
    if(preDecimalLength==1) {
        let dec: number = (numDecimals != 0 ? numDecimals : locMaxDecimals);
        let padCount: number = dec - (decimalString ? decimalString.length : 0);
        
        if(dec <= locMaxDecimals) {
            if(padCount === locMaxDecimals) {
                ret+=".";
            }
            ret = ret.padEnd(ret.length+padCount,"0");
        }
    }
    
    return ret.replace("\.",",")+"\u00A0%"; 
}
    
    findFirstNonZero(decimalString: string) {
    	let firstNonZero = 0;
        if(decimalString) {
            for (let i = 0; i < decimalString.length; i++) {
                let c: string = decimalString.charAt(i);
                if(c !== "0") {
                    
                    firstNonZero = i;
                    break;
                }
            }
        }
        return firstNonZero;
    }
}
