
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import Utils from "@/mixins/utils";
import {
    SearchResultBeneficialOwner
} from "@/models/search-result-beneficial-owner";
import SearchPageEntityCompanyInfoBeneficialOwner
    from "@/components/SearchPageEntityCompanyInfoBeneficialOwner.vue";
import Expandable from "@/components/Expandable.vue";
import FormattedText from "@/components/FormattedText.vue";


/**
 * This component represents an entry in a list of beneficial owners.
 */
@Component({
    components: {
        FormattedText,
        Expandable,
        SearchPageEntityCompanyInfoBeneficialOwner,
        SearchPageEntityLink
    }
})
export default class SearchPageEntityBeneficialOwnerListEntry extends Mixins(StateHelper, Utils) {
    /**
     * The beneficial owner.
     */
    @Prop()
    beneficialOwner: SearchResultBeneficialOwner;

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is
     * used when determining if the link is active or not by checking if the id
     * in the detailIds array on the level to the right of this one, is the same
     * as our id.
     */
    @Prop()
    level: number;

    expanded: boolean = false;
}
