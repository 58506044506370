
import {Component, Mixins, Prop} from "vue-property-decorator";
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultEntryVehicleDetails
} from "@/models/search-result-entry-vehicle-details";
import SelectOnClick from "@/components/SelectOnClick.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SearchPageEntity, {
    OpenEntityRequest
} from "@/components/SearchPageEntity.vue";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityEvent from "@/components/SearchPageEntityEvent.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import SearchPageEntityVehicleOwner
    from "@/components/SearchPageEntityVehicleOwner.vue";
import SearchPageEntityEventList
    from "@/components/SearchPageEntityEventList.vue";
import VehicleProperty from "@/components/VehicleProperty.vue";
import FNum from "@/components/FNum.vue";
import StatusIconCell from "@/components/StatusIconCell.vue";
import StatusIcon from "@/components/StatusIcon.vue";
@Component({
        components : {
        	FNum,
            StatusIcon,
            StatusIconCell,
            SearchPageEntityEventList,
            SearchPageEntityLink,
            SearchPageEntity,
            SearchPageEntityEvent,
            SearchPageEntityInfoHeader,
            SearchPageEntityVehicleOwner,
            SelectOnClick,
            SearchPageEntityExpandable,
            VehicleProperty            
        }
})
export default class SearchPageEntityVehicleInfo extends Mixins(StateHelper, SvgConstants, Utils){
    @Prop()
    level: number;

    @Prop()
    vehicle: SearchResultEntryVehicleDetails;

    /**
     * Controls whether to show the vacancy text.
     */
    showTheftText: boolean = false;

    getPropellant(): string {
        let ret: string = "";
        if(this.vehicle.propellant1) {
            ret += this.vehicle.propellant1;
        }
        if(this.vehicle.propellant2) {
            ret += ", " + this.vehicle.propellant2;
        }
        if(this.vehicle.propellant3) {
            ret += ", " + this.vehicle.propellant3;
        }
        return ret;
    }
    getEnginePower(): string {
        let ret: string = "";
        if(this.vehicle.enginePower1) {
            ret += this.vehicle.enginePower1 + "kW";
        }
        if(this.vehicle.enginePower2) {
            ret += ", " + this.vehicle.enginePower2 + "kW";
        }
        if(this.vehicle.enginePower3) {
            ret += ", " + this.vehicle.enginePower3 + "kW";
        }
        return ret;
    }
    
    propellantExists(propellant: string): boolean {
        return propellant!="Okänt";
    }
    
    couplingDeviceExists(couplingDevice: string): boolean {
        return couplingDevice!="Ingen";
    }
    get productionYear(){
        if(!this.vehicle.productionYear) {
            return "";
        }
        let pad: string = "";
        if(this.vehicle.productionMonth < 10){
            pad="0";
        }
        return this.vehicle.productionYear + "-"+ pad + this.vehicle.productionMonth
    }
    
    
    get linkId(): string {
        return String(this.hashCode("financialOwner-" + this.level + "-" + this.vehicle.id));
    }

    emitOpenEntityView(entityReference: OpenEntityRequest): void {
        entityReference.linkId = this.linkId;
        this.$emit('open-entity-view', entityReference);
    }
    
    getEnginePowerText(enginePower: number): string {
        const horses = Math.round(enginePower * 1.359621617);
        return enginePower + " kW (" + horses +" hk)";
    }
    
    get couplingDevice1Label(): string {
        return "Kopplingsanordning" + (this.couplingDeviceExists(this.vehicle.couplingDevice2) ? " 1" : "");
    }
    get couplingDevice2Label(): string {
        return "Kopplingsanordning" + (this.couplingDeviceExists(this.vehicle.couplingDevice1) ? " 2" : "");
    }
    
    get propellant1Label(): string {
        return "Drivmedel" + ((this.propellantExists(this.vehicle.propellant2) || this.propellantExists(this.vehicle.propellant3)) ? " 1" : "");
    }
    
    get propellant2Label(): string {
        if (this.propellantExists(this.vehicle.propellant1)){
            return "Drivmedel 2";
        } else if (!this.propellantExists(this.vehicle.propellant1) && this.propellantExists(this.vehicle.propellant3)){
            return "Drivmedel 1";
        } else {
            return "Drivmedel";
        }
    }
    
    get propellant3Label(): string {
        if (this.propellantExists(this.vehicle.propellant1) && this.propellantExists(this.vehicle.propellant2)){
            return "Drivmedel 3";
        } else if ((this.propellantExists(this.vehicle.propellant1) && !this.propellantExists(this.vehicle.propellant2)) || (!this.propellantExists(this.vehicle.propellant1) && this.propellantExists(this.vehicle.propellant2))) {
            return "Drivmedel 2";
        } else {
            return "Drivmedel";
        }
    }
    
    get enginePower1Label(): string {
        if(this.vehicle.enginePower3 || this.vehicle.enginePower2) {
            return "Motoreffekt 1";
        } else {
            return "Motoreffekt";
        }
    }
    
    get enginePower2Label(): string {
        if(this.vehicle.enginePower1) {
            return "Motoreffekt 2";
        } if(!this.vehicle.enginePower1 && this.vehicle.enginePower3) {
            return "Motoreffekt 1";
        } else {
            return "Motoreffekt";
        }
    }
    
    get enginePower3Label(): string {
        if(this.vehicle.enginePower1 && this.vehicle.enginePower2) {
            return "Motoreffekt 3";
        } if((!this.vehicle.enginePower1 && this.vehicle.enginePower2) || (this.vehicle.enginePower1 && !this.vehicle.enginePower2)) {
            return "Motoreffekt 2";
        } else {
            return "Motoreffekt";
        }
    }
    
    getBodyTypeText(bodyType: string, bodyTypeCode: string) {
    	return bodyTypeCode + " - " + bodyType;
    }
}
