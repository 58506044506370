var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"catalog_animation_w1 remark_catalog_animation_w1 person_remark_container1",class:{
              'flipping_step_no_person_body': _vm.step === '0:none-existing',
              'flipping_step1_body': _vm.onStep('1'),
              'flipping_step2_body': _vm.onStep('2'),
              'flipping_step3_body': _vm.onStep('3'),
              'flipping_step4_body': _vm.onStep('4'),
              'flipping_step5_body': _vm.onStep('5'),
              'flipping_step6_body': _vm.onStep('6')
              }},[_c('div',{staticClass:"remark_flipping_container1"},[_c('div',{staticClass:"remark_flipping_container2"},[_vm._m(0),(_vm.existsInCatalog)?_c('div',{staticClass:"flipping_container3"},[_vm._l((_vm.spreads),function(spread,index){return _c('catalog-animation-spread',{key:index,attrs:{"low":spread.low,"high":spread.high,"spread":index+1,"imgStr":spread.img,"coverStyle":"anm-kat-framsida","lastSpread":spread.isLast}})}),_c('img',{staticClass:"flipping_magnify_img",style:(_vm.magnifyImgStyle),attrs:{"src":require("../assets/flipping-magnify-glass.png"),"alt":""}}),_c('div',{staticClass:"remark_magnify_container1",style:(_vm.magnifyContainer1Style)},[_c('div',{staticClass:"remark_magnify_container2"},[_c('div',{staticClass:"remark_magnify_image",style:(_vm.remarkMagnifyImageStyle)}),_c('div',{staticClass:"remark_magnify_container3"})])])],2):_vm._e(),_c('div',{staticClass:"flipping_magnify_container4"},[(_vm.outOfCatalogViews)?[_vm._v(" "+_vm._s(_vm.outOfCatalogViewsMessage)+" ")]:(_vm.activeSubscription && !_vm.allowViewRemarks)?[_vm._v(" Ditt abonnemang saknar denna"),_c('br'),_vm._v(" behörighet ")]:_vm._e()],2),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"flipping_footer_container1 remark_footer_container1"},[_c('p',[_vm._v("Anmärkningskatalogen, periodisk skrift")]),_c('p',[_vm._v(_vm._s(_vm.catalog.editionYear)+" utgåva "+_vm._s(_vm.catalog.editionNum)+" av 12 (12 nr/år), utgiven "+_vm._s(_vm.catalog.publicationDate))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flipping_no_person_container1"},[_c('div',{staticClass:"flipping_no_person_container2"},[_c('strong',[_vm._v("Personen finns inte i Anmärkningskatalogen")]),_c('br'),_vm._v(" Detta kan exempelvis bero på att personen"),_c('br'),_vm._v(" är under 18 år, inte varit folkbokförd i Sverige på länge,"),_c('br'),_vm._v(" eller nyligen har invandrat till Sverige"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"remark_catalog_arrows1"},[_c('div',{staticClass:"remark_catalog_arrows2"},[_c('div',{staticClass:"remark_catalog_arrow1"},[_c('div',{staticClass:"remark_catalog_arrow_text"},[_vm._v("Antal")])]),_c('div',{staticClass:"remark_catalog_arrow2"},[_c('div',{staticClass:"remark_catalog_arrow_text"},[_vm._v("Tot. belopp")])]),_c('div',{staticClass:"remark_catalog_arrow3"},[_c('div',{staticClass:"remark_catalog_arrow_text"},[_vm._v("Tid")])]),_c('div',{staticClass:"remark_catalog_arrow4"},[_c('div',{staticClass:"remark_catalog_arrow_text"},[_vm._v("Skuld")])]),_c('div',{staticClass:"remark_catalog_arrow5"},[_c('div',{staticClass:"remark_catalog_arrow_text"},[_vm._v("Övrigt")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"remark_catalog_explanation1"},[_c('div',{staticClass:"remark_catalog_explanation2"},[_vm._v(" K = Konkurs"),_c('br'),_vm._v(" S = Skuldsanering"),_c('br'),_vm._v(" F = Förvaltarskap"),_c('br')]),_c('div',{staticClass:"remark_catalog_explanation2"},[_vm._v(" U = Utmätning"),_c('br'),_vm._v(" H = Historiskt skuldsaldo"),_c('br'),_vm._v(" – = Inget"),_c('br')])])
}]

export { render, staticRenderFns }