
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {SearchResultFinancial} from "@/models/search-result-financial";
import {FinancialPeriod} from "@/models/financial-period";

/**
     * Simple class for adding information about how to render a financial in
     * the chart.
     */
    class WrappedFinancial {
        financial: SearchResultFinancial;
        useBrighterColor: boolean;

        constructor(financial: SearchResultFinancial, useBrighterColor: boolean) {
            this.financial = financial;
            this.useBrighterColor = useBrighterColor;
        }
    }

    /**
     * This component represents the chart in the company info tab.
     */
    @Component({
        components: {}
    })
    export default class SearchPageEntityCompanyInfoChart extends Mixins(StateHelper, SvgConstants, Utils) {
        @Prop()
        financialPeriods: FinancialPeriod[];


        /**
         * Gets the list of financials to show in the chart. This may be a mix
         * of company and group financials, where group financials have
         * precedence.
         */
        get financialsToShow(): WrappedFinancial[] {
            let ret: WrappedFinancial[] = [];
            for (let financialPeriod of this.financialPeriods) {
                if (financialPeriod.group) {
                    ret.push(new WrappedFinancial(financialPeriod.group, false));
                } else {
                    ret.push(new WrappedFinancial(financialPeriod.company, !this.onlyCompany));
                }
            }
            return ret;
        }

        /**
         * Gets the min value among the values to be displayed in the chart.
         */
        get min(): number {
            let newMin: number = Number.MAX_SAFE_INTEGER;
            this.financialsToShow.forEach(f => {
                newMin = Math.min(newMin, f.financial.omsattning, f.financial.resultatEfterFinansnetto);
            });
            return newMin;
        }

        /**
         * Gets the max value among the values to be displayed in the chart.
         */
        get max(): number {
            let newMax: number = Number.MIN_SAFE_INTEGER;
            this.financialsToShow.forEach(f => {
                newMax = Math.max(newMax, f.financial.omsattning, f.financial.resultatEfterFinansnetto);
            });
            return newMax;
        }

        /**
         * True if we're only showing company financials.
         */
        get onlyCompany(): boolean {
            return !this.financialPeriods.find(f => !!f.group);
        }

        /**
         * True if we're only showing group financials.
         */
        get onlyGroup(): boolean {
            return this.financialPeriods.filter(f => !!f.group).length === this.financialPeriods.length;
        }

        /**
         * True if we're showing both company and group financials.
         */
        get mixed(): boolean {
            return !this.onlyCompany && !this.onlyGroup;
        }

        /**
         * Returns a style object with the height of the positive bar in the
         * chart, or height 0 if the value is negative. Notice that we also
         * have to remove the border for negative values.
         */
        positiveStyle(num: number): any {
            if (num > 0) {
                return {height: this.scaleHeight(num) + "%"};
            }
            return {height: 0, border: "none"};
        }

        /**
         * Returns a style object with the height of the negative bar in the
         * chart, or height 0 if the value is positive. Notice that we also
         * have to remove the border for negative values.
         */
        negativeStyle(num: number): any {
            if (num < 0) {
                return {height: this.scaleHeight(num) + "%"};
            }
            return {height: 0, border: "none"};
        }

        /**
         * Returns the height as a correct percentage of the
         * maximum or minimum respectively, depending on if the given value is
         * positive or negative, as well as which of the min or max that decides
         * the scale.
         *
         * @param num The value to get a bar height for.
         */
        scaleHeight(num: number): number {
            /*
              We would like the dominant value to touch the upper or lower grid line, so
              we have to check if it is the positive or the negative bound that limits us.
              Since the positive part of the scale is three times as high as the negative,
              the negative will dominate if the min value is negative and its absolute
              value is more than one third of the maximum value.
             */
            let negativeDecidesScale: boolean = this.min < 0 && Math.abs(this.min) / this.max > 1 / 3;

            /*
              Now we know which of the min and max that decides our scale, so let's set
              the denominator.
             */
            let denominator: number = negativeDecidesScale ? Math.abs(this.min) : this.max;

            if (denominator === 0) {
                // Should never happen.
                return 0;
            }

            let absNum: number = Math.abs(num);

            /*
              The height in the style is expressed as a percentage of the element's
              height, and the height of the negative bar is one third of the height of the
              positive bar. Thus, we have to scale the percentage value for negative
              numbers if the max decides the scale, and the positive number if the min
              decides the scale.
             */
            let part: number;
            if (num > 0 && negativeDecidesScale) {
                part = absNum / denominator * 100 / 3;
            } else if (num < 0 && !negativeDecidesScale) {
                part = absNum / denominator * 100 * 3;
            } else {
                part = absNum / denominator * 100;
            }
            return part;
        }
    }
