import {
    SearchResultHouseholdMember
} from "@/models/search-result-household-member";
import {
    SearchResultEntryIndividual
} from "@/models/search-result-entry-individual";
import {SearchResultCommitment} from "@/models/search-result-commitment";
import {
    SearchResultBoardConnection
} from "@/models/search-result-board-connection";
import {SearchResultNeighbor} from "@/models/search-result-neighbor";
import {DisplayableEvent} from "@/models/displayable-event";
import {SearchResultPhone} from "@/models/search-result-phone";
import {SearchResultEntryCatalog} from "@/models/search-result-entry-catalog";
import {Positionable} from "@/models/positionable";
import {
    SearchResultOwnershipRealProperty
} from "@/models/search-result-ownership-real-property";
import {
    SearchResultOwnershipVehicle
} from "@/models/search-result-ownership-vehicle";
import {RenderableText} from "@/models/renderable-text";
import {
    SearchResultTradingProhibition
} from "@/models/search-result-trading-prohibition";
import {StringBoolean} from "@/models/string-boolean";
import {
    SearchResultHistoricalCommitmentCompany
} from "@/models/search-result-historical-commitment-company";

/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
export class SearchResultEntryIndividualDetails extends SearchResultEntryIndividual {
    fbfDate: string = "";

    fbfMunicipality: string = "";

    fbfHash: string = "";

    fbfApartment: string = "";

    fbfCoord: Positionable;

    spHash: string = "";

    spCoord: Positionable;

    marriedTo: SearchResultHouseholdMember;

    householdMembers: SearchResultHouseholdMember[] = [];

    otherFamilyRelations: SearchResultHouseholdMember[] = [];

    phoneNumbers: SearchResultPhone[] = [];

    incomeCatalogs: SearchResultEntryCatalog[];

    remarkCatalog: SearchResultEntryCatalog;

    events: DisplayableEvent[];

    commitments: SearchResultCommitment[] = [];

    historicalCommitments: SearchResultHistoricalCommitmentCompany[] = [];

    boardConnections: SearchResultBoardConnection[] = [];

    neighbors: SearchResultNeighbor[] = [];

	realPropertyOwnerships: SearchResultOwnershipRealProperty[];
	
	vehicleOwnerships: SearchResultOwnershipVehicle[];

    allowCatalogView: boolean;

    pepEntries: RenderableText[] = [];

    sanctionLists: StringBoolean[] = [];

    tradingProhibition: SearchResultTradingProhibition;

    /**
     * Errors occurring during asynchronous loading of lists are stored in this
     * map, keyed on the name of the list.
     */
    errors: any = {};
}
