
import {Component, Prop, Vue} from 'vue-property-decorator';

/**
 * This component represents a link with an icon, the type of construct that
 * has now replaced common buttons.
 */
@Component
export default class TextIconLink extends Vue {
    /**
     * The text.
     */
    @Prop()
    text: string;

    /**
     * The icon html.
     */
    @Prop()
    icon: string;
}
