
    import {Component, Mixins} from 'vue-property-decorator';
    import StateHelper from "@/mixins/state-helper";
    import TermsOfUseBulletList from "@/components/TermsOfUseBulletList.vue";

    /**
     * This component shows the conditions in a popup modal.
     */
    @Component({
        components: {TermsOfUseBulletList}
    })
    export default class PopupRegistrationFormConditions extends Mixins(StateHelper) {
    }
