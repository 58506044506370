
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import StateHelper from "@/mixins/state-helper";
    import SvgConstants from "@/mixins/svg-constants";
    import {ContactUsParameters} from "@/models/end-user-subscription-parameters";
    import Validation, {ValidationStatus} from "@/mixins/validation";
    import {AxiosResponse} from "axios";
    import {MUTATION_POPUP_REGISTRATION_STATE} from "@/store/store";
    import PopupRegistrationFormAccountDetails
        from "@/components/PopupRegistrationFormAccountDetails.vue";
    import CustomInput from "@/components/CustomInput.vue";
    import {HTTP} from "@/services/http-provider";
    import Utils from "@/mixins/utils";
    import ErrorMessage from "@/components/ErrorMessage.vue";
    import CustomerSupportEmailLink
        from "@/components/CustomerSupportEmailLink.vue";
    import CustomerSupportPhoneNumber
        from "@/components/CustomerSupportPhoneNumber.vue";

    /**
     * This component represents the contact form in the registration modal.
     */
    @Component({
        components: {
            CustomerSupportPhoneNumber,
            CustomerSupportEmailLink,
            ErrorMessage,
            CustomInput,
            PopupRegistrationFormAccountDetails,
        }
    })
    export default class PopupRegistrationFormContact extends Mixins(StateHelper, SvgConstants, Utils, Validation) {
        @Prop()
        heading: string;

        @Prop({default: "GENERAL"})
        contactReason: string;

        step: string = "contact";

        errorMessage: string = "";

        contactUs: ContactUsParameters = new ContactUsParameters();


        mounted() {
            this.contactUs.reason = this.contactReason;
        }

        /**
         * Getter for the css class that determines which of the steps in the registration
         * process we're displaying. Thus, the step names must coincide with the css
         * classes.
         */
        get activeStepClass(): string {
            return "show_step_" + this.step;
        }

        /**
         * Closes the popup and navigates to the start page.
         */
        returnToStartPage(): void {
            this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, "");
            this.$router.push({path: "/"})
        }

        /**
         * Submits the contact form.
         */
        submit(): void {
            this.errorMessage = "";
            if (this.vPhone()(this.contactUs.phoneNumber) !== ValidationStatus.VALID && this.vEmail()(this.contactUs.email) !== ValidationStatus.VALID) {
                this.errorMessage += "Du måste ange antingen e-post eller telefonnummer. "
            }
            if (this.contactUs.message.length > 250) {
                this.errorMessage += "Meddelandet får inte vara mer än 250 tecken långt.";
            }

            if (!this.errorMessage) {
                this.$emit("step-changed", "spinner");
                HTTP.post("/api/contact", this.contactUs).then(() => {
                    this.$emit("step-changed", "sent");
                }).catch((response: AxiosResponse) => {
                    this.$emit("step-changed", "contact");
                    this.errorMessage = this.extractErrorMessage(response);
                });
            }
        }
    }
