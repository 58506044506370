
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import Auth from "@/mixins/auth";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {EntityViewRef} from "@/store/store-search";
import {DocumentEntity} from "@/models/document-entity";
import {MUTATION_SIDEBAR_RIGHT_STATUS} from '@/store/store';
import PreflightLink from "@/components/PreflightLink.vue";
import {Global} from "@/global";

/**
 * This component represents a single entry in the list of credit reports in the right
 * sidebar.
 */
@Component({
    components: {PreflightLink, ErrorMessage}
})
export default class SidebarRightDocumentEntityListEntry extends Mixins(Auth, StateHelper, SvgConstants, Utils) {
    @Prop()
    documentEntity: DocumentEntity;

    expanded: boolean = false;


    /**
     * Gets html for the toggle arrow symbol.
     */
    get toggleArrow(): string {
        return this.expanded ? " &#9661;" : " &#9655;";
    }

    /**
     * Show the entity the documents refer to.
     */
    showEntity(): void {
        this.$router.push({
            path: "sok",
            name: "search",
            query: {
                vad: this.documentEntity.id,
                detaljer: Utils.encode(new EntityViewRef(this.documentEntity.id)),
            }
        });
        if (!Utils.isMobile()) {
            this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "document-entity-list");
        }
    }

    /**
     * Generate the url for downloading the document with the given id.
     *
     * @param docId The document id.
     */
    buildUrl(docId: string) {
        let path: string = "/sapi/documents/open/" + docId + "?subscription=" + this.activeSubscriptionRefNo;
        return Global.url(path);
    }
}
