
    import {Component, Prop, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import StateHelper from "@/mixins/state-helper";
    import {Global} from "@/global";

    /**
     * This component handles choosing the correct image based on the global
     * state that tells us which site we're on. Here we expect all images to be
     * named 'siteTag'-'imageNameWithoutSitePrefix', where siteTag is
     * "faktakontroll" or "kreditupplysning".
     */
    @Component({
        components: {}
    })
    export default class SiteDependentImage extends Mixins(StateHelper, SvgConstants) {
        @Prop()
        imageNameWithoutSitePrefix: string;


        /**
         * Gets the actual image name, including site prefix.
         */
        get imageName(): string {
            return Global.siteTag() + "-" + this.imageNameWithoutSitePrefix;
        }
    }
