
import {Component, Mixins} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import {Global} from "@/global";
import SvgConstants from "@/mixins/svg-constants";
import V2CustomInput from "@/components_v2/V2CustomInput.vue";
import {
    EndUserSubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import Validation, {
    ValidationFunction,
    ValidationStatus
} from "@/mixins/validation";
import {
    CreateSelfServiceSubscriptionBankIDAuthRequest
} from "@/models/auth-request.model";
import {
    ACTION_AUTHENTICATE_OR_SIGN_WITH_BANK_ID,
    ACTION_CANCEL_BANK_ID,
    ACTION_SIGN_OUT
} from "@/store/store";
import {UserErrorMessage} from "@/models/error-message";
import V2Header from "@/components_v2/V2Header.vue";
import Utils from "@/mixins/utils";
import {HTTP} from "@/services/http-provider";
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import {AxiosResponse} from "axios";
import TextIconLink from "@/components/TextIconLink.vue";
import SimpleQr from "@/components/SimpleQr.vue";
import TermsOfUseBulletList from "@/components/TermsOfUseBulletList.vue";

@Component({
    components: {
        TermsOfUseBulletList,
        TextIconLink, V2Header, V2CustomInput, SimpleQr
    },
})
export default class SelfServiceSignUpPage extends Mixins(StateHelper, SvgConstants, Utils, Validation) {
    params: EndUserSubscriptionParameters = new EndUserSubscriptionParameters();

    /**
     * This request object lives during a single authentication process. This
     * makes it possible for the user to abort by setting the abort flag. Also,
     * the orderRef is set in this object by the authentication process. The
     * orderRef is required when cancelling the BankID process.
     */
    private authRequest: CreateSelfServiceSubscriptionBankIDAuthRequest;

    /**
     * Mapping from the name of the field to a human readable string. See
     * Validation#runValidationFunctions for more info.
     */
    private fieldName: any = {
        firstName: "Förnamnet",
        lastName: "Efternamnet",
        phoneNumber: "Telefonnumret",
        email: "E-postadressen",
        personalNumber: "Personnumret",
        companyName: "Företagsnamnet",
        organisationNumber: "Organisationsnumret",
        paymentEmail: "E-postadress för betalning",
        address1: "Adressen",
        zip: "Postnumret",
        city: "Postorten",
    };

    /**
     * Mapping from the name of the field to an error message for that
     * field. These can be set both during frontend validation but also when
     * receiving a validation error message from the server. We also have a
     * 'generic' mapping to which other types of errors can be mapped. The
     * error message shown below the fields is composed of these messages.
     * Notice that it is important to initialize all mappings since Vue
     * otherwise can't track when they change.
     */
    private complaintsStep1: any = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        companyName: "",
        organisationNumber: "",
        paymentEmail: "",
        address1: "",
        zip: "",
        city: "",
        generic: "",
    };

    private complaintsStep2: any = {
        viewedConditions: "",
        personalNumber: "",
        generic: "",
    };

    /**
     * Mapping from the name of the field in the EndUserParameters object to
     * the validation function for that field.
     */
    validationEndUser: any = {
        firstName: this.vAllOf(this.vLength(1, 250), this.externalValidation("firstName", this.complaintsStep1)),
        lastName: this.vAllOf(this.vLength(1, 250), this.externalValidation("lastName", this.complaintsStep1)),
        phoneNumber: this.vAllOf(this.vAllowEmpty(this.vPhone()), this.vMaxLength(250), this.externalValidation("phoneNumber", this.complaintsStep1)),
        email: this.vAllOf(this.vEmail(), this.vMaxLength(250), this.externalValidation("email", this.complaintsStep1)),

        personalNumber: this.vAllOf(this.vModulo10CheckSum(), this.externalValidation("personalNumber", this.complaintsStep2)),
    };

    /**
     * Mapping from the name of the field in the SubscriptionParameters object
     * to the validation function for that field.
     */
    validationSubscription: any = {
        companyName: this.vAllOf(this.vLength(1, 250), this.externalValidation("companyName", this.complaintsStep1)),
        organisationNumber: this.vAllOf(this.vModulo10CheckSum(), this.externalValidation("organisationNumber", this.complaintsStep1)),
        paymentEmail: this.vAllOf(this.vEmail(), this.vMaxLength(250), this.externalValidation("paymentEmail", this.complaintsStep1)),
        address1: this.vAllOf(this.vLength(1, 250), this.externalValidation("address1", this.complaintsStep1)),
        zip: this.vAllOf(this.vZipCode(), this.externalValidation("zip", this.complaintsStep1)),
        city: this.vAllOf(this.vLength(1, 250), this.externalValidation("city", this.complaintsStep1)),
    };

    step: number = 1;

    viewedConditions: boolean = false;

    signedOut: boolean = false;


    // noinspection JSMethodCanBeStatic
    mounted(): void {
        Global.setPrefixedTitle("Teckna abonnemang");

        // Always log out before signing up for a new subscription.
        this.$store.dispatch(ACTION_SIGN_OUT)
            .catch(() => {
            })
            .finally(() => {
                this.signedOut = true;
            });

        /*
          Set focus on first input field. Notice that we have to introduce a
          zero ms delay here since the input field does not seem to exist before
          that.
         */
        if (!Utils.isMobile()) {
            setTimeout(() => this.setFocusOnId("self-service-sign-up-first-name"), 0);
        }
    }

    /**
     * Triggered on scroll events for the conditions bullet list and detects
     * when the user has scrolled to the bottom.
     */
    onScroll(e: any) {
        // Add 10 since we seem to get some rounding promlems otherwise.
        if (Math.ceil(e.target.scrollTop) + Math.ceil(e.target.clientHeight) + 10 >= Math.floor(e.target.scrollHeight)) {
            this.viewedConditions = true;
            this.complaintsStep2.viewedConditions = "";
        }
    }

    /**
     * Compile an error message from our complaints map. Use the field names
     * in the fieldName map to produce a human-readable text.
     */
    get errorMessageStep1(): string {
        return this.createErrorMessage(this.complaintsStep1);
    }

    /**
     * Compile an error message from our complaints map. Use the field names
     * in the fieldName map to produce a human-readable text.
     */
    get errorMessageStep2(): string {
        return this.createErrorMessage(this.complaintsStep2);
    }

    /**
     * This method returns a validation function for the given field name
     * that will fail if there is a complaint for that field in the
     * complaints map. Thus, when we store complaints from the backend in
     * that map, the corresponding field will be marked as invalid as well.
     *
     * @param field The name of the field in the EndUserParameters object.
     * @param complaints The map to store complaints in.
     */
    externalValidation(field: string, complaints: any): ValidationFunction {
        return () => complaints[field] ? ValidationStatus.FORCE_INVALID : ValidationStatus.VALID;
    }

    /**
     * Remove the error message for the given field (if it is still valid),
     * as well as any generic error message. This will be triggered on the
     * change event for an input field.
     *
     * @param fieldName The field in the EndUserParameters object.
     * @param complaints The complaints map.
     */
    fieldChange(fieldName: string, complaints: any) {
        if (fieldName in this.params.endUserParameters) {
            this.onFieldChange(this.validationEndUser, complaints, fieldName, this.params.endUserParameters);
        } else if (fieldName in this.params.subscriptionParameters) {
            this.onFieldChange(this.validationSubscription, complaints, fieldName, this.params.subscriptionParameters);
        }
    }

    /**
     * Goes to step 2 if all validations are ok.
     */
    validateAndGoToStep2(): void {
        let endUser = this.params.endUserParameters;
        let actualValidationEndUser = {...this.validationEndUser};
        delete actualValidationEndUser.personalNumber;
        if (endUser.phoneNumber.trim().length === 0) {
            delete actualValidationEndUser.phoneNumber
        }

        this.runValidationFunctions(actualValidationEndUser, this.complaintsStep1, this.fieldName, endUser);
        this.runValidationFunctions(this.validationSubscription, this.complaintsStep1, this.fieldName, this.params.subscriptionParameters);

        if (!this.errorMessageStep1) {
            HTTP.post<EndUserSubscriptionOperationResponse>("/api/subscription/self-service-pre-check", this.params).then(() => {
                this.step = 2;
            }).catch((response: AxiosResponse) => {
                this.step = 1;
                this.handleErrorMessage(this.complaintsStep1, response.data);
            });
        }
    }

    /**
     * Aborts the BankID auth session.
     */
    abortBankIDAuth(): void {
        this.authRequest.abort = true;
        this.step = 2;

        // Ignore errors here.
        this.$store.dispatch(ACTION_CANCEL_BANK_ID, this.authRequest.orderRef).catch(() => {
        });
    }

    validatePnrAndStartBankIdAuthentication(): void {
        this.complaintsStep2.generic = "";
        this.complaintsStep2.personalNumber = "";

        // Add validation complaint if conditions are not viewed.
        if (!this.viewedConditions) {
            this.complaintsStep2.viewedConditions = "Du måste läsa användarvillkoren - scrolla längst ner.";
        }

        if (!this.errorMessageStep2) {
            HTTP.post<EndUserSubscriptionOperationResponse>("/api/subscription/self-service-pre-check", this.params).then(() => {
                this.step = 3;
                this.authRequest = new CreateSelfServiceSubscriptionBankIDAuthRequest(this.params);
                this.$store.dispatch(ACTION_AUTHENTICATE_OR_SIGN_WITH_BANK_ID, this.authRequest)
                    .then(() => {
                        this.step = 4;
                    })
                    .catch((response: UserErrorMessage | any) => {
                        /*
                         The status "aborted" means the user aborted from the
                         gui, and there is no need to tell her that.
                         */
                        if (response.errorType !== "aborted") {
                            if (response instanceof UserErrorMessage) {
                                /*
                                 We may get a UserErrorMessage for example if
                                 the user aborted the BankID session.
                                 */
                                this.complaintsStep2.generic = response.errorMessage;
                            } else {
                                this.handleErrorMessage(this.complaintsStep1, response.data);
                                this.handleErrorMessage(this.complaintsStep2, response.data);
                            }
                        }

                        // Go back to step 1 if we got errors in any field there.
                        if (this.errorMessageStep1) {
                            this.step = 1;
                        } else {
                            this.step = 2;
                        }
                    }).finally(() => this.authRequest = null);
            }).catch((response: AxiosResponse) => {
                this.step = 2;
                this.handleErrorMessage(this.complaintsStep2, response.data);
            });
        }
    }
}
