
import {Component, Mixins, Watch} from 'vue-property-decorator';
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import {EndUserParameters} from "@/models/end-user-subscription-parameters";
import {EventNotification} from "@/models/event-notification";
import ErrorMessage from "@/components/ErrorMessage.vue";
import SvgConstants from "@/mixins/svg-constants";
import Utils from "@/mixins/utils";
import CustomSelect, {CustomSelectOption} from "@/components/CustomSelect.vue";
import {
    ACTION_DELETE_NOTIFICATIONS,
    ACTION_FETCH_NOTIFICATIONS,
    ACTION_FETCH_NOTIFICATIONS_PAGE,
    ACTION_READ_NOTIFICATIONS,
    WrappedNotification
} from "@/store/store-notification";
import StateHelper from "@/mixins/state-helper";
import SidebarRightEventListEntry
    from "@/components/SidebarRightEventListEntry.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

/**
 * This component represents the list of monitors in the right sidebar.
 */
@Component({
    components: {
        SidebarRightCloseButton,
        ErrorMessage,
        SidebarRightEventListEntry,
        ProgressBar,
        CustomSelect
    },
})
export default class SidebarRightEventList extends Mixins(StateHelper, SvgConstants, Utils) {
    PAGE_SIZE: number = 50;

    displayOption: string = "all";

    displayed: WrappedNotification[] = [];

    actionErrorMessage: string = "";

    expandedIndex: number = 1;

    loading: boolean = false;

    enableShowMore: boolean = true;

    enableArrowPress: boolean = true;

    renderedPages: number = 1;

    reloadWhenNoLongerVisible: boolean = false;


    mounted() {
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success && this.signedIn) {
                this.fetchInit();
            }
        });
    }

    get allText(): string {
        return "Alla (" + this.numNotifications + ")";
    }

    get unreadText(): string {
        return "Olästa (" + this.numUnreadNotifications + ")";
    }

    get options(): CustomSelectOption[] {
        let ret: CustomSelectOption[] = [];
        ret.push(new CustomSelectOption("all", this.allText))
        ret.push(new CustomSelectOption("unread", this.unreadText))
        return ret;
    }

    async fetchInit(): Promise<void> {
        /*
          We do this to first get a small amount of notifications to avoid long
          loading times.
         */
        this.renderedPages = 1;
        this.reloadWhenNoLongerVisible = false;
        this.doFetch().then(() => {
            this.handleShowMoreClick();
        });
    }

    async doFetch(): Promise<void> {
        this.enableShowMore = false;
        if (this.loading) {
            return;
        }
        this.loading = true;
        try {
            await this.$store.dispatch(ACTION_FETCH_NOTIFICATIONS);
            this.enableShowMore = true;
            this.expandedIndex = -1;
            this.displayed = this.$store.state.notification.wrappedNotifications;
        } catch (e) {
            this.actionErrorMessage = this.extractErrorMessage(e);
        } finally {
            this.loading = false;
        }
    }

    handleUp(): void {
        if (!this.enableArrowPress) {
            return;
        }
        if (this.expandedIndex === -1) {
            return;
        }
        if (this.expandedIndex >= 1) {
            this.handleExpanded({
                "index": this.expandedIndex - 1,
                "value": true
            });
        }
    }

    handleDown(): void {
        if (!this.enableArrowPress) {
            return;
        }
        if (this.expandedIndex === -1) {
            return;
        }
        if (this.expandedIndex === this.displayed.length - 1 && !this.moreNotificationsToFetch) {
            return;
        }
        this.enableArrowPress = false;
        let newIndex: number = this.expandedIndex + 1;
        if (newIndex < this.displayed.length && newIndex < this.renderedPages * this.PAGE_SIZE) {
            this.enableArrowPress = true;
            this.handleExpanded({
                "index": newIndex,
                "value": true
            });
        } else {
            this.handleShowMoreClick().then(() => {
                this.enableArrowPress = true;
                if (newIndex < this.displayed.length) {
                    this.handleExpanded({
                        "index": newIndex,
                        "value": true
                    });
                }
            });
        }
    }

    scrollToExpanded(): void {
        if (this.expandedIndex === -1) {
            return
        }
        const ctx: any = this;
        const el: any = ctx.$refs['side-event-entry-' + this.expandedIndex][0].$el;
        smoothScrollIntoView(el, {
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
            scrollMode: 'if-needed'
        })
        // The line below would suffice if safari had implemented it.
        //el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    /**
     * Make sure we update the event list whenever the active user changes.
     *
     * @param val The new active user. May be undefined.
     * @param oldVal The old active user. May be undefined.
     */
    @Watch("$store.state.activeUser")
    onActiveUserChange(val: EndUserParameters, oldVal: EndUserParameters) {
        this.fetchInit();
    }

    /**
     * Make sure we update the event list when the server tells us to. The value
     * will be changed when this method is called, so there is no use in
     * checking the old and new values.
     */
    @Watch("$store.state.notification.notificationTime")
    onNotificationTimerChange() {
        if (this.isVisible()) {
            this.reloadWhenNoLongerVisible = true;
        } else {
            this.fetchInit();
        }
    }

    @Watch("$store.state.sidebarRightStatus")
    onSwitchSidebar(val: string) {
        if (val !== "event-list" && this.reloadWhenNoLongerVisible) {
            this.fetchInit();
        } else {
            this.renderedPages = 1;
        }
    }

    updateToDisplay(event: any): void {
        this.renderedPages = 1;
        this.displayOption = event;
        this.updateDisplayed();
    }

    updateDisplayed(): void {
        this.displayed = this.$store.state.notification.wrappedNotifications.filter((not: WrappedNotification) => {

            if (this.displayOption !== "all") {
                return !not.notification.viewed || not.expanded;
            }
            return true;
        });
        for (let i = 0; i < this.displayed.length; i++) {
            if (this.displayed[i].expanded) {
                this.expandedIndex = i;
            }
        }
    }

    selectAll(): void {
        let numSelected: number = 0;
        let newSelectValue: boolean = true;
        for (let i = 0; i < this.displayed.length; i++) {
            if (this.displayed[i].selected) {
                numSelected++;
            }
        }
        if (numSelected === this.displayed.length) {
            newSelectValue = false;
        }
        for (let i = 0; i < this.displayed.length; i++) {
            this.displayed[i].selected = newSelectValue;
        }
    }

    handleSelect(index: number): void {
        this.displayed[index].selected = !this.displayed[index].selected;
    }

    collapseAll(): void {
        for (let not of this.displayed) {
            not.expanded = false;
        }
    }

    async handleShowMoreClick(): Promise<void> {
        if (!this.enableShowMore) {
            return;
        }
        this.loading = true;
        this.enableShowMore = false;
        if ((this.renderedPages + 1) * this.PAGE_SIZE < this.displayed.length) {
            this.renderedPages = this.renderedPages + 1;
            this.loading = false;
            this.enableShowMore = true;
        } else {
            this.$store.dispatch(ACTION_FETCH_NOTIFICATIONS_PAGE).then(() => {
                this.renderedPages = this.renderedPages + 1;
                this.loading = false;
                this.enableShowMore = true;
                this.updateDisplayed();
            }).catch(e => {
                this.actionErrorMessage = this.extractErrorMessage(e);
            });
        }
    }

    handleExpanded(event: any): void {
        this.actionErrorMessage = "";
        this.collapseAll();
        this.displayed[event.index].expanded = event.value;
        if (event.value) {
            this.expandedIndex = event.index;
        } else {
            this.expandedIndex = -1;
        }

        let not: EventNotification = this.displayed[event.index].notification;
        if (!not.viewed) {
            this.$store.dispatch(ACTION_READ_NOTIFICATIONS, [not.id]).then(() => {
                not.viewed = true;
            }).catch(() => {
                this.actionErrorMessage = "Något gick fel, prova gärna igen";
            });
        }
        setTimeout(this.scrollToExpanded, 100);
    }

    handleOpenEntity(event: any): void {
        this.$root.$emit('sidebar-open-entity-view', event);
    }

    readSelected(): void {
        this.actionErrorMessage = "";
        let ids: number[] = [];
        for (let not of this.displayed) {
            if (not.selected && !not.notification.viewed) {
                ids.push(not.notification.id);
            }
        }
        if (ids.length === 0) {
            for (let not of this.displayed) {
                not.selected = false;
            }
            return;
        }
        this.$store.dispatch(ACTION_READ_NOTIFICATIONS, ids).then((readIds: number[]) => {
            for (let not of this.displayed) {
                if (readIds.includes(not.notification.id)) {
                    not.notification.viewed = true;
                }
                not.selected = false;
            }
        }).catch(() => {
            this.actionErrorMessage = "Något gick fel, prova gärna igen";
        });
    }

    deleteSelected(): void {
        let toDelete: number[] = [];
        let toExpand: number = -1;
        for (let not of this.displayed) {
            if (not.selected) {
                toDelete.push(not.notification.id);
            }
        }
        if (toDelete.length === 0) {
            for (let i = 0; i < this.displayed.length; i++) {
                let not: WrappedNotification = this.displayed[i];
                if (not.expanded) {
                    toDelete.push(not.notification.id);
                    if (this.displayed.length === 0) {
                        // Do nothing
                    } else if (i === this.displayed.length - 1) {
                        toExpand = i - 1;
                    } else {
                        toExpand = i;
                    }
                    break; // There should only be one expanded.
                }
            }
        }
        if (toDelete.length === 0) {
            return;
        }
        this.actionErrorMessage = "";
        this.$store.dispatch(ACTION_DELETE_NOTIFICATIONS, toDelete).then(() => {
            this.updateDisplayed();
            if (toExpand !== -1) {
                this.handleExpanded({"index": toExpand, "value": true});
            }
        }).catch(() => {
            this.actionErrorMessage = "Något gick fel, prova gärna igen";
        });
    }

    calculatePageStart(page: number): number {
        return (page - 1) * this.PAGE_SIZE;
    }

    calculatePageEnd(page: number): number {
        return page * this.PAGE_SIZE;
    }

    calculateEntryIndex(page: number, pageIdx: number): number {
        return pageIdx + (page - 1) * this.PAGE_SIZE;
    }

    get moreNotificationsToFetch(): boolean {
        return this.eventNotifications.length < this.numNotifications;
    }

    isVisible(): boolean {
        return this.sidebarRightStatus === "event-list";
    }
}
