
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {
    SearchResultRealPropertyTaxUnit
} from "@/models/search-result-real-property-tax-unit";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import FNum from "@/components/FNum.vue";

/**
 * This component represents a real property tax unit.
 */
@Component({
    components: {
        FNum,
        SearchPageEntityInfoHeader, SearchPageEntityLink, SelectOnClick
    }
})
export default class SearchPageEntityRealPropertyTaxUnit extends Mixins(StateHelper, Utils) {
    /**
     * The tax unit
     */
    @Prop()
    taxUnit: SearchResultRealPropertyTaxUnit;

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;

    get typeDescription() {
        return this.taxUnit.typeDescription + " (" + this.taxUnit.typeCode + ")";
    }
}
