
import {Component, Mixins} from 'vue-property-decorator';
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import SvgConstants from "@/mixins/svg-constants";
import CustomInput from "@/components/CustomInput.vue";
import {
    EndUserParameters,
    EndUserSubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {
    MUTATION_ACTIVE_USER,
    MUTATION_SIDEBAR_RIGHT_STATUS
} from "@/store/store";
import Validation, {
    ValidationFunction,
    ValidationStatus
} from "@/mixins/validation";
import {HTTP} from "@/services/http-provider";
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import {AxiosResponse} from "axios";
import StateHelper from "@/mixins/state-helper";
import ErrorMessage from "@/components/ErrorMessage.vue";

/**
 * This component represents the list of monitors in the right sidebar.
 */
@Component({
    components: {ErrorMessage, CustomInput, SidebarRightCloseButton},
})
export default class SidebarRightAccountDetailsForm extends Mixins(SvgConstants, StateHelper, Validation) {
    /**
     * A copy of the currently active user. Will be transferred to the real active
     * user on successful update.
     */
    endUser: EndUserParameters = new EndUserParameters();

    /**
     * Set to true when we should show the success message after successful saving.
     */
    showSuccess: boolean = false;

    /**
     * Boolean that makes it possible to remove the form from the DOM temporarily in
     * order to enable triggering of the "Save Password" dialog in Chrome.
     */
    showForm: boolean = true;

    /**
     * Mapping from the name of the field to a human readable string. See
     * Validation#runValidationFunctions for more info.
     */
    private fieldName: any = {
        firstName: "Förnamnet",
        lastName: "Efternamnet",
        phoneNumber: "Telefonnumret",
        email: "E-postadressen",
        password: "Lösenordet",
    };

    /**
     * Mapping from the name of the field to an error message for that field. These
     * can be set both during frontend validation but also when receiving a validation
     * error message from the server. We also have a 'generic' mapping to which other
     * types of errors can be mapped. The error message shown below the fields is
     * composed of these messages. Notice that it is important to initialize all
     * mappings since Vue otherwise can't track when they change.
     */
    private complaints: any = {
        generic: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
    };


    mounted() {
        this.updateModel(this.activeUser);
    }

    /**
     * Set up validations dynamically since they depend on whether the
     * subscription allows anonymous users.
     */
    get validation(): any {
        /**
         * Mapping from the name of the field in the EndUserParameters object to the
         * validation function for that field.
         */
        let validation: any = {
            firstName: this.vAllOf(this.vLength(1, 250), this.externalValidation("firstName")),
            lastName: this.vAllOf(this.vLength(1, 250), this.externalValidation("lastName")),
            phoneNumber: this.vAllOf(this.vAllowEmpty(this.vPhone()), this.vMaxLength(250), this.externalValidation("phoneNumber")),
            password: this.vAllOf(this.vPassword(), this.vMaxLength(250), this.externalValidation("password")),
        };

        if (this.allowAnonymousUsers) {
            validation.email = this.vAllOf(this.vMaxLength(250));
        } else {
            validation.email = this.vAllOf(this.vEmail(), this.vMaxLength(250), this.externalValidation("email"));
        }

        return validation;
    }

    /**
     * Compile an error message from our complaints map. Use the field names in the
     * fieldName map to produce a human readable text.
     */
    get errorMessage(): string {
        return this.createErrorMessage(this.complaints);
    }

    /**
     * The label for the email/username field.
     */
    get emailUsernameLabel(): string {
        return this.allowAnonymousUsers ? "Användarnamn" : "E-post";
    }

    /**
     * True if the current subscription allows anonymous users.
     */
    get allowAnonymousUsers(): boolean {
        return this.activeSubscription && this.activeSubscription.allowAnonymousUsers;
    }

    /**
     * Called when the user clicks the save button. Runs all validation functions and
     * if anyone fails, we store error messages in the complaints mapping mapped to
     * the name of the field.
     */
    save(): void {
        this.showSuccess = false;

        // We may have to filter the set of validation functions.
        let actualValidation = this.validation;
        if (this.activeSubscription && this.activeSubscription.skipPnrRequirement) {
            actualValidation = {
                firstName: this.validation.firstName,
                lastName: this.validation.lastName,
                email: this.validation.email,
                password: this.validation.password,
            }
        }

        // Run all validation functions and store error messages for those that fail.
        this.runValidationFunctions(actualValidation, this.complaints, this.fieldName, this.endUser);

        if (!this.errorMessage) {
            let body: EndUserSubscriptionParameters = new EndUserSubscriptionParameters();
            body.endUserParameters = this.endUser;
            body.subscriptionParameters = this.activeSubscription;
            HTTP.post<EndUserSubscriptionOperationResponse>("/sapi/subscription/update-user", body).then((response: EndUserSubscriptionOperationResponse) => {
                this.updateModel(response.endUserParameters);
                this.$store.commit(MUTATION_ACTIVE_USER, response.endUserParameters);
                /*
                  The lines below is a hack to remove the form from the DOM for a
                  short while, which is necessary for triggering the "Save Password"
                  dialog in Chrome. Unfortunately it makes the form blink, so this is
                  not an optimal solution.
                 */
                this.showSuccess = true;
                this.showForm = false;
                setTimeout(() => this.showForm = true, 1);

                setTimeout(() => {
                    this.showSuccess = false;
                    this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "menu");
                }, 1500);
            }).catch((response: AxiosResponse) => {
                this.handleErrorMessage(this.complaints, response.data);
            });
        }
    }

    /**
     * This method returns a validation function for the given field name that will
     * fail if there is a complaint for that field in the complaints map. Thus, when
     * we store complaints from the backend in that map, the corresponding field will
     * be marked as invalid as well.
     *
     * @param field The name of the field in the EndUserParameters object.
     */
    externalValidation(field: string): ValidationFunction {
        return () => this.complaints[field] ? ValidationStatus.FORCE_INVALID : ValidationStatus.VALID;
    }

    /**
     * Remove the error message for the given field (if it is still valid), as well as
     * any generic error message. This will be triggered on the change event for an
     * input field.
     *
     * @param fieldName The field in the EndUserParameters object.
     */
    fieldChange(fieldName: string) {
        this.onFieldChange(this.validation, this.complaints, fieldName, this.endUser);
    }

    private updateModel(endUser: EndUserParameters) {
        if (endUser) {
            this.endUser = JSON.parse(JSON.stringify(endUser));
        } else {
            this.endUser = new EndUserParameters();
        }
    }
}
