
import {Component, Mixins, Watch} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import PopupRegistrationForm from "@/components/PopupRegistrationForm.vue";

/**
 * This component represents a popup modal dialog.
 */
@Component({
    components: {
        PopupRegistrationForm,
    }
})
export default class PopupRegistration extends Mixins(StateHelper) {
    showPopup: boolean = false;


    /**
     * Checks if the current registration popup is of the given type.
     *
     * @param type The type.
     */
    isType(type: string): boolean {
        return this.popupRegistrationStatus.startsWith(type);
    }

    mounted() {
        // Sets the showPopup status flag. Needed since the Watch function will
        // not register the first value after page load.
        this.setDelayedPopup(this.popupRegistrationStatus)
    }

    /**
     * Sets the showPopup status flag a short while after the global status has
     * been set. By doing this we ensure the dom is rendered before adding the
     * class that controls the transition of the container, so that the
     * animation works as expected.
     *
     * @param val The new status value.
     */
    @Watch("popupRegistrationStatus")
    onMonitors(val: string) {
        this.setDelayedPopup(val);
    }

    /**
     * Sets the showPopup status flag after a short delay. By doing this we
     * ensure the dom is rendered before adding the class that controls the
     * transition of the container, so that the animation works as expected.
     *
     * @param val The new status value.
     */
    setDelayedPopup(val: string){
        setTimeout(() => {
            this.showPopup = !!val;
        }, 0);
    }
}
