import {Component, Mixins} from "vue-property-decorator";
import Serializer from './application-state-serializer'
import {
    EmailLoginApplicationState
} from '@/models/email-login-application-state'
import StateHelper from "@/mixins/state-helper";
import {Location} from "vue-router/types/router";
import {EntityViewRef} from "@/store/store-search";
import Utils, {UTILS} from "@/mixins/utils";

const PRESERVE_ENTITIES_STRING = "preserve_entities";

/**
 * This class contains various methods for saving and getting state related to
 * page navigation and for rerouting to pages properly.
 */
@Component({})
export default class Routing extends Mixins(Serializer, StateHelper) {

    /**
     * Reroutes to saved path or to "/" if no path state is saved. However, only
     * restore the saved state if the user who just signed in is the same as
     * the one previously signed in, or if there is no previously signed in
     * user. If we don't check for this we may end up with a situation where a
     * new user is redirected to the previous user's state.
     *
     * The path state should be stored on route.params.state
     */
    rerouteToSavedPath(): void {
        let params = this.$route.params;
        let sameOrNoPreviousUser: boolean = !this.$store.state.previousUserHash || UTILS.simpleHash(this.activeUser.refNo) === this.$store.state.previousUserHash;
        if (params.state && this.activeUser && sameOrNoPreviousUser) {
            let state = this.deserializeState(params.state);
            this.$router.replace({path: state.path, query: state.query});
        } else {
            this.$router.replace("/");
        }
    }

    /**
     * Gets the serialized {@link EmailLoginApplicationState} that is currently
     * saved on the global route object. If no state state exists, a new
     * EmailLoginApplicationState with no specified subscription and
     * userInviteLinkId field is created and serialized.
     */
    getSerializedState(): string {
        if (this.$route.params.state) {
            return this.$route.params.state;
        } else {
            return this.serializeState(null, null);
        }
    }

    /**
     * Indicates that open entities should be preserved when changing the route
     * on the search page. Does so by including the details in the query as
     * well as setting a "preserve entities" flag in the params. The flag can
     * be read using
     * {@link getPreserveEntities}.
     *
     * @param params params to add preserve entities flag to.
     * @param query query to add details to.
     * @param details The current open details that should be kept open after
     *     navigation.
     */
    static preserveEntities(params: Location["params"], query: Location["query"], details: EntityViewRef[]) {
        params[PRESERVE_ENTITIES_STRING] = "true";
        Utils.addDetailsToQuery(query, details);
    }

    /**
     * Returns true if the given params contains a "preserve entities" flag to
     * signal that entities should be preserved on the search page upon
     * navigation. Can be set using {@link preserveEntities}.
     * @param params
     */
    static getPreserveEntities(params: Location["params"]): boolean {
        return PRESERVE_ENTITIES_STRING in params && params[PRESERVE_ENTITIES_STRING] === "true";
    }
}
