
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import Utils, {UTILS} from "@/mixins/utils";
import {
    SearchResultBeneficialOwner
} from "@/models/search-result-beneficial-owner";
import SearchPageEntityBeneficialOwnerListEntry
    from "@/components/SearchPageEntityBeneficialOwnerListEntry.vue";


/**
 * This component represents a list of beneficial owners.
 */
@Component({
    components: {
        SearchPageEntityBeneficialOwnerListEntry,
    }
})
export default class SearchPageEntityBeneficialOwnerList extends Mixins(StateHelper, Utils) {
    /**
     * The list of beneficial owners.
     */
    @Prop()
    beneficialOwners: SearchResultBeneficialOwner[];

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is
     * used when determining if the link is active or not by checking if the id
     * in the detailIds array on the level to the right of this one, is the same
     * as our id.
     */
    @Prop()
    level: number;

    numToShow: number = UTILS.CHUNK_SIZE;


    /**
     * Gets the entries currently visible in the list. For large lists, we
     * don't show all entries since that may consume an excessive amount of
     * resources in the browser.
     */
    get visibleEntries(): SearchResultBeneficialOwner[] {
        return this.beneficialOwners.slice(0, this.numToShow);
    }
}
