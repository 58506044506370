import Vue from 'vue'
import Router from 'vue-router'
import VueRouter, {RawLocation, Route} from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import SearchPage from "@/views/SearchPage.vue";
import VerifyEmailPage from "@/views/VerifyEmailPage.vue";
import SSOLoginPage from "@/views/SSOLoginPage.vue";
import FullScreenMessagePage from "@/views/FullScreenMessagePage.vue";
import UserInvitePage from "@/views/UserInvitePage.vue";
import WelcomePage from "@/views/WelcomePage.vue";
import HelpUsHelpPage from "@/views/HelpUsHelpPage.vue";
import SelfServiceSignUpPage from "@/views/SelfServiceSignUpPage.vue";
import {UTILS} from "@/mixins/utils";
import SignInOneTimeCodePage from "@/views/SignInOneTimeCodePage.vue";

Vue.use(Router);

export type AnyFunction<RETURN_T = any> = (...args: any[]) => RETURN_T;

/*
  Newer Vue Router versions are more picky about what to consider an error
  during navigation. For example, it is an error to navigate to the same url
  twice, and also to navigate to a new url inside a navigation guard. The
  correct way to handle this is probably to always perform error handling on all
  places where navigation takes place but that may become a bit tedious. Hence,
  we override the default push() and replace() methods with ones that catches
  and ignores certain errors that we consider not to be serious.
 */

// Overrides the push() function.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
    return new Promise((resolve, reject) => {
        originalPush.call(this, location, () => {
            resolve(this.currentRoute);
        }, (err) => {
            handleError(this, err, resolve, reject);
        });
    });
};

// Overrides the replace() function.
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location: RawLocation): Promise<Route> {
    return new Promise((resolve, reject) => {
        originalReplace.call(this, location, () => {
            resolve(this.currentRoute);
        }, (err) => {
            handleError(this, err, resolve, reject);
        });
    });
};

// Common error handling for both push() and replace().
let handleError: AnyFunction = function (self: VueRouter, err: any, resolve: any, reject: any) {
    if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
        resolve(self.currentRoute);
    } else if (Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)) {
        resolve(self.currentRoute);
    } else {
        reject(err);
    }
};

/**
 * Paths that don't need authentication. Beginning slash is added in the export.
 */
let openPathNames = [
    "engangskod",
    "hjalp-oss-hjalpa",
    "inbjudan",
    "inloggad-i-annan-flik",
    "verifiera-inloggning",
    "sso",
];
export let openPaths = openPathNames.map(value => '/' + value);
// Useful if using window.location.pathname since that will include BASE_URL.
// BASE_URL includes trailing slash.
export let fullOpenPaths = openPathNames.map(value => process.env.BASE_URL + value);

export const PATH_REDIRECT_SIGN_IN: string = "/redirectSignIn";
export const REDIRECT_SIGN_IN_KEEP_STATE_QUERY_NAME: string = "keepState";
export const KEEP_SIDEBAR_OPEN_QUERY_NAME: string = "keepSidebarOpen";

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: HomePage
        },
        {
            path: "/sok",
            name: "search",
            component: SearchPage
        },
        {
            path: "/teckna-abonnemang",
            name: "self-service-sign-up",
            component: SelfServiceSignUpPage
        },
        {
            path: "/engangskod/:codeId",
            name: "sign-in-one-time-code",
            component: SignInOneTimeCodePage
        },
        {
            path: "/e/:codeId",
            redirect: "/engangskod/:codeId"
        },
        {
            path: "/verifiera-inloggning/:linkId",
            name: "verify-email",
            component: VerifyEmailPage
        },
        {
            path: "/v/:linkId",
            redirect: "/verifiera-inloggning/:linkId"
        },
        {
            path: "/inbjudan/:userInviteLinkId",
            name: "user-invite",
            component: UserInvitePage
        },
        {
            path: "/sso/:status?",
            name: "sso-login",
            component: SSOLoginPage,
        },
        {
            path: "/i/:userInviteLinkId",
            redirect: "/inbjudan/:userInviteLinkId"
        },
        {
            path: "/p/:encodedId",
            redirect: to => {
                let encodedId: string = to.params.encodedId;
                if (UTILS.isEncodedId(encodedId)) {
                    return {path: 'sok', query: {vad: encodedId}};
                } else {
                    return "/";
                }
            }
        },
        {
            path: "/inloggad-i-annan-flik",
            name: "signed-in-other-tab",
            component: FullScreenMessagePage,
            props: {type: "signed-in-other-tab"}
        },
        {
            path: "/valkommen",
            name: "welcome",
            component: WelcomePage
        },
        {
            path: "/hjalp-oss-hjalpa",
            name: "help-us-help",
            component: HelpUsHelpPage
        },
        {
            // No component here since it should always be caught in a
            // beforeEach function. Having this here prevents the router from
            // redirecting to "home" (the beforeEach function will in the end
            // redirect to "home").
            path: PATH_REDIRECT_SIGN_IN,
            name: "redirectSignIn"
        },
        {
            path: "/*",
            name: "missing",
            redirect: {name: "home"}
        }
    ]
})
