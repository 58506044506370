
import {Component, Prop, Vue} from 'vue-property-decorator';
import FNum from "@/components/FNum.vue";

/**
 * A simple table row with a header and a numerical value.
 */
@Component({
    components: {FNum}
})
export default class NumericalTableRow extends Vue {
    @Prop()
    header: string;

    @Prop()
    value: number;
}
