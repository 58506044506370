
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import VisibleAware from "@/mixins/visible-aware";
import StateHelper from "@/mixins/state-helper";
import {HTTP} from "@/services/http-provider";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Utils from "@/mixins/utils";
import {DisplayableDocument} from "@/models/displayable-document";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import {
    ACTION_FETCH_DOCUMENT_ENTITIES,
    ACTION_FLASH_NEW_DOCUMENT
} from "@/store/store-documents";
import SearchPageEntityDocumentList
    from "@/components/SearchPageEntityDocumentList.vue";
import SvgConstants from "@/mixins/svg-constants";
import {
    SearchResultEntryRealPropertyDetails
} from "@/models/search-result-entry-real-property-details";
import SearchPageEntitySimpleExpandable
    from "@/components/SearchPageEntitySimpleExpandable.vue";

/**
 * This component represents the documents tab for real properties.
 */
@Component({
    components: {
        SearchPageEntitySimpleExpandable,
        SearchPageEntityDocumentList,
        SearchPageEntityExpandable,
        ErrorMessage
    }
})
export default class SearchPageEntityRealPropertyDocuments extends Mixins(StateHelper, VisibleAware, Utils, SvgConstants) {
    @Prop()
    entity: SearchResultEntryRealPropertyDetails;

    documents: DisplayableDocument[] = [];

    loading: boolean = true;

    errorMessage: string = "";

    private firstLoadDone: boolean = false;


    /**
     * Reload documents if we're mounted while being visible.
     */
    mounted() {
        if (this.isVisible) {
            this.onVisibleChange(true);
        }
    }

    /**
     * Filters out the real property reports.
     */
    get realPropertyReports(): DisplayableDocument[] {
        return this.documents.filter((document) => document.type === "REAL_PROPERTY_REPORT");
    }

    /**
     * Filters out the real property taxation reports.
     */
    get realPropertyTaxationReports(): DisplayableDocument[] {
        return this.documents.filter((document) => document.type === "REAL_PROPERTY_TAXATION_REPORT");
    }

    /**
     * Filters out the title deed and historical owner reports.
     */
    get titleDeedAndHistoricalOwnerReports(): DisplayableDocument[] {
        return this.documents.filter((document) => document.type === "TITLE_DEED_AND_HISTORICAL_OWNER_REPORT");
    }

    /**
     * Reload documents when we become visible.
     *
     * @param val The new visible value.
     */
    @Watch("isVisible")
    onVisibleChange(val: boolean): void {
        if (val) {
            if (this.firstLoadDone) {
                return;
            }
            this.errorMessage = "";
            this.documents = [];

            this.loadDisplayableDocuments().then(() => this.firstLoadDone = true);
        }
    }

    private loadDisplayableDocuments(): Promise<void> {
        return new Promise<void>(resolve => {
            let timeout = setTimeout(() => this.loading = true, 500);
            let url: string = "/sapi/documents/list/" + this.entity.id;
            if (this.activeSubscriptionRefNo) {
                url = url + "?subscription=" + this.activeSubscriptionRefNo;
            }
            HTTP.get<Record<string, DisplayableDocument[]>>(url).then((documents: Record<string, DisplayableDocument[]>) => {
                let docs: DisplayableDocument[] = [];
                for (let key in documents) {
                    docs = docs.concat(documents[key]);
                }
                this.documents = docs;
            }).catch(response => {
                this.errorMessage = this.extractErrorMessage(response);
            }).finally(() => {
                clearTimeout(timeout);
                this.loading = false;
                resolve();
            });
        });
    }

    /**
     * Adds a new document to the list, and makes sure the entity is present in
     * the right sidebar document list as well.
     *
     * @param doc The document.
     */
    addNewDocument(doc: DisplayableDocument): void {
        this.documents.unshift(doc);
        this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES));
        this.$store.dispatch(ACTION_FLASH_NEW_DOCUMENT, doc);
    }

    /**
     * Deletes a document from the list and makes sure the entity is removed
     * from the right sidebar document list if we have no more documents for
     * this entity.
     *
     * @param documentId The document id.
     */
    deleteDisplayableDocument(documentId: string): void {
        this.removeDisplayableDocument(this.documents, documentId);
        this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES));
    }

    private removeDisplayableDocument(arr: DisplayableDocument[], documentId: string): void {
        arr.forEach((doc: DisplayableDocument, index: number) => {
            if (doc.id === documentId) {
                arr.splice(index, 1);
            }
        });
    }

    /**
     * True if were only showing one document type, in which case we can expand
     * that list by default.
     */
    get showSingleDocumentType(): boolean {
        return this.numberOfShownDocuments() === 1;
    }

    private numberOfShownDocuments(): number {
        return [
            this.allowFetchRealPropertyReport,
            this.allowFetchRealPropertyTaxationReport,
            this.allowFetchTitleDeedAndHistoricalOwnerReport
        ].filter(value => value === true).length
    }
}
