
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SelectOnClick from "@/components/SelectOnClick.vue";

/**
 * A spread in a catalog animation.
 */
@Component({
    components: {SelectOnClick}
})
export default class CatalogAnimationSpread extends Mixins(Utils) {

    /** 
     * The low page number in the spread
     */
	@Prop()
	low: number
	
    /** 
     * The high page number in the spread
     */	
	@Prop()
    high: number
    
    /** 
     * The index of the spread
     */ 
    @Prop()
    spread: number
    
    /** 
     * Whether it is the last spread or not
     */
    @Prop()
    lastSpread: boolean
    
    /** 
     * The string reference to the image blob
     */
    @Prop()
    imgStr: string
    
    /** 
     * The name of the image to use as the cover.
     */
    @Prop()
    coverStyle: string
    
    get imgStyle(): any {
    	return {"background-image":"url("+this.imgStr+")"};
    }
    
    get spreadClass(): string {
    	if(this.lastSpread) {
    		return "flipping_spread flipping_spread_last";
    	}
    	return "flipping_spread flipping_spread"+this.spread;
    }
    
    get leftClass(): string {
    	return "flipping_page flipping_page_left flipping_page"+this.high;
    }
    get rightClass(): string {
    	
    	let classes: string = "flipping_page flipping_page_right flipping_page"+this.low;
        if(this.low==0) {
            classes+=" income_cover_page "+this.coverStyle;  
        }
    	return "flipping_page flipping_page_right flipping_page"+this.low; 
    }
    
    get coverClass(): string {
    	return "flipping_page flipping_page_right flipping_page"+this.low+" income_cover_page "+this.coverStyle;  
    }
    
    get lastClass(): string {
    	return "flipping_page flipping_page_right flipping_page_final flipping_page"+this.low;  
    }
}
