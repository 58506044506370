

import {Component, Prop} from "vue-property-decorator";
import Vue from 'vue';
import {MonitorPriority} from "@/models/frontend-monitor-config";

/**
 * Component for displaying a priority picker together with an explaination in front of it.
 */
@Component
export default class AutoSelfPriorityPicker extends Vue {

    @Prop()
    description: string;

    @Prop({required: true})
    priority: MonitorPriority;

    @Prop()
    disableToggles: boolean;

}
