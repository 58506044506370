
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {MapAddress} from "@/components/SearchPageEntityInfoMap.vue";
import SearchPageEntityInfoExpandableMap
    from "@/components/SearchPageEntityInfoExpandableMap.vue";
import SearchPageEntityEvent from "@/components/SearchPageEntityEvent.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import {
    SearchResultEntryAddressLocationDetails
} from "@/models/search-result-entry-address-location-details";
import SearchPageEntityApartmentList
    from "@/components/SearchPageEntityApartmentList.vue";
import {SearchResultApartmentInfo} from "@/models/search-result-apartment-info";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import {DisplayableEvent} from "@/models/displayable-event";
import CustomSelect, {CustomSelectOption} from "@/components/CustomSelect.vue";
import SearchPageEntityRealPropertiesList
    from "@/components/SearchPageEntityRealPropertiesList.vue";
import SearchPageEntityRealPropertyList
    from "@/components/SearchPageEntityRealPropertyList.vue";
import SearchPageEntityHouseholdMemberList
    from "@/components/SearchPageEntityHouseholdMemberList.vue";
import SearchPageEntityEventList
    from "@/components/SearchPageEntityEventList.vue";
import {ClickEventData} from "@/views/SearchPage.vue";
import SearchPageEntitySimpleCompanyList
    from "@/components/SearchPageEntitySimpleCompanyList.vue";
import SearchPageEventFilterSettings
    from "@/components/SearchPageEventFilterSettings.vue";

/**
 * This component represents the info tab for address locations in a search
 * result details view.
 */
@Component({
    components: {
        SearchPageEventFilterSettings,
        SearchPageEntitySimpleCompanyList,
        SearchPageEntityEventList,
        SearchPageEntityHouseholdMemberList,
        SearchPageEntityRealPropertyList,
        SearchPageEntityRealPropertiesList,
        CustomSelect,
        SearchPageEntityApartmentList,
        SearchPageEntityExpandable,
        SearchPageEntityEvent,
        SearchPageEntityInfoExpandableMap,
        SelectOnClick
    }
})
export default class SearchPageEntityAddressLocationInfo extends Mixins(StateHelper, SvgConstants, Utils) {
    readonly ALL_FILTER_OPTION: CustomSelectOption = new CustomSelectOption("1", "Allt på adressen");

    readonly COMPANY_FILTER_OPTION: CustomSelectOption = new CustomSelectOption("2", "Företag");

    readonly NO_APARTMENT_NUMBER_FILTER_VALUE: CustomSelectOption = new CustomSelectOption("3", "Saknar lägenhetsnummer");

    readonly REAL_PROPERTY_FILTER_VALUE: CustomSelectOption = new CustomSelectOption("4", "Fastigheter");


    @Prop()
    level: number;

    @Prop()
    addressLocation: SearchResultEntryAddressLocationDetails;

    @Prop()
    eventFilters: string[];

    /**
     * Gets addresses to be shown on the map.
     */
    get mapAddresses(): MapAddress[] {
        let ret: MapAddress[] = [];
        ret.push(new MapAddress("", this.addressTag, this.addressLocation.coord));
        return ret;
    }

    /**
     * Gets the contents of the address tag as html.
     */
    get addressTag(): string {
        if (this.addressLocation.streetAddress) {
            return [this.addressLocation.streetAddress, this.addressLocation.city].join("<br>");
        } else {
            return this.addressLocation.city;
        }
    }

    /**
     * Getter for the filter stored in the EntityViewRef for this level. This
     * is the numerical filter value that is updated on navigation and here we
     * convert it to the type of value we have in our drop-down menu.
     */
    get filterValue(): string {
        if (this.details.length > this.level) {
            let filterNum: number = this.details[this.level].addressLocationFilter;
            if (!filterNum) {
                return this.ALL_FILTER_OPTION.value;
            } else {
                switch (filterNum) {
                    case 1:
                        return this.ALL_FILTER_OPTION.value;
                    case 2:
                        return this.COMPANY_FILTER_OPTION.value;
                    case 3:
                        return this.NO_APARTMENT_NUMBER_FILTER_VALUE.value;
                    case 4:
                        return this.REAL_PROPERTY_FILTER_VALUE.value;
                    default:
                        /*
                          We may have the case where we are given an apartment
                          number which we have no information about anumor. In
                          that case, use the ALL filter as fallback.
                         */
                        let apartmentNum: string = String(filterNum).padStart(4, '0');
                        return this.filterApartments(apartmentNum).length === 0 ? this.ALL_FILTER_OPTION.value : apartmentNum;
                }
            }
        }
        return this.ALL_FILTER_OPTION.value;
    }

    /**
     * Formats the street address, adding the apartment number if appropriate.
     */
    get streetAddress(): string {
        let ret: string = this.addressLocation.streetAddress;
        if (this.isApartmentFilter) {
            ret += " lgh " + this.filterValue;
        }
        return ret;
    }

    /**
     * True if we're currently using the "all" filter display mode.
     */
    get isAllFilter(): boolean {
        return this.filterValue === this.ALL_FILTER_OPTION.value;
    }

    /**
     * True if we're currently using the "companies" filter display mode.
     */
    get isCompanyFilter(): boolean {
        return this.filterValue === this.COMPANY_FILTER_OPTION.value;
    }

    /**
     * True if we're currently using the "no apartment number" filter display
     * mode.
     */
    get isNoApartmentNumberFilter(): boolean {
        return this.filterValue === this.NO_APARTMENT_NUMBER_FILTER_VALUE.value;
    }

    /**
     * True if we're currently using a filter display mode for a specific
     * apartment.
     */
    get isApartmentFilter(): boolean {
        return this.filterValue.length === 4;
    }

    /**
     * True if we're currently using the "real property" filter display mode.
     */
    get isRealPropertyFilter(): boolean {
        return this.filterValue === this.REAL_PROPERTY_FILTER_VALUE.value;
    }

    /**
     * Formats the header for the apartment list.
     */
    get apartmentListHeader(): string {
        return this.isApartmentFilter ? "Boende i lägenheten" : "Boende på adressen";
    }

    /**
     * Getter for events filtered using the display mode filter as well as
     filter specified from the event filter settings.
     *
     */
    get filteredEvents(): DisplayableEvent[] {
        if (this.addressLocation.events === null) {
            return [];
        } else {
            return this.addressLocation.events
                .filter(this.displayModeEventsFilter)
                .filter((event) => this.filterAddressLocationEvent(event.type, this.eventFilters));
        }
    }

    /**
     * Gets the number of events that were filtered from the filter settings.
     */
    get nrOfFilteredFromFilterSettings(): number {
        if (this.addressLocation.events === null) {
            return 0;
        } else {
            const after_display_filter = this.addressLocation.events
                .filter(this.displayModeEventsFilter);
            const after_event_filter = after_display_filter
                .filter((event) => this.filterAddressLocationEvent(event.type, this.eventFilters));
            return after_display_filter.length - after_event_filter.length;
        }
    }

    /**
     * Filters depending on what display mode filter currently is in use.
     */
    displayModeEventsFilter(event: DisplayableEvent): boolean {
        if (this.isAllFilter)
            return true;
        if (this.isCompanyFilter)
            return this.isCompanyEvent(event);
        if (this.isNoApartmentNumberFilter)
            return event.payload.lgh === "";
        else
            return event.payload.lgh === this.filterValue;
    }

    /**
     * Getter for apartments filtered using the current filter.
     */
    get filteredApartments(): SearchResultApartmentInfo[] {
        if (this.isAllFilter) {
            return this.addressLocation.apartments;
        } else if (this.isCompanyFilter) {
            return [];
        } else if (this.isNoApartmentNumberFilter) {
            return this.addressLocation.apartments.filter(apartment => apartment.apartmentNumber === "");
        } else {
            return this.filterApartments(this.filterValue);
        }
    }

    /**
     * Gets apartments with the given four digit apartment number.
     *
     * @param apartmentNumber The four digit apartment number.
     */
    filterApartments(apartmentNumber: string): SearchResultApartmentInfo[] {
        return this.addressLocation.apartments.filter(apartment => apartment.apartmentNumber === apartmentNumber);
    }

    /**
     * Gets the list of options to present in the drop down menu.
     */
    get options(): CustomSelectOption[] {
        let ret: CustomSelectOption[] = [
            this.ALL_FILTER_OPTION,
            this.COMPANY_FILTER_OPTION,
            this.REAL_PROPERTY_FILTER_VALUE
        ];
        return ret.concat(this.addressLocation.apartments.map(apartment => apartment.apartmentNumber ? new CustomSelectOption(apartment.apartmentNumber, "Lägenhet " + apartment.apartmentNumber) : this.NO_APARTMENT_NUMBER_FILTER_VALUE));
    }

    /**
     * Formats the description for an apartment.
     *
     * @param apartment An apartment.
     */
    apartmentDescription(apartment: SearchResultApartmentInfo): string {
        return apartment.apartmentNumber ? "Lägenhet " + apartment.apartmentNumber : "Saknar lägenhetsnummer"
    }

    /**
     * Returns true if the given event is a company event, and thus should be
     * visible when using the company filter.
     *
     * @param evt An event.
     */
    isCompanyEvent(evt: DisplayableEvent): boolean {
        switch (evt.type) {
            case "AddressLocationCompanyDeregistered":
            case "AddressLocationCompanyRegistered":
                return true;
            default:
                return false;

        }
    }

    /**
     * Returns the number of individuals in the first x apartments, for any
     * given x.
     *
     * @param numApartments The number of apartmets to count individuals in.
     */
    numIndividualsInXFirstApartments(numApartments: number): number {
        let num: number = Math.min(numApartments, this.filteredApartments.length);
        let count: number = 0;
        this.filteredApartments.slice(0, num).forEach((aparment: SearchResultApartmentInfo) => count += aparment.individuals.length);
        return count;
    }

    /**
     * This method is called when the user changes the value of the filter
     * drop-down. It triggers navigation to an url containing info about the
     * filter, which in turn triggers the actual filtering in this component,
     * through the "filterValue" getter.
     */
    filterChanged(newValue: string): void {
        let entityReference = new OpenEntityRequest(this.addressLocation.id, this.level, "same");
        entityReference.addressLocationFilter = Number(newValue);
        entityReference.replaceParent = true;
        this.openEntityView({data: entityReference});
    }

    /**
     * Re-emits the request to open a new entity.
     */
    @Emit()
    openEntityView(event: ClickEventData<OpenEntityRequest>): ClickEventData<OpenEntityRequest> {
        return event;
    }

}
