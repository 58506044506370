
    import {Component, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import PopupMessageCloseButton from "@/components/PopupMessageCloseButton.vue";

    /**
     * This component represents the popup showing the "reason" one ought to have when
     * creating a credit report for an individual.
     */
    @Component({
        components: {PopupMessageCloseButton}
    })
    export default class PopupMessageReason extends Mixins(SvgConstants) {
    }
