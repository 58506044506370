
    import {Component, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import PopupMessageCloseButton
        from "@/components/PopupMessageCloseButton.vue";

    /**
     * This component represents the popup telling the user that there is a new
     * version of the user interface, and that she thus has to reload the page.
     */
    @Component({
        components: {PopupMessageCloseButton}
    })
    export default class PopupMessageReload extends Mixins(SvgConstants) {
        /**
         * Reloads the page.
         */
        reload(): void {
            location.reload();
        }
    }
