
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import {SearchResultNeighbor} from "@/models/search-result-neighbor";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {GIVEN_NAME_FILTER} from "@/services/filters";
import SearchPageEntityInfoHeader
  from "@/components/SearchPageEntityInfoHeader.vue";
import FNum from "@/components/FNum.vue";
import StatusIconCell from "@/components/StatusIconCell.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";

/**
 * This component represents a list of neighbors.
 */
@Component({
    components: {
      StatusIconRow,
        StatusIconCell,
        FNum, SearchPageEntityInfoHeader, SearchPageEntityLink
    }
})
export default class SearchPageEntityNeighborList extends Mixins(StateHelper) {
    /**
     * The list of neighbors.
     */
    @Prop()
    neighbors: SearchResultNeighbor[];

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;


    // noinspection JSMethodCanBeStatic
    /**
     * Helper for extracting the complete name of a neighbor.
     *
     * @param neighbor The neighbor.
     */
    neighborName(neighbor: SearchResultNeighbor): string {
        let firstNames = GIVEN_NAME_FILTER(neighbor.firstNames, neighbor.givenNameCode, "extract-with-fallback");
        return `${firstNames} ${neighbor.lastNames}`;
    }
}
