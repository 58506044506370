
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import VisibleAware from "@/mixins/visible-aware";
import StateHelper from "@/mixins/state-helper";
import IncomeAnimation from "@/components/IncomeAnimation.vue";
import RemarkAnimation from "@/components/RemarkAnimation.vue";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import SearchPageEntityNeighborList
    from "@/components/SearchPageEntityNeighborList.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import {SearchResultEntryCatalog} from "@/models/search-result-entry-catalog";
import SearchPageEntityHouseholdMemberListWithHeading
    from "@/components/SearchPageEntityHouseholdMemberListWithHeading.vue";

/**
 * This component represents the income tab in a search result details view.
 */
@Component({
    components: {
        SearchPageEntityHouseholdMemberListWithHeading,
        SearchPageEntityExpandable,
        SearchPageEntityInfoHeader,
        SearchPageEntityNeighborList,
        IncomeAnimation,
        RemarkAnimation,
    }
})
export default class SearchPageEntityIndividualIncome extends Mixins(SvgConstants, Utils, VisibleAware, StateHelper) {
    @Prop()
    individual: SearchResultEntryIndividualDetails;

    @Prop()
    level: number;

    /**
     * Set to true when showing income catalogs for multiple years.
     */
    showAll: boolean = false;

    /**
     * Formats the heading for each income catalog.
     *
     * @param catalog The catalog.
     */
    formatIncomeHeading(catalog: SearchResultEntryCatalog): string {
    	if(catalog.incomeYear == -1 || (catalog.middleX == -1 && catalog.middleY == -1)) {
    		return "Inkomst";
    	}
        return "Inkomst " + catalog.incomeYear;
    }
}
