/**
 * Frontend enumeration for entity types.
 */
export enum EntityType {
    UNKNOWN = 0,
    INDIVIDUAL = 1,
    ORGANISATION = 2,
    ADDRESS_LOCATION = 3,
    REAL_PROPERTY = 4,
    VEHICLE = 5,
}
