import {EntityStatus} from "@/models/entity-status";

/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
export class SearchResultBaseIndividual {
    givenNameCode: string;

    firstNames: string;

    middleNames: string;

    lastNames: string;

    secretIdentity: boolean;

    status: EntityStatus;
}
