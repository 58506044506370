
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import {BankIDAuthRequest} from "@/models/auth-request.model";
import {ACTION_AUTHENTICATE_OR_SIGN_WITH_BANK_ID, ACTION_CANCEL_BANK_ID} from "@/store/store";
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import ErrorMessage from "@/components/ErrorMessage.vue";
import SimpleQr from "@/components/SimpleQr.vue";
import {UserErrorMessage} from "@/models/error-message";
import {SafenodeQuestionerParameters} from "@/models/safenode-questioner-parameters";
import {ACTION_FETCH_QUESTIONERS} from "@/store/store-questioner";
import TextIconLink from "@/components/TextIconLink.vue";

/**
     * This component handles authentication and signing with BankID.
     */
    @Component({
        components: {ErrorMessage,SimpleQr,TextIconLink},
    })
    export default class BankIdAuthentication extends Mixins(StateHelper, SvgConstants, Utils) {
        /**
         * The text to be displayed to the user. Not needed if questioner is
         * present.
         */
        @Prop()
        text: string;

        /**
         * The type of BankID operation to perform. Either 'authenticate' or 'sign'.
         */
        @Prop()
        type: string;

        /**
         * Set to true if the button should be clickable.
         */
        @Prop({default: true})
        enableButton: boolean;

        /**
         * Questioner parameters if this BankID operation is for signing the terms for
         * a new questioner. This being set implicitly means that the operation is
         * 'sign' and that we should use values specified in these parameters for
         * authentication calls.
         */
        @Prop()
        questioner: SafenodeQuestionerParameters;

        /**
         * True if we should show the spinner.
         */
        showSpinner: boolean = false;

        errorMessage: string = "";

        /**
         * This request object lives during a single authentication process. This makes it
         * possible for the user to abort by setting the abort flag.
         */
        private authRequest: BankIDAuthRequest;

        /**
         * Perform authentication process.
         */
        authenticate(): void {
            if(this.showSpinner) {
                return;
            }
            
            this.errorMessage = "";
            this.showSpinner = true;
            let refNoOrZero: string = this.activeSubscriptionRefNo || "ZERO";
            let self = this;

            if (this.questioner) {
                this.authRequest = new BankIDAuthRequest(this.questioner.name, this.type, this.questioner.orgNo);
            } else {
                this.authRequest = new BankIDAuthRequest(refNoOrZero, this.type, undefined);
            }
            this.$store.dispatch(ACTION_AUTHENTICATE_OR_SIGN_WITH_BANK_ID, this.authRequest)
                .catch((response: UserErrorMessage | any) => {
                    /*
                      The status "aborted" means the user aborted from the gui, and there
                      is no need to tell her that.
                    */
                    if (response.errorType !== "aborted") {
                        this.errorMessage = this.extractErrorMessage(response);
                    }
                })
                .finally(() => {
                    /*
                      The BankID process is done, let us reload the list of
                      questioners to ensure that they are updated.
                    */
                    self.$emit("bank-id-completed");
                    this.ignoreError(this.$store.dispatch(ACTION_FETCH_QUESTIONERS));
                    this.showSpinner = false;
                });
        }

        /**
         * Aborts an ongoing BankID authentication process.
         */
        abort(): void {
            this.showSpinner = false;
            this.authRequest.abort = true;
            if(!!this.authRequest && !!this.authRequest.orderRef) {
                this.$store.dispatch(ACTION_CANCEL_BANK_ID, this.authRequest.orderRef).catch(() => {});
            }
        }
    }
