
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import {CustomSelectOption} from "@/components/CustomSelect.vue";

/**
 * This component represents an expandable part in the details view.
 */
@Component({
    components: {
        ProgressBar,
        ErrorMessage,
        SearchPageEntityInfoHeader,
        SearchPageEntityCompanyInfoHeader: SearchPageEntityInfoHeader
    }
})
export default class SearchPageEntityExpandable extends Vue {
    /**
     * The header.
     */
    @Prop()
    heading: string;

    /**
     * A class to set on the heading.
     */
    @Prop()
    headingClass: string;

    /**
     * The text to show as the "expand" link at the right edge.
     */
    @Prop({default: "Visa alla"})
    expandText: string;

    /**
     * The text to show as the "contract" link at the right edge.
     */
    @Prop({default: "Visa färre"})
    contractText: string;

    /**
     * The number of elements to show in the "teaser" section.
     */
    @Prop({default: 0})
    teaserSize: number;

    /**
     * The number of elements in the list.
     */
    @Prop({default: 0})
    listLength: number;

    /**
     * If specified, we show this number instead of the actual teaser size in
     * the header.
     */
    @Prop()
    teaserSizeHeader: number;

    /**
     * If specified, we show this number instead of the actual list length in
     * the header.
     */
    @Prop()
    listLengthHeader: number;

    /**
     * The text to show if there is nothing to show in the expandable part.
     */
    @Prop()
    nothingToShowText: string;

    /**
     * Set to some string to show as fixed icon text if non-expandable.
     * Useful when we only have one short line of text to display and that
     * line can fit on the same line as the header.
     */
    @Prop()
    fixedIconText: string;

    /**
     * Set to true if we should never show the number of entries.
     */
    @Prop({default: false})
    hideCount: boolean;

    /**
     * The options to put in the dropdown at the right on the header line. A
     * falsy value means that no dropdown should be shown. When the user selects
     * an option a "select-change" event is triggered, with the selected value
     * as event payload.
     */
    @Prop()
    options: CustomSelectOption[];

    /**
     * The default value. If this is changed from outside the component the
     * selected value will be updated accordingly.
     */
    @Prop()
    default: string;

    /**
     * The min width of the dropdown in pixels. May be used in order to avoid
     * wide items to be hidden. Not the most elegant solution, but still...
     */
    @Prop()
    dropDownMinWidth: string;

    /**
     * Set to true to force showing the expanded part.
     */
    @Prop({default: false})
    forceExpand: boolean;

    /**
     * Show some sort of spinner instead of the lists, while true.
     */
    @Prop({default: false})
    loading: boolean;

    /**
     * Show this error message instead of the lists, if it is set.
     */
    @Prop()
    errorMessage: string;

    /**
     * Whether to show the expanded part or not.
     */
    expanded: boolean = false;


    /**
     * Returns true if we have something to show in the expanded part.
     */
    get canExpand(): boolean {
        return this.teaserSize < this.listLength && this.listLength > 0;
    }

    get showNothingToShow(): boolean {
        return this.listLength === 0 && !this.loading && !!this.nothingToShowText;
    }

    /**
     * Toggles whether to show the expanded part or not.
     */
    toggle(): void {
        if (this.canExpand) {
            this.expanded = !this.expanded;
            this.$emit("expand-change", this.expanded);
        }
    }

    /**
     * Returns the header string with the number of shown elements and the
     * number of total elements if necessary.
     */
    formatHeader(): string {
        let teaserSize: number = this.teaserSizeHeader ? this.teaserSizeHeader : this.teaserSize;
        let listLength: number = this.listLengthHeader ? this.listLengthHeader : this.listLength;

        if (listLength <= teaserSize || listLength <= 1 || this.hideCount) {
            return this.heading;
        }
        let numShown: number = this.expanded ? listLength : teaserSize;
        return this.heading + " (" + numShown + " av " + listLength + ")";
    }

    /**
     * Returns the map to pass to the :class attribute for the heading.
     */
    headingClassMap(): any {
        let classMap: any = {
            expandable: this.canExpand
        };
        if (this.headingClass) {
            classMap[this.headingClass] = true;
        }
        return classMap;
    }

    /**
     * Renders the text for the expand and contract link.
     */
    headingIconText(): string {
        if (this.fixedIconText) {
            return this.fixedIconText;
        } else if (!this.canExpand) {
            return "";
        } else if (this.forceExpand) {
            return ""
        } else if (this.expanded) {
            return this.contractText + " &#9661;";
        } else {
            return this.expandText + " &#9655;";
        }
    }

    get isExpanded(): boolean {
        return this.forceExpand || this.expanded;
    }
}
