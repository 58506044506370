
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SvgConstants from "@/mixins/svg-constants";
import {EndUserParameters} from "@/models/end-user-subscription-parameters";
import CustomInput from "@/components/CustomInput.vue";
import Validation, {
    ValidationFunction,
    ValidationStatus
} from "@/mixins/validation";
import Utils from "@/mixins/utils";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PopupRegistrationFormDataPolicy
    from "@/components/PopupRegistrationFormDataPolicy.vue";

/**
 * This component represents a form where user details can be filled in.
 */
@Component({
    components: {PopupRegistrationFormDataPolicy, ErrorMessage, CustomInput}
})
export default class PopupRegistrationFormAccountDetails extends Mixins(StateHelper, SvgConstants, Utils, Validation) {
    @Prop()
    heading: string;

    @Prop()
    signInLinkClicked: Function;

    @Prop()
    backClicked: Function;

    @Prop()
    errorResponse: any;

    @Prop()
    skipPnrAndPhone: boolean;

    @Prop()
    allowAnonymousUsers: boolean;

    endUser: EndUserParameters = new EndUserParameters();

    termsAccepted: boolean = false;


    /**
     * Mapping from the name of the field to an error message for that field. These
     * can be set both during frontend validation but also when receiving a validation
     * error message from the server. We also have a 'generic' mapping to which other
     * types of errors can be mapped. The error message shown below the fields is
     * composed of these messages. Notice that it is important to initialize all
     * mappings since Vue otherwise can't track when they change.
     */
    private complaints: any = {
        generic: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        personalNumber: "",
    };


    /**
     * Compile an error message from our complaints map. Use the field names in the
     * fieldName map to produce a human-readable text.
     */
    get errorMessage(): string {
        if (!this.errorResponse) {
            return "";
        }
        return this.createErrorMessage(this.complaints);
    }

    /**
     * The label to user for the email or username field, which depends on if
     * the subscription allows anonymous users or not.
     */
    get emailUsernameLabel(): string {
        return this.allowAnonymousUsers ? "Användarnamn:" : "E-post:";
    }

    /**
     * We have to use dynamic field names since anonymous users have a
     * "username" instead of an "email".
     */
    get fieldName(): any {
        /**
         * Mapping from the name of the field to a human-readable string. See
         * Validation#runValidationFunctions for more info.
         */
        let fieldName: any = {
            firstName: "Förnamnet",
            lastName: "Efternamnet",
            phoneNumber: "Telefonnumret",
            password: "Lösenordet",
            personalNumber: "Personnumret",
        };
        fieldName.email = this.allowAnonymousUsers ? "Användarnamnet" : "E-postadressen";
        return fieldName;
    }

    /**
     * Mapping from the name of the field in the EndUserParameters object to the
     * validation function for that field. We have to handle this dynamically
     * since the validation varies depending on if the subscription allows
     * anonymous users or not.
     */
    get validation(): any {
        let validation: any = {
            firstName: this.vAllOf(this.vLength(1, 250), this.externalValidation("firstName")),
            lastName: this.vAllOf(this.vLength(1, 250), this.externalValidation("lastName")),
            phoneNumber: this.vAllOf(this.vPhone(), this.vMaxLength(250), this.externalValidation("phoneNumber")),
            personalNumber: this.vAllOf(this.vModulo10CheckSum(), this.externalValidation("personalNumber")),
        };
        if (this.allowAnonymousUsers) {
            validation.email = this.vAllOf(this.vMaxLength(250), this.externalValidation("email"));
        } else {
            validation.email = this.vAllOf(this.vEmail(), this.vMaxLength(250), this.externalValidation("email"));
        }
        return validation;
    }

    /**
     * Called when the user clicks the submit button. Runs all validation functions and
     * if anyone fails, we store error messages in the complaints mapping mapped to
     * the name of the field.
     */
    submit(): void {
        if (!this.termsAccepted) {
            this.complaints.generic = "Du måste acceptera användarvillkoren.";
        }

        // We may have to filter the set of validation functions.
        let actualValidation = this.validation;
        if (this.skipPnrAndPhone) {
            actualValidation = {
                firstName: this.validation.firstName,
                lastName: this.validation.lastName,
                email: this.validation.email,
            }
        }

        // Run all validation functions and store error messages for those that fail.
        this.runValidationFunctions(actualValidation, this.complaints, this.fieldName, this.endUser);

        if (!this.errorMessage) {
            this.$emit("confirm-clicked", this.endUser);
        }
    }

    /**
     * This method returns a validation function for the given field name that will
     * fail if there is a complaint for that field in the complaints map. Thus, when
     * we store complaints from the backend in that map, the corresponding field will
     * be marked as invalid as well.
     *
     * @param field The name of the field in the EndUserParameters object.
     */
    externalValidation(field: string): ValidationFunction {
        return () => this.complaints[field] ? ValidationStatus.FORCE_INVALID : ValidationStatus.VALID;
    }

    /**
     * Remove the error message for the given field (if it is still valid), as well as
     * any generic error message. This will be triggered on the change event for an
     * input field.
     *
     * @param fieldName The field in the EndUserParameters object.
     */
    fieldChange(fieldName: string) {
        this.onFieldChange(this.validation, this.complaints, fieldName, this.endUser);
    }

    /**
     * When the parent component receives an error message from the subscription
     * creation, the errorResponse property is updated. Here we take care of the
     * error messages that belong to this component.
     *
     * @param val The response. ValidationErrorMessage or a UserErrorMessage responses
     *            are handled properly. Others are ignored.
     */
    @Watch("errorResponse")
    onErrorResponseChange(val: any): void {
        this.handleErrorMessage(this.complaints, val);
    }
}
