import Axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse
} from "axios";
import {Global} from "@/global";
import {signOutRedirectIfSuitable,} from './auth-service'
import {MUTATION_NOTIFICATION_TIME} from "@/store/store-notification";
import {
    ACTION_FETCH_BANNER_MESSAGE,
    ACTION_REFRESH_AUTH_TOKENS,
    MUTATION_BANNER_MESSAGE,
    MUTATION_POPUP_MESSAGE_STATUS,
    MUTATION_SIDEBAR_RIGHT_STATUS,
    store
} from "@/store/store";

/**
 * Axios always treats headers in lower case.
 */
const CLIENT_VERSION_HEADER: string = "x-client-version";
const GENERATION_HEADER: string = "x-generation";
const NOTIFICATION_TIME_HEADER: string = "x-notification-time";
const BANNER_HEADER: string = "x-banner";

/**
 * A wrapped response containing the same id as was given when the request was
 * initiated.
 */
export class WrappedResponse<T> {
    /**
     * Constructor.
     *
     * @param response The response from the http request.
     * @param requestId The id that was given when the request was initiated.
     */
    constructor(response: T, requestId: number) {
        this.response = response;
        this.requestId = requestId;
    }

    response: T;

    requestId: number;
}

/**
 * Extension to the request configuration that allows us to specify own
 * attributes to be used for example in interceptors.
 */
export interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    /**
     * The number of times to retry a request if it failed.
     */
    numRetries?: number;

    /**
     * Number of times tried so far.
     */
    __retryCount?: number;

    /**
     * The delay in milliseconds between each retry.
     */
    retryDelayMs?: number;

    /**
     * If the response should not be caught and processed in any receptor
     */
    dontCatch?: boolean;
}

/**
 * Axios based http functionality. We should only have one global instance of
 * this class.
 *
 * Notice that this class prepends the Global.BASE_URL to all request urls.
 * Thus, inside the application urls using this class for interaction with the
 * backend should NOT include the base url in the specified urls. For example,
 * inside the application we write "/api/auth/login/credentials", which by this
 * class will be mapped to "<BASE_URL>/api/auth/login/credentials".
 */
export class Http {
    /**
     * If this promise exists, we know we're currently performing a refresh.
     * Other calls that also needs refresh can then wait on this promise instead
     * of initiating a new refresh.
     */
    private refreshPromise: Promise<void>;


    /**
     * The axios client to use.
     */
    private axiosClient: AxiosInstance;

    private clientVersion: string = null;

    private indexGeneration: string = null;

    private bannerVersion: string = null;


    constructor() {
        let self = this;

        // The ApiClient wraps calls to the underlying Axios client.
        this.axiosClient = Axios.create({
            timeout: 60000,
            headers: {
                "X-Initialized-At": Date.now().toString()
            }
        });


        /**
         * Create an interceptor that adds the current known client version to
         * each request, so that the sever can determine if it should recommend
         * us to reload the page or not.
         */
        this.axiosClient.interceptors.request.use((config: ExtendedAxiosRequestConfig) => {
            if (this.clientVersion) {
                config.headers.common[CLIENT_VERSION_HEADER] = btoa(this.clientVersion).replace(/=+$/, "");
            }
            return config;
        });

        /**
         * Add interceptor that checks for the NOTIFICATION_TIME_HEADER in all
         * responses and updates the stored timestamp if it has changed. A new
         * timestamp indicates there may be new monitor notifications to fetch.
         */
        this.axiosClient.interceptors.response.use((response: AxiosResponse) => {
            if (response && response.headers && response.headers[NOTIFICATION_TIME_HEADER]) {
                /*
                  Create temporary "any" reference here, since typescript's type
                  checking does not understand the VuEx module stuff.
                 */
                let state: any = store.state;

                let serverNotificationTime = atob(response.headers[NOTIFICATION_TIME_HEADER]);
                if (state.notification.notificationTime !== serverNotificationTime) {
                    store.state.appLoaded.then((success: boolean) => {
                        if (success) {
                            store.commit("notification/" + MUTATION_NOTIFICATION_TIME, serverNotificationTime);
                        }
                    });
                }
            }
            return response;
        });

        /**
         * Create an interceptor that handles the case where we have to reload
         * the application in order to get the latest functionality. There are
         * two main cases for this:
         *
         * 1) We detect that the client version sent by the server is later than
         *    the one we're aware of. In this case we simply reload the page
         *    silently.
         * 2) The server returns a 418 response for a request. This means the
         *    request will not be served with the version we sent to the server.
         *    In this case we show a message to the user asking her to reload
         *    the application.
         *
         * Also see ReloadOnNewVersionFilter.java for more information.
         */
        this.axiosClient.interceptors.response.use((response: AxiosResponse) => {
            if (response && response.headers && response.headers[CLIENT_VERSION_HEADER]) {
                let newVersion = atob(response.headers[CLIENT_VERSION_HEADER]);
                if (this.clientVersion === null) {
                    // We have no version yet - set it to the server's version.
                    this.clientVersion = newVersion;
                } else if (this.clientVersion < newVersion) {
                    //  Case 1 above - the server's version is later than ours.
                    location.reload();
                }
            }
            return response;
        }, (err: any) => {
            if (err && err.response && err.response.status === 418) {
                // Case 2 above - show a message to the user.
                store.commit(MUTATION_POPUP_MESSAGE_STATUS, "reload");
            }
            return Promise.reject(err);
        });

        /**
         * Add an interceptor that checks for the GENERATION_HEADER in all
         * responses. If the header has changed, the new value is later than the
         * previously stored and the request is a search or an entity view, we
         * reload the application since there may be new data for already open
         * detail views. Notice that this will happen as soon as we see a new
         * generation. There is a theoretical possibility that the request where
         * we first see a new generation hit the first web node, so that when
         * reloading we may have requests hitting the second web node, which may
         * still have the old index version. However, the normal time between
         * loading of the index on the first and second web node is around three
         * milliseconds, so that is an acceptable risk for now. We may have to
         * rethink that if we would scale up to many more web nodes in the
         * future.
         *
         * Also see ReloadOnIndexChangeFilter.java.
         */
        this.axiosClient.interceptors.response.use((response: AxiosResponse) => {
            if (response && response.headers && response.headers[GENERATION_HEADER]) {
                let newGeneration = atob(response.headers[GENERATION_HEADER]);
                if (this.indexGeneration === null) {
                    // We have no index generation yet - let's store it.
                    this.indexGeneration = newGeneration;
                } else if (this.indexGeneration < newGeneration && response.config && response.config.url) {
                    if (response.config.url === "/app/sapi/search" || response.config.url.startsWith("/app/sapi/search/entity/")) {
                        location.reload();
                    }
                }
            }
            return response;
        });

        /**
         * This interceptor checks the X-Banner header. If there is no such
         * header, nothing is done. If there is one, and it is empty, we make
         * sure to remove the current banner message. If there is one, and it is
         * not empty, we compare its timestamp to our currently stored
         * bannerVersion. If our stored version is less than the new version, we
         * make sure to fetch the new banner message from the server.
         *
         * Also see BannerMessageUpdateFilter.java.
         */
        this.axiosClient.interceptors.response.use((response: AxiosResponse) => {
            if (response && response.headers && response.headers[BANNER_HEADER] !== undefined) {
                // We have a X-Banner header.
                let headerValue: string = response.headers[BANNER_HEADER];

                if (!headerValue && this.bannerVersion) {
                    /*
                      The header is empty, and we have not seen that yet. Let's
                      hide the current banner and empty our stored version.
                     */
                    this.bannerVersion = "";
                    store.commit(MUTATION_BANNER_MESSAGE, "");
                } else {
                    // The header contained a non-empty value.
                    let newBannerVersion = atob(headerValue);
                    if (this.bannerVersion === null || this.bannerVersion < newBannerVersion) {
                        // Store current version and fetch current banner.
                        this.bannerVersion = newBannerVersion;
                        store.dispatch(ACTION_FETCH_BANNER_MESSAGE).catch(() => {
                            store.commit(MUTATION_BANNER_MESSAGE, "");
                        });
                    }
                }
            }
            return response;
        });

        /**
         * Create an interceptor that honours the numRetries and retryDelayMs
         * parameters of the ExtendedAxiosRequestConfig and thus can retry a
         * failed request a given number of times.
         */
        this.axiosClient.interceptors.response.use(undefined, (err: any) => {
            let response = err.response;
            let dontCatch: boolean = !!err.config && err.config.dontCatch;
            let ignoreStatus: boolean = !!response && (response.status === 401 || response.status === 403 || response.status === 429);
            let controlledUserErrorMessage: boolean = !!response && response.status == 400 && response.data && ((response.data.type === "USER_MESSAGE" && response.data.errorType === "userMessage") || (response.data.type === "VALIDATION"));

            if (dontCatch || ignoreStatus || controlledUserErrorMessage) {
                /*
                  No point in retrying if we:
                  - Have wrong authentication.
                  - Are accessing something forbidden.
                  - Get a 429 response meaning we have made too many requests.
                  - Receive a UserErrorMessage that originates in a thrown
                    UserErrorMessageException, since we then know we've done
                    something bad.
                 */
                return Promise.reject(err);
            }

            let config = err.config;

            // If config does not exist or the retry option is not set, reject.
            if (!config || !config.numRetries) {
                return Promise.reject(err);
            }

            // Set the variable for keeping track of the retry count.
            config.__retryCount = config.__retryCount || 0;

            // Check if we've maxed out the total number of retries.
            if (config.__retryCount >= config.numRetries) {
                // Reject with the error
                return Promise.reject(err);
            }

            // Increase the retry count.
            config.__retryCount += 1;

            // Create new promise to handle retry.
            let backoff = new Promise<void>((resolve) => {
                setTimeout(() => resolve(), config.retryDelayMs || 3000);
            });

            // Return the promise in which we retry the request.
            return backoff.then(() => {
                return self.axiosClient.request(config);
            });
        });

        /**
         * Create an interceptor that catches authentication and forbidden
         * errors and refreshes the access token before trying one more time.
         * If refreshing the access token did not help, then the current state
         * is saved and the user is redirected to the login page.
         */
        this.axiosClient.interceptors.response.use(undefined, async (err: any) => {
            if (err && err.response && err.config && !err.config.dontCatch) {
                if (err.response.status === 401 || err.response.status === 403) {
                    // For now a hard-coded error message string is used to
                    // trigger the popup, maybe functionality to display
                    // arbitrary error messages should be implemented.
                    if (err.response.data && err.response.data.message === "Tjänsten används på en annan enhet.") {
                        store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "");
                        store.commit(MUTATION_POPUP_MESSAGE_STATUS, "logout");
                    } else {
                        /*
                          If We have an ongoing refresh call in the form of a
                          refreshPromise then we just wait until it resolves.
                          If we don't have an ongoing refresh, we initiate a
                          new one and store it as our current refresh promise.
                          This enables multiple requests being retried once we
                          have gotten a new access token.

                          If the refresh promise succeeds, we attempt the same
                          request again, and make sure any potential errors are
                          not caught in the interceptor, in order to avoid
                          infinite refresh loops.

                          If either the refresh promise fails or the repeated
                          request fails again, we save the current state and
                          redirect to the sign-in page.
                         */
                        return new Promise(((resolve, reject) => {
                            if (!this.refreshPromise) {
                                // Let's initiate one.
                                this.refreshPromise = store.dispatch(ACTION_REFRESH_AUTH_TOKENS);
                            }

                            this.refreshPromise.then(async () => {
                                let config = err.config;
                                config.dontCatch = true;
                                try {
                                    /*
                                      Retry our request, but with the dontCatch
                                      flag set, so we don't end up here twice.
                                     */
                                    let response = await self.axiosClient.request(err.config);
                                    resolve(response)
                                } catch (ee) {
                                    let e: any = ee;
                                    /*
                                      The refresh has succeeded. Check if we're
                                      still not authorized, and in that case,
                                      sign out.
                                     */
                                    if (e && e.response && (e.response.status === 401 || e.response.status === 403)) {
                                        /*
                                          Empty catch since we don't want to do
                                          anything special if logout call failed
                                         */
                                        await signOutRedirectIfSuitable(true).catch(() => {
                                        });
                                    }
                                    reject("Retry after refresh rejected for path " + err.config.url);
                                }
                            }).catch(async () => {
                                /*
                                  Empty catch since we don't want to do anything
                                  special if logout call failed
                                 */
                                await signOutRedirectIfSuitable(true).catch(() => {
                                });
                                reject("Rejected refreshPromise");
                            }).finally(() => this.refreshPromise = undefined);
                        }))
                    }
                }
            }
            // Reject normally if we have not returned earlier
            return Promise.reject(err);
        });
    }

    /**
     * Performs a get request to the given path (not url) with the provided
     * parameters. The specified id will be included in the wrapped response,
     * which makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param params Optional parameters.
     * @param numRetries The number of times to retry a failed request.
     */
    public async get<T>(path: string, params?: any, numRetries: number = 0): Promise<T> {
        let config: ExtendedAxiosRequestConfig = {
            method: "get",
            url: path,
            params: params,
            numRetries: numRetries
        };
        try {
            return await this.request(config);
        } catch (error) {
            return (Promise.reject(error));
        }
    }

    /**
     * Performs a get request to the given path (not url) with the provided
     * parameters. The specified id will be included in the wrapped response,
     * which makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param params Optional parameters.
     * @param requestId The request id that will be included in the wrapped
     *     response.
     * @param numRetries The number of times to retry a failed request.
     */
    public async getWrapped<T>(path: string, requestId: number, params?: any, numRetries: number = 0): Promise<WrappedResponse<T>> {
        try {
            let response: T = await this.get<T>(path, params, numRetries);
            return new WrappedResponse(response, requestId);
        } catch (error) {
            return Promise.reject(new WrappedResponse(error, requestId));
        }
    }

    /**
     * Performs a post request to the given path (not url) with the provided
     * body. The specified id will be included in the wrapped response, which
     * makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param data The data to post.
     * @param numRetries The number of times to retry a failed request.
     * @param dontCatch If the response should not be caught by the
     *     interceptors.
     */
    public async post<T>(path: string, data?: any, numRetries: number = 0, dontCatch: boolean = false): Promise<T> {
        try {
            let config: ExtendedAxiosRequestConfig = {
                method: "post",
                url: path,
                data: data,
                numRetries: numRetries,
                dontCatch: dontCatch
            };
            return await this.request<T>(config);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Performs a post request to the given path (not url) with the provided
     * body. The specified id will be included in the wrapped response, which
     * makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param requestId The request id that will be included in the wrapped
     *     response.
     * @param data The data to post.
     * @param numRetries The number of times to retry a failed request.
     */
    public async postWrapped<T>(path: string, requestId: number, data?: any, numRetries: number = 0): Promise<WrappedResponse<T>> {
        try {
            let response: T = await this.post<T>(path, data, numRetries);
            return new WrappedResponse(response, requestId);
        } catch (error) {
            return Promise.reject(new WrappedResponse(error, requestId));
        }
    }

    /**
     * Fetch data from the given path and store it as a blob.
     *
     * @param path The path.
     * @param mimeType The mime type of the contents of the blob.
     * @returns A promise that resolves to the blob when fetching is completed.
     */
    public async fetchAsBlob(path: string, mimeType: string): Promise<Blob> {
        try {
            let config: ExtendedAxiosRequestConfig = {
                method: "get",
                url: path,
                responseType: "blob"
            };
            let blobPart: BlobPart = await this.request<BlobPart>(config);
            return new Blob([blobPart], {type: mimeType})
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private request<T>(config: ExtendedAxiosRequestConfig): Promise<T> {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers["Content-Type"] = "application/json;charset=UTF-8";

        // Always prepend the base url to all requests.
        config.url = Global.url(config.url);

        return new Promise<T>((resolve, reject) => {
            this.axiosClient.request<T>(config)
                .then((response: AxiosResponse<T>) => resolve(response.data))
                .catch((error: AxiosError) => {
                    // Reject error response if the response and the error
                    // exists, otherwise just reject error (which might be
                    // undefined or null).
                    if (error) {
                        reject(error.response || error);
                    } else {
                        reject(error)
                    }
                });
        });
    }
}
