
import {Component, Mixins} from 'vue-property-decorator';
import SidebarRightMenu from "@/components/SidebarRightMenu.vue";
import SidebarRightMonitorSettings from "@/components/SidebarRightMonitorSettings.vue";
import SidebarRightEventList from "@/components/SidebarRightEventList.vue";
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import SidebarRightChooseSubscription
  from "@/components/SidebarRightChooseSubscription.vue";
import SidebarRightAccountDetails
  from "@/components/SidebarRightAccountDetails.vue";
import SidebarRightCompanyDetails
  from "@/components/SidebarRightCompanyDetails.vue";
import SidebarRightFreeriderList
  from "@/components/SidebarRightFreeriderList.vue";
import StateHelper from "@/mixins/state-helper";
import SidebarRightPaymentList from "@/components/SidebarRightPaymentList.vue";
import SidebarRightDocumentEntityList
  from "@/components/SidebarRightDocumentEntityList.vue";
import SidebarRightUserWebAppSettings
  from "@/components/SidebarRightUserWebAppSettings.vue";

@Component({
        components: {
            SidebarRightPaymentList,
            SidebarRightFreeriderList,
            SidebarRightCompanyDetails,
            SidebarRightAccountDetails,
            SidebarRightChooseSubscription,
            SidebarRightCloseButton,
            SidebarRightDocumentEntityList,
            SidebarRightMonitorSettings,
            SidebarRightEventList,
            SidebarRightUserWebAppSettings,
            SidebarRightMenu
        }
    })
    export default class SidebarRight extends Mixins(StateHelper) {
        loggedIn(): boolean {
            return this.$store.getters.signedIn
        }

        get status(): string {
            return this.sidebarRightStatus;
        }
    }
