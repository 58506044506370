import {FrontendMonitorPriorityConfig} from "@/models/frontend-monitor-priority-config";

// TS magic to get the all property names that has a certain type.
type KeysOfType<O, T> = {
    [K in keyof O]: O[K] extends T ? K : never
}[keyof O]

/**
 * Names of all configurable self priority fields.
 */
export type AutoSelfPriorityFields = KeysOfType<FrontendMonitorConfig, MonitorPriority>;

/**
 * Priority levels a monitor can have.
 */
export type MonitorPriority = "HIGH" | "NORMAL" | "LOW" | "OFF";

/**
 * See java class with same name.
 */
export class FrontendMonitorConfig {

    autoSelfPriority: MonitorPriority;

    autoSelfHouseholdPriority: MonitorPriority;

    autoSelfVehiclePriority: MonitorPriority;

    autoSelfAddressPriority: MonitorPriority;

    autoSelfRealPropertyPriority: MonitorPriority;

    autoSelfBehindRealPropertyPriority: MonitorPriority;

    autoSelfBehindCompanyPriority: MonitorPriority;

    autoSelfCompanyPriority: MonitorPriority;

    high: FrontendMonitorPriorityConfig;

    normal: FrontendMonitorPriorityConfig;

    low: FrontendMonitorPriorityConfig;
    
    constructor(config: FrontendMonitorConfig){
        this.autoSelfPriority = config.autoSelfPriority;
        this.autoSelfHouseholdPriority = config.autoSelfHouseholdPriority;
        this.autoSelfCompanyPriority = config.autoSelfCompanyPriority;
        this.autoSelfBehindCompanyPriority = config.autoSelfBehindCompanyPriority;
        this.autoSelfAddressPriority = config.autoSelfAddressPriority;
        this.autoSelfRealPropertyPriority = config.autoSelfRealPropertyPriority;
        this.autoSelfBehindRealPropertyPriority = config.autoSelfBehindRealPropertyPriority;
        this.autoSelfVehiclePriority = config.autoSelfVehiclePriority;
        this.high = new FrontendMonitorPriorityConfig(config.high);
        this.normal = new FrontendMonitorPriorityConfig(config.normal);
        this.low = new FrontendMonitorPriorityConfig(config.low);
    }
 }
