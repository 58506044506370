
    import Vue from 'vue';
    import {Component} from 'vue-property-decorator';
    
    @Component
    export default class SelectOnClick extends Vue {
        /**
         * Handle a click by selecting either the text in the component.
         */
        public onClick(event: MouseEvent): void {
            let selection = window.getSelection();
            selection.removeAllRanges();
            let range = document.createRange();
            let target = this.$refs["select-text"] as Element;
            range.selectNode(target);
            selection.addRange(range);
        }
    }
