var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container_w1",class:{
     show_home: _vm.showHome,
     show_search: _vm.showSearch,
     show_popup_other_tab: _vm.isFullScreen,
     show_edge_helper_phone: _vm.showEdgeHelperPhone,
     is_v2: _vm.isV2
     },attrs:{"id":"app"}},[(_vm.isLoaded)?[_c('page-header'),_c('router-view'),_c('router-view',{attrs:{"name":"sidebar_left"}}),(_vm.activeUser)?_c('sidebar-right'):_vm._e(),_c('popup-registration'),_c('popup-message')]:[_c('router-view'),_c('popup-message')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }