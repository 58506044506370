
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import VisibleAware from "@/mixins/visible-aware";
import StateHelper from "@/mixins/state-helper";
import {HTTP} from "@/services/http-provider";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Utils from "@/mixins/utils";
import {DisplayableDocument} from "@/models/displayable-document";
import SearchPageEntityCommonCredit
    from "@/components/SearchPageEntityCommonCredit.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import {
    ACTION_FETCH_DOCUMENT_ENTITIES,
    ACTION_FLASH_NEW_DOCUMENT
} from "@/store/store-documents";
import SvgConstants from "@/mixins/svg-constants";
import SearchPageEntitySimpleExpandable
    from "@/components/SearchPageEntitySimpleExpandable.vue";
import SearchPageEntityDocumentList
    from "@/components/SearchPageEntityDocumentList.vue";
import {MUTATION_ACTIVE_INLINE_MODAL} from "@/store/store";
import {IndividualDocumentInfo} from "@/models/individual-document-info";
import {
    FrontendLegalDocumentListing
} from "@/models/frontend-legal-document-listing";
import SearchPageEntityLegalDocumentListing
    from "@/components/SearchPageEntityLegalDocumentListing.vue";

/**
 * This component represents the documents tab in a search result details view.
 */
@Component({
    components: {
        SearchPageEntityLegalDocumentListing,
        SearchPageEntityDocumentList,
        SearchPageEntitySimpleExpandable,
        SearchPageEntityExpandable,
        SearchPageEntityCommonCredit,
        ErrorMessage
    }
})
export default class SearchPageEntityIndividualDocuments extends Mixins(StateHelper, VisibleAware, Utils, SvgConstants) {
    @Prop()
    entity: SearchResultEntryIndividualDetails;

    loading: boolean = false;

    firstLoadDone: boolean = false;

    errorMessage: string = "";

    displayableDocuments: DisplayableDocument[] = [];

    individualDataMinDate: string = "";

    individualDataMaxDate: string = "";

    individualCommitmentDataMinDate: string = "";

    individualCommitmentDataMaxDate: string = "";

    legalDocumentListing: FrontendLegalDocumentListing = null;

    /**
     * Reload documents if we're mounted while being visible.
     */
    mounted() {
        if (this.isVisible) {
            this.onVisibleChange(true);
        }
    }

    /**
     * Reload documents when we become visible.
     *
     * @param val The new visible value.
     */
    @Watch("isVisible")
    onVisibleChange(val: boolean): void {
        if (val) {
            if (this.firstLoadDone) {
                return;
            }
            this.errorMessage = "";
            if (this.entity.secretIdentity && !this.allowDocumentsForSecretIndividuals) {
                this.firstLoadDone = true;
            } else {
                this.loadDocumentInfo().then(() => this.firstLoadDone = true);
            }
        }
    }

    /**
     * Gets individual report documents.
     */
    get individualReportDocuments(): DisplayableDocument[] {
        return this.showIndividualReport ? this.filter("INDIVIDUAL_REPORT") : [];
    }

    /**
     * Gets individual commitment report documents.
     */
    get individualCommitmentReportDocuments(): DisplayableDocument[] {
        return this.showIndividualCommitmentReport ? this.filter("INDIVIDUAL_COMMITMENT_REPORT") : [];
    }

    /**
     * Gets credit report documents.
     */
    get creditReportDocuments(): DisplayableDocument[] {
        return this.showCreditReports ? this.filter("CREDIT_REPORT") : [];
    }

    private filter(type: string): DisplayableDocument[] {
        return this.displayableDocuments.filter(document => type === document.type);
    }

    /**
     * Load relevant document data.
     */
    loadDocumentInfo(): Promise<void> {
        return new Promise<void>(resolve => {
            let timeout = setTimeout(() => this.loading = true, 500);
            let url: string = "/sapi/individual-document-info/" + this.entity.id + "/" + this.activeSubscriptionRefNo;
            HTTP.get<IndividualDocumentInfo>(url).then((docInfo: IndividualDocumentInfo) => {
                if (docInfo.error) {
                    this.errorMessage = docInfo.error;
                }

                // Individual report time span data.
                if (docInfo.individualDataTimeSpan) {
                    this.individualDataMinDate = docInfo.individualDataTimeSpan.earliest;
                    this.individualDataMaxDate = docInfo.individualDataTimeSpan.latest;
                }

                // Individual commitment report time span data.
                if (docInfo.individualCommitmentDataTimeSpan) {
                    this.individualCommitmentDataMinDate = docInfo.individualCommitmentDataTimeSpan.earliest;
                    this.individualCommitmentDataMaxDate = docInfo.individualCommitmentDataTimeSpan.latest;
                }

                // Displayable documents.
                if (Array.isArray(docInfo.displayableDocuments)) {
                    this.displayableDocuments = docInfo.displayableDocuments;
                } else {
                    this.displayableDocuments = [];
                }

                // Legal documents
                this.legalDocumentListing = docInfo.legalDocumentListing;
            }).catch(response => {
                if (!this.errorMessage) {
                    this.errorMessage = this.extractErrorMessage(response);
                }
            }).finally(() => {
                clearTimeout(timeout);
                this.loading = false;
                resolve();
            });
        });
    }

    addNewDocument(doc: DisplayableDocument) {
        this.displayableDocuments.unshift(doc);
        this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES));
        this.$store.dispatch(ACTION_FLASH_NEW_DOCUMENT, doc);
    }

    deleteDisplayableDocument(type: string, documentId: string): void {
        this.removeDisplayableDocument(this.displayableDocuments, documentId);
        this.ignoreError(this.$store.dispatch(ACTION_FETCH_DOCUMENT_ENTITIES));
    }

    removeDisplayableDocument(arr: DisplayableDocument[], documentId: string) {
        arr.forEach((doc: DisplayableDocument, index: number) => {
            if (doc.id === documentId) {
                arr.splice(index, 1);
            }
        });
    }

    /**
     * True if we should show individual reports.
     */
    get showIndividualReport(): boolean {
        return this.allowFetchIndividualReport;
    }

    /**
     * True if we should show individual commitment reports.
     */
    get showIndividualCommitmentReport(): boolean {
        return this.allowFetchIndividualCommitmentReport;
    }

    /**
     * True if we should show legal documents.
     */
    get showLegalDocuments(): boolean {
        return this.signedIn && this.hasActiveSubscription && this.allowLegalDocuments;
    }

    /**
     * True if we should show credit reports.
     */
    get showCreditReports(): boolean {
        return this.signedIn && this.allowCreditReport;
    }

    /**
     * True if were only showing one document type, in which case we can expand
     * that list by default.
     */
    get showSingleDocumentType(): boolean {
        return this.numberOfShownDocuments() === 1;
    }

    private numberOfShownDocuments(): number {
        return [
            this.showIndividualReport,
            this.showIndividualCommitmentReport,
            this.showCreditReports
        ].filter(value => value === true).length
    }

    get tagForCreditReport(): string {
        return this.isDemoSubscription ? 'Demo' : '';
    }

    /**
     * Closes the active inline modal.
     */
    closeActiveInlineModal(): void {
        this.$store.commit(MUTATION_ACTIVE_INLINE_MODAL, "");
    }

    /**
     * Handle event triggered by the user requesting a new legal document
     * listing by simply replacing the one we currently have.
     *
     * @param legalDocumentListing The new listing.
     */
    newLegalDocumentListing(legalDocumentListing: FrontendLegalDocumentListing) {
        this.legalDocumentListing = legalDocumentListing;
    }
}
