
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import StatusIconRow from "@/components/StatusIconRow.vue";
import AdditionalInfoRow from "@/components/AdditionalInfoRow.vue";
import {
    SearchResultBasicBoardMember
} from "@/models/search-result-basic-board-member";

/**
 * This component represents a board member in the list of board members.
 */
@Component({
    components: {AdditionalInfoRow, StatusIconRow}
})
export default class SearchPageEntityCompanyInfoBoardMember extends Mixins(Utils) {
    @Prop()
    member: SearchResultBasicBoardMember;


    /**
     * True if we should show the id number.
     */
    get showIdNumber(): boolean {
        return !!this.member.idNumber;
    }

    /**
     * Concatenates the function texts with <br> as separator.
     */
    formatFunctions(member: SearchResultBasicBoardMember): string {
        return member.functions.join("<br>");
    }

    /**
     * Checks if a board member has an address that needs to be split into
     * multiple lines.
     *
     * @param member The member.
     */
    hasLongAddress(member: SearchResultBasicBoardMember): boolean {
        return this.approximateWidth(this.formatAddressAndCountry(member)) > 50;
    }

    /**
     * Formats address and country for the given board member, if the board
     * member is not clickable - that is - lacks id.
     *
     * @param member The member.
     */
    formatAddressAndCountry(member: SearchResultBasicBoardMember): string {
        if (member.id) {
            return "";
        }
        let parts: string[] = [
            member.careOf ? "c/o " + member.careOf : "",
            member.streetAddress,
            (member.zip + " " + member.city).trim(),
            member.country
        ];
        return parts.filter(part => !!part).join(", ").replace(/,,/, ",");
    }
}
