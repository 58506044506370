
    import {Component, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import PopupMessageCloseButton from "@/components/PopupMessageCloseButton.vue";
    import CustomerSupportPhoneNumber
        from "@/components/CustomerSupportPhoneNumber.vue";
    import CustomerSupportEmailLink
        from "@/components/CustomerSupportEmailLink.vue";
    import Utils from "@/mixins/utils";
    import StateHelper from "@/mixins/state-helper";

    /**
     * This component represents the popup showing an error message if something
     * went wrong.
     */
    @Component({
        components: {
            CustomerSupportEmailLink,
            CustomerSupportPhoneNumber, PopupMessageCloseButton}
    })
    export default class PopupMessageError extends Mixins(SvgConstants, Utils, StateHelper) {
        /**
         * Get the message, if any.
         */
        get message(): string | null {
            let index: number = this.popupMessageStatus.indexOf(":");
            if (index !== -1) {
                let parts: string[] = this.popupMessageStatus.substr(index + 1).split("=");
                let key: string = parts[0];
                let value: string = parts[1];
                switch (key) {
                    case "errorMessage":
                        return value;
                }
            }
            return null;
        }

        /**
         * Closes the popup and forces navigation to "/".
         */
        close(): void {
            this.$emit("close");
            window.location.href = this.rootUrl();
        }
    }
