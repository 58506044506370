
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";

/**
 * This component represents a slider button.
 */
@Component
export default class SliderButton extends Mixins(StateHelper) {
    @Prop()
    active: boolean;
}
