
    import {Component, Prop, Vue} from 'vue-property-decorator';

    /**
     * This component represents the text about data policy to be displayed in
     * registration dialogs
     */
    @Component
    export default class PopupRegistrationFormDataPolicy extends Vue {
        @Prop()
        hidePersonalNumberInfo: boolean;
    }
