
    import {Component, Vue} from 'vue-property-decorator';
    import HomePage from "@/views/HomePage.vue";
    import {MUTATION_POPUP_MESSAGE_STATUS} from "@/store/store";
    import {HTTP} from "@/services/http-provider";
    import {SubscriptionParameters} from "@/models/end-user-subscription-parameters";

    /**
     * This component represents a page where we send an api call in order to
     * display some information about the user in the logs, such as ip address,
     * headers, etc. The user is shown a simple "Tack för hjälpen" message.
     */
    @Component({
        components: {
            HomePage
        },
        /**
         * Make sure we close the popup whenever we navigate from this page.
         *
         * @param to The new route.
         * @param from The old route.
         * @param next Must call this in order to complete navigation.
         */
        beforeRouteLeave(to, from, next) {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "");
            next();
        }
    })
    export default class HelpUsHelpPage extends Vue {
        mounted() {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=Vänligen vänta...");
            setTimeout(() => {
                HTTP.get<SubscriptionParameters>("/api/help-us-help").then(() => {
                    this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=Tack för hjälpen! Vi har nu fått den information vi behöver.");
                }).catch(() => {
                    this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=Jaha, det där gick inte riktigt som vi tänkt oss. Vi måste tyvärr be dig att kontakta kundtjänst.");
                });
            }, 1000);
        }
    }
