
    import {Component, Mixins} from 'vue-property-decorator';
    import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
    import SidebarRightAccountDetailsForm
        from "@/components/SidebarRightAccountDetailsForm.vue";
    import VisibleAware from "@/mixins/visible-aware";

    /**
     * This component represents the list of monitors in the right sidebar.
     */
    @Component({
        components: {
            SidebarRightAccountDetailsForm,
            SidebarRightCloseButton
        },
    })
    export default class SidebarRightAccountDetails extends Mixins(VisibleAware) {
    }
