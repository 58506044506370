
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SvgConstants from "@/mixins/svg-constants";
import {
    EndUserParameters,
    EndUserSubscriptionParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {AxiosResponse} from "axios";
import {
    ACTION_CONFIRM_INVITE,
    ACTION_CREATE_USER_FOR_SUBSCRIPTION,
    MUTATION_POPUP_REGISTRATION_STATE
} from "@/store/store";
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import PopupRegistrationFormAccountDetails
    from "@/components/PopupRegistrationFormAccountDetails.vue";
import Utils from "@/mixins/utils";
import PopupRegistrationFormContact
    from "@/components/PopupRegistrationFormContact.vue";
import PopupRegistrationFormCloseButton
    from "@/components/PopupRegistrationFormCloseButton.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import MountAware from "@/mixins/mount-aware";
import ApplicationStateSerializer from "@/mixins/application-state-serializer";
import Logo from "@/components/Logo.vue";
import SiteDependentImage from "@/components/SiteDependentImage.vue";
import PopupRegistrationFormConditions
    from "@/components/PopupRegistrationFormConditions.vue";
import Validation from "@/mixins/validation";

export class SuccessfulSignInEvent {
    showPasswordForm: boolean;

    response: EndUserSubscriptionOperationResponse;


    constructor(emailSignIn: boolean, response: EndUserSubscriptionOperationResponse) {
        this.showPasswordForm = emailSignIn;
        this.response = response;
    }
}

/**
 * This component represents the registration modal for Small packages.
 */
@Component({
    components: {
        PopupRegistrationFormConditions,
        SiteDependentImage,
        Logo,
        ErrorMessage,
        PopupRegistrationFormCloseButton,
        PopupRegistrationFormContact,
        PopupRegistrationFormAccountDetails,
    }
})
export default class PopupRegistrationForm extends Mixins(ApplicationStateSerializer, MountAware, StateHelper, SvgConstants, Utils) {
    /**
     * The string controlling which type of dialog to show.
     */
    @Prop()
    type: string;

    step: string = "register_company";

    errorResponse: any = {};

    endUserSubscriptionParameters = new EndUserSubscriptionParameters();

    inviteErrorMessage: string = "";

    /**
     * This object contains the actual properties of the invite
     * subscription. We can't show the default properties in this case since
     * the properties of the subscription the user has been invited to may
     * not have the default values.
     */
    subscriptionProperties: EndUserSubscriptionParameters = new EndUserSubscriptionParameters();

    mounted() {
        if (this.isInvite) {
            this.extractSubscriptionPropertiesFromRegistrationStatus();
            this.setStep("register_account");
        } else {
            this.setStep("register_company");
        }
    }

    /*
      Parse the invite properties stored as a json object in the
      popupRegistrationStatus, after the "invite:" marker.
     */
    private extractSubscriptionPropertiesFromRegistrationStatus() {
        let serializedProperties = this.popupRegistrationStatus.substr(this.popupRegistrationStatus.indexOf(":") + 1);
        this.subscriptionProperties = JSON.parse(serializedProperties);
    }

    /**
     * Closes the popup. If we're dealing with an invite, we also navigate
     * to the home page.
     */
    closePopup(): void {
        if (this.isInvite) {
            this.$router.push("/");
        }
        this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, "");
    }

    /**
     * Returns true if we're currently dealing with an invite registration process.
     */
    get isInvite(): boolean {
        return this.popupRegistrationStatus.startsWith("invite");
    }

    /**
     * Convenience getter for the invite subscription
     */
    get inviteSubscription(): SubscriptionParameters {
        return this.$store.state.popupRegistrationState.inviteSubscription;
    }

    /**
     * Sets the active step in the registration process.
     *
     * @param step The new step. This must coincide with the show_step_* classes in
     *             the css.
     */
    setStep(step: string): void {
        this.inviteErrorMessage = "";
        this.step = step;
    }

    /**
     * Returns a function that when called changes the step to the given step.
     *
     * @param step The step to change to when the returned function is called. See
     *             setStep() for more info about steps.
     */
    changeStepFunction(step: string): Function {
        return () => this.setStep(step);
    }

    /**
     * Getter for the css class that determines which of the steps in the registration
     * process we're displaying. Thus, the step names must coincide with the css
     * classes.
     */
    get activeStepClass(): string {
        return "show_step_" + this.step;
    }

    /**
     * Check if we should skip pnr and phone validation in the account form. We're
     * allowed to do this if we're dealing with an invite and the subscription the
     * invite refers to allows it.
     */
    get skipPnrAndPhone(): boolean {
        return this.isInvite && this.subscriptionProperties.subscriptionParameters.skipPnrRequirement;
    }

    /**
     * Stores the subscription from the first step and continues to the account
     * details step.
     *
     * @param subscription The subscription from the form in the first step.
     */
    storeSubscriptionAndContinue(subscription: SubscriptionParameters) {
        this.endUserSubscriptionParameters.subscriptionParameters = subscription;

        if (this.signedIn && this.activeUser) {
            this.submit(this.activeUser);
        } else {
            /*
              Set the subscription package here since the submit method is not always
              called (for example when we use email login during registration and
              confirm from the same browser).
             */
            this.setIsZero();
            this.setStep("register_account");
        }
    }

    /**
     * Called when the user has signed in with an existing user. What we do depends on
     * whether or not this is in invite:
     *
     * 1) No invite: Create the new subscription for the user.
     * 2) Invite: Add the user to the subscription.
     *
     * @param event The response from the successful sign in.
     */
    successfulSignIn(event: SuccessfulSignInEvent): void {
        let response: EndUserSubscriptionOperationResponse = event.response;

        if (response.message) {
            /*
              If we use email sign in and confirm the link in another tab in the same
              browser, we continue the creation or confirmation process in that tab
              (see VerifyEmailPage for how that is done). Hence, we just show a
              message to the user here in that case.
             */
            this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, "");
            this.$router.push("/inloggad-i-annan-flik");
        } else {
            /*
              Here, we either have a password login or an email login
              confirmed in a different browser. Thus, perform confirmation
              or creation here.
             */
            this.endUserSubscriptionParameters.endUserParameters = response.endUserParameters;
            this.endUserSubscriptionParameters.inviteLinkId = this.$route.params["userInviteLinkId"];

            let action: string = this.isInvite ? ACTION_CONFIRM_INVITE : ACTION_CREATE_USER_FOR_SUBSCRIPTION;

            this.$store.dispatch(action, this.endUserSubscriptionParameters).then(() => {
                // Close plans popup
                this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, "");

                // Navigate to welcome page.
                this.$router.push({
                    name: "welcome",
                    params: {
                        newCustomer: "true",
                        showPassword: event.showPasswordForm ? "true" : undefined,
                    }
                });
            }).catch((response: AxiosResponse) => {
                if (this.isInvite) {
                    this.setStep("register_account");
                    this.inviteErrorMessage = this.extractErrorMessage(response);
                } else {
                    // Go to the first step since there should be no errors for the end user.
                    this.setStep("register_company");
                    this.errorResponse = response.data;
                }
            });
        }
    }

    /**
     * Dispatch the action for creating a new subscription with the given
     * parameters. For invites, we create a new user (which implicitly gets a
     * zero subscription) and then we confirm the invite with that newly created
     * user.
     *
     * For "normal" registration we simply use the api for creating
     * subscriptions.
     *
     * @param endUser The end user for which to create a subscription.
     */
    submit(endUser: EndUserParameters): void {
        this.endUserSubscriptionParameters.endUserParameters = endUser;

        /*
          Must set the zero status here as well, since
          storeSubscriptionAndContinue is not always called.
         */
        this.setIsZero();

        /*
          If we're confirming an invite, we include the refNo of the
          subscription to which the user was invited, so that it will be
          possible to check that there are enough allowed users before actually
          creating the new user.
         */
        if (this.isInvite) {
            this.endUserSubscriptionParameters.subscriptionParameters.refNo = this.inviteSubscription.refNo;
            this.endUserSubscriptionParameters.inviteLinkId = this.$route.params["userInviteLinkId"];
        }

        this.$store.dispatch(ACTION_CREATE_USER_FOR_SUBSCRIPTION, this.endUserSubscriptionParameters).then((response: EndUserSubscriptionOperationResponse) => {
            if (this.isInvite) {
                let showPasswordFormOnWelcomeScreen: boolean = Validation.EMAIL.test(response.endUserParameters.email);
                this.successfulSignIn(new SuccessfulSignInEvent(showPasswordFormOnWelcomeScreen, response));
            } else {
                // Close plans popup
                this.$store.commit(MUTATION_POPUP_REGISTRATION_STATE, "");

                // Navigate to welcome page.
                this.$router.push({
                    name: "welcome",
                    params: {
                        newCustomer: "true",
                        showPassword: "true",
                    }
                });
            }
        }).catch((response) => {
            this.errorResponse = response.data;
        });
    }

    private setIsZero(): void {
        /*
          When registering a new user during an invite process, the user has
          confirmed she is a new user and thus the subscription should be a
          Zero subscription.
         */
        /* TODO: This method is obsolete, not used anymore, needs to be removed when this entire component is rewritten */
    }
}
