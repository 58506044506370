import {Component, Vue} from "vue-property-decorator";
import {EmailLoginApplicationState} from "@/models/email-login-application-state";
import {SubscriptionParameters} from "@/models/end-user-subscription-parameters";
import {Route} from "vue-router";

/**
 * This class contains various helper methods for serializing and deserializing the
 * application state. This is necessary during email logins when we sometimes want to
 * restore the application state in the tab the user confirmed the email link in.
 */
@Component
export default class ApplicationStateSerializer extends Vue {
    /**
     * Serializes the application state to a string. The state of the
     * application is modeled by the {@link EmailLoginApplicationState} class.
     */
    serializeState(subscription: SubscriptionParameters = null, userInviteLinkId?: string): string {
        return JSON.stringify(this.createApplicationState(subscription, userInviteLinkId));
    }

    /**
     * Serializes the application state to a string. The state of the
     * application is modeled by the {@link EmailLoginApplicationState} class.
     *
     * @param state State to serialize
     */
    static serializeState(state: EmailLoginApplicationState): string {
        return JSON.stringify(state);
    }

    /**
     * Creates an application state from the given parameters.
     *
     * @param route Route that conatains path and query info.
     * @param subscription Subscription to save in state. Optional.
     * @param userInviteLinkId Link id to save in state. Optional.
     */
    static createApplicationState(route: Route, subscription: SubscriptionParameters = null, userInviteLinkId?: string): EmailLoginApplicationState {
        let state = new EmailLoginApplicationState();
        state.path = route.path;
        state.query = route.query;
        state.subscription = subscription;
        state.userInviteLinkId = userInviteLinkId;
        return state;
    }

    /**
     * Creates an application state from the given parameters and the current
     * route.
     *
     * @param subscription Subscription to save in state. Optional.
     * @param userInviteLinkId Link id to save in state. Optional.
     */
    createApplicationState(subscription: SubscriptionParameters = null, userInviteLinkId?: string): EmailLoginApplicationState {
        return ApplicationStateSerializer.createApplicationState(this.$route, subscription, userInviteLinkId);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Deserializes the application state from a string.
     */
    deserializeState(state: string): EmailLoginApplicationState {
        return JSON.parse(state);
    }
}
