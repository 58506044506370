
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import Utils, {UTILS} from "@/mixins/utils";
import {
    SearchResultOwnershipRealProperty
} from "@/models/search-result-ownership-real-property";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import FNum from "@/components/FNum.vue";
import Fraction from "@/components/Fraction.vue";

/**
 * This component represents a list of real properties.
 */
@Component({
    components: {FNum, SearchPageEntityInfoHeader, SearchPageEntityLink, Fraction}
})
export default class SearchPageEntityRealPropertiesList extends Mixins(StateHelper, Utils) {
    /**
     * The list of real properties
     */
    @Prop()
    realProperties: SearchResultOwnershipRealProperty[];

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;

    numToShow: number = UTILS.CHUNK_SIZE;


    /**
     * Gets the entries currently visible in the list. For large lists, we
     * don't show all entries since that may consume too much resources in
     * the browser.
     */
    get visibleEntries(): SearchResultOwnershipRealProperty[] {
        return this.realProperties.slice(0, this.numToShow);
    }

    formatRemark(property: SearchResultOwnershipRealProperty): string {
        return property.remark.toLowerCase()
    }

    formatPropertyType(property: SearchResultOwnershipRealProperty): string {
        if (property.propertyType) {
            return property.propertyType;
        }
        return "";
    }
}
