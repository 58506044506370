import {Component, Mixins} from "vue-property-decorator";
import UniqueId from "@/mixins/unique-id";
import StateHelper from "@/mixins/state-helper";
import {MUTATION_ACTIVE_INLINE_MODAL} from "@/store/store";

/**
 * This mixin provides read and write of the active state of an inline modal. An
 * inline modal is a dialog asking for user input, but that is displayed inline,
 * such as the company report ordering dialog and various confirmation dialogs
 * for deletions etc. Only one inline modal should be active at any given time.
 */
@Component
export default class InlineModal extends Mixins(StateHelper, UniqueId) {
    /**
     * Returns true if this is currently the active inline modal.
     */
    get isActiveInlineModal(): boolean {
        return this.activeInlineModal === this.uniqueId;
    }

    /**
     * Toggles the state of this inline modal.
     */
    toggleInlineModal(): void {
        this.$store.commit(MUTATION_ACTIVE_INLINE_MODAL, this.isActiveInlineModal ? "" : this.uniqueId);
    }

    /**
     * Closes this inline modal.
     */
    closeInlineModal(): void {
        this.$store.commit(MUTATION_ACTIVE_INLINE_MODAL, "");
    }
}
