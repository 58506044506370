export class FrontendMonitorPriorityConfig {
    
    deliveryMethods: string[] = [];

    autoMonitorsOnIndividualsOnCompany: boolean;

    incomeCatalogUpdateDeliveryMethods: string[] = [];
    
    eventGroupFilters: string[] = [];
    
    constructor(config: FrontendMonitorPriorityConfig) {
        this.deliveryMethods = [];
        for(let dm of config.deliveryMethods) {
            this.deliveryMethods.push(dm);
        }
        
        this.autoMonitorsOnIndividualsOnCompany = config.autoMonitorsOnIndividualsOnCompany;
        
        this.incomeCatalogUpdateDeliveryMethods = [];
        for(let dm of config.incomeCatalogUpdateDeliveryMethods) {
            this.incomeCatalogUpdateDeliveryMethods.push(dm);
        }
        
        this.eventGroupFilters = [];
        for(let egf of config.eventGroupFilters) {
            this.eventGroupFilters.push(egf);
        }
        
    }
    
}