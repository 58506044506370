
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {DATE_FILTER} from "@/services/filters";
import Auth from "@/mixins/auth";
import {Global} from "@/global";
import PreflightLink from "@/components/PreflightLink.vue";
import {SearchResultEntryCompany} from "@/models/search-result-entry-company";
import {DisplayableDocument} from "@/models/displayable-document";

/**
 * This component represents the list of protocols in the documents tab.
 */
@Component({
    components: {PreflightLink, ErrorMessage}
})
export default class SearchPageEntityCompanyDocumentsProtocolList extends Mixins(Auth, StateHelper, SvgConstants) {
    @Prop()
    company: SearchResultEntryCompany;

    @Prop()
    protocols: DisplayableDocument[];


    /**
     * Formats the text for the protocol.
     *
     * @param protocol The protocol.
     */
    formatProtocol(protocol: DisplayableDocument): string {
        return "Protokoll " + DATE_FILTER(protocol.documentDate);
    }

    /**
     * Gets the url for opening the protocol in a new tab.
     *
     * @param protocol The protocol.
     */
    openUrl(protocol: DisplayableDocument): string {
        let path: string = "/sapi/documents/protocol/open/" + protocol.id + "?orgNo=" + this.company.orgNo + "&subscription=" + this.activeSubscriptionRefNo;
        return Global.url(path);
    }
}
