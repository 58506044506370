
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import SidebarRightUserConfigFilterRadioGroup
    from "@/components/SidebarRightUserConfigFilterRadioGroup.vue";
import {CustomSelectOption} from "@/components/CustomSelect.vue";
import CustomRadioButton from "@/components/CustomRadioButton.vue";
import Expandable from "@/components/Expandable.vue";


/**
 * This component represents an expandable list of radio buttons
 */
@Component({
    components: {
        Expandable,
        CustomRadioButton, SidebarRightUserConfigFilterRadioGroup
    }
})
export default class SearchPageEntityRadioButtonListSettings extends Mixins(SvgConstants, StateHelper, Utils) {
    /**
     * The label to write next to the header icon.
     */
    @Prop()
    heading: string;

    /**
     * The right aligned description
     */
    @Prop()
    description: string;

    /**
     * The icon to render in the header, as html.
     */
    @Prop()
    icon: string;

    /**
     * The options to render radio buttons for.
     */
    @Prop()
    options: CustomSelectOption[];

    /**
     * The default value. If this is changed from outside the component the
     * selected value will be updated accordingly.
     */
    @Prop()
    defaultSelected: string;

    selected: string = "";

    expanded: boolean = false;


    mounted() {
        if (!this.defaultSelected && this.options && this.options.length) {
            this.selected = this.options[0].value;
        } else {
            this.selected = this.defaultSelected;
        }
        this.$emit("change", this.selected);
    }

    /**
     * It must be possible to change the selected value from outside this
     * component. This is accomplished by watching the default value, and
     * explicitly setting the selected element if the default value changes.
     *
     * @param newVal The new value.
     */
    @Watch("defaultSelected")
    onDefaultChange(newVal: string) {
        this.handleClick(newVal);
    }

    handleClick(clickedValue: string): void {
        if (clickedValue !== this.selected) {
            this.selected = clickedValue;
            this.$emit("change", clickedValue);
        }
    }
}
