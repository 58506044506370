const TERMS_ACCEPTED_KEY: string = "terms_accepted";

/**
 * Key or the secret stored in local storage in order to determine if an email
 * confirmation occurs in the same browser as it was started.
 */
const EMAIL_SECRET_KEY: string = "email_secret";

/**
 * Key for the login type in local storage.
 */
const LOGIN_TYPE_KEY: string = "login_type";

/**
 * Key for storing the username if it should be remembered. Can contain either
 * an email address or a personal number depending on how a user signed in.
 */
const LOGIN_REMEMBER_USERNAME_KEY: string = "login_remember_username";

/**
 * The key for storing the id of the active subscription in local storage.
 */
const ACTIVE_SUBSCRIPTION_KEY: string = "active_subscription";


export class StorageService {
    private static getItem(key: string): string {
        return localStorage.getItem(key);
    }

    private static storeItem(key: string, value: string) {
        if (StorageService.getItem(TERMS_ACCEPTED_KEY)) {
            localStorage.setItem(key, value);
        }
    }

    /**
     * Stores boolean that terms are accepted. Since this is performed after
     * logging in, we now know the user has accepted the terms, and thus we can store a
     * marker that remembers this. This is important since we're not allowed to store
     * cookies or local data unless the terms have been accepted.
     */
    static storeTermsAccepted(): void {
        localStorage.setItem(TERMS_ACCEPTED_KEY, "true");
    }

    /**
     * Stores the secret used in order to determine if an email confirmation occurs in the
     * same browser as it was started. The value is stored only if the user has accepted
     * the terms.
     *
     * @param secret The secret to store.
     */
    static storeEmailSecret(secret: string): void {
        /*
          Notice that we don't care about the TERMS_ACCEPTED flag here. If we did that we
          would not be able to set the email secret as soon as a user has cleared her
          local storage, even though the terms are accepted. By trying to log in via email
          link it should be pretty clear that the user intends to log in and thereby has
          agreed to the conditions.
         */
        localStorage.setItem(EMAIL_SECRET_KEY, secret);
    }

    /**
     * Removes the email secret if it has been set.
     */
    static removeEmailSecret(): void {
        localStorage.removeItem(EMAIL_SECRET_KEY);
    }

    /**
     * Retrieves the secret used in order to determine if an email confirmation occurs in
     * the same browser as it was started.
     *
     * @returns The secret, or null if no secret has been stored.
     */
    static getEmailSecret(): string {
        return StorageService.getItem(EMAIL_SECRET_KEY);
    }

    /**
     * Gets the remembered username, if any.
     *
     * @returns The remembered username, or null if there is none.
     */
    static getRememberedLoginUsername(): string {
        return StorageService.getItem(LOGIN_REMEMBER_USERNAME_KEY);
    }

    /**
     * Sets the remembered username, if any.
     *
     * @param usernameToRemember The username to remember.
     */
    static setRememberedLoginUsername(usernameToRemember: string): void {
        this.storeItem(LOGIN_REMEMBER_USERNAME_KEY, usernameToRemember);
    }

    /**
     * Clears the remembered username.
     */
    static clearRememberedLoginUsername(): void {
        localStorage.removeItem(LOGIN_REMEMBER_USERNAME_KEY);
    }

    /**
     * Gets the preferred login type.
     *
     * @returns The preferred login type, or null if none has been set.
     */
    static getLoginType(): string {
        return StorageService.getItem(LOGIN_TYPE_KEY);
    }

    /**
     * Sets the preferred login type.
     *
     * @param newType The new preferred login type.
     */
    static setLoginType(newType: string) {
        this.storeItem(LOGIN_TYPE_KEY, newType);
    }

    /**
     * Retrieves the refNo of the active subscription from local storage for the given
     * user refNo.
     *
     * @param {string} userRefNo The refNo of the user.
     * @returns {string | null} The refNo of the stored active subscription or null if
     * none is stored.
     */
    static getPersistedActiveSubscriptionForUser(userRefNo: string) {
        return StorageService.getItem(ACTIVE_SUBSCRIPTION_KEY + "_" + userRefNo);
    }


    /**
     * Sets the active subscription refNo.
     *
     * @param userRefNo The end user refNo.
     * @param subscriptionRefNo The new active subscription refNo.
     */
    static persistActiveSubscriptionForUser(userRefNo: string, subscriptionRefNo: string) {
        this.storeItem(ACTIVE_SUBSCRIPTION_KEY + "_" + userRefNo, subscriptionRefNo);
    }
}
