
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import Utils from "@/mixins/utils";
    import SvgConstants from "@/mixins/svg-constants";
    import RemarkAnimation from "@/components/RemarkAnimation.vue";
    import VisibleAware from "@/mixins/visible-aware";
    import StateHelper from "@/mixins/state-helper";
    import {SearchResultEntryCompanyDetails} from "@/models/search-result-entry-company-details";

    /**
     * This component represents the income tab in a search result details view.
     */
    @Component({
        components: {
            RemarkAnimation,
        }
    })
    export default class SearchPageEntityCompanyRemarks extends Mixins(SvgConstants, Utils, VisibleAware, StateHelper) {
        @Prop()
        company: SearchResultEntryCompanyDetails;

        @Prop()
        level: number;
    }
