
import {Component, Mixins} from 'vue-property-decorator';
import HomePage from "@/views/HomePage.vue";
import Utils from "@/mixins/utils";

/**
 * This component represents the page where a user is directed when clicking
 * on a link in a sign-in one-time code email. It stores the code as a param
 * and redirects to the sign-in page.
 */
@Component({
    components: {
        HomePage
    },
})
export default class SignInOneTimeCodePage extends Mixins(Utils) {
    mounted() {
        const codeId: string = this.$route.params["codeId"];
        this.$store.state.signedOutIfNecessary.then(() => {
            this.$router.push({
                name: "home",
                params: {
                    codeId: codeId
                }
            });
        });
    }
}
