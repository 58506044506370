
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils, {UTILS} from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultHistoricalCommitmentCompany
} from "@/models/search-result-historical-commitment-company";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";
import FMillions from "@/components/FMillions.vue";
import FNum from "@/components/FNum.vue";
import {
    SearchResultHistoricalCommitment
} from "@/models/search-result-historical-commitment";

/**
 * This component represents the list of historical commitments.
 */
@Component({
    components: {
        FNum, FMillions, StatusIconRow,
        SearchPageEntityLink
    }
})
export default class SearchPageEntityHistoricalCommitmentsList extends Mixins(StateHelper, Utils) {
    /**
     * Notice that historicalCommitments may be null initially, indicating that
     * they are fetched asynchronously.
     */
    @Prop()
    historicalCommitments: SearchResultHistoricalCommitmentCompany[];

    @Prop()
    showHeader: boolean;

    @Prop()
    level: number;

    numToShow: number = UTILS.CHUNK_SIZE;


    /**
     * Gets the entries currently visible in the list. For large lists, we
     * don't show all entries since that may consume lots of resources in the
     * browser.
     */
    get visibleEntries(): SearchResultHistoricalCommitmentCompany[] {
        return this.historicalCommitments === null ? [] : this.historicalCommitments.slice(0, this.numToShow);
    }

    /**
     * Concatenates the function texts with <br> as separator.
     */
    formatFunctionTexts(commitment: SearchResultHistoricalCommitment): string {
        return commitment.functionTexts.join("<br>");
    }

    /**
     * Class map for the end date field.
     *
     * @param commitment The commitment.
     */
    endDateClassMap(commitment: SearchResultHistoricalCommitment): any {
        return {
            date: !!commitment.endDate,
            no_date: !commitment.endDate
        };
    }

    /**
     * Checks if there is at least one function with a "wide" text in the given
     * company.
     *
     * @param commitmentCompany The company.
     */
    hasWideFunction(commitmentCompany: SearchResultHistoricalCommitmentCompany): boolean {
        for (let commitment of commitmentCompany.commitments) {
            let maxLen: number = Math.max(...commitment.functionTexts.map(text => text.length));
            if (maxLen > 7) {
                return true;
            }
        }
        return false;
    }
}
