import {ActionTree, Module} from "vuex";
import {HTTP} from "@/services/http-provider";
import {resetState, RootState} from "@/store/store";
import {
    SafenodeQuestionerParameters
} from "@/models/safenode-questioner-parameters";
import {Global} from "@/global";

export const MUTATION_QUESTIONERS = "questioners";
export const MUTATION_SELECT_QUESTIONER = "selectQuestioner";
export const MUTATION_SHOW_QUESTIONER_IN_TERMS = "showQuestionerInTerms";

/**
 * The string before the / is the namespace (as set in the root store) and the
 * names after the / must match the names of the functions in the ActionTree.
 */
export const ACTION_SELECT_QUESTIONER = "questioner/selectQuestioner";
export const ACTION_FETCH_QUESTIONERS = "questioner/fetchQuestioners";
export const ACTION_VALIDATE_QUESTIONER = "questioner/validateQuestioner";
export const ACTION_DELETE_QUESTIONER = "questioner/deleteQuestioner";


export interface QuestionerState {
    questioners: SafenodeQuestionerParameters[];
    showInTerms: SafenodeQuestionerParameters;
    selected: string;
}

export const actions: ActionTree<QuestionerState, RootState> = {
    /**
     * Fetches the list of questioners for the currently signed in user from
     * the server.
     *
     * @param context The context.
     */
    fetchQuestioners(context): Promise<SafenodeQuestionerParameters[]> {
        return new Promise((resolve, reject) => {
            if (context.rootGetters.signedIn && context.rootState.activeSubscription && context.rootState.activeSubscription.refNo && context.rootState.activeSubscription.permissions.creditReport) {
                HTTP.post<SafenodeQuestionerParameters[]>("/sapi/questioner/" + context.rootState.activeSubscription.refNo + "/list").then((response: SafenodeQuestionerParameters[]) => {
                    context.commit(MUTATION_QUESTIONERS, response);
                    resolve(response)
                }).catch((error) => {
                    context.commit(MUTATION_QUESTIONERS, []);
                    reject(error);
                });
            } else {
                context.commit(MUTATION_QUESTIONERS, []);
                resolve([])
            }
        })
    },

    /**
     * Marks the given orgNo as a selected questioner.
     *
     * @param context The context.
     * @param orgNoSelected The orgNo to mark as selected.
     */
    selectQuestioner(context, orgNoSelected: string): void {
        if (context.rootGetters.signedIn && context.rootState.activeSubscription) {
            context.commit(MUTATION_SELECT_QUESTIONER, orgNoSelected);
        }
    },

    /**
     * Validates the given questioner against the backend.
     *
     * @param context The context.
     * @param orgNo The orgNo to validate as a questioner.
     *
     * @return A promise that resolves to an instance of
     *     SafenodeQuestionerParameter to be shown in the frontend.
     */
    validateQuestioner(context, orgNo: string): Promise<SafenodeQuestionerParameters> {
        return new Promise((resolve, reject) => {
            if (context.rootGetters.signedIn && context.rootState.activeSubscription && context.rootState.activeSubscription.permissions.creditReport) {
                HTTP.post<SafenodeQuestionerParameters>("/sapi/questioner/validate/" + context.rootState.activeSubscription.refNo + "/" + orgNo).then((response: SafenodeQuestionerParameters) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(undefined)
            }
        })
    },

    /**
     * Deletes the given questioner.
     *
     * @param context The context.
     * @param questioner The questioner to delete.
     *
     *  * @return A promise that resolves to the removed questioner.
     */
    deleteQuestioner(context, questioner: SafenodeQuestionerParameters): Promise<SafenodeQuestionerParameters> {
        return new Promise((resolve, reject) => {
            if (context.rootGetters.signedIn && context.rootState.activeSubscription && context.rootState.activeSubscription.permissions.creditReport) {
                HTTP.post("/sapi/questioner/" + context.rootState.activeSubscription.refNo + "/delete/" + questioner.orgNo)
                    .then(() => {
                        resolve(questioner);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }
};

/**
 * Returns the initial state of this store.
 */
function initialState(): QuestionerState {
    return {
        questioners: [],
        showInTerms: undefined,
        selected: ""
    };
}

/**
 * This module handles the questioner list as well as the actions that are
 * possible to perform upon it.
 */
export const questionerModule: Module<QuestionerState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        /**
         * Sets the complete list of questioners. Properly marking the first
         * one
         * as selected if there is no questioner matching the currently
         * selected one.
         *
         * @param state The state.
         * @param questioners The new set of questioners.
         */
        [MUTATION_QUESTIONERS]: (state: QuestionerState, questioners: SafenodeQuestionerParameters[]) => {
            if (questioners.length > 0) {
                let selectedQuestioner =  questioners.find( q => q.orgNo === state.selected);
                if (!selectedQuestioner) {
                    state.selected = questioners[0].orgNo;
                }
            } else {
                state.selected = "";
            }
            state.questioners = questioners;
        },

        /**
         * Stores the given questioner parameters to make it accessible for the
         * terms popup.
         * @param state The state.
         * @param showInTerms The questioner parameters to show in terms.
         */
        [MUTATION_SHOW_QUESTIONER_IN_TERMS]: (state: QuestionerState, showInTerms: SafenodeQuestionerParameters) => {
            state.showInTerms = showInTerms
        },

        /**
         * Marks the given questioner as selected
         * @param state The state.
         * @param questioner The questioner to mark as selected.
         */
        [MUTATION_SELECT_QUESTIONER]: (state: QuestionerState, selected: string) => state.selected = selected,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: QuestionerState) => resetState(state, initialState()),
    },
    actions: actions
};

