import {ActionTree, Module} from "vuex";
import {HTTP} from "@/services/http-provider";
import {resetState, RootState} from "@/store/store";
import {SearchResultResponse} from "@/models/search-response";
import {SearchRequest} from "@/models/search-request";
import {WrappedResponse} from "@/services/http";
import {Global} from "@/global";
import {DetailViewConfig} from "@/models/detail-view-config";

export const MUTATION_DETAILS = "detail";
export const MUTATION_SEARCH_RESULT = "searchResult";
export const MUTATION_FORCE_SEARCH = "forceSearch";
export const MUTATION_UPDATE_FILTERS = "updateFilters";
export const MUTATION_UPDATE_DETAIL_VIEW_CONFIG = "updateDetailViewConfig";
export const MUTATION_UPDATE_USER_COMPANY_FINANCIAL_CONFIG = "updateUserCompanyFinancialConfig";
export const ACTION_UPDATE_ENTITY_FILTER = "search/updateEntityFilters";
export const ACTION_GET_ENTITY_FILTER = "search/getEntityFilters";

/**
 * The string before the / is the namespace (as set in the root store) and the
 * names after the / must match the names of the functions in the ActionTree.
 */
export const ACTION_PERFORM_SEARCH = "search/performSearch";

class EntityFilterResponse {
    publicId: string;
    filters: string[];
}

/**
 * This class stores a public id and a link id. It is used in serialized form
 * in the url in order to be able to reload a state where we have detail views
 * open and where we highlight the correct links that were used when opening
 * the entities.
 */
export class EntityViewRef {
    /**
     * The public id of the entity.
     */
    publicId: string;

    /**
     * The zero based level of the detail view from which this view was opened.
     * May be -1 for the leftmost entity view.
     */
    parentLevel: number;

    /**
     * The id of the link that was clicked when opening the view for the entity.
     */
    linkId: string;

    /**
     * The address location filter. If defined, it will be either an apartment
     * number any of the special values defined in
     * SearchPageEntityAddressLocationInfo.
     */
    addressLocationFilter?: number;


    constructor(publicId: string, parentLevel: number = -1, linkId: string = "0", addressLocationFilter: number  = undefined) {
        this.publicId = publicId;
        this.parentLevel = parentLevel;
        this.linkId = linkId;
        this.addressLocationFilter = addressLocationFilter;
    }

    /**
     * Gets a key that identifies this ref as uniquely as possible. Notice that
     * it is possible we get the same unique key for references opened from the
     * same link, but currently this is good enough for our purposes.
     */
    get uniqueKey(): string {
        return this.publicId + "|" + this.linkId;
    }
}

export interface SearchState {
    /**
     * The current search result.
     */
    searchResultResponse: SearchResultResponse;

    /**
     * This array contains one entry for each currently open view. It is
     * populated by parsing the url and decoding the "detaljer" parameter when
     * navigating to a new search.
     */
    details: EntityViewRef[];

    /**
     * This is a counter that is increased every time we need to force a
     * search. This is necessary when searching again for the same search
     * string (without having any details shown), due to the fact that Vue does
     * not treat navigation to the same url as a real navigation event. The
     * actual value of this field should thus always be ignored, but the change
     * may be tracked using @Watch where necessary.
     */
    forceSearch: number;
    
    entityFilter: Map<string,string[]>;

    /**
     * The wrapper object for various user config parameters.
     */
    detailViewConfig: DetailViewConfig;
}

/**
 * This counter is incremented by one for each search, making us able to track
 * the response and discard old responses that arrive out of order.
 */
let searchId: number = 0;

/**
 * Here we store the id of the latest response received so that we know when
 * old responses should be discarded.
 */
let receivedId: number = 0;


export const actions: ActionTree<SearchState, RootState> = {
    /**
     * Perform a search using the provided search request. If responses arrive
     * in a different order than they were sent, all responses but the latest
     * will be discarded. That is true for both successful an unsuccessful
     * responses.
     *
     * @param context The context.
     * @param searchRequest The search request.
     */
    performSearch(context, searchRequest: SearchRequest): Promise<SearchResultResponse> {
        return new Promise((resolve, reject) => {
            // Get us a new id for the search.
            searchId++;

            HTTP.postWrapped<SearchResultResponse>("/sapi/search", searchId, searchRequest, 2).then((response: WrappedResponse<SearchResultResponse>) => {
                // Only resolve the promise if the id is the latest we have received.
                if (response.requestId > receivedId) {
                    receivedId = response.requestId;
                    context.commit(MUTATION_SEARCH_RESULT, response.response);
                    resolve(response.response);
                }
            }).catch((error) => {
                // Only reject the promise if the id is the latest we have received.
                if (error.requestId > receivedId) {
                    receivedId = error.requestId;
                    context.commit(MUTATION_SEARCH_RESULT, []);
                    reject(error);
                }
            });
        })
    },
    
    updateEntityFilters(context, request: EntityFilterResponse): Promise<string[]> {
        return new Promise((resolve, reject) => {
            HTTP.post<EntityFilterResponse>("/sapi/search/update-filter/",request).then((response: EntityFilterResponse) => {
                    context.commit(MUTATION_UPDATE_FILTERS, response);
                    resolve(response.filters)
                }).catch((error) => {
                    reject(error);
                });
        });
    },
    
    getEntityFilters(context, publicId: string): Promise<string[]> {
        let id = (publicId === "" || publicId === null)  ? "0" : publicId;
        return new Promise((resolve, reject) => {
            HTTP.get<EntityFilterResponse>("/sapi/search/get-filter/"+id).then((response: EntityFilterResponse) => {
                    context.commit(MUTATION_UPDATE_FILTERS, response);
                    resolve(response.filters)
                }).catch((error) => {
                    reject(error);
                });
        });
    }
};

/**
 * Returns the initial state of this store.
 */
function initialState(): SearchState {
    return {
        searchResultResponse: new SearchResultResponse(),
        details: [],
        forceSearch: 0,
        entityFilter: new Map<string,string[]>(),
        detailViewConfig: new DetailViewConfig()
    };
}

export const searchModule: Module<SearchState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        [MUTATION_DETAILS]: (state: SearchState, details: EntityViewRef[]) => state.details = details,
        [MUTATION_SEARCH_RESULT]: (state: SearchState, searchResultResponse: SearchResultResponse) => state.searchResultResponse = searchResultResponse,
        [MUTATION_FORCE_SEARCH]: (state: SearchState) => state.forceSearch++,
        [MUTATION_UPDATE_FILTERS]: (state: SearchState, response: EntityFilterResponse) => {
            let neo = new Map<string,string[]>();
            for (let [key, value] of state.entityFilter) {
                neo.set(key,value);
            }
            neo.set(response.publicId,response.filters);
            state.entityFilter = neo;
        },

        /**
         * Updates the entire detail view config object.
         *
         * @param state The state.
         * @param detailViewConfig The new config object.
         */
        [MUTATION_UPDATE_DETAIL_VIEW_CONFIG]: (state: SearchState, detailViewConfig: DetailViewConfig) => state.detailViewConfig = detailViewConfig,

        /**
         * Updates only the company financial type of the detail view config.
         *
         * @param state The state.
         * @param companyFinancialType The new company financial type.
         */
        [MUTATION_UPDATE_USER_COMPANY_FINANCIAL_CONFIG]: (state: SearchState, companyFinancialType: string) => state.detailViewConfig.companyFinancialType = companyFinancialType,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: SearchState) => resetState(state, initialState()),
    },
    actions: actions
};
