
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import Auth from "@/mixins/auth";
    import Utils from "@/mixins/utils";
    import StateHelper from "@/mixins/state-helper";
    import {HTTP} from "@/services/http-provider";
    import {SearchResultEntryCatalog} from "@/models/search-result-entry-catalog";
    import CatalogAnimationSpread from "@/components/CatalogAnimationSpread.vue";

    /**
     * This component represents the remark animation that is part of the income tab in
     * the search result details.
     */
    @Component({
      components: {CatalogAnimationSpread}
    })
    export default class RemarkAnimation extends Mixins(Auth, StateHelper,Utils) {
        /**
         * The search result individual for which to show the remarks.
         */
        @Prop()
        catalog: SearchResultEntryCatalog;

        @Prop()
        id: string;

        /**
         * The style of the flipping sprite. Used to change the background image depending on
         * which individual we're viewing the remarks for.
         *
         * @type A map of css attributes.
         */
        style: any = {};

        /**
         * The style for the magnifying glass. Used for animation of the magnifying glass.
         *
         * @type A map of css attributes.
         */
        magnifyImgStyle: any = {};


        /**
         * The style for the container for the actual remark image. Used for scaling the image
         * when the magnifying glass has zoomed in on it.
         *
         * @type A map of css attributes.
         */
        magnifyContainer1Style: any = {};

        /**
         * The style for the actual remark image container. Used for setting the background to
         * the correct remarks image.
         *
         * @type A map of css attributes.
         */
        remarkMagnifyImageStyle: any = {};

        /**
         * Keeps track of the various steps in the animation. See #handleMagnifyAnimation().
         */
        step: string = "0";

        /**
         * Will be set to a proper error message if we're out of catalog views.
         * This may happen both when the pool is exhausted and when a user
         * exceeds the usage limitation.
         */
        outOfCatalogViewsMessage: string = null;

        /**
         * The catalog spreads to be displayed during the animation.
         */
        spreads: any[] = [];

        private flipSpriteObjectUrl: string;

        private catalogImageObjectUrl: string;


        mounted() {
            if (this.existsInCatalog) {
                HTTP.get<any>("/api/catalog/remark/flip-sprite/" + this.id)
                    .then((wrapper: any) => {
                        let data = wrapper.image;
                        const blob = this.b64toBlob(data, "image/jpeg");
                        this.flipSpriteObjectUrl = URL.createObjectURL(blob)
                        for (let i = 0; i < wrapper.numPages; i += 2) {
                            let spread: any = {};
                            spread.low = i;
                            if (i + 1 == wrapper.numPages) {
                                spread.high = -1;
                                spread.isLast = true;
                            } else {
                                spread.high = i + 1;
                                spread.isLast = false;
                            }
                            spread.img = this.flipSpriteObjectUrl;
                            this.spreads.push(spread);
                        }
                        this.style["background-image"] = "url(" + this.flipSpriteObjectUrl + ")";
                        this.handleMagnifyAnimation();
                    });
            } else {
                setTimeout(() => {
                    this.step = "0:none-existing";
                }, 500);
            }
        }

        beforeDestroy() {
            URL.revokeObjectURL(this.flipSpriteObjectUrl);
            URL.revokeObjectURL(this.catalogImageObjectUrl);
        }

        /**
         * Checks if the individual exists in the catalog. Individuals that don't does not
         * have any page coordinates, so that's how we do it.
         */
        get existsInCatalog(): boolean {
            return this.catalog.middleX !== -1;
        }

        /**
         * Returns true if we're not allowed to view more catalogs
         */
        get outOfCatalogViews(): boolean {
            return this.outOfCatalogViewsMessage !== null;
        }

        /**
         * Determines if we're currently on the given step. Notice that since
         * we don't want the animation to start if we're out of catalog views,
         * we run only step 5 and 6 in those cases. Hence the somewhat ugly if
         * expression in this method.
         *
         * @param step The step to check if we're on.
         */
        onStep(step: string):boolean {
            return (this.step >= step && !this.outOfCatalogViews) || (this.step >= step && step >= "5");
        }

        private handleMagnifyAnimation() {
            // We know we now either have a valid access token or no access token at all.
            this.magnifyContainer1Style = {
                'left': "" + (this.catalog.middleX) + "px",
                'top': "" + (this.catalog.middleY) + "px",
            };


            /*
              Create a background image url that shows the actual remark image if the
              request to fetch it succeeds, and otherwise shows the default image. This is
              the backup for the cases where the token expires during the request or when
              the user tries to view the remarks for someone she is not allowed to view it for,
              which typically is the case for private subscriptions if you try to view
              anyone but yourself.
             */
            let url: string = "require(../assets/nobodys_income_blurred.png)";

            let allowedToViewRemarks = this.signedIn && this.allowedForSubscription() && this.allowViewRemarks;
            if (allowedToViewRemarks) {
                // Only try to fetch real remarks image if we're properly logged in.
                url = "/sapi/catalog/remark/ind" +
                    "?id=" + this.id +
                    "&subscriptionRefNo=" + this.activeSubscription.refNo;

            }

            HTTP.fetchAsBlob(url, "image/png")
                .then((blob: Blob) => {
                    // Setting the step to "1" starts the flipping animation.
                    this.step = "1";

                    this.catalogImageObjectUrl = URL.createObjectURL(blob);
                    this.remarkMagnifyImageStyle["background-image"] = "url(" + this.catalogImageObjectUrl + ")";
                    
                    // Since the number of spreads can vary, we set the root timeout based on the number of spreads.  
                    let timeout = 2000 + (this.spreads.length * 2 - 1) * 1500/19 ;
                    let stepLength = 500;
                    
                    // Lift the magnifying glass to above the middle of the spread.
                    setTimeout(() => {
                        this.magnifyImgStyle = {
                            "z-index": "30",
                            "left": "110px",
                            "top": "70px",
                            "transform": "scale(1.5)",
                        };
                    }, timeout);

                    // Lower the magnifying glass to above the correct row and column.
                    setTimeout(() => {
                        this.magnifyImgStyle = {
                            "z-index": "30",
                            "left": "" + (this.catalog.middleX - 30) + "px",
                            "top": "" + (this.catalog.middleY - 30) + "px",
                            "transform": "scale(1)",
                        };
                    }, timeout+=stepLength);

                    // Show the actual remarks image.
                    setTimeout(() => {
                        this.step = "2";
                        this.magnifyContainer1Style = {
                            'left': "",
                            'top': "",
                        };
                    }, timeout+=stepLength);

                    // Display the red highlight bar.
                    setTimeout(() => {
                        this.step = "3";
                    }, timeout+=stepLength);

                    // Lower the heading arrows.
                    setTimeout(() => {
                        this.step = "4";
                      /*
                       Set these timers here since outOfCatalogViews may not have
                       been set before.
                       */
                        if (!allowedToViewRemarks || this.outOfCatalogViews) {
                            setTimeout(() => {
                                this.step = "5";
                            }, 1000);
                            setTimeout(() => {
                                this.step = "6";
                            }, 1500);
                        }
                    }, timeout+=2*stepLength);
                })
                .catch((e) => {
                    if (e.status == 402 || e.status == 429) {
                        this.outOfCatalogViewsMessage = e.status == 402 ? "Ditt abonnemang har slut på katalogvisningar." : "För många katalogvisningar på för kort tid. Kontakta kundtjänst för att få hjälp.";
                        this.step = "5";
                        setTimeout(() => {
                            this.step = "6";
                        }, 500);
                    }
                });
        }

        /**
         * Checks if the current user is allowed to check remarks with the
         * active subscription.
         */
        private allowedForSubscription(): boolean {
            return this.activeUser && this.hasActiveSubscription;
        }
    }
