
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import {ID_NUMBER_FILTER} from "@/services/filters";
import StateHelper from "@/mixins/state-helper";
import SvgConstants from "@/mixins/svg-constants";
import {DisplayableEntity} from "@/models/displayable-entity";
import {EventNotification} from "@/models/event-notification";
import {WrappedNotification} from "@/store/store-notification";
import {EntityType} from "@/models/entity-type";
import SelectOnClick from "@/components/SelectOnClick.vue";
import SearchPageEntityEventList
    from "@/components/SearchPageEntityEventList.vue";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {
    SearchResultEntryIndividual
} from "@/models/search-result-entry-individual";

/**
 * This component represents a single entry in the list of freeriders in the right
 * sidebar.
 */
@Component({
    components: {SelectOnClick, SearchPageEntityEventList, SearchPageEntityLink}
})
export default class SidebarRightEventListEntry extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    wrappedNotification: WrappedNotification;

    level: number = 0;


    get linkId(): string {
        return String(this.hashCode(this.notification.id + "-" + this.notification.sentDate + "-" + this.notification.entityType));
    }

    get relationLinkId(): string {
        return String(this.hashCode(this.notification.id + "-" + this.notification.sentDate + "-COMPANY"));
    }

    isSelected(): boolean {
        return this.wrappedNotification.selected;
    }

    /**
     * Toggles whether to show the expanded part or not.
     */
    toggle(): void {
        this.$emit("expand-change", {
            "index": this.$vnode.key,
            "value": !this.wrappedNotification.expanded,
            "id": this.notification.id
        });
    }

    handleSelect(): void {
        this.$emit("on-select", this.$vnode.key);
    }

    get entity(): DisplayableEntity {
        return this.wrappedNotification.notification.entity;
    }

    get notification(): EventNotification {
        return this.wrappedNotification.notification;
    }

    /**
     * Replace the link's linkId with the linkId of this event, since it is the
     * event div that should be marked as active. Also sets the address location
     * filter.
     */
    emitOpenEntityView(entityReference: any): void {
        this.$emit('open-entity-view', entityReference);
    }

    displayName(entity: DisplayableEntity): string {
        switch (this.getEntityType(entity.id)) {
            case EntityType.UNKNOWN:
                return "";
            case EntityType.INDIVIDUAL:
                if (entity.unknown) {
                    return "Okänd individ: " + ID_NUMBER_FILTER(entity.idNumber);
                }
                let individual: SearchResultEntryIndividual = this.convertToSearchResultEntryIndividual(entity);
                let firstNames = this.getGivenOrFirstOrPnrWithTruncation(individual, 20);
                let lastNames = this.getMiddleAndLastNames(individual);
                return `${firstNames} ${lastNames}`;
            case EntityType.ORGANISATION:
                return entity.unknown ? "Okänt företag: " + ID_NUMBER_FILTER(entity.idNumber) : entity.companyName;
            case EntityType.ADDRESS_LOCATION:
                return entity.unknown ? "Okänd adressplats" : entity.streetAddressAndCity;
            case EntityType.REAL_PROPERTY:
                return this.formatSidebarRealProperty(entity);
            case EntityType.VEHICLE:
                return this.formatSidebarVehicle(entity);
            default:
                return "";
        }
    }
}
