
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SelectOnClick from "@/components/SelectOnClick.vue";

/**
 * This component represents a number converted to millions and formatted with a
 * decent number of decimals, and in a way that prevents line breaks in the
 * middle of the number. It is also possible to specify a unit to be placed
 * after the number.
 */
@Component({
    components: {SelectOnClick}
})
export default class FMillions extends Mixins(Utils) {
    /**
     * The number to format.
     */
    @Prop()
    value: number | string;

    /**
     * The unit, if any.
     */
    @Prop()
    unit: string;

    /**
     * Set to true in order to avoid wrapping the formatted number in a
     * select-on-click tag.
     */
    @Prop({default: false})
    skipSelect: boolean;


    /**
     * Renders the html.
     */
    get renderNumber(): string {
        let ret: string = this.formatMillionsPlain(this.value);
        if (this.unit) {
            ret += " " + this.unit;
        }
        return ret;
    }
}
