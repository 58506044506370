
    import {Component, Mixins} from 'vue-property-decorator';
    import PopupMessageCloseButton from "@/components/PopupMessageCloseButton.vue";
    import StateHelper from "@/mixins/state-helper";
    import {HTTP} from "@/services/http-provider";

    /**
     * This component represents the popup showing the terms for Safenode.
     */
    @Component({
        components: {PopupMessageCloseButton}
    })
    export default class PopupMessageSafenodeTerms extends Mixins(StateHelper) {

        safenodeTermBullets: string[] = [];

        mounted(): void {
            let url = "/api/auth/safenode-terms-no-creator";
            let payload = null;
            if (this.popupMessageStatus === 'safenode-terms-questioner') {
                url = "/api/auth/safenode-terms-with-creator";
                payload = this.questionerShowInTerms;
            }
            HTTP.post<string[]>(url, payload).then((terms: string[]) => {
                this.safenodeTermBullets = terms;
            });
        }
    }
