
    import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
    import L, {LatLngExpression} from 'leaflet'
    import {Positionable} from "@/models/positionable";
    import SvgConstants from "@/mixins/svg-constants";
    import Utils from "@/mixins/utils";


    /**
     * Container for the center and zoom level of a map.
     */
    export class MapPosition {
        center: L.LatLngExpression;

        zoomLevel: number;


        constructor(center: LatLngExpression, zoomLevel: number) {
            this.center = center;
            this.zoomLevel = zoomLevel;
        }
    }

    /**
     * Representation of the map state, containing both an optional MapPosition and the
     * addresses to show in the map.
     */
    export class MapState {
        position: MapPosition;

        addresses: MapAddress[];


        constructor(position: MapPosition, addresses: MapAddress[]) {
            this.position = position;
            this.addresses = addresses;
        }
    }

    /**
     * A wrapper around a coordinate that also contains a type, making it possible to
     * apply different styling to different type of markers.
     */
    export class MapAddress {
        /**
         * The type, for example "fbf" or "sp".
         */
        type: string;

        /**
         * The tag to display inside the address box. Pure html.
         */
        labelHtml: string;

        /**
         * The coordinate. May be null if we have no coordinate for this address.
         */
        coord: Positionable;


        constructor(type: string, labelHtml: string, coord: Positionable) {
            this.type = type;
            this.labelHtml = labelHtml;
            this.coord = coord;
        }
    }

    /**
     * This component represents the small map in the info tab in the details view.
     */
    @Component({})
    export default class SearchPageEntityInfoMap extends Mixins(SvgConstants, Utils) {
        readonly urlTemplate = window.location.origin + "/app/api/map/getTile?x={x}&y={y}&z={z}";
        
        readonly minZoom: number = 6;

        readonly maxZoom: number = 20;

        /**
         * The number of meters per pixel at lat 62 (~middle of Sweden) using our minimum
         * zoom level. This is used when determining if all markers fit inside the visible
         * map or not.
         */
        readonly metersPerPixelAtMinZoom = 40075016.686 * Math.abs(Math.cos(62 * 180 / Math.PI)) / Math.pow(2, this.minZoom + 8);


        @Prop()
        id: string;

        @Prop()
        mapState: MapState;

        map: L.Map;

        icons: L.DivIcon[] = [];

        leafletMarkers: L.Marker[] = [];


        mounted() {
            this.createIcons();
            this.reset();
        }

        /**
         * Filters out all addresses that have coordinates.
         */
        get addressesWithCoords(): MapAddress[] {
            return this.mapState.addresses.filter(address => address.coord);
        }

        /**
         * Resets the map state, which involves creating a new map, and the markers to
         * show on it. Also resets the position. If we have a center and a zoom level in
         * the MapState given as input, we use that. Otherwise we try to fit the map
         * around the given addresses.
         */
        reset(): void {
            if (this.map) {
                this.map.off();
                this.map.remove();
            }

            // Create the map and the tile layer.
            this.map = new L.Map(this.id, {
                attributionControl: false,
                keyboard: false
            });

            let layer = new L.TileLayer(this.urlTemplate, {
                minZoom: this.minZoom,
                maxZoom: this.maxZoom,
                attribution: '<div class="map-attribution"><a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap</a> | <a href="https://geotailor.com" target="_blank">Geo Tailor</a></div>',
            });

            L.control.attribution({
                prefix: "",
                position: "bottomleft"
            }).addTo(this.map);

            // Use our own zoom controls
            this.map.removeControl(this.map.zoomControl);

            // Create the markers.
            let withCoords: MapAddress[] = this.addressesWithCoords;
            for (let i = 0; i < withCoords.length; i++) {
                let address: MapAddress = withCoords[i];
                let leafletMarker: L.Marker = L.marker(this.toLatLng(address.coord), {icon: this.icons[i]});
                leafletMarker.addTo(this.map);
                this.leafletMarkers.push(leafletMarker);
            }

            // Update to values from the entity and show the map.
            if (this.mapState.position) {
                this.map.setView(this.mapState.position.center, this.mapState.position.zoomLevel);
            } else {
                this.resetPosition();
            }
            layer.addTo(this.map);
        }

        /**
         * Reset the position - fit the map around the given addresses.
         */
        resetPosition(): void {
            /*
              Find the min and max lat and check if the distance between them in pixels
              is less than the height of the map. In that case, we can fit all markers
              inside the map. Otherwise, just zoom in around the first marker.
             */
            let forFitBounds: any[] = [];
            let minLat: number = 360;
            let maxLat: number = -360;
            let someLng: number = 0;

            let withCoords: MapAddress[] = this.addressesWithCoords;

            // Not much to do when we have no coords.
            if (withCoords.length === 0) {
                return;
            }

            for (let i = 0; i < withCoords.length && i < this.leafletMarkers.length; i++) {
                let lat = withCoords[i].coord.yCoord;
                let lng = withCoords[i].coord.xCoord;
                let coord: LatLngExpression = [lat, lng];
                this.leafletMarkers[i].setLatLng(coord);
                forFitBounds.push(coord);
                minLat = Math.min(minLat, lat);
                maxLat = Math.max(maxLat, lat);

                // Pick some lng - the first one will do.
                if (someLng === 0) {
                    someLng = lng;
                }
            }

            // This is the default if we only have one marker.
            this.map.setView(forFitBounds[0], 14);

            // Check if the most distant latitudes fit inside the map height.
            if (forFitBounds.length > 1 && this.map.distance([minLat, someLng], [maxLat, someLng]) < this.map.getSize().y * this.metersPerPixelAtMinZoom) {
                // Yup! Fit the map around all markers.
                this.map.fitBounds(forFitBounds, {
                    paddingTopLeft: [50, 30],
                    paddingBottomRight: [10, 10]
                });
            }
        }

        /**
         * Emit the state of the map when the user clicks the expand button. Necessary
         * since we then have to change which instance of this component that is visible.
         */
        expandClicked(): void {
            this.$emit("sizeChange", new MapPosition(this.map.getCenter(), this.map.getZoom()));
        }

        /**
         * Pans the map to the given coordinate.
         */
        panTo(coord: Positionable) {
            if (coord) {
                this.map.panTo(this.toLatLng(coord));
            }
        }

        /**
         * We have to watch when the mapState changes since that is what happens when the
         * user clicks on a different result entry in the hit list, and we then must
         * reposition the map.
         */
        @Watch("mapState")
        onMarkersChange(): void {
            this.createIcons();
            this.resetPosition();
        }

        // noinspection JSMethodCanBeStatic
        /**
         * Maps the address type to the class defining the styling of the address label.
         *
         * @param type The type, like 'fbf' or 'sp' etc.
         * @return The class
         */
        typeToAddressLabelClass(type: string): string {
            if (type === "sp" || type === "second") {
                return "object_map_adress_special";
            } else {
                return "object_map_adress";
            }
        }

        /**
         * Create marker icons and style them according to the type of the marker.
         */
        private createIcons() {
            this.addressesWithCoords.forEach(address => {
                let className: string = "object_map_pin";
                if (address.type === "sp" || address.type === "second") {
                    className += " object_map_pin_special";
                }
                this.icons.push(L.divIcon({
                    className: className,
                    iconSize: [40, 30], // size of the icon
                    iconAnchor: [10, 30], // point of the icon which will correspond to marker's location
                    html: "<div>" + this.svg.pinWithShadow + "</div>"
                }));
            });
        }
    }
