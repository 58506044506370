import {SearchResultEntry} from "@/models/search-result-entry";


/**
 * See the corresponding java class for more documentation.
 */
export class SearchResultResponse {
    totalNumHits: number = 0;

    tooManyHits: boolean = false;

    hitOutsideIndex: boolean = false;

    hits: SearchResultEntry[] = [];

    mainHitId: string

    /**
     * This is only set if there was an error during the search. Always null for
     * successful searches. Thus, this field should be used in order to differentiate
     * between an empty search result and a failed search.
     */
    errorMessage?: string;
}
