import {Component, Vue} from "vue-property-decorator";
import {
    GIVEN_NAME_FILTER,
    ID_NUMBER_FILTER,
    TRUNCATE_FILTER
} from "@/services/filters";
import {DisplayableEntity} from "@/models/displayable-entity";
import {
    EndUserParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {
    SearchResultEntryIndividual
} from "@/models/search-result-entry-individual";
import {
    SearchResultEntryCompanyDetails
} from "@/models/search-result-entry-company-details";
import {Positionable} from "@/models/positionable";
import {LatLng, LatLngExpression} from "leaflet";
import {SearchResultEntryCompany} from "@/models/search-result-entry-company";
import {EntityViewRef} from "@/store/store-search";
import {SimpleIndividual} from "@/models/simple-individual";
import {
    SearchResultNamedBvIndividual
} from "@/models/search-result-named-bv-individual";
import {
    SearchResultEntryAddressLocation
} from "@/models/search-result-entry-address-location";
import {SearchResultHousingInfo} from "@/models/search-result-housing-info";
import {SearchResultEntry} from "@/models/search-result-entry";
import {SearchResultEntryDetails} from "@/models/search-result-entry-details";
import {EntityType} from "@/models/entity-type";
import {DisplayableDocument} from "@/models/displayable-document";
import {Location} from "vue-router/types/router";
import {
    SearchResultSimpleNamedIndividual
} from "@/models/search-result-simple-named-individual";
import {HTTP} from "@/services/http-provider";
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import {MUTATION_LAST_SEARCH_STRING} from "@/store/store";
import {
    ACTION_CREATE_MONITOR,
    ACTION_FETCH_MONITORS,
    MonitorRequest
} from "@/store/store-monitor";
import {CreateMonitorResponse} from "@/models/create-monitor-response";
import {MonitorPriority} from "@/models/frontend-monitor-config";

/**
 * Interface that contains the fields needed to determine if an event contains
 * data about if modifier keys were pressed down or not.
 */
export interface ModifierKeyEvent {
    ctrlKey: boolean,
    metaKey: boolean
}

@Component({})
export default class Utils extends Vue {
    /**
     * The max number of entries to show in a list by default. If the list has
     * more entries, we display a "Visa fler" link at the bottom.
     */
    public readonly CHUNK_SIZE: number = 100;

    public readonly EM_DASH: string = "\u2014";

    public readonly EN_DASH: string = "\u2013";

    public readonly ZERO_WIDTH_SPACE: string = "\u200b";

    public readonly ZERO_WIDTH_NO_BREAK_SPACE: string = "\ufeff";


    // noinspection NonAsciiCharacters
    /**
     * This map contains an approximate "width factor" for each character. The
     * idea is to be able to estimate rendered string length without having to
     * actually render the string. This will of course not be pixel perfect,
     * but will still be useful in most cases.
     *
     * The table is created by rendering each character in 14 px Roboto and
     * dividing by ten, which seem to give us a decent factor where for example
     * an 'a' is about one
     * "character" wide, while an 'i' is only about half a "character" wide.
     *
     */
    private charToWidthFactor: { [key: string]: number; } = {
        "a": 1.01479797363281,
        "b": 1.04668121337891,
        "c": 0.9765380859375,
        "d": 1.05214691162109,
        "e": 0.988380432128906,
        "f": 0.647685241699219,
        "g": 1.04668121337891,
        "h": 1.02755126953125,
        "i": 0.452742004394531,
        "j": 0.445454406738281,
        "k": 0.945565795898438,
        "l": 0.452742004394531,
        "m": 1.63515472412109,
        "n": 1.02937316894531,
        "o": 1.0639892578125,
        "p": 1.04668121337891,
        "q": 1.06034545898438,
        "r": 0.631288146972656,
        "s": 0.961962890625,
        "t": 0.609425354003906,
        "u": 1.02846221923828,
        "v": 0.903662109375,
        "w": 1.40195159912109,
        "x": 0.924613952636719,
        "y": 0.882710266113281,
        "z": 0.924613952636719,
        "å": 1.01479797363281,
        "ä": 1.01479797363281,
        "ö": 1.0639892578125,
        "A": 1.21702880859375,
        "B": 1.16146087646484,
        "C": 1.21429595947266,
        "D": 1.22340545654297,
        "E": 1.06034545898438,
        "F": 1.03119506835938,
        "G": 1.27077484130859,
        "H": 1.32998657226563,
        "I": 0.507398986816406,
        "J": 1.02937316894531,
        "K": 1.16965942382813,
        "L": 1.00386657714844,
        "M": 1.62877807617188,
        "N": 1.32998657226563,
        "O": 1.2826171875,
        "P": 1.17694702148438,
        "Q": 1.2826171875,
        "R": 1.14870758056641,
        "S": 1.10680389404297,
        "T": 1.11318054199219,
        "U": 1.2097412109375,
        "V": 1.18696746826172,
        "W": 1.65519561767578,
        "X": 1.16965942382813,
        "Y": 1.12046813964844,
        "Z": 1.11682434082031,
        "Å": 1.21702880859375,
        "Ä": 1.21702880859375,
        "Ö": 1.2826171875
    };

    /**
     * Calculates the approximate width in a unit that should somehow
     * correspond to the
     * 'number of characters'. However, different characters have different
     * width in different fonts and styles, so this method tries to approximate
     * each character to a default character times a factor that is lower for
     * narrower characters and higher for wider characters. For example, an "m"
     * is wider than a "j" so the string "mmm" will get a higher width than
     * "jjj" even though they have the same number of characters. The character
     * "a" has width of about 1 while a "j" is somewhere around
     * 0.45. See the charToWidthFactor map for all widths. Characters not in
     * that map are considered to have the width 1.
     *
     * @param s The string. If null or undefined, zero will be returned.
     * @return The width as described above.
     */
    public approximateWidth(s: string): number {
        if (!s) {
            return 0;
        }
        let ret: number = 0;
        for (let i = 0; i < s.length; i++) {
            let factor: number = this.charToWidthFactor[s.charAt(i)];
            ret += factor ? factor : 1;
        }
        return ret;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Calculates how many characters of the given string that fits in the
     * given
     * "approximate" width. See the method approximateWidth() for more info
     * about what that is.
     *
     * @param s The string. Must not be null.
     * @param approxWidth The approximate width we want to have, in number of
     *     characters.
     */
    public numCharsForApproximateWidth(s: string, approxWidth: number): number {
        while (s.length > 1 && this.approximateWidth(s) > approxWidth) {
            s = s.substr(0, s.length - 2);
        }
        return s.length;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Converts the given string of names to the initials of that name. For
     * example, passing the string "Anna Beata-Cecilia" will return the string
     * "A B-C".
     *
     * @param name The name. If null or undefined, the empty string is
     *     returned.
     * @return The initials as described above. Never null.
     */
    public initials(name: string): string {
        let ret: string = "";
        let separator: string = " ";

        if (!name) {
            return "";
        }

        for (let i = 0; i < name.length; i++) {
            let c: string = name.charAt(i);
            if (separator.length > 0) {
                ret += separator + c;
                separator = "";
            } else if (c === " " || c === "-") {
                separator = c;
            }
        }
        return ret.trim();
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Translates the given age unit enum string to a human-readable gender
     * string.
     *
     * @param ageUnit The age unit enum value.
     */
    public getAgeUnit(ageUnit: string): string {
        switch (ageUnit) {
            case "YEAR":
                return "år";
            case "YEARS":
                return "år";
            case "MONTH":
                return "mån";
            case "MONTHS":
                return "mån";
            default:
                return "";
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Translates the given gender enum string to a human readable gender
     * string.
     *
     * @param gender The gender enum value.
     */
    public getGender(gender: string): string {
        switch (gender) {
            case "MAN":
                return "Man";
            case "MALE":
                return "Man";
            case "WOMAN":
                return "Kvinna";
            case "FEMALE":
                return "Kvinna";
            case "BOY":
                return "Pojke";
            case "GIRL":
                return "Flicka";
            default:
                return "";
        }
    }

    /**
     * Creates a string like "Kvinna, 42 år" or "Kvinna, avliden", or
     * Avregistrerad, depending on whether the individual is alive,
     * deregistered or 'normal'.
     *
     * @param individual The individual.
     */
    public getGenderAndAgeOrDeceased(individual: SearchResultEntryIndividual) {
        let genderPrefix: string = this.getGender(individual.gender) + ", ";
        if (individual.deceased) {
            return genderPrefix + "Avliden";
        } else if (individual.deregistered) {
            return "Avregistrerad"
        } else {
            return genderPrefix + individual.age + " år";
        }
    }

    /**
     * Creates a string like "Kvinna, 42 år" or "Kvinna, f.1938", depending on
     * whether the individual is alive, deregistered or 'normal'.
     *
     * @param individual                 The individual.
     * @param alwayBirthYearInResultList If true, birth year is always displayed
                                         instead of age.
     */
    public getGenderAndAgeOrBirthdate(individual: SearchResultEntryIndividual, alwayBirthYearInResultList: boolean) {
        let genderPrefix: string = this.getGender(individual.gender) + ", ";
        if (individual.deceased || individual.deregistered || alwayBirthYearInResultList) {
            return genderPrefix + "f." + individual.birthDate.substr(0, 4);
        } else {
            return genderPrefix + individual.age + " år";
        }
    }

    /**
     * Gets a string like "Kvinna 42 år", or "Kvinna, f.1938" from the given
     * individual.
     *
     * @param individual The individual.
     */
    getGenderAndAgeOrBirthdateFromSimple(individual: SearchResultSimpleNamedIndividual) {
        let birthYear: number = individual.birthdate ? Number(individual.birthdate.substr(0, 4)) : 0;
        return this.getGenderAndAgeOrBirthdateFromParams(individual.gender, individual.age, birthYear, individual.deceased);
    }

    /**
     * Gets a string like "Kvinna 42 år", or "Kvinna, f.1938" from the given
     * parameters.
     *
     * @param gender The gender.
     * @param age The age in years.
     * @param birthYear The four digit birthdate.
     * @param deceased True if the individual is deceased.
     * @param ageUnit The age unit. See
     */
    getGenderAndAgeOrBirthdateFromParams(gender: string, age: number, birthYear: number, deceased: boolean, ageUnit: string = "YEARS") {
        let ret = [];
        let genderStr: string = this.getGender(gender);
        if (genderStr) {
            ret.push(genderStr);
        }
        if (deceased) {
            ret.push(birthYear ? "f." + birthYear : "");
        } else if (age) {
            ret.push(age + " " + this.getAgeUnit(ageUnit));
        }
        return ret.join(", ");
    }

    /**
     * Gets a string like "Maria Johansson, Kvinna 42 år", or "Maria Johansson,
     * avliden" from the given individual.
     *
     * @param individual The individual.
     */
    getNameAndAgeOrDeceasedFromSimple(individual: SearchResultSimpleNamedIndividual) {
        let ret: string = individual.name;
        if (individual.deceased) {
            ret += ", avliden";
        } else if (individual.age) {
            ret += ", " + individual.age + " år";
        }
        return ret;
    }

    /**
     * Gets a string like "Maria Johansson, Kvinna 42 år", or "Maria Johansson,
     * avliden" from the given individual.
     *
     * @param individual The individual.
     */
    getNameAndAgeOrNameAndBirthDateIfDeceasedFromSimple(individual: SearchResultSimpleNamedIndividual) {
        let ret: string = individual.name;
        if (!individual.deceased && individual.age) {
            ret += ", " + individual.age + " år";
        } else if (individual.deceased && individual.birthdate) {
            ret += ", f." + individual.birthdate.substr(0, 4);
        }
        return ret;
    }

    /**
     * Gets a string like "Maria Johansson, Kvinna 42 år", or "Maria Johansson,
     * avliden" from the given individual.
     *
     * @param name The name.
     * @param age The age in years.
     * @param birthYear The four digit birth year.
     * @param deceasedOrDeregistered True if the individual is deceased or
     *     deregistered.
     */
    getNameAndAgeOrBirthdateFromParams(name: string, age: number, birthYear: number, deceasedOrDeregistered: boolean) {
        let ret: string = name;
        if (deceasedOrDeregistered && birthYear) {
            ret += ", f." + birthYear;
        } else if (age) {
            ret += ", " + age + " år";
        }
        return ret;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets a name string for the individual according to:
     *
     * 1) The given name.
     * 2) No given name - use all first names.
     * 3) No first names - use personal number (mostly for secret identity).
     *
     * If any part of the resulting name is longer than the given number of
     * characters that part is truncated to numChars characters plus one
     * ellipsis character.
     *
     * @param individual The individual.
     * @param numChars The number of characters. May be -1 if no truncation is
     *     necessary.
     */
    public getGivenOrFirstOrPnrWithTruncation(individual: SimpleIndividual, numChars: number = -1) {
        if (individual.secretIdentity) {
            return individual.personalNumber;
        } else {
            let firstOrGiven: string = GIVEN_NAME_FILTER(individual.firstNames, individual.givenNameCode, "extract-with-fallback");
            return TRUNCATE_FILTER(firstOrGiven, numChars, numChars !== -1);
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets a name string for the monitor entry according to:
     *
     * 1) The given name.
     * 2) No given name - use all first names.
     * 3) No first names - use personal number (mostly for secret identity).
     *
     * If any part of the resulting name is longer than the given number of
     * characters that part is truncated to numChars characters plus one
     * ellipsis character.
     *
     * @param individual The individual.
     * @param numChars The number of characters. May be -1 if no truncation is
     *     necessary.
     */
    public getGivenOrFirstOrPnrWithTruncationForSidebar(individual: SimpleIndividual, numChars: number = -1) {
        if (individual.secretIdentity) {
            return ID_NUMBER_FILTER(individual.personalNumber, true);
        } else {
            let firstOrGiven: string = GIVEN_NAME_FILTER(individual.firstNames, individual.givenNameCode, "extract-with-fallback");
            return TRUNCATE_FILTER(firstOrGiven, numChars, numChars !== -1);
        }
    }

    /**
     * Produces a string with name and possibly age, if present, for a board
     * member. For deceased and deregistered individuals we use the birth year
     * instead.
     *
     * @param member The member.
     */
    public nameAndPossiblyAgeOrBirthdate(member: SearchResultNamedBvIndividual): string {
        let name: string = this.getDisplayNameForBoardMember(member);
        return this.getNameAndAgeOrBirthdateFromParams(name, member.age, member.birthYear, member.deceased || member.deregistered);
    }


    /**
     * Submits the create monitor request and also fetches updated monitors
     * if any monitors were deleted together with the request.
     */
    public createMonitorFetchUpdates(request: MonitorRequest) {
        return this.$store.dispatch(ACTION_CREATE_MONITOR, request)
            .then((result: CreateMonitorResponse) => {
                if (result.numberOfDeletedMonitors > 0) {
                    return this.$store.dispatch(ACTION_FETCH_MONITORS);
                }
            });
    }

    /**
     * Formats the entire housing info string. Returns html.
     */
    public formatHousingInfo(housingInfo: SearchResultHousingInfo, lineBreak: boolean = true): string {
        if (!housingInfo) {
            return "";
        }
        let ret: string = this.formatHousingTypeAndConstructionYear(housingInfo);
        let roomsKitchenAndArea: string = this.formatRoomsKitchenAndArea(housingInfo.roomsAndKitchen, housingInfo.area);
        if (ret && roomsKitchenAndArea) {
            ret += lineBreak ? "<br>" : ", ";
        }
        return ret + roomsKitchenAndArea;
    }

    /**
     * Formats the string for housing type and construction year.
     */
    formatHousingTypeAndConstructionYear(hi: SearchResultHousingInfo): string {
        let ret: string = "";
        if (hi.housingType) {
            ret += hi.housingType;
        }
        if (hi.constructionYear) {
            if (ret) {
                ret += ", byggår ";
            } else {
                ret += "Byggår ";
            }
            ret += hi.constructionYear;
        }
        return ret;
    }

    /**
     * Formats the string for number of rooms, kitchen type and area.
     */
    formatRoomsKitchenAndArea(roomsAndKitchen: string, area: number): string {
        let ret: string = "";
        if (roomsAndKitchen) {
            ret += roomsAndKitchen;
        }
        if (area) {
            if (ret) {
                ret += ", ";
            }
            ret += area + " kvm";
        }
        return ret;
    }

    private formatSidebarIndividual(individual: SearchResultEntryIndividual, unknown: boolean) {
        if (unknown) {
            return "Ej längre tillgänglig: " + ID_NUMBER_FILTER(individual.personalNumber);
        }

        if (individual.secretIdentity) {
            let ret: string = `${ID_NUMBER_FILTER(individual.personalNumber, true)}, ${this.getGender(individual.gender)} ${individual.age} år`
            if (individual.fbfCity) {
                ret = ret + ", " + individual.fbfCity;
            }
            return ret;
        }
        let firstNames = this.getGivenOrFirstOrPnrWithTruncation(individual, 20);
        let lastNames = this.getMiddleAndLastNames(individual);
        let ageOrDeceased: string = individual.deceased ? "avliden" : individual.age + " år";
        return `${lastNames}, ${firstNames}, ${this.getGender(individual.gender)} ${ageOrDeceased}, ${individual.fbfCity}`
    }

    // noinspection JSMethodCanBeStatic
    public formatSidebarCompany(company: SearchResultEntryCompany) {
        return `${company.name}`;
    }

    // noinspection JSMethodCanBeStatic
    public formatSidebarRealProperty(entry: DisplayableEntity) {
        return entry.unknown ? "Okänd fastighet" : `${entry.realPropertyName}${entry.city ? ", " + entry.city : ""}`;
    }

    // noinspection JSMethodCanBeStatic
    public formatSidebarVehicle(entry: DisplayableEntity) {
        if (entry.unknown) {
            let ret: string = "Okänt fordon";
            if (entry.vehicleRegistrationNumber) {
                ret += " " + this.formatRegistrationNumber(entry.vehicleRegistrationNumber);
            }
            return ret;
        } else {
            return `${entry.vehicleType} ${this.formatRegistrationNumber(entry.vehicleRegistrationNumber)}`;
        }
    }

    /**
     * Formats the given displayable entity to a string to be presented to the
     * user.
     *
     * @param entity The entity to format.
     */
    public formatDisplayableEntity(entity: DisplayableEntity) {
        let formattedId: string = entity.idNumber ? ID_NUMBER_FILTER(entity.idNumber) : entity.id;
        switch (this.getEntityType(entity.id)) {
            case EntityType.UNKNOWN:
                return "";
            case EntityType.INDIVIDUAL:
                if (entity.unknown) {
                    return "Okänd individ: " + formattedId;
                }
                return this.formatSidebarIndividual(this.convertToSearchResultEntryIndividual(entity), entity.unknown);
            case EntityType.ORGANISATION:
                if (entity.unknown) {
                    return "Okänd organisation: " + formattedId;
                }
                let company: SearchResultEntryCompany = new SearchResultEntryCompany();
                company.name = entity.companyName;
                company.city = entity.city;
                return this.formatSidebarCompany(company);
            case EntityType.ADDRESS_LOCATION:
                return entity.unknown ? "Okänd adressplats" : entity.streetAddressAndCity;
            case EntityType.REAL_PROPERTY:
                return this.formatSidebarRealProperty(entity);
            case EntityType.VEHICLE:
                return this.formatSidebarVehicle(entity);
            default:
                return "";
        }
    }

    /**
     * Converts the given displayable entity to a SearchResultEntryIndividual
     * so that it can be used in other utility methods. Requires the entity to
     * be of individual type.
     *
     * @param entity The entity, assumed to represent an individual.
     */
    convertToSearchResultEntryIndividual(entity: DisplayableEntity): SearchResultEntryIndividual {
        let individual: SearchResultEntryIndividual = new SearchResultEntryIndividual();
        individual.personalNumber = entity.idNumber;
        individual.secretIdentity = entity.secretIdentity;
        individual.firstNames = entity.firstNames;
        individual.middleNames = entity.middleNames;
        individual.lastNames = entity.lastNames;
        individual.givenNameCode = entity.givenNameCode;
        individual.age = entity.age;
        individual.gender = entity.gender;
        individual.fbfCity = entity.city;
        individual.deceased = entity.deceased;
        return individual;
    }

    entityTypeText(entity: DisplayableEntity): string {
        switch (this.getEntityType(entity.id)) {
            case EntityType.UNKNOWN:
                return "";
            case EntityType.INDIVIDUAL:
                return "person";
            case EntityType.ORGANISATION:
                return "företag";
            case EntityType.ADDRESS_LOCATION:
                return "adressplats";
            case EntityType.REAL_PROPERTY:
                return "fastighet";
            case EntityType.VEHICLE:
                return "fordon";
            default:
                return "";
        }
    }


    /**
     * Returns the human-readable name of the document's type.
     *
     * @param document The document.
     */
    documentName(document: DisplayableDocument): string {
        switch (document.type) {
            case "ARTICLES_OF_ASSOCIATION":
                return "Bolagsordning";
            case "STATUTES":
                return "Stadgar";
            case "FINANCIAL_PLAN":
                return "Ekonomisk plan";
            case "CASE_LIST":
                return "Ärendeförteckning";
            case "CERTIFICATE_OF_REGISTRATION":
                return "Registreringsbevis";
            case "CERTIFICATE_OF_REGISTRATION_ENGLISH":
                return "Registreringsbevis engelska";
            case "CREDIT_REPORT":
                return "Kreditupplysning";
            case "REAL_PROPERTY_REPORT":
                return "Fastighetsrapport";
            case "REAL_PROPERTY_TAXATION_REPORT":
                return "Fastighetstaxeringsrapport";
            case "TITLE_DEED_AND_HISTORICAL_OWNER_REPORT":
                return "Lagfart och tidigare ägare"
            case "COMPANY_REPORT":
                return "Företagsrapport";
            case "COMPANY_MORTGAGE_REPORT":
                return "Företagsinteckningar";
            case "COMPANY_VEHICLE_REPORT":
                return "Fordonsinnehav";
            case "COMPANY_CAPITAL_REPORT":
                return "Kapitalrapport";
            case "INDIVIDUAL_REPORT":
                return "Personrapport";
            case "INDIVIDUAL_COMMITMENT_REPORT":
                return "Företagsengagemang";
            default:
                return "";
        }
    }

    /**
     * Formats the given document for display.
     *
     * @param document The document.
     */
    formatDocument(document: DisplayableDocument): string {
        let date: string = document.documentDate ? document.documentDate : document.created;
        return this.documentName(document) + " " + date;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets the middle and last name concatenated.
     *
     * @param ind The SearchResultEntry.
     */
    public getMiddleAndLastNames(ind: SimpleIndividual) {
        return this.getMiddleAndLastNamesFromStrings(ind.middleNames, ind.lastNames);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets the middle and last name concatenated.
     *
     * @param middleNames The middle names.
     * @param lastNames   The last names.
     */
    public getMiddleAndLastNamesFromStrings(middleNames: string, lastNames: string) {
        let ret: string[] = [];
        if (middleNames && middleNames.length > 0) {
            ret.push(middleNames);
        }
        if (lastNames && lastNames.length > 0) {
            ret.push(lastNames);
        }
        return ret.join(" ");
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Checks if the given entry has an fbf address that is to be displayed.
     *
     * @param entry The entry.
     * @returns {boolean} True if the entry has an fbf address that is to be
     *     displayed.
     */
    public hasFbfAddress(entry: SearchResultEntryIndividual): boolean {
        return !!(entry.fbfStreetAddress || entry.fbfRegion);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Checks if the given entry has an sp address that is to be displayed.
     *
     * @param entry The entry.
     * @returns {boolean} True if the entry has an sp address that is to be
     *     displayed.
     */
    public hasSpAddress(entry: SearchResultEntryIndividual): boolean {
        return !!(entry.spCareOf || entry.spAddressPrefix || entry.spStreetAddress || entry.spZip || entry.spCity);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Checks if the given entry has a foreign address that is to be displayed.
     *
     * @param entry The entry.
     * @returns {boolean} True if the entry has a foreign address that is to be
     *     displayed.
     */
    public hasForeignAddress(entry: SearchResultEntryIndividual): boolean {
        return !!(entry.foreignAddress1 || entry.foreignAddress2 || entry.foreignAddress3 || entry.foreignCountry);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Checks if the given entry has a postal address that is to be displayed.
     *
     * @param entry The entry.
     * @returns {boolean} True if the entry has an sp address that is to be
     *     displayed.
     */
    public hasPostalAddress(entry: SearchResultEntryCompany): boolean {
        return !!(entry.streetAddress || entry.zip);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Formats an address location for display, using the street address if it
     * is present, and the zip otherwise. The city is always shown.
     *
     * @param addressLocation The address location.
     */
    formatAddressLocation(addressLocation: SearchResultEntryAddressLocation) {
        let ret: string;
        if (addressLocation.streetAddress) {
            ret = addressLocation.streetAddress;
            if (addressLocation.city) {
                ret += ", " + addressLocation.city;
            }
        } else {
            ret = addressLocation.zip + " " + addressLocation.city;
        }
        return ret.trim();
    }

    /**
     * Returns true if the given hash is not active and should not render a
     * clickable link.
     *
     * @param hash The hash.
     */
    inactiveHash(hash: string): boolean {
        return !hash || Number(hash) === 0;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets the display name for a board member according to:
     *
     * 1) Given name + middleNames (if exists) + lastName according to the
     * fields firstNames, middleNames, lastNames and givenNameCode if both
     * firstNames and lastNames exists.
     * 2) firstNames +  middleNames (if exists) + lastNames if both firstNames
     * and lastNames exists.
     * 3) The name string from the Bolagsverket data.
     *
     * @param entry The board member to get the display name for.
     */
    public getDisplayNameForBoardMember(entry: SearchResultNamedBvIndividual): string {
        let ret = [];
        if (entry.firstNames && entry.lastNames) {
            ret.push(GIVEN_NAME_FILTER(entry.firstNames, entry.givenNameCode, 'extract-with-fallback'));
            if (entry.middleNames) {
                ret.push(entry.middleNames);
            }
            ret.push(entry.lastNames)
        } else {
            ret.push(entry.bvName);
        }

        return ret.join(' ')
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets a human-readable string for the given householdHousingType.
     *
     * @param householdHousingType The type.
     */
    public getHouseholdHousingType(householdHousingType: string): string {
        switch (householdHousingType) {
            case "SINGLE_FAMILY":
                return "Villa/radhus";
            case "MULTI_FAMILY":
                return "Lägenhet";
            default:
                return "Oklar";
        }
    }

    /**
     * Gets the text to display as housing type. Uses the housingInfo but falls
     * back to the householdHousingType.
     */
    public getHousingType(individual: SearchResultEntryIndividual): string {
        if (individual.housingInfo && individual.housingInfo.housingType) {
            return individual.housingInfo.housingType;
        }
        return this.getHouseholdHousingType(individual.householdHousingType)
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets a human-readable string for the given householdCompositionType.
     *
     * @param householdCompositionType The type.
     */
    public getHouseholdCompositionType(householdCompositionType: string): string {
        switch (householdCompositionType) {
            case "SINGLE_PERSON_HOUSEHOLD":
                return "Ensamhushåll";
            case "MULTIPLE_PERSON_HOUSEHOLD":
                return "Familjehushåll";
            default:
                return "Oklar";
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * If the given subscription is a demo subscription, the string (DEMO) is
     * returned, otherwise the empty string is returned.
     *
     * @param {SubscriptionParameters} subscription The subscription.
     * @returns {string} The string as described above.
     */
    public getDemoNotification(subscription: SubscriptionParameters): string {
        return subscription.demo ? " (DEMO)" : "";
    }

    /**
     * Returns true if the given company is either of:
     *
     * 51 - Ekonomisk förening
     * 53 - Bostadsrättsförening
     * 54 - Kooperativ hyresrättsförening
     * 94 - Understödsförening
     *
     * @param company The company. May be null or undefined in which case false
     *     is returned.
     */
    public isAssociation(company: SearchResultEntryCompanyDetails): boolean {
        return company && ["51", "53", "54", "94"].indexOf(company.form) !== -1;
    }

    /**
     * Returns true if the given company is:
     *
     * 53 - Bostadsrättsförening
     *
     * @param company The company. May be null or undefined in which case false
     *     is returned.
     */
    public isHousingSociety(company: SearchResultEntryCompanyDetails): boolean {
        return company && ["53"].indexOf(company.form) !== -1;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Used to check if a company is expected to appear in the remark catalog.
     * Returns true if the given company is either of:
     *
     * 10 - Enskild näringsidkare
     * 21 - Enkelt bolag
     * 31 - Handelsbolag / Kommanditbolag
     *
     * @param company The company. May be null or undefined in which case false
     *     is returned.
     */
    public isCompanyInRemarkCatalog(company: SearchResultEntryCompanyDetails): boolean {
        /*
         10 - Enskild näringsidkare
         21 - Enkelt bolag
         31 - Handelsbolag / Kommanditbolag
         */
        return company && ["10", "21", "31"].indexOf(company.form) !== -1;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Copies the given object.
     * @param obj The object to copy.
     */
    public copy<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Tries to extract an error message from the given response, which will
     * work if the response is a UserErrorMessage, or an AxiosResponse
     * containing such a message as its data. Otherwise, we return the given
     * default message, or a common default message if none is specified.
     *
     * @param response The response.
     * @param defaultMessage The optional default message.
     */
    public extractErrorMessage(response: any, defaultMessage?: string): string {
        if (response && response.response) {
            response = response.response;
        }
        if (response && response.data && response.data.type === "USER_MESSAGE") {
            return response.data.errorMessage;
        } else if (response && response.type === "USER_MESSAGE") {
            return response.errorMessage;
        } else if (defaultMessage) {
            return defaultMessage;
        } else {
            return "Något gick fel. Prova gärna igen."
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets a single string from the given argument, that may be either a
     * string or an array of strings. In the latter case, we return a comma
     * separated list of the entries in the array.
     *
     * @param what The string or string array.
     */
    public getSingleString(what: string | string[]): string {
        return what instanceof Array ? what.join(",") : what;
    }

    /*
     * Cleans the string from &,<,>,'," by replacing them 
     * with the html encoding equivalent.
     */
    static cleanStringFromHtml(str: string): string {
        return str.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/'/g, "&#x27;")
            .replace(/"/g, "&quot;")
    }


    /**
     * Function that formats a number into millions with thousands separator
     * wrapped with no break span tag. It should be used instead of "{{
     * numberToFormat | millions | emDashOnFalsy }}", Proper usage is adding
     * the following to the enclosing tag
     * 'v-html="formatMillions(numberToFormat)"'. Should preferably not be used
     * if the FMillions component can be used instead.
     */
    formatMillions(value: number | string): string {
        return this.wrapToNoLineBreak(this.formatMillionsPlain(value));
    }

    /**
     * Function that formats a number into millions with thousands separator.
     * Should preferably not be used if the FMillions component can be used
     * instead.
     */
    formatMillionsPlain(value: number | string): string {
        return this.$options.filters.emDashOnFalsy(this.$options.filters.millions(value));
    }

    /**
     * Function that formats a number into money with thousands separator and a
     * suitable prefix to "kr", wrapped with no break span tag. It should be
     * used instead of "{{ numberToFormat | money | emDashOnFalsy }}", Proper
     * usage is adding the following to the enclosing tag
     * 'v-html="formatMoney(numberToFormat)"'.
     */
    formatMoney(value: number | string, numFixedDecimals?: number): string {
        return this.wrapToNoLineBreak(this.$options.filters.emDashOnFalsy(this.$options.filters.money(value, numFixedDecimals)));
    }

    /**
     * Function that formats a number into number with thousands separator
     * wrapped with no break span tag. It should be used instead of "{{
     * numberToFormat | number | emDashOnFalsy }}", Proper usage is adding the
     * following to the enclosing tag 'v-html="formatNumber(numberToFormat)"'.
     * Should preferably not be used if the FNum component can be used instead.
     */
    formatNumber(value: number | string, numFixedDecimals?: number, maxValueForDecimals?: number): string {
        return this.wrapToNoLineBreak(this.formatNumberPlain(value, numFixedDecimals, maxValueForDecimals));
    }

    /**
     * Formats the given number by inserting thin space as separator and
     * replacing the number with em dash if falsy. Should preferably not be used
     * if the FNum component can be used instead.
     *
     * @param value The value to format, number or string.
     * @param numFixedDecimals Optional number of fixed decimals
     * @param maxValueForDecimals Optional max value above which we ignore
     * decimals and round to the closest whole number.
     */
    formatNumberPlain(value: number | string, numFixedDecimals?: number, maxValueForDecimals?: number): string {
        return this.$options.filters.emDashOnFalsy(this.$options.filters.number(value, numFixedDecimals, maxValueForDecimals));
    }

    /**
     * Wraps the supplied string in a non-breaking span.
     */
    wrapToNoLineBreak(str: string, clean?: boolean): string {
        const useStr = clean ? Utils.cleanStringFromHtml(str) : str;
        return '<span style="white-space:nowrap">' + useStr + "</span>";
    }

    formatRegistrationNumber(value: string): string {
        return this.wrapToNoLineBreak(this.formatRegistrationNumberNoWrap(value));
    }

    formatRegistrationNumberNoWrap(value: string): string {
        const THIN_SPACE: string = "\u2009";
        return value.substring(0, 3) + THIN_SPACE + value.substring(3, 6);
    }

    /**
     * Returns true if we're currently on a mobile device. Function borrowed
     * from http://detectmobilebrowsers.com/
     */
    public static isMobile(): boolean {
        let check: boolean = false;
        // @ts-ignore
        let toCheckAgainst: any = navigator.userAgent || navigator.vendor || window.opera;
        (function (a) {
            // noinspection RegExpSingleCharAlternation,RegExpRedundantEscape
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(toCheckAgainst);
        return check;
    }

    /**
     * Returns true if we're on macOS.
     */
    isMacOS(): boolean {
        return navigator.appVersion.indexOf('Mac') !== -1;
    }

    /**
     * Returns the name of the modifier key on the platform we're on.
     */
    modifierKey(): string {
        return this.isMacOS() ? "kommandotangenten (⌘)" : "ctrl-tangenten";
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Sets focus to the search input field if it is currently present in the
     * DOM.
     */
    setFocusOnSearchInputField(doFocus: boolean): void {
        let searchInput = document.getElementById("searchInputField");
        if (searchInput) {
            if (doFocus) {
                searchInput.focus();
            } else {
                searchInput.blur();
            }
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Either sets focus to or blurs the element with the given id if it is
     * currently present in the DOM.
     */
    setFocusOnId(id: string, doFocus: boolean = true): void {
        let element = document.getElementById(id);
        if (element) {
            if (doFocus) {
                element.focus();
            } else {
                element.blur();
            }
        }
    }

    /**
     * Selects the text in the search input field.
     */
    selectTextInSearchField(): void {
        let searchInput: any = document.getElementById("searchInputField");
        if (searchInput) {
            searchInput.select();
        }
    }

    /**
     * Store the value of the search field as the latest such value-
     */
    storeLatestSearchString(): void {
        let searchInput: any = document.getElementById("searchInputField");
        if (searchInput) {
            this.$store.commit(MUTATION_LAST_SEARCH_STRING, searchInput.value);
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Gets the human-readable text for payment status.
     *
     * @param paymentStatus The status.
     */
    getPaymentStatusText(paymentStatus: string): string {
        switch (paymentStatus) {
            case "PAID":
                return "Betald";
            case "UNPAID":
                return "Obetald";
            case "EXPIRED":
                return "Förfallen";
            default:
                return "";
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Checks if the two given subscriptions have different refNos, or if one
     * is undefined and the other is not.
     *
     * @param a The new subscription.
     * @param b The old subscription.
     */
    differentRefNo(
        a: SubscriptionParameters | EndUserParameters,
        b: SubscriptionParameters | EndUserParameters): boolean {
        // noinspection PointlessBooleanExpressionJS
        return !!(a && !b || !a && b || a && b && a.refNo !== b.refNo);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Returns true if the value is truhy or zero. Useful for checking null and
     * undefined values.
     *
     * @param value The value.
     */
    truthyOrZero(value: number): boolean {
        return !!(value || value === 0);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Converts the given Positionable to a Leaflet LatLngExpression.
     *
     * @param coord The positionable.
     */
    toLatLng(coord: Positionable): LatLngExpression {
        return new LatLng(coord.yCoord, coord.xCoord);
    }

    /**
     * Use Vue's built in $set method and set the specified field of the given
     * object to the provided value, if the value is truthy.
     *
     * @param object The object to set the field in.
     * @param fieldName The name of the field to set.
     * @param value The value to set.
     */
    setIfTruthy(object: any, fieldName: string, value: any) {
        if (value) {
            this.$set(object, fieldName, value);
        }
    }

    /**
     * Produces a java-style hash code for the given string. Convenience method
     * wrapping its static counterpart, which facilitates its usage in Vue
     * components.
     *
     * @param s The string. Must not be null.
     */
    hashCode(s: string) {
        return Utils.sHashCode(s)
    };

    /**
     * Produces a java-style hash code for the given string.
     *
     * @param s The string. Must not be null.
     */
    public static sHashCode(s: string) {
        let hash = 0, i, chr;
        if (s.length === 0) {
            return hash;
        }
        for (i = 0; i < s.length; i++) {
            chr = s.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    }


    // noinspection JSMethodCanBeStatic
    /**
     * Encodes a public id and a link id to a base64 encoded string. The string
     * we encode with base 64 is made up of the following contents:
     *
     * | 32 bits linkId | 32 bits left | 32 bits right | 8 bits extra | 8 bits
     * parent level | [ 16 bits address location filter|]
     *
     * Where linkId is what it sounds like and left and right are each a
     * nine-digit number making up one half each of the 18 last digits of the
     * public id. We also XOR each part with the link id in order to avoid large
     * parts of the ids to be the same for different entities. The 8 extra bits
     * indicates what type of public id we're dealing with. It is zero for
     * individuals and organisations and 3, 4, or 5 for address locations, real
     * properties and vehicles respectively. The 8 bit parent level indicates
     * from which entity view this view was created, thus making up a tree
     * hierarchy of entity views. Since the level can range from -1 we add one
     * to the actual level before encoding it, so we don't have to deal with
     * negative numbers. The optional last 16 bits contain the address location
     * filter if we have any. This is either an apartment number or any of the
     * constants defined in SearchPageEntityAddressLocationInfo.
     *
     * The reason for this somewhat complicated scheme is that we previously
     * only had individuals and organisations, and that their public ids never
     * exceed 18 digits, which is possible to squeeze into two signed integers.
     * When doing bitwise operations in javascript, numbers will be represented
     * as signed integers internally, so 9 digits is what we safely can deal
     * with without even more complicated schemes. When introducing other types
     * of ids, the public id can be 19 digits. Hence, the 8 extra bits that
     * contains the first digit of the public id.
     *
     * We also want to be able to still support old public ids, for which the
     * encoded string was just 96 bits (no extra part) so when we see that in
     * the decode method, we can safely assume the extra part and the parent
     * level is zero.
     *
     * NOTICE!!!
     *
     * When changing this method, one must also change the corresponding java
     * method {@code EmailUtils.encodePublicIdAndLevel} which is used when
     * creating public ids for emails.
     *
     * @param detail A single detail to encode, containing a public id and a
     *     link id.
     */
    public static encode(detail: EntityViewRef): string {
        // Scrambled ids are 18 characters and others are 19.
        let scrambled: boolean = detail.publicId.length < 19;

        /*
          For scrambled ids we can fit the entire 18 digits in the left and
          right parts, but for others, we use the 18 last digits and put the
          first digit in the extra part.
         */
        let publicIdPartToSplit = scrambled ? detail.publicId.padStart(18, '0') : detail.publicId.substr(1);
        let linkId: number = Number(detail.linkId);
        let leftPart: number = Number(publicIdPartToSplit.substr(0, 9)) ^ linkId;
        let rightPart: number = Number(publicIdPartToSplit.substr(9)) ^ linkId;
        let extraPart: number = scrambled ? 0 : Number(detail.publicId.charAt(0));
        let parentLevelPlusOne: number = Number(detail.parentLevel) + 1;

        let arr: string = String.fromCharCode(
            linkId & 0xff, linkId >> 8 & 0xff, linkId >> 16 & 0xff, linkId >> 24 & 0xff,
            leftPart & 0xff, leftPart >> 8 & 0xff, leftPart >> 16 & 0xff, leftPart >> 24 & 0xff,
            rightPart & 0xff, rightPart >> 8 & 0xff, rightPart >> 16 & 0xff, rightPart >> 24 & 0xff,
            extraPart & 0xff, parentLevelPlusOne & 0xff
        );

        /*
          If we have an addressLocationFilter defined we encode it here as 16
          extra bits.
         */
        if (detail.addressLocationFilter) {
            arr += String.fromCharCode(
                detail.addressLocationFilter & 0xff,
                detail.addressLocationFilter >> 8 & 0xff
            );
        }
        /*
          We don't really need the "=" padding, so let's remove it in order to
          get nicer urls.
         */
        return btoa(arr).replace(/=+$/, "");
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Decodes a string encoded with the encode() method back to a
     * EntityViewRef. See documentation for the encode() method.
     *
     * @param encoded The encoded string.
     */
    public decode(encoded: string): EntityViewRef {
        let res: string = atob(encoded);

        /*
          Remove the address location filter part (if there is one) from the
          string before handling the rest of the decoding.
         */
        let alFilter: number = undefined;
        if (res.length > 14) {
            let alFilterStr: string = res.substr(res.length - 2);
            alFilter = alFilterStr.charCodeAt(0) | alFilterStr.charCodeAt(1) << 8
            res = res.substr(0, res.length - 2);
        }

        /*
          Ids on the old format are encoded using 12 characters (96 bits) while
          the new format uses 14-16 characters (112-128 bits).
         */
        let oldFormat: boolean = res.length === 12;

        let linkId: number = res.charCodeAt(0) | res.charCodeAt(1) << 8 | res.charCodeAt(2) << 16 | res.charCodeAt(3) << 24;
        let leftPart: number = (res.charCodeAt(4) | res.charCodeAt(5) << 8 | res.charCodeAt(6) << 16 | res.charCodeAt(7) << 24) ^ linkId;
        let rightPart: number = (res.charCodeAt(8) | res.charCodeAt(9) << 8 | res.charCodeAt(10) << 16 | res.charCodeAt(11) << 24) ^ linkId;
        let extraPart: number = oldFormat ? 0 : res.charCodeAt(12);
        let parentLevelPlus1: number = oldFormat ? 0 : res.charCodeAt(13);

        let publicId = String(leftPart).padStart(9, '0') + String(rightPart).padStart(9, '0');
        if (extraPart !== 0) {
            publicId = String(extraPart) + publicId;
        }
        return new EntityViewRef(publicId, parentLevelPlus1 - 1, String(linkId), alFilter);
    }

    /**
     * Returns the last element of the list, or null if the list is null or
     * empty.
     *
     * @param list The list.
     */
    public last<T>(list: T[]): T {
        return list && list.length > 0 ? list[list.length - 1] : null;
    }

    /**
     * Handle clicks on "home" links, which should work like this: If we're on
     * the root of the application (on BASE_URL, which normally is /app)
     * we should navigate to the real root of the site (without BASE_URL that
     * is) but if we're on any other page, we should navigate to the root of the
     * app. In the former case, we must also remove the user cookie, so that we
     * won't be redirected right back again.
     */
    public handleHomeClick(): void {
        if (this.$route.path === "/") {
            // Have the server remove the cookie and redirect to the real root
            window.location.href = this.rootUrl();
        } else {
            this.$router.push("/");
        }
    }

    /**
     * Performs a request that removes the user cookie, and then redirects the
     * user to a path relative to the absolute path of the app (that is, without
     * the "/app") so that we can link to pages on the main page in front of the
     * application.
     *
     * @param path The path. May be null in which case we redirect to the root.
     */
    public removeUserCookieAndRedirectToOuterApp(path: string): void {
        if (!path) {
            path = "";
        } else if (path.charAt(0) !== "/") {
            path = "/" + path;
        }
        HTTP.get<EndUserSubscriptionOperationResponse>("/api/remove-user-cookie").catch(() => {
            // Really don't care much here.
        }).finally(() => window.location.href = this.appUrl() + path);
    }

    /**
     * This should be used if we want to go to "/", because it removes the
     * "user=true" cookie first.
     */
    public rootUrl(): string {
        return "/app/home";
    }

    /**
     * Makes it possible to access the static global siteUrl from views.
     */
    public appUrl(): string {
        return window.location.origin;
    }

    /**
     * Returns true if the given string starts with any of the provided
     * candidates.
     *
     * @param s The string to check.
     * @param candidates The candidates to check against.
     */
    public startsWithAnyOf(s: string, candidates: string[]): boolean {
        for (let candidate of candidates) {
            if (s.startsWith(candidate)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Create a key that is unique for an entity context, based on the id of the
     * entity and the level on which it is shown.
     *
     * For example used when wanting to specify entity and level when triggering
     * navigation to a SearchPageEntity with a specific tab open.
     *
     * @param publicId The public id of the entity.
     * @param level The level of the entity in the context.
     */
    public createPublicIdAndLevelKey(publicId: string, level: number): string {
        return level + "-" + publicId;
    }

    /**
     * Divides the numerator by the denominator if the numerator is a number
     * and the denominator is not null. Otherwise, null is returned.
     *
     * @param numerator The numerator. May be null or undefined, in which case
     *     null is returned.
     * @param denominator The denominator.
     */
    divideByOrNull(numerator: number, denominator: number) {
        // noinspection SuspiciousTypeOfGuard
        return typeof numerator === "number" && denominator !== 0 ? numerator / denominator : null;
    }

    /**
     * Returns the first defined element of the given list.
     *
     * @param list The list of elements.
     */
    firstDefined(...list: any): any {
        if (!list) {
            return null;
        }
        return list.find((entry: any) => !!entry);
    }

    /**
     * This is actually a real sleep function that blocks the execution. DO NOT
     * use this for any other purpose than debugging.
     *
     * @param sleepDuration The sleep duration in milliseconds.
     */
    sleepFor(sleepDuration: number): void {
        let now = new Date().getTime();
        while (new Date().getTime() < now + sleepDuration) {
            /* Do nothing */
        }
    }

    /**
     * Returns true if the given code represents any of the Ctrl keys.
     *
     * @param code The code.
     * @private
     */
    isCtrlClick(code: string) {
        return code === "ControlLeft" || code === "ControlRight";
    }

    /**
     * Returns true if the given code represents any of the Meta/Cmd keys.
     *
     * @param code The code.
     * @private
     */
    isMetaClick(code: string) {
        return code === "MetaLeft" || code === "MetaRight" || code === "OSLeft" || code === "OSRight";
    }

    /**
     * Renders the right or down pointing triangle.
     */
    expandArrow(active: boolean): string {
        return active ? "&#9661;" : "&#9655;";
    }

    /**
     * Shows the correct text for the expand arrow link depending on the number
     * of elements in the list.
     *
     * @param list The list.
     */
    expandText(list: any[]): string {
        return list && list.length > this.CHUNK_SIZE ? "Visa fler" : "Visa alla";
    }

    /**
     * Extracts the entity from the given entry. It is guaranteed that only one
     * is set at any time.
     *
     * @param result The result from which to extract the entity.
     */
    getEntity(result: SearchResultEntry | SearchResultEntryDetails): any {
        if (result.individual) {
            return result.individual;
        } else if (result.company) {
            return result.company;
        } else if (result.addressLocation) {
            return result.addressLocation;
        } else if (result.realProperty) {
            return result.realProperty;
        } else if (result.vehicle) {
            return result.vehicle;
        }
        return null;
    }

    /**
     * Return the given list, or the empty list if the given list is null or
     * undefined.
     *
     * @param list The list.
     */
    nn(list: any[]): any[] {
        return list === null || list === undefined ? [] : list;
    }

    /**
     * Return the given map, or an empty map if the given list is null or
     * undefined.
     *
     * @param map The map.
     */
    em(map: any): any {
        return map === null || map === undefined ? {} : map;
    }

    /**
     * Gets the entity type for the entity with the given id.
     *
     * @param publicId The public id.
     */
    getEntityType(publicId: string): EntityType {
        if (!publicId) {
            return EntityType.UNKNOWN;
        }
        switch (publicId.charAt(0)) {
            case '1':
                return EntityType.INDIVIDUAL;
            case '2':
                return EntityType.ORGANISATION;
            case '3':
                return EntityType.ADDRESS_LOCATION;
            case '4':
                return EntityType.REAL_PROPERTY;
            case '5':
                return EntityType.VEHICLE;
        }
        return EntityType.UNKNOWN;
    }

    getNextMonitorPriorityValue(prevValue: string, allowToggleOff: boolean = false): MonitorPriority {
        let newValue: MonitorPriority;

        if (prevValue === "HIGH") {
            newValue = "NORMAL";
        } else if (prevValue === "NORMAL") {
            newValue = "LOW";
        } else if (prevValue === "LOW") {
            if (allowToggleOff) {
                newValue = "OFF";
            } else {
                newValue = "HIGH";
            }
        } else if (prevValue === "OFF") {
            if (allowToggleOff) {
                newValue = "HIGH";
            } else {
                newValue = "OFF";
            }
        } else {
            newValue = "NORMAL";
        }
        return newValue;
    }

    /**
     * Parses the given string into a date and returns a date string on the
     * format yyyy-MM-dd if parsing was successful, or null otherwise. Only the
     * formats yyyy-MM-dd and yyyyMMdd are allowed.
     *
     * @param dateStr The string to parse.
     */
    parseDate(dateStr: string): string {
        if (!dateStr) {
            return null;
        }
        let numeric: string = dateStr.replace(/\D/g, "");
        if (numeric.length !== 8) {
            return null;
        }
        let formattedDate: string = numeric.substr(0, 4) + "-" + numeric.substr(4, 2) + "-" + numeric.substr(6, 2);
        return isNaN(Date.parse(formattedDate)) ? null : formattedDate;
    }

    filterEvent(publicId: string, eventType: string, filters: string[]) {
        if (!filters) {
            return true;
        }
        switch (publicId.charAt(0)) {
            case "1":
                return this.filterIndividualEvent(eventType, filters);
            case "2":
                return this.filterCompanyEvent(eventType, filters);
            case "3":
                return this.filterAddressLocationEvent(eventType, filters);
            default:
                return true;
        }
    }

    filterCompanyEvent(eventType: string, filters: string[]): boolean {
        switch (eventType) {
            case "CompanyVehicleAdded":
            case "CompanyVehicleRemoved":
                return filters.includes("COMPANY_VEHICLE");
            case "RemarkCancelled":
            case "DebtBalanceUpdate":
            case "NewKonkursansokan":
            case "NewUtmatning":
            case "NewTredskodom":
            case "NewAllmantMal":
            case "NewBetForel":
            case "NewAnsokanBetForel":
                return filters.includes("COMPANY_REMARKS");
            case "NewFinancials":
            case "UpdatedFinancials":
                return filters.includes("COMPANY_FINANCIALS");
            case "BoardInfoChange":
            case "BoardMemberFunctionAddition":
            case "BoardMemberFunctionRemoval":
            case "ProcurationChange":
            case "CompanyBeneficialOwnerChange":
            case "CompanyBeneficialReportingObligationChange":
            case "BoardMemberFunctionAggregated":
                return filters.includes("COMPANY_BOARD_MEMBER");
            case "NewAnnualReport":
            case "UpdatedAnnualReport":
            case "BvSubmittedAnnualReport":
                return filters.includes("COMPANY_ANNUAL_REPORT");
            case "BvNewCase":
            case "BvClosedCaseNoRegistration":
            case "BvUpdatedCase":
                return filters.includes("COMPANY_BV_CASE");
            case "CompanyRealPropertyOwnerChange":
            case "CompanyRealPropertyOwnerAdded":
            case "CompanyRealPropertyOwnerRemoved":
                return filters.includes("COMPANY_REAL_PROPERTY");
            default:
                return filters.includes("COMPANY_GENERAL");
        }
    }

    filterIndividualEvent(eventType: string, filters: string[]): boolean {
        switch (eventType) {
            case "ReportCardUpdate":
                return true; // Always true.
            case "RatsitCatalogIncome":
                return filters.includes("INDIVIDUAL_INCOME_CATALOG");
            case "IndividualVehicleAdded":
            case "IndividualVehicleRemoved":
                return filters.includes("INDIVIDUAL_VEHICLE");
            case "CompanyFunctionAddition":
            case "CompanyFunctionRemoval":
            case "CompanyFunctionAggregated":
            case "IndividualBeneficialOwnerChange":
                return filters.includes("INDIVIDUAL_BOARD_MEMBER");
            case "IndividualPEPChange":
            case "IndividualSanctionChange":
            case "IndividualTradingProhibitionDecision":
            case "IndividualTradingProhibitionRemoval":
                return filters.includes("INDIVIDUAL_PEP_SANCTION_AND_TRADING_PROHIBITION");
            case "IndividualRealPropertyOwnerChange":
            case "IndividualRealPropertyOwnerAdded":
            case "IndividualRealPropertyOwnerRemoved":
                return filters.includes("INDIVIDUAL_REAL_PROPERTY");
            default:
                return filters.includes("INDIVIDUAL_GENERAL");
        }
    }

    filterAddressLocationEvent(eventType: string, filters: string[]): boolean {
        switch (eventType) {
            case "AddressLocationCompanyDeregistered":
            case "AddressLocationCompanyRegistered":
                return filters.includes("ADDRESS_LOCATION_COMPANY");
            case "AddressLocationPersonDeregistered":
            case "AddressLocationPersonRegistered":
                return filters.includes("ADDRESS_LOCATION_INDIVIDUAL");
            default:
                return true;
        }
    }

    formatEnumeration(values: string[]): string {
        if (values.length == 0) {
            return "";
        } else if (values.length == 1) {
            return values[0];
        } else {
            return values.slice(0, values.length - 1).join(", ") + " och " + values[values.length - 1];
        }
    }

    /**
     * Checks if any modifier keys were hold down on the given event.
     *
     * @param event
     */
    static modifierKeyPressed(event: ModifierKeyEvent): boolean {
        return event.metaKey || event.ctrlKey;
    }

    /**
     * Adds a "detaljer" key value pair to the given query. The given query
     * will get a new "detaljer" key, or update the existing value, if the
     * given details are non-empty. If the given details are empty then no
     * changes will be made to the given query.
     *
     * @param query The query to add details to
     * @param details The details to add. If empty then nothing will be added
     *     to the query.
     */
    static addDetailsToQuery(query: Location["query"], details: EntityViewRef[]): void {
        // Don't include the 'detaljer' parameter if we have no detail ids.
        if (details && details.length > 0) {
            query["detaljer"] = details.map(d => Utils.encode(d)).join(",");
        }
    }

    /**
     * Returns true if the given string is likely to be an encoded id.
     *
     * @param encodedId The string to check.
     */
    public isEncodedId(encodedId: string): boolean {
        return encodedId && encodedId.length === 12 && encodedId.charAt(0) === "0";
    }

    /**
     * Wrap a promise in this method if all errors should be ignored.
     *
     * @param promise The promise. May be null in which case this is a no-op.
     */
    public ignoreError(promise: Promise<any>): void {
        if (promise) {
            promise.catch(() => {
            });
        }
    }

    /**
     * A very simple hash function for strings, producing a hash string.
     *
     * @param s The string to produce a hash string for.
     */
    public simpleHash(s: string): string {
        let hash: number = 0;
        if (!s) {
            return "";
        }
        for (let x = 0; x < s.length; x++) {
            let ch: number = s.charCodeAt(x);
            hash = ((hash << 5) - hash) + ch;
            hash = hash & hash;
        }
        return String(hash);
    }

    private dec2hex(dec: number) {
        // 0..255 -> 00..FF
        return dec.toString(16).padStart(2, "0");
    }

    /**
     * Returns a cryptographically decent string of the given length, where each
     * pair of characters are the hex value of a random integer from 0..255.
     * That is - the resulting string contains only characters 0123456789abcdef.
     * If for some reason the window.crypto should not be available, we fall
     * back to a more "unsafe" way of generating a random string, using
     * Math.random().
     *
     * @param len The length of the string to generate.
     */
    public randomString(len: number) {
        let arr: Uint8Array = new Uint8Array((len + 1) / 2);
        try {
            window.crypto.getRandomValues(arr);
        } catch (e) {
            return this.unsafeRandomString(len);
        }
        return Array.from(arr, this.dec2hex).join('').substring(0, len);
    }

    /**
     * Generate a random string of the given length containing characters
     * 0123456789abcdef, using Math.random(), which is not a very random
     * "randomness".
     *
     * @param len The length of the string to generate.
     */
    public unsafeRandomString(len: number) {
        let ret: string = "";
        while (ret.length < len) {
            ret += Math.random().toString(16).substring(2);
        }
        return ret.substring(0, len);
    }
    
    /**
     * Gets the string used to render the qr code.
     */
    bankIDqrString(): string {
        return this.$store.state.bankIdQrString;
    }
    
    /**
     * Gets the auto start href for use on mobile devices.
     */
    bankIDautoStartHrefMobile(): string {
        return this.bankIDautoStartHrefDesktop();
    }
    
    bankIDstatusMessage(): string {
        return this.$store.state.bankIdStatusMessage;
    }
    
    /**
     * Gets the auto start href for use on desktop devices.
     */
    bankIDautoStartHrefDesktop(): string {
        let token = this.$store.state.bankIdAutoStartToken;
        return "bankid:///?autostarttoken="+token+"&redirect=null";
    }
    
    /**
     * Converts b64 data to blob.
     */
    b64toBlob(b64Data: any, contentType: string = '', sliceSize: number = 512): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }
}

export const UTILS: Utils = new Utils();
