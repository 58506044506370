
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import StateHelper from "@/mixins/state-helper";
    import PopupMessageWelcome from "@/components/PopupMessageWelcome.vue";
    import PopupMessageFullScreen from "@/components/PopupMessageFullScreen.vue";
    import PopupMessageReason from "@/components/PopupMessageReason.vue";
    import PopupMessageLogout from "@/components/PopupMessageLogout.vue";
    import {
        MUTATION_POPUP_MESSAGE_STATUS,
        MUTATION_SIDEBAR_RIGHT_STATUS
    } from "@/store/store";
    import Utils from "@/mixins/utils";
    import PopupMessageSafenodeTerms from "@/components/PopupMessageSafenodeTerms.vue";
    import ApplicationStateSerializer from "@/mixins/application-state-serializer";
    import {Route} from "vue-router";
    import PopupMessageError from "@/components/PopupMessageError.vue";
    import Routing from "@/mixins/routing";
    import PopupMessageReload from "@/components/PopupMessageReload.vue";
    import PopupMessagePersonalNumber from "@/components/PopupMessagePersonalNumber.vue";

    /**
     * This component represents a popup modal dialog for simple messages.
     */
    @Component({
        components: {
            PopupMessageReload,
            PopupMessageError,
            PopupMessageSafenodeTerms,
            PopupMessageReason,
            PopupMessageFullScreen,
            PopupMessageWelcome,
            PopupMessageLogout,
            PopupMessagePersonalNumber,
        }
    })
    export default class PopupMessage extends Mixins(ApplicationStateSerializer, StateHelper, Utils, Routing) {
        /**
         * This array contains popupMessageStatus values for which the shown
         * dialog should not close when the user clicks outside it.
         */
        readonly unclosable: string[] = ["reload", "personal-number"];

        showPopup: boolean = false;

        /**
         * Sets the showPopup status flag. For non-full screen messages we set the flag a
         * short while after the global status has been set. By doing this we ensure the
         * DOM is rendered before adding the class that controls the transition of the
         * container, so that the animation works as expected. For full screen messages
         * however, we don't want an animation, so then we just add the class at once.
         *
         * @param val The new status value.
         */
        @Watch("popupMessageStatus")
        onPopupMessageStatusChange(val: string) {
            if (val.startsWith("full-screen")) {
                this.showPopup = !!val;
            } else {
                setTimeout(() => {
                    this.showPopup = !!val;
                }, 0);
            }
        }

        /**
         * Watch for url change. Necessary in order to close the welcome dialog when
         * navigating from the /valkommen url using the browsers back or forward buttons.
         */
        @Watch("$route", {immediate: true, deep: true})
        onUrlChange(to: Route, from: Route) {
            if (from && from.path.startsWith("/valkommen") && to && !to.path.startsWith("/valkommen")) {
                this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "");
            }
        }

        /**
         * Close the dialog, but only if the mouse event had the transparent screen behind
         * the dialog as target (thus, not when the user clicks inside the message
         * dialog) and we are not currently showing a full screen message.
         *
         * @param event The event.
         */
        close(event: MouseEvent): void {
            // Don't allow close if this is a personal number input field
            if (this.$route.params.showPersonalNumber === "true") {
                return;
            }
            // Only close if the click was on the transparent element behind the dialog.
            let element: Element = <Element>event.target;
            if (element.classList.contains("popup_message_w1") && !this.isFullScreen && this.unclosable.indexOf(this.popupMessageStatus) === -1) {
                this.setFocusOnSearchInputField(true);
                this.closeAndRerouteToSavedPath();
            }
        }

        closeAndRerouteToSavedPath(): void {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "");

            /*
              The normal case when showing the welcome screen is that we're rerouted here
              from someplace inside the application and thus have a saved state to which
              we should return when closing.
             */
            if (this.$route.path.startsWith("/valkommen")) {
                this.rerouteToSavedPath();

                /*
                  Display subscription selection if it is specified in the
                  route params and the account has multiple subscriptions.
                 */
                if (this.$route.params.showSubscriptionSelection && this.hasMultipleSubscriptions) {
                    /*
                      Wait for a while since there may be navigation going on, and all
                      navigation closes the right sidebar. Thus, navigation must have
                      completed before this call, otherwise the dialog will just be
                      closed without the user having time to see it.
                     */
                    setTimeout(() => this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "choose-subscription:sign-in"), 500);
                }
            }
        }
    }
