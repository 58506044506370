
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {
    MUTATION_POPUP_MESSAGE_STATUS,
    MUTATION_SIDEBAR_RIGHT_STATUS
} from "@/store/store";
import {KEEP_SIDEBAR_OPEN_QUERY_NAME} from "@/router"
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {Route} from "vue-router";
import Utils from "@/mixins/utils";
import {Dictionary, Location} from "vue-router/types/router";
import {EntityViewRef, MUTATION_FORCE_SEARCH} from "@/store/store-search";
import Routing from "@/mixins/routing";
import {ClickEventData} from "@/views/SearchPage.vue";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import SliderButton from "@/components/SliderButton.vue";
import Tooltip from "@/components/Tooltip.vue";

/**
 * This component represents the header of the page, where the search field is
 * located.
 */
@Component({
    components: {Tooltip, SliderButton}
})
export default class PageHeader extends Mixins(StateHelper, SvgConstants, Utils) {
    searchString: string = "";

    /**
     * Set to true when we have detail views open, in order to show the "träfflista"
     * button in the mobile version.
     */
    showObjects: boolean = false;


    mounted() {
        if (!Utils.isMobile()) {
            this.setFocusOnSearchInputField(true);
        }
        this.$root.$on('sidebar-open-entity-view', this.handleSidebarOpenEntityView);
    }

    /**
     * True if the search input field should be visible.
     */
    get showSearchInput(): boolean {
        return this.signedIn && this.hasActiveSubscription;
    }

    /**
     * Gets the initial of the first name of the active user.
     */
    get activeUserInitial(): string {
        return this.activeUser && this.activeUser.firstName ? this.activeUser.firstName.substr(0, 1) : "-";
    }

    /**
     * Shows the right sidebar with the menu.
     */
    toggleSidebarRightMenu(): void {
        let isOnMenu = this.sidebarRightStatus === "menu";
        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, this.sidebarRightStatus && isOnMenu ? "" : "menu");
    }

    /**
     * Searches for the current search string, by navigating to the proper search url.
     */
    performSearch(event: KeyboardEvent | PointerEvent): void {
        /*
          Unset focus in the search input field since we need the number keys
          for opening search results, and the focus will be restored as soon as
          the user starts typing any letters.
         */
        this.setFocusOnSearchInputField(false);

        /*
          Since Vue router does not treat navigation to the current url as a real
          navigation, we have to force a new search for the same search string using
          the store instead. Thus, tell the store that we want a new search for the
          same search string. If we're currently showing any details, Vue will treat
          the difference in the query as a real navigation, so we don't want to force
          a new search in that case, since that will be taken care of by the normal
          mechanisms. The same goes for if we're currently using a filter, in which
          case the parameter 'typ' accounts for the difference in url.
         */
        if (this.searchString === this.$route.query.vad && !this.$route.query.detaljer && !this.$route.query.typ) {
            this.$store.commit("search/" + MUTATION_FORCE_SEARCH);
        } else {
            let query = {
                vad: this.searchString,
                filter: this.$route.query.filter,
            };
            let params: Location["params"] = {};
            // Keep current detail parameters and preserve entities if holding down a modifier key
            if (Utils.modifierKeyPressed(event) || this.permanentExpertMode) {
                Routing.preserveEntities(params, query, this.details);
            }
            this.$router.push({
                name: "search",
                query,
                params
            })
        }
    }

    handleSidebarOpenEntityView(event: ClickEventData<OpenEntityRequest>) {
        const data: OpenEntityRequest = event.data;
        let viewRef: EntityViewRef = new EntityViewRef(data.id, -1, String(data.linkId), data.addressLocationFilter);
        let keepSidebarOpen: string = "true";
        /* 
         We don't want it to stay open if we can only see one
         entity at a time or if we are on mobile.
        */
        if (Utils.isMobile() || window.innerWidth < 1020) {
            keepSidebarOpen = "false";
        }
        let query: Dictionary<string | string[]> = {
            vad: data.id,
            filter: "",
            detaljer: Utils.encode(viewRef),
            [KEEP_SIDEBAR_OPEN_QUERY_NAME]: keepSidebarOpen,
        };

        let params: Dictionary<string> = {};
        params[this.createPublicIdAndLevelKey(data.id, 0)] = "info";
        this.$router.push({
            name: "search",
            query: query,
            params: params
        });
    }

    /**
     * Called when the user clicks on the logo text. Necessary since the header logo
     * can be visible from the full screen page and thus, we make sure we exit the
     * full screen mode before navigating to the home page. We also set the focus to
     * the search input field explicitly here, since the PageHeader component is not
     * reloaded when the url is the same.
     */
    handleLogoClick(): void {
        if (!Utils.isMobile()) {
            this.setFocusOnSearchInputField(true);
        }
        this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "");
        this.handleHomeClick();
    }

    /**
     * Navigates back to the search result list.
     */
    backToSearchResult(): void {
        let what: string = this.getSingleString(this.$router.currentRoute.query["vad"]);
        let query: Dictionary<string | string[]> = {vad: what};
        this.$router.push({
            path: "sok",
            query: query
        })
    }


    /**
     * Make sure we keep the search string in the search field updated on page
     * reloads etc.
     *
     * @param to The new url.
     */
    @Watch("$route", {immediate: true, deep: true})
    onUrlChange(to: Route) {
        let what: string = this.getSingleString(to.query["vad"]);

        // If we have detail ids, we have open detail views.
        this.showObjects = !!this.getSingleString(to.query["detaljer"]);

        let publicId: number = Number(what);
        let isPublicId = !(isNaN(publicId) || publicId < 9999999999999);
        if (isPublicId || this.isEncodedId(what)) {
            this.searchString = "";
        } else {
            this.searchString = what;
        }
    }

    /**
     * Sets the value of the search input field to the latest stored value, if
     * there is one.
     */
    restoreStoredValue(): void {
        if (this.$store.state.lastSearchString) {
            this.searchString = this.$store.state.lastSearchString;
        }
    }

    /**
     * Gets the banner message as an array of strings where each entry
     * represents a single line. We use this somewhat tedious way to render the
     * message in order to avoid v-html, since the contents of the message come
     * from the database (even though we control that content - better safe than
     * sorry...).
     */
    get bannerMessageParts(): string[] {
        return this.bannerMessage ? this.bannerMessage.split("\\n") : [];
    }

    /**
     * Detects if we should add an extra class to the 'header_search_w1' element
     * in order to move it down below the banner message. If there is no banner
     * message displayed, we don't have to do anything, but otherwise we add a
     * class depending on how many rows the banner message has. A maximum of
     * three lines is supported. Longer messages will be rendered behind the
     * search input field on the mobile start page.
     */
    get additionalSearchInputClass(): any {
        if (this.bannerMessage) {
            let numRows: number = this.bannerMessageParts.length;
            if (numRows === 1) {
                return "one_line_banner";
            } else if (numRows === 2) {
                return "two_line_banner";
            } else {
                return "three_line_banner";
            }
        } else {
            return "";
        }
    }
    
    get isMobileDevice(): boolean {
        return Utils.isMobile();
    }
}
