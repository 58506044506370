
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import BankIdAuthentication from "@/components/BankIdAuthentication.vue";
import AgreeToSafenodeTerms from "@/components/AgreeToSafenodeTerms.vue";
import StateHelper from "@/mixins/state-helper";
import FaqItem from "@/components/FaqItem.vue";
import {MUTATION_POPUP_MESSAGE_STATUS} from "@/store/store";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Utils from "@/mixins/utils";
import Auth from "@/mixins/auth";
import {SearchResultEntryCompanyDetails} from "@/models/search-result-entry-company-details";
import {SearchResultEntryIndividualDetails} from "@/models/search-result-entry-individual-details";
import {SimpleIndividual} from "@/models/simple-individual";
import SvgConstants from "@/mixins/svg-constants";
import {Global} from "@/global";
import {
    GIVEN_NAME_FILTER,
} from "@/services/filters";
import {
  ACTION_DELETE_QUESTIONER,
  ACTION_FETCH_QUESTIONERS,
  ACTION_VALIDATE_QUESTIONER,
  MUTATION_SHOW_QUESTIONER_IN_TERMS
} from "@/store/store-questioner";
import {SafenodeQuestionerParameters} from "@/models/safenode-questioner-parameters";
import {DisplayableDocument} from "@/models/displayable-document";
import {HTTP} from "@/services/http-provider";
import SearchPageEntityDocumentList
  from "@/components/SearchPageEntityDocumentList.vue"
import ProgressBar from "@/components/ProgressBar.vue";

    /**
     * This component represents the credit report tab in a search result details view.
     */
    @Component({
        components: {
            ProgressBar,
            SearchPageEntityDocumentList, ErrorMessage, FaqItem, BankIdAuthentication, AgreeToSafenodeTerms}
    })
    export default class SearchPageEntityCommonCredit extends Mixins(Auth, StateHelper, SvgConstants, Utils) {
        @Prop()
        individual: SearchResultEntryIndividualDetails;

        @Prop()
        company: SearchResultEntryCompanyDetails;

        @Prop()
        reports: Array<DisplayableDocument>;

        @Prop()
        isVisible: boolean;

        loading: boolean = false;

        showProgressBar = false;

        errorMessage: string = "";

        termsAccepted: boolean = false;

        readonly creditSupplierText: string = "Kreditupplysningen tillhandahålls av Safenode AB.";

        // Following values are for the new questioner form.
        newQuestionerStep: string = "inactive";

        newQuestionerPnrOrgNo: string = "";

        newQuestioner: SafenodeQuestionerParameters = {} as SafenodeQuestionerParameters;

        newQuestionerError: string = "";

        // For some reason we can't use a convenience getter for this..
        activeQuestioner: string = this.$store.state.questioner.selected;

        removingQuestioner: string = "";

        newDocumentId: string = "";
        
        startBankIDFunction: Function;

        /**
         * Convenience getter for the id of the entity.
         */
        get id(): string {
            return this.individual ? this.individual.id : this.company.id;
        }

        /**
         * Convenience getter for checking if the entity is an ab
         */
        get entityIsAb(): boolean {
            return !!this.company && this.company.aktiebolag;
        }

        /**
         * Creates a new credit report for our entity.
         * We require active questioner for non-aktiebolag
         */
        createReport(): void {
            if (!this.entityIsAb && !this.activeQuestioner) {
                return;
            }

            this.errorMessage = "";
            let timeout = setTimeout(() => {
                this.showProgressBar = true;
                this.loading = true;
            }, 300);


            let url: string;
            let params: string[] = [];

            if (this.signedInAs(this.id)) {
                url = "/sapi/documents/order/credit-report-self/"
            } else {
                url = "/sapi/documents/order/credit-report/" + this.id;
                if (!this.entityIsAb) {
                    params.push("questionerOrgNo=" + this.activeQuestioner);
                }
            }
            if (this.activeSubscriptionRefNo) {
                params.push("subscription=" + this.activeSubscriptionRefNo);
            }
            url = url + "?" + params.join("&");

            HTTP.post<DisplayableDocument>(url).then((response: DisplayableDocument) => {
                if (this.loading) {
                    setTimeout(() => {
                        this.$emit("new-credit-report", response);
                        this.showProgressBar = false;
                    }, 500);
                } else {
                    this.$emit("new-credit-report", response);
                    this.showProgressBar = false;
                }
            }).catch((error) => {
                this.errorMessage = this.extractErrorMessage(error)
                this.showProgressBar = false;
            }).finally(() => {
                clearTimeout(timeout);
                this.loading = false;
            });
        }

        /**
         * Shows the popup dialog with "reasons" for creating a credit report.
         */
        showPopupReason(): void {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "reason");
        }

        // noinspection JSMethodCanBeStatic
        /**
         * Gets the static url for example reports.
         */
        getExampleReportUrl(): string {
            let examplePart = "example";
            /*
              Sole proprietorship (enskild firma) results in a report on the individual
              so we show the individual example pdf for them.
             */
            if (this.company && !this.company.soleProprietorship) {
                examplePart = examplePart + "-company";
            }
            return Global.url("/api/documents/credit/" + examplePart + "/Exempel - faktakontroll.se");
        }

        /**
         * Shows a popup dialog with Safenode's terms.
         */
        showSafenodeTerms(): void {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "safenode-terms");
        }

        /**
         * Shows a popup dialog with Safenode's terms for a questioner.
         */
        showSafenodeQuestionerTerms(): void {
            if (this.newQuestioner && this.newQuestioner.orgNo) {
                this.$store.commit("questioner/" + MUTATION_SHOW_QUESTIONER_IN_TERMS, this.newQuestioner);
                this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "safenode-terms-questioner");
            }
        }

        /**
         * Toggles showing the form for new questioner.
         */
        toggleNewQuestioner(): void {
            if (this.newQuestionerStep === "inactive") {
                this.newQuestionerPnrOrgNo = "";
                this.newQuestionerStep = "form";
            } else {
                this.newQuestionerStep = "inactive";
                this.newQuestioner = {} as SafenodeQuestionerParameters;
                this.$store.commit("questioner/" + MUTATION_SHOW_QUESTIONER_IN_TERMS, undefined);
            }
        }

        /**
         * Sets the step to show the sign form when adding a new questioner
         * if said questioner actually validates.
         */
        validateAndMaybeStartProcessForQuestioner(): void {
            this.$store.dispatch(ACTION_VALIDATE_QUESTIONER, this.newQuestionerPnrOrgNo)
                .then(response => {
                    this.newQuestioner = response;
                    this.newQuestionerStep = "agreeToTerms";
                })
                .catch(error => this.newQuestionerError = this.extractErrorMessage(error))
        }

        onNewQuestionerProcessEnded() {
            this.toggleNewQuestioner();
        }

        /**
         * Make sure we update the selected questioner whenever it changes in the
         * global state.
         *
         * @param val The new selected questioner.
         * @param oldVal The old selected questioner.
         */
        @Watch("$store.state.questioner.selected")
        onSelectedQuestionerChange(val: string, oldVal: string): void {
            this.activeQuestioner = val;
        }

        resetQuestionerError() {
            this.newQuestionerError = "";
        }

        deleteQuestioner(questioner: SafenodeQuestionerParameters) {
            this.$store.dispatch(ACTION_DELETE_QUESTIONER, questioner)
                .catch(() => {})
                .finally(() => {
                  this.ignoreError(this.$store.dispatch(ACTION_FETCH_QUESTIONERS));
                  this.removingQuestioner = "";
                });
        }

        deleteCreditReport(creditReportId: number): void {
          this.$emit("delete-document", creditReportId);
        }
        
        getIndividualDisplayName(i: SimpleIndividual): string {
            return GIVEN_NAME_FILTER(i.firstNames, i.givenNameCode, "extract-with-fallback") + " " + this.getMiddleAndLastNames(i);
        }
        
        getSoleProprietorshipDisplayName(): string {
            let member = this.company.boardMembers.find(b => b.isPrincipal);
            return this.getDisplayNameForBoardMember(member);
        } 
        
        get requiresBankIdAuth(): boolean {
            return !!this.individual || (!!this.company && this.company.soleProprietorship) || (!!this.company && this.company.form === "31");
        }

        get showOrderButton(): boolean {
            // Must be signed in
            if (!this.signedIn) {
                return false;
            }
            // Signed in as self -> show
            if (this.signedInAs(this.id)) {
                return true;
            }

            // Must be authenticated with bankId and the entity requires it
            if (!this.authWithBankId && this.requiresBankIdAuth) {
                return false;
            }

            // Entity is ab and terms are signed -> show
            if (this.entityIsAb && this.signedTermsForActiveSubscription) {
                return true;
            }

            // Entity is not ab and a questioner is selected -> show
            if (!this.entityIsAb && !!this.activeQuestioner) {
                return true;
            }

            // Don't show
            return false;
        }

        get showPreviousReports(): boolean {
            // Do not show the previous reports if terms are not signed for the active subscription.
            if (this.entityIsAb && !this.signedTermsForActiveSubscription) {
                return false;
            }
            return true;
        }
    }
