
import {Component, Prop, Vue} from 'vue-property-decorator';

/**
 * This component represents a row in a multiline table, just to avoid some
 * boilerplate code.
 */
@Component
export default class AdditionalInfoRow extends Vue {
    /**
     * The header.
     */
    @Prop()
    text: string;
}
