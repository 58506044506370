
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {
    PaymentParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import SidebarRightPaymentListEntry
    from "@/components/SidebarRightPaymentListEntry.vue";
import {HTTP} from "@/services/http-provider";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {ACTION_SET_PAYMENTS} from "@/store/store-payments";

/**
 * This component represents the list of payments in the right sidebar.
 */
@Component({
    components: {
        ErrorMessage,
        SidebarRightPaymentListEntry,
        SidebarRightCloseButton
    },
})
export default class SidebarRightPaymentList extends Mixins(StateHelper, Utils) {
    errorMessage: string = "";

    mounted() {
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success && this.payments.length === 0) {
                this.updatePayments(this.activeSubscription, null);
            }
        });
    }

    get payments() {
        return this.$store.state.payments.paymentList;
    }

    /**
     * Make sure we update the payment list whenever the active subscription
     * changes.
     *
     * @param val The new active subscription. May be undefined.
     * @param oldVal The old active subscription. May be undefined.
     */
    @Watch("$store.state.activeSubscription")
    onActiveSubscriptionChange(val: SubscriptionParameters, oldVal: SubscriptionParameters) {
        this.updatePayments(val, oldVal);
    }

    updatePayments(val: SubscriptionParameters, oldVal: SubscriptionParameters): void {
        if (!val || !val.refNo || !val.owner) {
            this.$store.dispatch(ACTION_SET_PAYMENTS, []);
        } else if (this.differentRefNo(val, oldVal)) {
            this.errorMessage = "";
            HTTP.get<PaymentParameters[]>("/sapi/payment/list/" + val.refNo + "/payments").then((payments: PaymentParameters[]) => {
                this.$store.dispatch(ACTION_SET_PAYMENTS, payments);
            }).catch(response => {
                // Empty the list of payments for good measure
                this.$store.dispatch(ACTION_SET_PAYMENTS, []);
                this.errorMessage = this.extractErrorMessage(response);
            })
        }
    }
}
