import {DisplayableEntity} from "@/models/displayable-entity";
import {MonitoredEntity} from "@/models/monitored-entity";
import {MonitorList} from "@/models/monitor-list";
import {UserMonitors} from "@/models/user-monitors";
import {ActionTree, Module} from "vuex";
import {HTTP} from "@/services/http-provider";
import {resetState, RootState} from "@/store/store";
import {UTILS} from "@/mixins/utils";
import {Global} from "@/global";
import {CreateMonitorResponse} from "@/models/create-monitor-response";

export const MUTATION_CREATE_MONITOR = "createMonitor";
export const MUTATION_MONITORS = "monitors";
export const MUTATION_MANUAL_MONITORS = "manualMonitors";
export const MUTATION_MONITOR_LISTS = "monitorLists";
export const MUTATION_ALTER_MONITOR_LIST = "alterMonitorList";
export const MUTATION_UPDATE_MONITOR_LIST_PRIORITY = "updateMonitorListPriority"; 
export const MUTATATION_SET_LOADING = "setLoading";
export const MUTATATION_UPDATE_SELF_AUTO_MONITOR_ENABLED = "updateSelfAutoMonitorEnabled";

/**
 * The string before the / is the namespace (as set in the root store) and the
 * names after the / must match the names of the functions in the ActionTree.
 */
export const ACTION_CREATE_MONITOR = "monitor/createMonitor";
export const ACTION_DELETE_MONITOR = "monitor/deleteMonitor";
export const ACTION_FETCH_MONITORS = "monitor/fetchMonitors";
export const ACTION_IMPORT_MONITOR_LIST = "monitor/importList";
export const ACTION_DELETE_MONITOR_LIST = "monitor/deleteList";
export const ACTION_DELETE_MONITOR_LIST_ENTRY = "monitor/deleteListEntry";
export const ACTION_PRIORITY_MONITOR_LIST = "monitor/updateListPriority";
export const ACTION_UPDATE_SELF_AUTO_MONITOR_ENABLED = "monitor/updateSelfAutoMonitorEnabled";

export interface MonitorState {
    monitors: UserMonitors;
    loading: boolean;
}

export class MonitorRequest {
    subscriptionRefNo: string

    publicId: string;

    priority: string;

    excludeFromAuto: boolean;
}

export const actions: ActionTree<MonitorState, RootState> = {
    /**
     * Fetches the list of monitors for the currently signed in user from the
     * server.
     *
     * @param context The context.
     */
    fetchMonitors(context): Promise<UserMonitors> {
        context.commit(MUTATATION_SET_LOADING,true);
        return new Promise((resolve, reject) => {
            if (context.rootGetters.signedIn && context.rootState.activeSubscription) {
                HTTP.post<UserMonitors>("/sapi/monitor/list/" + context.rootState.activeSubscription.refNo).then((response: UserMonitors) => {
                    context.commit(MUTATION_MONITORS, response);
                    context.commit(MUTATATION_SET_LOADING,false);
                    
                    resolve(response)
                }).catch((error) => {
                    context.commit(MUTATION_MONITORS, new UserMonitors([], [], [], [], []));
                    reject(error);
                });
            } else {
                let empty = new UserMonitors([], [], [], [], []);
                context.commit(MUTATION_MONITORS, empty);
                resolve(empty);
            }
        })
    },

    /**
     * Creates the given monitor on the server and adds it as the first element
     * in our monitor list. The full list of manual monitors is not retrieved
     * from the server.
     *
     * @param context The context.
     * @param monitor The monitor entry to add.
     */
    createMonitor(context, monitor: MonitorRequest): Promise<CreateMonitorResponse> {
        monitor.subscriptionRefNo=context.rootState.activeSubscription.refNo;
        return new Promise((resolve, reject) => {
            HTTP.post<CreateMonitorResponse>("/sapi/monitor/create",monitor).then((createdMonitors) => {
                createdMonitors.createdEntities.forEach(m => context.commit(MUTATION_CREATE_MONITOR, m));
                resolve(createdMonitors);
            }).catch((error) => {
                reject(error);
            });
        })
    },

    /**
     * Deletes the monitor with the given public id. The monitor list will be
     * updated to the state after the deletion.
     *
     * @param context The context.
     * @param publicId The public id of the monitor to delete.
     */
    deleteMonitor(context, publicId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            HTTP.post<DisplayableEntity[]>("/sapi/monitor/delete/" + context.rootState.activeSubscription.refNo + "/" + publicId).then((manualMonitors: DisplayableEntity[]) => {
                context.commit(MUTATION_MANUAL_MONITORS, manualMonitors);
                resolve();
            }).catch((error) => {
                reject(error);
            });
        })
    },

    /**
     * Imports a list of monitors from a file. The created monitor list is then
     * retrieved from the server.
     */
    importList(context, list: {priority: string, listFile: File}): Promise<void> {
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            formData.append("listFile", list.listFile);
            formData.append("priority", list.priority);
            HTTP.post<MonitorList>("/sapi/monitor/add-list/" + context.rootState.activeSubscription.refNo, formData).then((importedList: MonitorList) => {
                context.commit(MUTATION_ALTER_MONITOR_LIST, importedList);
                resolve();
            }).catch((error) => {
                reject(error);
            })
        });
    },
    
    /**
     * Deletes a list of monitors with a given id, then retrieves the list of
     * monitor lists from the server.
     */
    deleteList(context, listId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            HTTP.post<MonitorList[]>("/sapi/monitor/delete-list/" + context.rootState.activeSubscription.refNo + "/" + listId)
            .then((monitorLists: MonitorList[]) => {
                context.commit(MUTATION_MONITOR_LISTS, monitorLists);
                resolve();
            }).catch((error) => {
                reject(error);
            })
        });
    },

    /**
     * Deletes an entry with a given publicId from a list of monitors, then
     * retrieves the list of monitor lists from the server.
     */
    deleteListEntry(context, entry: {listId: number, publicId: string}): Promise<void> {
        return new Promise((resolve, reject) => {
            HTTP.post<MonitorList>("/sapi/monitor/delete-list-entry/" + context.rootState.activeSubscription.refNo + "/" + entry.listId + "/" + entry.publicId)
            .then((changedList: MonitorList) => {
                context.commit(MUTATION_ALTER_MONITOR_LIST, changedList);
                resolve();
            }).catch((error) => {
                reject(error);
            })
        });
    },
    
    updateListPriority(context, list: {listId: number, priority: string}): Promise<void> {
        return new Promise((resolve, reject) => {
            HTTP.post<MonitorList>("/sapi/monitor/update-list-priority/" + context.rootState.activeSubscription.refNo + "/" + list.listId + "?priority=" + list.priority)
            .then(() => {
                context.commit(MUTATION_UPDATE_MONITOR_LIST_PRIORITY, list);
                resolve();
            }).catch((error) => {
                reject(error);
            })
        });
    },
    
    updateSelfAutoMonitorEnabled(context, enabled: boolean): Promise<boolean> {
        return new Promise((resolve, reject) => {
            HTTP.post<boolean>("/sapi/monitor/set-self-auto-monitor-enabled/" + context.rootState.activeSubscription.refNo + "/" + enabled)
            .then((newValue: boolean) => {
                context.commit(MUTATATION_UPDATE_SELF_AUTO_MONITOR_ENABLED, newValue);
                resolve(newValue);
            }).catch((error) => {
                reject(error);
            })
        });
    }
};

/**
 * Returns the initial state of this store.
 */
function initialState(): MonitorState {
    return {
        monitors: new UserMonitors([], [], [], [], []),
        loading : false,
    };
}

/**
 * This module handles the monitor list as well as the actions that are
 * possible to perform upon it.
 */
export const monitorModule: Module<MonitorState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        /**
         * Adds the given monitor at the correct place in our list of manual
         * monitors.
         *
         * @param state The state.
         * @param monitor The monitor to add.
         */
        [MUTATION_CREATE_MONITOR]: (state: MonitorState, monitor: MonitoredEntity) => {
            let oldIndex = state.monitors.manual.map(m => m.id).indexOf(monitor.id);
            if(oldIndex !== -1) {
                state.monitors.manual.splice(oldIndex, 1, monitor);
                return;
            }
            let getName = (m: MonitoredEntity) => UTILS.formatDisplayableEntity(m);
            let name = getName(monitor);
            let i;
            for (i = 0; i < state.monitors.manual.length; ++i) {
                let c = name.localeCompare(getName(state.monitors.manual[i]), "sv-SE");
                if (c < 0) {
                    break;
                }
            }
            state.monitors.manual.splice(i, 0, monitor);
        },

        /**
         * Sets the complete list of monitors.
         *
         * @param state The state.
         * @param monitors The new set of monitors.
         */
        [MUTATION_MONITORS]: (state: MonitorState, monitors: UserMonitors) => state.monitors = monitors,
        
        /**
         * Set only the manual monitors.
         * 
         * @param state The state.
         * @param manualMonitors The new list of manual monitors.
         */
        [MUTATION_MANUAL_MONITORS]: (state: MonitorState, manualMonitors: MonitoredEntity[]) => { 
            state.monitors.manual = manualMonitors
        },
        
        /**
         * Set only the monitor lists.
         * 
         * @param state The state.
         * @param manualMonitors The new list of monitor lists.
         */
        [MUTATION_MONITOR_LISTS]: (state: MonitorState, monitorLists: MonitorList[]) => state.monitors.lists = monitorLists,
        
        /**
         * Updates the monitor list with matching id, or simply adds the list
         * to the front of the list of monitor lists, if it is new.
         * 
         * @param state The state.
         * @param list  The monitor list to update or add.
         */
        [MUTATION_ALTER_MONITOR_LIST]: (state: MonitorState, list: MonitorList) => {
            // Note: findIndex does not seem to have support in IE?
            let index = state.monitors.lists.findIndex(l => l.id === list.id);
            if (index === -1) {
                state.monitors.lists.unshift(list);
            } else {
                state.monitors.lists.splice(index, 1, list);
            }
        },
        
        [MUTATION_UPDATE_MONITOR_LIST_PRIORITY]: (state: MonitorState, list: {listId: number, priority: string}) => {
            let index = state.monitors.lists.map(l => l.id).indexOf(list.listId);
            if(index !== -1) {
                state.monitors.lists[index].priority = list.priority;
            }
        },
        [MUTATATION_SET_LOADING]: (state: MonitorState, value: boolean) => state.loading = value,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: MonitorState) => resetState(state, initialState()),
        
        /**
         * Updates the state when enabling/disabling self auto monitors.
         */
        [MUTATATION_UPDATE_SELF_AUTO_MONITOR_ENABLED]: (state: MonitorState, newValue: boolean) => {
            if(!newValue) {
                state.monitors.selfAutomatic=[];
            }
        }
    },
    actions: actions
};

