
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {SearchResultVehicleOwner} from "@/models/search-result-vehicle-owner";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";
import AdditionalInfoRow from "@/components/AdditionalInfoRow.vue";

/**
 * This component represents a real property owner.
 */
@Component({
    components: {
        AdditionalInfoRow,
        StatusIconRow,
        SearchPageEntityInfoHeader,
        SearchPageEntityLink,
        SelectOnClick
    }
})
export default class SearchPageEntityVehicleOwner extends Mixins(StateHelper, Utils) {
    @Prop()
    owner: SearchResultVehicleOwner;

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;
}
