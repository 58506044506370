/**
 * See documentation for the corresponding java class.
 */
export class EndUserSubscriptionParameters {
    subscriptionParameters: SubscriptionParameters = new SubscriptionParameters();

    endUserParameters: EndUserParameters = new EndUserParameters();

    /**
     * Store the invite link id here when confirming an invite.
     */
    inviteLinkId: string;
}

/**
 * See documentation for the corresponding java class.
 */
export class SubscriptionParameters {
    refNo: string;

    allowEditCompanyDetails: boolean;

    organisationNumber: string = "";

    companyName: string = "";

    department: string = "";

    paymentDeliveryMethod: string = "EMAIL";

    paymentEmail: string = "";

    paymentReference: string = "";

    address1: string = "";

    address2: string = "";

    address3: string = "";

    zip: string = "";

    city: string = "";

    endDate?: string;

    termsSigned: boolean = false;

    owner: boolean = false;

    availableCreditReports: boolean = false;

    selfServiceReachedLimit: boolean = false;

    numAllowedUsers: number = 0;

    numCreditReports?: number;

    numMonitors?: number;

    demo?: boolean;

    permissions: PermissionWrapper;

    skipPnrRequirement: boolean;

    hasManager: boolean;

    description: string;

    hasLegalDocumentSubscription: boolean;

    allowAnonymousUsers: boolean;
}

/**
 * See documentation for the corresponding java class.
 */
export class EndUserParameters {
    refNo: string;

    publicId: string;

    personalNumber: string = "";

    firstName: string = "";

    lastName: string = "";

    phoneNumber: string = "";

    email: string = "";

    password: string = "";

    numAllowedMonitors: number = 0;

    selfAutoMonitorEnabled: boolean | null = false;

    defaultSelfAutoMonitorEnabled: boolean = false;

    autoMonitorAllowed: boolean = false;

    ssoManaged: boolean = false;
}

export function isSelfAutoMonitorEnabled(parameters: EndUserParameters): boolean {
    // Don't make it member of EndUserParameters class because when receiving an EndUserParameters json object from the
    // back end it won't be instanced as a class.
    if (parameters.selfAutoMonitorEnabled !== null) {
        return parameters.selfAutoMonitorEnabled;
    } else {
        return parameters.defaultSelfAutoMonitorEnabled;
    }
}

/**
 * See documentation for the corresponding java class.
 */
export class ListUserParameters {
    id: string = "";

    status: string = "";

    name: string = "";

    email: string = "";

    role: string = "";

    subscriptionRefNo: string = "";

    /**
     * This field is only relevant in the gui. Set it to true to highlight a freerider in
     * the list.
     */
    highlight?: boolean;
}

/**
 * See documentation for the corresponding java class.
 */
export class ContactUsParameters {
    name: string = "";

    phoneNumber: string = "";

    email: string = "";

    preferredContactMethod: string = "PHONE";

    message: string = "";

    reason: string = "GENERAL";
}

/**
 * See documentation for the corresponding java class.
 */
export interface PaymentParameters {
    id: string;

    companyName: string;

    subscriptionPackage: string;

    status: string;

    dueDate: Date;

    ocrNo: string;
}

/**
 * See documentation for the corresponding java class.
 */
export class PermissionWrapper {
    viewIncome: boolean;

    viewRemark: boolean;

    creditReport: boolean;

    monitor: boolean;

    monitorLists: boolean;

    viewHousingRegister: boolean;

    viewCompanyGroup: boolean;

    viewAuxiliaryCompanyName: boolean;

    viewLeiCode: boolean;

    viewChildren: boolean;

    viewFamilyRelations: boolean;

    pep: boolean;

    autoMonitor: boolean;

    fetchAnnualReportAb: boolean = false;

    fetchAnnualReportNonAb: boolean = false;

    fetchCertificateOfRegistration: boolean = false;

    fetchCertificateOfRegistrationEnglish: boolean = false;

    fetchArticlesOfAssociation: boolean = false;

    fetchCaseList: boolean = false;

    fetchCaseInfo: boolean = false;

    fetchProtocol: boolean = false;

    fetchStatutes: boolean = false;

    fetchFinancialPlan: boolean = false;

    fetchCompanyReport: boolean = false;

    fetchCompanyMortgageReport: boolean = false;

    fetchCompanyVehicleReport: boolean = false;

    fetchCompanyCapitalReport: boolean = false;

    addressLocation: boolean = false;

	realProperty: boolean = false;

    fetchRealPropertyReport: boolean = false;

    fetchRealPropertyTaxationReport: boolean = false;

	vehicle: boolean = false;

	individualVehicleOwner: boolean = false;

	companyVehicleOwner: boolean = false;

    viewRealPropertyPurchasePrice: boolean = false;

    fetchIndividualReport: boolean = false;

    fetchIndividualCommitmentReport: boolean = false;

    fetchTitleDeedAndHistoricalOwnerReport: boolean = false;

    boardHistory: boolean = false;

    viewOutdatedPersons: boolean = false;

    viewOutdatedCompanies: boolean = false;

    legalDocuments: boolean = false;

    payrollTax: boolean = false;

    sanctionIndividual: boolean = false;

    sanctionCompany: boolean = false;

    documentsForSecretIndividuals: boolean = false;
}
