
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {AnnualReportEntry} from "@/models/annual-report-entry";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {PERIOD_FILTER} from "@/services/filters";
import Auth from "@/mixins/auth";
import {SearchResultEntryCompanyDetails} from "@/models/search-result-entry-company-details";
import {Global} from "@/global";
import PreflightLink from "@/components/PreflightLink.vue";

/**
     * This component represents the list of annual reports in the documents tab.
     */
    @Component({
        components: {PreflightLink, ErrorMessage}
    })
    export default class SearchPageEntityCompanyDocumentsAnnualReportList extends Mixins(Auth, StateHelper, SvgConstants) {
        @Prop()
        company: SearchResultEntryCompanyDetails;

        @Prop()
        annualReports: AnnualReportEntry[];

        @Prop()
        noReports: boolean;


        /**
         * Formats the text for the annual report.
         *
         * @param annualReport The report.
         */
        formatAnnualReport(annualReport: AnnualReportEntry): string {
            return `<span>Årsredovisning ${PERIOD_FILTER(annualReport.periodEnd)}</span>`;
        }

        /**
         * Gets the url for opening the report in a new tab.
         *
         * @param annualReport The report.
         */
        openUrl(annualReport: AnnualReportEntry): string {
            let period: string = PERIOD_FILTER(annualReport.periodEnd);
            let path: string = "/sapi/annual-report/open/" + annualReport.refNo + "?orgNo=" + this.company.orgNo + "&period=" + period;
            if (this.activeSubscriptionRefNo) {
                path = path + "&subscription=" + this.activeSubscriptionRefNo;
            }
            if (annualReport.sourceBv) {
                path = path + "&bv=true";
            }
            return Global.url(path);
        }
    }
