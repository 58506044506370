
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {SearchResultCommitment} from "@/models/search-result-commitment";
import SvgConstants from "@/mixins/svg-constants";
import Utils, {UTILS} from "@/mixins/utils";
import FNum from "@/components/FNum.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";
import {COMPANY_FORM_ABBREV_FILTER} from "@/services/filters";
import FMillions from "@/components/FMillions.vue";

/**
 * This component represents a list of board commitments.
 */
@Component({
    methods: {COMPANY_FORM_ABBREV_FILTER},
    components: {
        FMillions,
        StatusIconRow,
        FNum,
        SearchPageEntityLink
    }
})
export default class SearchPageEntityCommitmentList extends Mixins(StateHelper, SvgConstants, Utils) {
    /**
     * The list of commitments.
     */
    @Prop()
    commitments: SearchResultCommitment[];

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;

    numToShow: number = UTILS.CHUNK_SIZE;


    /**
     * Gets the entries currently visible in the list. For large lists, we
     * don't show all entries since that may consume lots of resources in the
     * browser.
     */
    get visibleEntries(): SearchResultCommitment[] {
        return this.commitments.slice(0, this.numToShow);
    }

    /**
     * Concatenates the function texts with <br> as separator.
     */
    formatFunctionTexts(commitment: SearchResultCommitment): string {
        return commitment.functionTexts.join("<br>");
    }
}
