import Vue from 'vue'
import Vuex, {ActionContext, ActionTree, StoreOptions} from 'vuex'
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import {
    AuthRequest,
    BankIDAuthRequest,
    BankIDCollectRequest
} from "@/models/auth-request.model";
import {HTTP} from "@/services/http-provider";
import {AuthInfoResponse} from "@/models/auth-info-response";
import {
    EndUserParameters,
    EndUserSubscriptionParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {BankIDCreateResponse} from "@/models/bank-id-create-response"
import {monitorModule} from "@/store/store-monitor";
import {searchModule} from "@/store/store-search";
import {StorageService} from "@/services/storage.service";
import {freeriderModule} from "@/store/store-freeriders";
import {notificationsModule} from "@/store/store-notification";
import {userWebAppSettingsModule} from "@/store/store-user-web-app-settings";
import {UserErrorMessage} from "@/models/error-message";
import {Global} from "@/global";
import {questionerModule} from "@/store/store-questioner";
import {paymentsModule} from "@/store/store-payments";
import {documentsModule} from "@/store/store-documents";
import {UTILS} from "@/mixins/utils";
import {
    OneTimeCodeConfirmRequest
} from "@/models/one-time-code-confirm-request";

Vue.use(Vuex);

export const MUTATION_APP_LOADED = "appLoaded";
export const MUTATION_SIGNED_OUT_IF_NECESSARY = "signedOutIfNecessary";
export const MUTATION_EXTRA_SETUP_DONE = "extraSetupDone";
export const MUTATION_RESET_ALL_STORES = "resetAllStores";
export const MUTATION_ACTIVE_SUBSCRIPTION = "activeSubscription";
export const MUTATION_ACTIVE_USER = "activeUser";
export const MUTATION_AUTH_INFO = "authenticationInfo";
export const MUTATION_POPUP_MESSAGE_STATUS = "popupMessageStatus";
export const MUTATION_POPUP_REGISTRATION_STATE = "popupRegistrationStatus";
export const MUTATION_SIDEBAR_RIGHT_STATUS = "sidebarRightStatus";
export const MUTATION_SUBSCRIPTION_LIST = "subscriptionList";
export const MUTATION_CTRL_PRESSED = "ctrlPressed";
export const MUTATION_META_PRESSED = "metaPressed";
export const MUTATION_ACTIVE_INLINE_MODAL = "activeInlineModal";
export const MUTATION_LAST_SEARCH_STRING = "lastSearchString";
export const MUTATION_BANNER_MESSAGE = "bannerMessage";
export const MUTATION_PREVIOUS_USER_HASH = "previousUserHash";
export const MUTATION_BANK_ID_QR = "bankIdQr";
export const MUTATION_BANK_ID_AUTO_START_TOKEN = "bankIdAutoStartToken";
export const MUTATION_BANK_ID_STATUS_MESSAGE = "bankIdStatusMessage";

export const ACTION_AUTHENTICATE_WITH_USERNAME_AND_PASSWORD = "authenticateWithUsernameAndPassword";
export const ACTION_CONFIRM_ONE_TIME_CODE = "confirmOneTimeCode";
export const ACTION_AUTHENTICATE_OR_SIGN_WITH_BANK_ID = "authenticateWithBankId";
export const ACTION_CANCEL_BANK_ID = "cancelBankId";
export const ACTION_CONFIRM_INVITE = "confirmInvite";
export const ACTION_CREATE_USER_FOR_SUBSCRIPTION = "createUserForSubscription";
export const ACTION_GET_ACTIVE_USER = "getActiveUser";
export const ACTION_REFRESH_AUTH_TOKENS = "refreshAuthTokens";
export const ACTION_SIGN_OUT = "signOut";
export const ACTION_SIGN_OUT_ALL = "signOutAll";
export const ACTION_DELETE_LOCAL_DATA_LOGOUT = "deleteLocalTokens";
export const ACTION_AGREE_TO_SAFENODE_TERMS = "agreeToSafenodeTerms";
export const ACTION_FETCH_BANNER_MESSAGE = "fetchBannerMessage";

/**
 * The default number of times to retry a http request where that may be
 * needed. Notice that this is explicitly specified in each call and not a
 * global default.
 */
const NUM_RETRIES: number = 3;


export class PopupRegistrationState {
    status: string;

    /**
     * When the user confirms an invite, the subscription the invite refers to
     * is fetched from the server. This happens in a different component than
     * the one that uses the subscription later on. Hence, we store the
     * subscription here in order to avoid having to fetch it twice.
     */
    inviteSubscription: SubscriptionParameters;


    constructor(status: string, inviteSubscription?: SubscriptionParameters) {
        this.status = status;
        this.inviteSubscription = inviteSubscription;
    }
}

/**
 * The global root state accessible to all components through the $store.state
 * object.
 */
export interface RootState {
    /**
     * Will be resolved as soon as we consider the application fully loaded.
     * Currently, this means that the active user and subscription must have
     * been fetched. Resolves to true for successful loading and false
     * otherwise.
     */
    appLoaded: Promise<boolean>;

    /**
     * When the app loads certain paths, we have to be signed out, for example
     * when confirming an email link. Since signing out requires a request to
     * the server in order for the cookies to be removed, we have to wait until
     * that request finishes. This promise resolves, either when that request
     * has finished, or directly, if we're on a path where signing out is not
     * necessary.
     */
    signedOutIfNecessary: Promise<void>;

    /**
     * Normally, it is the App component that is responsible for determining
     * when the application is loaded. However, since we now use cookies for
     * storing authentication tokens, we sometimes have to wait until the cookie
     * has been set from a call initiated from another component. For example,
     * when verifying an email link from the same browser, the cookie is set
     * from the call verifying the link, which is triggered from the
     * VerifyEmailPage component. Hence, we have to wait until that call has
     * been handled before the App component can finish loading the application.
     */
    extraSetupDone: Promise<void>;

    /**
     * An AuthInfoResponse from the most current authentication. May be
     * undefined when not signed in.
     *
     * Used to determine when we probably are signed in, i.e. we have a valid
     * access token set as a cookie. The access and refresh cookies are http
     * only so there is no way to check their validity form javascript. Instead
     * we set this value to undefined on 401 errors and similar events, and we
     * set it to contain auth information after successful authentication from
     * the server.
     */
    authorityInfo: AuthInfoResponse;

    /**
     * The currently active user. May be undefined if not signed in.
     */
    activeUser: EndUserParameters;

    /**
     * The currently active subscription. May be undefined if not signed in.
     */
    activeSubscription: SubscriptionParameters;

    /**
     * Controls if and what to show in the message popup.
     */
    popupMessageStatus: string;

    /**
     * Controls if and what to show in the registration popup.
     */
    popupRegistrationState: PopupRegistrationState;

    /**
     * The currently active user's list of available subscriptions. May be
     * empty if not signed in.
     */
    subscriptionList: SubscriptionParameters[];

    /**
     * Controls if and what to show in the right sidebar. See SidebarRightMenu
     * for more info.
     */
    sidebarRightStatus: string;

    /**
     * True as long as any ctrl key is pressed.
     */
    ctrlPressed: boolean;

    /**
     * True as long as any meta (cmd) key is pressed.
     */
    metaPressed: boolean;

    /**
     * If an "inline modal" is active, its id is stored here. An inline modal is
     * a dialog asking for user input, but that is displayed inline, such as the
     * company report ordering dialog and various confirmation dialogs for
     * deletions etc. Only one inline modal should be active at any given time.
     */
    activeInlineModal: string;

    /**
     * As soon as the user performs a search, the search string is stored here.
     * This makes it possible to restore the latest search string until the next
     * search is performed.
     */
    lastSearchString: string;

    /**
     * The text to show as a banner message, or the empty string if no message
     * should be shown.
     */
    bannerMessage: string;

    /**
     * Temporary storage of a hash of the previous user´s refNo. Used when
     * failing a refresh and redirecting the user to the sign-in page. Only if
     * it is the same user who signs in the next time should we restore the
     * application state.
     */
    previousUserHash: string;
    
    /**
     * The current bank id qr image, if one exists.
     */
    bankIdQrString: string;
    
    /**
     * The current bank id auto start token, if one exists.
     */
    bankIdAutoStartToken: string;
    
    /**
     * The message to display instead of the qr code.
     */
    bankIdStatusMessage: string;
}

/**
 * Sets the auth tokens and calls handleEndUserSubscriptionOperationResponse
 * unless the response indicates we should redirect. In this case, we redirect
 * immediately. This can happen if a user signs in from the wrong site, and we
 * have a UserRedirect configured.
 *
 * @param context The context.
 * @param response endUserAndSubscription The new active user and subscription.
 *     Must not be null. Must have the endUserParameters and subscriptions list
 *     set.
 */
let handleAuthResponse = (context: ActionContext<RootState, RootState>, response: EndUserSubscriptionOperationResponse): void => {
    /*
     If the redirectTo is set, we know we should redirect immediately.
     */
    if (response.redirectTo) {
        window.location.replace(response.redirectTo);
    }
    context.commit(MUTATION_AUTH_INFO, response.authInfoResponse);
    handleEndUserSubscriptionOperationResponse(context, response);
};

/**
 * Sets the active user and subscription. <strong>Important</strong> this
 * function needs to be called after authentication for the web app to work
 * properly outside of the sign in page. This is either done by the
 * ACTION_GET_ACTIVE_USER action or by one of the authentication actions.
 * <p>
 * The active subscription is set
 * according to the following rules:
 *
 * 1. If an explicit active subscription is given in
 *    endUserAndSubscription.subscriptionParameters we use that one.
 *
 * 2. If endUserAndSubscription.subscriptions contains exactly one element, we
 * use that subscription.
 *
 * 3. If we have stored a previously active subscription in our local storage,
 * and that subscription is among those in
 * endUserAndSubscription.subscriptions, we use that one.
 *
 * 4. As a last fallback we use the first subscription in
 *    endUserAndSubscription.subscriptions.
 *
 * @param context The context.
 * @param response endUserAndSubscription The new active user and subscription.
 *     Must not be null. Must have the endUserParameters and subscriptions list
 *     set.
 */
let handleEndUserSubscriptionOperationResponse = (context: ActionContext<RootState, RootState>, response: EndUserSubscriptionOperationResponse): void => {
    context.commit(MUTATION_ACTIVE_USER, response.endUserParameters);

    let activeSubscription: SubscriptionParameters = response.subscriptionParameters;

    /*
     If the response contains no explicit active subscription and the list of
     subscriptions to choose from has more than one element, we try to set the active
     subscription to the one with the id we have possibly previously stored. We store
     the active subscription when the user explicitly selects one. If we can't find
     a stored id we use the first subscription in the list as the active one.
     */
    if (!activeSubscription) {
        if (response.subscriptions.length === 1) {
            activeSubscription = response.subscriptions[0];
        } else if (response.subscriptions.length > 1 && response.endUserParameters) {
            let previouslyActiveSubscriptionRefNo = StorageService.getPersistedActiveSubscriptionForUser(response.endUserParameters.refNo);
            for (let sub of response.subscriptions) {
                if (sub.refNo === previouslyActiveSubscriptionRefNo) {
                    activeSubscription = sub;
                    break;
                }
            }
            // If all else fails - choose the first subscription in the list.
            if (!activeSubscription) {
                activeSubscription = response.subscriptions[0];
            }
        }
    }

    context.commit(MUTATION_ACTIVE_SUBSCRIPTION, activeSubscription);
    context.commit(MUTATION_SUBSCRIPTION_LIST, response.subscriptions);
};

let rootActions: ActionTree<RootState, RootState> = {
    /**
     * Perform authentication with username and password.
     *
     * @param context The context.
     * @param authRequest The authentication parameters. The id and secret will
     *     be set to the empty string on a successful sign in.
     */
    [ACTION_AUTHENTICATE_WITH_USERNAME_AND_PASSWORD]: (context, authRequest: AuthRequest): Promise<EndUserSubscriptionOperationResponse> => {
        return new Promise((resolve, reject) => {
            HTTP.post<EndUserSubscriptionOperationResponse>("/api/auth/login/credentials", authRequest).then((response: EndUserSubscriptionOperationResponse) => {
                handleAuthResponse(context, response);
                // We don't want the credentials to hang around longer than
                // necessary.
                authRequest.secret = "";
                resolve(response)
            }).catch((error) => {
                context.commit(MUTATION_AUTH_INFO, undefined);
                reject(error);
            });
        })
    },

    /**
     * Confirm a sign-in one-time code.
     *
     * @param context The context.
     * @param oneTimeCodeConfirmRequest The code id and the code.
     */
    [ACTION_CONFIRM_ONE_TIME_CODE]: (context, oneTimeCodeConfirmRequest: OneTimeCodeConfirmRequest): Promise<EndUserSubscriptionOperationResponse> => {
        return new Promise((resolve, reject) => {
            HTTP.post<EndUserSubscriptionOperationResponse>("/api/auth/login/one-time-code/confirm", oneTimeCodeConfirmRequest).then((response: EndUserSubscriptionOperationResponse) => {
                handleAuthResponse(context, response);
                resolve(response)
            }).catch((error) => {
                context.commit(MUTATION_AUTH_INFO, undefined);
                reject(error);
            });
        })
    },

    /**
     * Perform authentication or signing with BankID. This involves the startup
     * call that generates a bankIdRefNo and is then followed by several long
     * polls to the check-status endpoint with the given reference number. On
     * successful completion the promise is resolved to the response object. In
     * other cases the promise is rejected with a UserErrorMessage containing
     * status that can be either of:
     *
     * aborted   - The user aborted the process from the web gui.
     * cancelled - The request has been cancelled in the BankID app.
     * error     - Something went wrong with the authentication process.
     * timeout   - The user did not complete the authentication process on her
     * phone during the specified number of polls.
     *
     * and a descriptive errorMessage to be shown to the user.
     *
     * @param context The context.
     * @param authRequest The authentication parameters.
     */
    [ACTION_AUTHENTICATE_OR_SIGN_WITH_BANK_ID]: (context, authRequest: BankIDAuthRequest): Promise<EndUserSubscriptionOperationResponse> => {
        let numPolls: number = 60;
        let pollCount: number = 0;

        /**
         * Returns a promise that either resolves to:
         *
         * 1) The response from the server, or...
         * 2) A new promise. This happens when the first poll returns without
         * the BankID process being completed. This will continue to happen
         * until the link is confirmed or we have polled numPolls times, in
         * which case we reject with the string "timeout".
         *
         * @param request The BankID collect request containing the order ref
         *     to poll.
         */
        let poll = (request: BankIDCollectRequest): Promise<EndUserSubscriptionOperationResponse> => {
            return new Promise<EndUserSubscriptionOperationResponse>((resolve, reject) => {
                HTTP.post<EndUserSubscriptionOperationResponse>("/api/auth/bankid/check-status/" + authRequest.action, request, NUM_RETRIES).then((response: EndUserSubscriptionOperationResponse) => {
                    if (response && response.authInfoResponse) {
                        // The BankID process has completed.
                        handleAuthResponse(context, response);
                        resolve(response);
                    } else if (authRequest.abort) {
                        // The user has aborted the process in the web gui.
                        reject(new UserErrorMessage("aborted", "Användaren avbröt autentiseringen i webbgränssnittet."));
                    } else if (response.status === "self-service-created") {
                        // This was the response for a self-service creation.
                        resolve(response);
                    } else if (response.status === "cancelled") {
                        // The user has cancelled the login in the BankID app.
                        reject(new UserErrorMessage("cancelled", "Autentiseringen avbröts i mobilen."));
                    } else if (response.status === "error") {
                        // Something went wrong during the BankID process.
                        reject(new UserErrorMessage("error", response.message || "Något gick fel. Prova gärna igen."));
                    } else {
                        context.commit(MUTATION_BANK_ID_QR, response.bankIdQrData);
                        if (++pollCount > numPolls) {
                            // We have exceeded the maximum number of polls.
                            context.dispatch(ACTION_CANCEL_BANK_ID, request.orderRef).catch(() => {});
                            reject(new UserErrorMessage("timeout", "Autentiseringen tog för lång tid."));
                        } else {
                            let message = undefined;
                            if(response.status === "userSign") {
                                message = response.message;
                            }
                            context.commit(MUTATION_BANK_ID_STATUS_MESSAGE, message);
                            // No verification yet. Keep checking...
                            resolve(poll(request));
                        }
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        };

        return new Promise<EndUserSubscriptionOperationResponse>((resolve, reject) => {
            // Initiate a new BankId action and retrieve an order ref that we
            // can poll.
            HTTP.post<BankIDCreateResponse>("/api/auth/bankid/" + authRequest.action, authRequest).then((response: BankIDCreateResponse) => {
                let orderRef=response.orderRef;
                
                context.commit(MUTATION_BANK_ID_AUTO_START_TOKEN, response.autoStartToken);
                /*
                  Report the orderRef back in the request object in order to
                  make it possible to abort a session from the outside.
                 */
                authRequest.orderRef = orderRef;
                let subRefNo: string = context.state.activeSubscription ? context.state.activeSubscription.refNo : undefined;
                let request = new BankIDCollectRequest(orderRef, subRefNo);
                // Start polling.
                return poll(request).then((response: EndUserSubscriptionOperationResponse) => {
                    resolve(response);
                }).catch(error => reject(error));
            }).catch(error => {
                if (authRequest.abort) {
                    reject(new UserErrorMessage("aborted", "Användaren avbröt autentiseringen i webbgränssnittet."));
                } else {
                    reject(error);
                }
            }).finally(() => {
                context.commit(MUTATION_BANK_ID_AUTO_START_TOKEN, undefined);
                context.commit(MUTATION_BANK_ID_QR, undefined);
                context.commit(MUTATION_BANK_ID_STATUS_MESSAGE, undefined);
            });
        })
    },

    /**
     * Cancels an ongoing BankID session.
     *
     * @param context The context.
     * @param orderRefNo The refNo of the session to cancel.
     */
    [ACTION_CANCEL_BANK_ID]: (context, orderRefNo: string): Promise<void> => {
        if(!!orderRefNo) {
            return HTTP.post<void>("/api/auth/bankid/cancel/" + orderRefNo, "{}", 0, true).then(() => {
                
                context.commit(MUTATION_BANK_ID_AUTO_START_TOKEN, undefined);
                context.commit(MUTATION_BANK_ID_QR, undefined);
                context.commit(MUTATION_BANK_ID_STATUS_MESSAGE, undefined);
            });
        }
        return Promise.resolve();
    },

    /**
     * Agrees to terms for the given auth request, without BankID.
     * @param context The context.
     * @param authRequest The authentication parameters to use when agreing to
     *     the terms.
     */
    [ACTION_AGREE_TO_SAFENODE_TERMS]: (context, authRequest: AuthRequest): Promise<EndUserSubscriptionOperationResponse> => {
        return new Promise<EndUserSubscriptionOperationResponse>((resolve, reject) => {
            HTTP.post<EndUserSubscriptionOperationResponse>("/api/auth/agree-to-safenode-terms/", authRequest).then((response: EndUserSubscriptionOperationResponse) => {
                handleAuthResponse(context, response);
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        })
    },

    /**
     * Confirms an invite. Requires that the user is logged in as the end user
     * who is invited. Also requires that the inviteLinkId is set on the
     * parameters object.
     */
    [ACTION_CONFIRM_INVITE]: (context, endUserSubscriptionParameters: EndUserSubscriptionParameters): Promise<EndUserSubscriptionOperationResponse> => {
        return new Promise((resolve, reject) => {
            HTTP.post<EndUserSubscriptionOperationResponse>("/sapi/subscription/invite/confirm/" + endUserSubscriptionParameters.inviteLinkId, endUserSubscriptionParameters, NUM_RETRIES).then((response: EndUserSubscriptionOperationResponse) => {
                /*
                 No authentication tokens are returned from the confirm request, since
                 we're already signed in when performing it.
                 */
                handleEndUserSubscriptionOperationResponse(context, response);
                resolve(response)
            }).catch((error) => {
                reject(error);
            });
        })
    },

    /**
     * Create a subscription based on the given parameters.
     */
    [ACTION_CREATE_USER_FOR_SUBSCRIPTION]: (context, endUserSubscriptionParameters: EndUserSubscriptionParameters): Promise<EndUserSubscriptionOperationResponse> => {
        return new Promise((resolve, reject) => {
            HTTP.post<EndUserSubscriptionOperationResponse>("/api/subscription/create-user-for-subscription", endUserSubscriptionParameters).then((response: EndUserSubscriptionOperationResponse) => {
                /*
                 Authentication tokens for the newly created user/subscription
                 object are included in the response.
                 */
                handleAuthResponse(context, response);
                resolve(response)
            }).catch((error) => {
                reject(error);
            });
        })
    },

    /**
     * Fetch information about the currently logged in user and its
     * authentication privileges. This info is stored in the vuex store. This
     * will receive all data needed for the rest of the app to work and display
     * properly.
     */
    [ACTION_GET_ACTIVE_USER]: (context): Promise<EndUserSubscriptionOperationResponse> => {
        return new Promise((resolve, reject) => {
            HTTP.get<EndUserSubscriptionOperationResponse>("/sapi/subscription/active-user", {}, 2).then((response: EndUserSubscriptionOperationResponse) => {
                // We handle this as an auth response since this is called when
                // the app loads for the first time
                handleAuthResponse(context, response);
                resolve(response)
            }).catch((error) => {
                reject(error);
            });
        })
    },

    /**
     * Refreshes our authentication tokens using our refresh token.
     */
    [ACTION_REFRESH_AUTH_TOKENS]: (context): Promise<AuthInfoResponse> => {
        return new Promise((resolve, reject) => {
            HTTP.post<AuthInfoResponse>("/api/auth/refresh", "{}", 0, true).then((authResponse: AuthInfoResponse) => {
                if (!authResponse) {
                    reject("Failed refresh, invalid tokens")
                } else {
                    context.commit(MUTATION_AUTH_INFO, authResponse);
                    resolve(authResponse);
                }
            }).catch(() => {
                // Token is revoked on the servers side so if any error occurs
                // then our current tokens will no longer work
                context.commit(MUTATION_AUTH_INFO, undefined);
                reject();
            });
        })
    },

    /**
     * Signs out by invalidating the active token on the server and removing
     * authentication info and other data locally.
     */
    [ACTION_SIGN_OUT]: (context): Promise<void> => {
        return context.dispatch(ACTION_DELETE_LOCAL_DATA_LOGOUT).then(() => {
            return HTTP.post<void>("/api/auth/logout", "{}", 0, true);
        });
    },

    /**
     * Signs out all logged in sessions by invalidating the tokens on the server
     * and removing the authentication info other data locally.
     */
    [ACTION_SIGN_OUT_ALL]: (context): Promise<void> => {
        return context.dispatch(ACTION_DELETE_LOCAL_DATA_LOGOUT).then(() => {
            return HTTP.post<void>("/api/auth/logout-all", "{}", 0, true);
        });
    },

    /**
     * Delete the local authentication info and the active user. This is
     */
    [ACTION_DELETE_LOCAL_DATA_LOGOUT]: (context): Promise<void> => {
        return new Promise((resolve) => {
            context.commit(MUTATION_RESET_ALL_STORES, undefined);
            resolve();
        });
    },

    /**
     * Fetches the current banner message.
     */
    [ACTION_FETCH_BANNER_MESSAGE]: (context): Promise<string> => {
        return new Promise((resolve, reject) => {
            HTTP.get<string>("/sapi/banner-message").then((bannerMessage: string) => {
                context.commit(MUTATION_BANNER_MESSAGE, bannerMessage);
            }).catch(() => {
                context.commit(MUTATION_BANNER_MESSAGE, "");
                reject();
            });
        })
    },
};

let appLoadedResolve: any;
let appLoaded: boolean = false;
let signedOutIfNecessaryResolve: any;
let signedOutIfNecessary: boolean = false;
let extraSetupDoneResolve: any;
let extraSetupDone: boolean = false;

const storeOptions: StoreOptions<RootState> = {
    /**
     * Notice! It is important to explicitly define all fields in the state
     * here, even if the initial value is "undefined". This is because Vuex
     * will not be able to track when they change otherwise.
     */
    state: {
        appLoaded: new Promise<boolean>((resolve) => {
            appLoadedResolve = resolve;
        }),
        signedOutIfNecessary: new Promise<void>((resolve) => {
            signedOutIfNecessaryResolve = resolve;
        }),
        extraSetupDone: new Promise<void>((resolve) => {
            extraSetupDoneResolve = resolve;
        }),
        authorityInfo: undefined,
        activeUser: undefined,
        activeSubscription: undefined,
        popupMessageStatus: "",
        popupRegistrationState: new PopupRegistrationState(""),
        subscriptionList: [],
        sidebarRightStatus: "",
        ctrlPressed: false,
        metaPressed: false,
        activeInlineModal: "",
        lastSearchString: "",
        bannerMessage: "",
        previousUserHash: "",
        bankIdQrString: "",
        bankIdAutoStartToken: "",
        bankIdStatusMessage: "",
    },

    getters: {
        /**
         * Count as signed in if we at least one specified authority.
         *
         * @param state Store state
         */
        signedIn: state => {
            if (state.authorityInfo && state.authorityInfo.authorities) {
                return state.authorityInfo.authorities.length > 0;
            } else {
                return false;
            }
        },
        authorityInfo: state => state.authorityInfo
    },

    mutations: {
        /**
         * Resolves the promise indicating that the application is fully loaded.
         *
         * @param state The state.
         */
        [MUTATION_APP_LOADED]: (state: RootState, success: boolean) => {
            if (!appLoaded) {
                appLoaded = true;
                /*
                 Remove the white div covering the entire screen before the
                 application is loaded, unless we're in prerendering mode, in
                 which case it must be present, since it otherwise won't be
                 included in the prerendered index.html and thus not visible
                 for end users landing on the page.
                 */
                if (!Global.isPrerendering()) {
                    let elem: Element = document.getElementById("loader-wrapper");
                    if (elem) {
                        elem.parentElement.removeChild(elem);
                    }
                }
                appLoadedResolve(success);
            }
        },

        /**
         * Resolves the promise indicating that we have been successfully signed
         * out, if we're on a path where that is necessary.
         *
         * @param state The state.
         */
        [MUTATION_SIGNED_OUT_IF_NECESSARY]: () => {
            if (!signedOutIfNecessary) {
                signedOutIfNecessary = true;
                signedOutIfNecessaryResolve();
            }
        },

        /**
         * Resolves the promise indicating that any extra setup required for the
         * application to be considered loaded, has been completed.
         *
         * @param state The state.
         */
        [MUTATION_EXTRA_SETUP_DONE]: () => {
            if (!extraSetupDone) {
                extraSetupDone = true;
                extraSetupDoneResolve();
            }
        },

        /**
         * Resets submodule stores to their initial state, and this store to a
         * suitable state.
         *
         * @param state The state.
         */
        [MUTATION_RESET_ALL_STORES]: () => {
            /*
              Notice that we don't reset the previousUserRefNo here since we
              need it after signing in again.
             */
            store.commit(MUTATION_ACTIVE_USER, undefined);
            store.commit(MUTATION_AUTH_INFO, undefined);
            store.commit(MUTATION_ACTIVE_SUBSCRIPTION, undefined);
            store.commit(MUTATION_SUBSCRIPTION_LIST, undefined);
            store.commit(MUTATION_ACTIVE_INLINE_MODAL, undefined);
            store.commit(MUTATION_LAST_SEARCH_STRING, undefined);

            Object.keys(storeOptions.modules).forEach((module) => store.commit(module + "/" + Global.MUTATION_RESET_STORE));
        },

        /**
         * Sets the active subscription. Also stores it in the local store so
         * we can use it when the user returns to the page at a later time.
         *
         * @param state The state.
         * @param activeSubscription The active subscription. May be null if
         *     the user is not signed in.
         */
        [MUTATION_ACTIVE_SUBSCRIPTION]: (state: RootState, activeSubscription: SubscriptionParameters) => {
            state.activeSubscription = activeSubscription;
            if (state.activeUser && state.activeSubscription) {
                StorageService.persistActiveSubscriptionForUser(state.activeUser.refNo, state.activeSubscription.refNo);
            }
        },

        /**
         * Sets the active user.
         *
         * @param state The state.
         * @param activeUser The active user. May be null if the user is not
         *     signed in.
         */
        [MUTATION_ACTIVE_USER]: (state: RootState, activeUser: EndUserParameters) => {
            if (state.activeUser) {
                store.commit(MUTATION_PREVIOUS_USER_HASH, UTILS.simpleHash(state.activeUser.refNo));
            }
            state.activeUser = activeUser
        },

        /**
         * Set if we are signed in or not by setting information about the
         * authentication.
         *
         * @param state The state.
         * @param authTokens The authentication tokens. May be null if the user
         *     is not signed in.
         */
        [MUTATION_AUTH_INFO]: (state: RootState, authInfo: AuthInfoResponse) => {
            state.authorityInfo = authInfo;
            if (state.authorityInfo) {
                StorageService.storeTermsAccepted();
            }
        },

        /**
         * Sets the status of the popup message modal. This controls if it is
         * shown and in that case, which content that is to be displayed.
         *
         * @param state The state.
         * @param newStatus The status. The empty string means it should be
         *     closed. For other values, see the html in PopupMessage.vue.
         */
        [MUTATION_POPUP_MESSAGE_STATUS]: (state: RootState, newStatus: string) => {
            if (state.popupMessageStatus === 'personal-number' && newStatus !== '') {
                // Special case, don't allow changing away from personal number popup, unless it's to clear the message
                return;
            }
            state.popupMessageStatus = newStatus;
        },

        /**
         * Sets the state for the popup registration modal. This controls if it
         * is shown and in that case, which content that is to be displayed.
         *
         * @param state The state. This may either be a string representing the
         *     status, or a complete PopupRegistrationState object.
         * @param newStatus The status. The empty string means it should be
         *     closed. For other values, see the html in PopupRegistration.vue.
         */
        [MUTATION_POPUP_REGISTRATION_STATE]: (state: RootState, newState: PopupRegistrationState | string) => {
            if (typeof newState === "string") {
                state.popupRegistrationState = new PopupRegistrationState(newState);
            } else {
                state.popupRegistrationState = newState;
            }
        },

        /**
         * Sets the status of the right sidebar. This controls if it is shown
         * and in that case, which content that is to be displayed.
         *
         * @param state The state.
         * @param newStatus The status. The empty string means it should be
         *     closed. For other values, see the html in SidebarRightMenu.vue.
         */
        [MUTATION_SIDEBAR_RIGHT_STATUS]: (state: RootState, newStatus: string) => {
            state.sidebarRightStatus = newStatus;
        },

        /**
         * Sets the list of available subscriptions.
         *
         * @param state The state.
         * @param subscriptionList The list of available subscriptions.
         */
        [MUTATION_SUBSCRIPTION_LIST]: (state: RootState, subscriptionList: SubscriptionParameters[]) => state.subscriptionList = subscriptionList,

        /**
         * Sets the value of the ctrlPressed property.
         *
         * @param state The state.
         * @param pressed The new value of the ctrlPressed property
         */
        [MUTATION_CTRL_PRESSED]: (state: RootState, pressed: boolean) => state.ctrlPressed = pressed,

        /**
         * Sets the value of the metaPressed property.
         *
         * @param state The state.
         * @param pressed The new value of the metaPressed property
         */
        [MUTATION_META_PRESSED]: (state: RootState, pressed: boolean) => state.metaPressed = pressed,

        /**
         * Sets the value of the activeInlineModal property.
         *
         * @param state The state.
         * @param activeInlineModal The new value of the activeInlineModal
         *     property
         */
        [MUTATION_ACTIVE_INLINE_MODAL]: (state: RootState, activeInlineModal: string) => state.activeInlineModal = activeInlineModal,

        /**
         * Sets the value of the lastSearchString property.
         *
         * @param state The state.
         * @param lastSearchString The new value of the lastSearchString
         *     property.
         */
        [MUTATION_LAST_SEARCH_STRING]: (state: RootState, lastSearchString: string) => state.lastSearchString = lastSearchString,

        /**
         * Sets the value of the bannerMessage property.
         *
         * @param state The state.
         * @param bannerMessage The new value of the bannerMessage property.
         */
        [MUTATION_BANNER_MESSAGE]: (state: RootState, bannerMessage: string) => state.bannerMessage = bannerMessage,

        /**
         * Sets the value of the previousUserRefNo property.
         *
         * @param state The state.
         * @param previousUserRefNo The new value of the previousUserRefNo
         *     property.
         */
        [MUTATION_PREVIOUS_USER_HASH]: (state: RootState, previousUserRefNo: string) => state.previousUserHash = previousUserRefNo,

        /**
         * Sets the value of the bankId qr code string.
         * 
         * @param state The state.
         * @param bankIdQrString The bankId qr image string
         */
        [MUTATION_BANK_ID_QR]: (state: RootState, bankIdQrString: string) => state.bankIdQrString = bankIdQrString,

        /**
         * Sets the value of the bankId auto start token.
         * 
         * @param state The state.
         * @param autoStartToken The bankId auto start token.
         */
        [MUTATION_BANK_ID_AUTO_START_TOKEN]: (state: RootState, autoStartToken: string) => state.bankIdAutoStartToken = autoStartToken,

        /**
         * Sets the value of the bankId status message.
         * 
         * @param state The state.
         * @param bankIdStatusMessage The bankId status message.
         */
        [MUTATION_BANK_ID_STATUS_MESSAGE]: (state: RootState, bankIdStatusMessage: string) => state.bankIdStatusMessage = bankIdStatusMessage,
            
    },

    actions: rootActions,

    modules: {
        freerider: freeriderModule,
        notification: notificationsModule,
        monitor: monitorModule,
        questioner: questionerModule,
        search: searchModule,
        payments: paymentsModule,
        documents: documentsModule,
        userWebAppSettings: userWebAppSettingsModule
    }
};


export const store = new Vuex.Store<RootState>(storeOptions);
export default store;

/*
  Helper function that resets the given state to the value of the provided
  initialState, in a way that preserves reactivity.
 */
export function resetState(state: any, initialState: any) {
    Object.assign(state, initialState);
}
