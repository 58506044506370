
    import {Component, Mixins} from 'vue-property-decorator';
    import HomePage from "@/views/HomePage.vue";
    import {
        ACTION_GET_ACTIVE_USER,
        MUTATION_EXTRA_SETUP_DONE,
        MUTATION_POPUP_MESSAGE_STATUS
    } from "@/store/store";
    import {EndUserSubscriptionOperationResponse} from "@/models/end-user-subscription-operation-response";
    import Utils from "@/mixins/utils";
    import ApplicationStateSerializer
        from "@/mixins/application-state-serializer";
    import {EmailLoginApplicationState} from "@/models/email-login-application-state";
    import {EndUserSubscriptionParameters} from "@/models/end-user-subscription-parameters";
    import StateHelper from "@/mixins/state-helper";
    import {AxiosResponse} from "axios";
    import {StorageService} from "@/services/storage.service";
    import {AuthRequest} from "@/models/auth-request.model";
    import Validation from "@/mixins/validation";
    import {PATH_REDIRECT_SIGN_IN} from "@/router";

    /**
     * This component represents the page where a user is directed when clicking on a
     * verify email link. It checks that the link is good and presents a message to the
     * user depending on the outcome.
     */
    @Component({
        components: {
            HomePage
        },
    })
    export default class SSOLoginPage extends Mixins(ApplicationStateSerializer, StateHelper, Utils) {
        mounted() {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=Ett ögonblick...");

            if (this.$route.params['status'] === 'fel') {
                // Something went wrong, probably the user tried SSO login with an email that does not
                // exist in the system
                let errorMessage: string = "Kunde inte logga in med SSO. Vänligen kontrollera epostadressen.";
                this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "error:errorMessage=" + errorMessage);
                this.$router.replace({name: "home"});
            } else {
                // Now we should already have the auth cookies set, because we came here from a successful
                // SSO login reditect.
                this.$store.dispatch(ACTION_GET_ACTIVE_USER).then((response: EndUserSubscriptionOperationResponse) => {
                    if (response.authInfoResponse) {
                        /*
                            Navigate to welcome page, which opens the Welcome popup window.
                        */
                        this.$router.replace({
                            name: "welcome",
                            params: {
                                state: JSON.stringify({path: "/"}),
                                showPassword: "false",
                                showSubscriptionSelection: true ? "true" : undefined,
                            }
                        });
                    }
                    // Try to make sure the cookie has really been set...
                    setTimeout(() => {
                        this.$store.commit(MUTATION_EXTRA_SETUP_DONE);
                    }, 10);
                }).catch((response) => {
                    let errorMessage: string = this.extractErrorMessage(response, "Något gick fel.");
                    this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=" + errorMessage);
                });
            }
        }
    }
