/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
import {SearchResultIndividual} from "@/models/search-result-individual";

export class SearchResultEntryIndividual extends SearchResultIndividual {
    marriedToCompleteName: string = "";

    livesWith: string = "";

    nofCommitments: number;
}
