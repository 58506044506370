
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import {
    SearchResultHouseholdMember
} from "@/models/search-result-household-member";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SearchPageEntityHouseholdMemberList
    from "@/components/SearchPageEntityHouseholdMemberList.vue";

/**
 * This component represents a list of household members with a heading.
 */
@Component({
    components: {
        SearchPageEntityHouseholdMemberList,
        SearchPageEntityInfoHeader, SearchPageEntityLink
    }
})
export default class SearchPageEntityHouseholdMemberListWithHeading extends Mixins(Utils) {
    @Prop()
    heading: string;

    @Prop()
    members: SearchResultHouseholdMember[];

    @Prop()
    level: number;
}
