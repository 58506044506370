
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SvgConstants from "@/mixins/svg-constants";
import Utils, {UTILS} from "@/mixins/utils";
import {SearchResultSimpleCompany} from "@/models/search-result-simple-company";
import FMillions from "@/components/FMillions.vue";
import FNum from "@/components/FNum.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";

/**
 * This component represents a list of simple companies.
 */
@Component({
    components: {
        StatusIconRow,
        FNum,
        FMillions,
        SearchPageEntityLink
    }
})
export default class SearchPageEntitySimpleCompanyList extends Mixins(StateHelper, SvgConstants, Utils) {
    /**
     * The list of companies.
     */
    @Prop()
    companies: SearchResultSimpleCompany[];

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;

    numToShow: number = UTILS.CHUNK_SIZE;


    /**
     * Gets the entries currently visible in the list. For large lists, we
     * don't show all entries since that may consume too much resources in
     * the browser.
     */
    get visibleEntries(): SearchResultSimpleCompany[] {
        return this.companies.slice(0, this.numToShow);
    }
}
