import {
    SearchResultBaseIndividual
} from "@/models/search-result-base-individual";

/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
export class SimpleIndividual extends SearchResultBaseIndividual {
    personalNumber: string = "";

    gender: string = "";
}
