
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Auth from "@/mixins/auth";
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import {HTTP} from "@/services/http-provider";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import {SearchResultEntryCatalog} from "@/models/search-result-entry-catalog";
import CatalogAnimationSpread from "@/components/CatalogAnimationSpread.vue";


/**
 * This component represents the income animation that is part of the income tab in
 * the search result details.
 */
@Component({
    components: {CatalogAnimationSpread}
})
export default class IncomeAnimation extends Mixins(Auth, StateHelper,Utils) {
    /**
     * The search result individual for which to show the income.
     */
    @Prop()
    individual: SearchResultEntryIndividualDetails;

    /**
     * The catalog to use.
     */
    @Prop()
    catalog: SearchResultEntryCatalog;

    /**
     * The style of the flipping sprite. Used to change the background image depending on
     * which individual we're viewing the income for.
     *
     * @type A map of css attributes.
     */
    style: any = {};

    /**
     * The style for the magnifying glass. Used for animation of the magnifying glass.
     *
     * @type A map of css attributes.
     */
    magnifyImgStyle: any = {};


    /**
     * The style for the container for the actual income image. Used for scaling the image
     * when the magnifying glass has zoomed in on it.
     *
     * @type A map of css attributes.
     */
    magnifyContainer1Style: any = {};

    /**
     * The style for the actual income image container. Used for setting the background to
     * the correct income image.
     *
     * @type A map of css attributes.
     */
    magnifyContainer2Style: any = {};

    /**
     * Keeps track of the various steps in the animation. See #handleMagnifyAnimation().
     */
    step: string = "0";

    /**
     * Will be set to a proper error message if we're out of catalog views.
     * This may happen both when the pool is exhausted and when a user
     * exceeds the usage limitation.
     */
    outOfCatalogViewsMessage: string = null;

    private flipSpriteObjectUrl: string;

    private catalogImageObjectUrl: string;

    /**
     * The catalog spreads to be displayed during the animation.
     */
    spreads: any[] = [];

    mounted() {
        if (this.existsInCatalog && this.individual.allowCatalogView) {
            /*
              If the user is allowed to see both income and remarks we want
              to increase the probability that the remark requests hit the
              server first, due to the fact that it is one of the income
              requests that increases the catalog view count and thus risks
              exhausting the pool. If exhaustion occurs before the remark
              request we may have the case where we're allowed to see income
              but not remarks. This delay is no guarantee, but since the
              cost of a potential failure is low and the cost for
              synchronizing the calls is rather high, we're ok with this.
             */
            setTimeout(() => {
                HTTP.get<any>("/api/catalog/income/flip-sprite/" + this.individual.id + "/" + this.catalog.id)
                    .then((wrapper: any) => {
                        let data = wrapper.image;
                        const blob = this.b64toBlob(data, "image/jpeg");
                        this.flipSpriteObjectUrl = URL.createObjectURL(blob)
                        for (let i = 0; i < wrapper.numPages; i += 2) {
                            let spread: any = {};
                            spread.low = i;
                            if (i + 1 == wrapper.numPages) {
                                spread.high = -1;
                                spread.isLast = true;
                            } else {
                                spread.high = i + 1;
                                spread.isLast = false;
                            }
                            spread.img = this.flipSpriteObjectUrl;
                            this.spreads.push(spread);
                        }

                        this.style["background-image"] = this.wrapUrl(this.flipSpriteObjectUrl);

                        // Setting the step to "1" starts the flipping animation.
                        this.step = "1";

                        this.handleMagnifyAnimation();
                    });
            }, this.allowViewRemarks ? 100 : 0);
        } else {
            setTimeout(() => {
                this.step = "0:none-existing";
            }, 500);
        }
    }

    beforeDestroy() {
        URL.revokeObjectURL(this.flipSpriteObjectUrl);
        URL.revokeObjectURL(this.catalogImageObjectUrl);
    }

    /**
     * Checks if the individual exists in the catalog. Individuals that don't does not
     * have any page coordinates, so that's how we do it.
     */
    get existsInCatalog(): boolean {
        return this.catalog.middleX !== -1;
    }

    /**
     * Returns true if we're not allowed to view more catalogs
     */
    get outOfCatalogViews(): boolean {
        return this.outOfCatalogViewsMessage !== null;
    }

    get editionNum(): string {
        if (this.catalog.editionSubNum == 0) {
            return this.catalog.editionNum.toString();
        }
        return this.catalog.editionNum.toString() + ":" + this.catalog.editionSubNum.toString();
    }

    get coverImageStyle(): string {
        if (this.catalog.id >= 207) {
            return "income_cover_page2";
        } else {
            return "income_cover_page1";
        }
    }

    private handleMagnifyAnimation() {
        this.magnifyContainer1Style = {
            'left': "" + (this.catalog.middleX) + "px",
            'top': "" + (this.catalog.middleY) + "px",
        };

        /*
          Create a background image url that shows the actual income
          image if the request to fetch it succeeds, and otherwise shows
          the default image. This is the backup for the cases where the
          token expires during the request or when the user tries to
          view the income for someone she is not allowed to view it for.
         */
        let url: string = "require(../assets/nobodys_income_blurred.png)";

        let allowedToViewIncome = this.signedIn && this.allowedForSubscription() && this.allowViewIncome;

        if (allowedToViewIncome) {
            // Only try to fetch real income image if we're properly logged in.
            url = "/sapi/catalog/income/ind" +
                "/" + this.individual.id +
                "/" + this.catalog.id +
                "?subscriptionRefNo=" + this.activeSubscription.refNo;
        }

        HTTP.fetchAsBlob(url, "image/png")
            .then((blob: Blob) => {
                this.catalogImageObjectUrl = URL.createObjectURL(blob);
                this.magnifyContainer2Style["background-image"] = this.wrapUrl(this.catalogImageObjectUrl);

                /*
                  Lift the magnifying glass to above the middle of the
                  spread.
                 */
                setTimeout(() => {
                    this.magnifyImgStyle = {
                        "z-index": "30",
                        "left": "110px",
                        "top": "70px",
                        "transform": "scale(1.5)",
                    };
                }, 3500);

                /*
                  Lower the magnifying glass to above the correct row and
                  column.
                 */
                setTimeout(() => {
                    this.magnifyImgStyle = {
                        "z-index": "30",
                        "left": "" + (this.catalog.middleX - 30) + "px",
                        "top": "" + (this.catalog.middleY - 30) + "px",
                        "transform": "scale(1)",
                    };
                }, 4000);

                // Show the actual income image.
                setTimeout(() => {
                    this.step = "2";
                    this.magnifyContainer1Style = {
                        'left': "",
                        'top': "",
                    };
                }, 4500);

                // Display the green highlight bar.
                setTimeout(() => {
                    this.step = "3";
                }, 5000);

                // Lower the heading arrows.
                setTimeout(() => {
                    this.step = "4";
                    /*
                      Set these timers here since outOfCatalogViews may not
                      have been set before.
                     */
                    if (!allowedToViewIncome || this.outOfCatalogViews) {
                        setTimeout(() => {
                            this.step = "5";
                        }, 1000);
                        setTimeout(() => {
                            this.step = "6";
                        }, 1500);
                    }
                }, 6000);
            })
            .catch((e) => {
                if (e.status == 402) {
                    this.outOfCatalogViewsMessage = "Ditt abonnemang har slut på katalogvisningar.";
                } else if (e.status == 429) {
                    this.outOfCatalogViewsMessage = "För många katalogvisningar på för kort tid. Kontakta kundtjänst för att få hjälp.";
                }
                this.step = "0:none-existing";
            });
    }

    /**
     * Checks if the current user is allowed to check income with the active
     * subscription.
     */
    private allowedForSubscription(): boolean {
        return this.activeUser && this.hasActiveSubscription;
    }

    private wrapUrl(url: string): string {
        return "url(" + url + ")";
    }
}
