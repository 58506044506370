
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import HomePage from "@/views/HomePage.vue";
    import {MUTATION_POPUP_MESSAGE_STATUS} from "@/store/store";

    /**
     * This component represents a page for displaying a full screen message. It can be
     * initialized with properties in the route in order to determine which message to
     * display.
     */
    @Component({
        components: {
            HomePage
        },
        /**
         * Make sure we close the popup whenever we navigate from a full screen page.
         *
         * @param to The new route.
         * @param from The old route.
         * @param next Must call this in order to complete navigation.
         */
        beforeRouteLeave(to, from, next) {
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "");
            next();
        }
    })
    export default class FullScreenMessagePage extends Vue {
        @Prop()
        type: string;

        @Prop()
        errorMessage: string;


        mounted() {
            /*
              Pass on potential properties as arguments to the message status. See the
              component PopupMessageFullScreen for more info.
             */
            let status: string = "full-screen";
            if (this.type) {
                status += ":type=" + this.type;
            } else if (this.errorMessage) {
                status += ":errorMessage=" + this.errorMessage;
            }
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, status);
        }
    }
