
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import SelectOnClick from "@/components/SelectOnClick.vue";

    /**
     * This component represents a two column table row.
     */
    @Component({
        components: {SelectOnClick}
    })
    export default class TwoColTableRow extends Vue {
        /**
         * The heading.
         */
        @Prop()
        heading: string;

        /**
         * The text.
         */
        @Prop()
        text: string;
    }
