
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants, {SVG} from "@/mixins/svg-constants";

/**
 * This component represents a status icon based on the given status.
 */
@Component({
    components: {}
})
export default class StatusIcon extends Mixins(SvgConstants) {
    /**
     * The status. If set to "WARNING" or "INFO", the appropriate icon is
     * displayed. Any other value will result in no icon
     */
    @Prop()
    status: string;

    /**
     * A class to add when displaying the info icon.
     */
    @Prop({default: "icon_gray"})
    infoClass: string;

    /**
     * Set to true in order to use a "light" info icon.
     */
    @Prop()
    light: boolean;


    get classMap(): any {
        let ret: any = {};
        if (this.status === "WARNING") {
            ret["icon_red"] = true;
        } else if (this.status === "INFO") {
            ret[this.infoClass] = true;
        }
        return ret;
    }

    get iconHtml(): string {
        if (this.status === "WARNING") {
            return SVG.warning;
        } else if (this.status === "INFO") {
            return this.light ? SVG.infoCircleLight : SVG.infoCircleRegular;
        }
        return "";
    }
}
