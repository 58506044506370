
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {MUTATION_SIDEBAR_RIGHT_STATUS} from "@/store/store";

import SvgConstants from "@/mixins/svg-constants";

/**
 * This component represents the top right close button in the right sidebar.
 */
@Component({
    components: {}
})
export default class SidebarRightCloseButton extends Mixins(SvgConstants) {
    /**
     * Set to true if we should close the sidebar, false if we should go back to the
     * menu. Default is true.
     */
    @Prop({default: true})
    closeSidebar: boolean;


    /**
     * Close the current view by either closing the entire sidebar or by going back to
     * the menu.
     */
    closeSidebarRight(): void {
        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, this.closeSidebar ? "" : "menu");
    }
}
