
    import {Component, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import Utils from "@/mixins/utils";
    import StateHelper from "@/mixins/state-helper";

    /**
     * This component represents the header on v2 pages.
     */
    @Component
    export default class V2Header extends Mixins(StateHelper, SvgConstants, Utils) {
        header1MenuShow: boolean = false;

        /**
         * Navigates to the proper location when the user clicks the logo.
         */
        handleLogoClick(): void {
            window.location.href = this.rootUrl();
        }
    }
