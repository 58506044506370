import {ActionTree, Module} from "vuex";
import {HTTP} from "@/services/http-provider";
import {resetState, RootState} from "@/store/store";
import {
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {DocumentEntity} from "@/models/document-entity";
import {DisplayableDocument} from '@/models/displayable-document';
import {Global} from "@/global";

export const MUTATION_DOCUMENT_ENTITIES = "documentEntities";
export const MUTATION_NEW_DOCUMENT_ID = "newDocumentId";

/**
 * The string before the / is the namespace (as set in the root store) and the
 * names after the / must match the names of the functions in the ActionTree.
 */
export const ACTION_FETCH_DOCUMENT_ENTITIES = "documents/fetchDocumentEntities";
export const ACTION_FLASH_NEW_DOCUMENT = "documents/flashNewDocument";

export interface DocumentsState {
    entities: DocumentEntity[];
    newDocumentId: string;
}

export const actions: ActionTree<DocumentsState, RootState> = {
    /**
     * Fetches the list of document entities for the currently signed-in user
     * from the server.
     *
     * @param context The context.
     */
    fetchDocumentEntities(context): Promise<DocumentEntity[]> {
        return new Promise((resolve, reject) => {
            let activeSub: SubscriptionParameters = context.rootState.activeSubscription;
            if (context.rootGetters.signedIn && activeSub) {
                let activeSubscription: string = activeSub.refNo;
                HTTP.post<DocumentEntity[]>("/sapi/documents/list-entities", JSON.stringify(activeSubscription)).then((response: DocumentEntity[]) => {
                    context.commit(MUTATION_DOCUMENT_ENTITIES, response);
                    resolve(response)
                }).catch((error) => {
                    context.commit(MUTATION_DOCUMENT_ENTITIES, []);
                    reject(error);
                });
            } else {
                context.commit(MUTATION_DOCUMENT_ENTITIES, []);
                resolve([])
            }
        })
    },

    /**
     * "Flashes" a document in a list by saving the id of the document in the
     * store for 5 seconds.
     *
     * @param context The context.
     * @param doc The document to flash.
     */
    flashNewDocument(context, doc: DisplayableDocument) {
        context.commit(MUTATION_NEW_DOCUMENT_ID, doc);
        setTimeout(() => {
            context.commit(MUTATION_NEW_DOCUMENT_ID, "");
        }, 3000)
    }
};

/**
 * Returns the initial state of this store.
 */
function initialState(): DocumentsState {
    return {
        entities: [],
        newDocumentId: ""
    };
}

export const documentsModule: Module<DocumentsState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        [MUTATION_DOCUMENT_ENTITIES]: (state: DocumentsState, entities: DocumentEntity[]) => state.entities = entities,

        [MUTATION_NEW_DOCUMENT_ID]: (state: DocumentsState, doc: DisplayableDocument) => state.newDocumentId = doc.id,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: DocumentsState) => resetState(state, initialState()),
    },
    actions: actions
};

