
import {Component, Prop, Vue} from 'vue-property-decorator';
import CustomSelect, {
    CustomSelectOption
} from "@/components/CustomSelect.vue";

/**
 * This component represents a header in the info view.
 */
@Component({
    components: {CustomSelect}
})
export default class SearchPageEntityInfoHeader extends Vue {
    /**
     * The header.
     */
    @Prop()
    heading: string;

    /**
     * An optional tag next to the heading, colored rounded box with white
     * text.
     */
    @Prop()
    tag: string;

    /**
     * The html to show to the right on the header line.
     */
    @Prop()
    rightEdgeHtml: string;

    /**
     * The options to put in the dropdown at the right on the header line. A
     * falsy value means that no dropdown should be shown. When the user selects
     * an option a "select-change" event is triggered, with the selected value
     * as event payload.
     */
    @Prop()
    options: CustomSelectOption[];

    /**
     * The default value. If this is changed from outside the component the
     * selected value will be updated accordingly.
     */
    @Prop()
    default: string;

    /**
     * The min width in pixels. May be used in order to avoid wide items to be
     * hidden. Not the most elegant solution, but still...
     */
    @Prop()
    minWidth: string;


    /**
     * Gets the default value to select in the dropdown. May be null if no
     * dropdown is to be shown.
     */
    get defaultValue(): string {
        return this.default ? this.default : (this.options ? this.options[0].value : null)
    }

    get style(): any {
        let style: any = {};
        if (this.minWidth) {
            style.minWidth = this.minWidth;
        }
        return style;
    }
}
