/**
 * See the java class with the same name for documentation.
 */
export class SearchRequest {
    searchString: string;
    filter: SearchFilter;
    subscriptionRefNo: string;
}

export class SearchFilter {
    includeIndividuals: boolean;
    includeCompanies: boolean;
    includeAddressLocations: boolean;
    includeRealProperties: boolean;
    includeVehicles: boolean;


    constructor(filterString: string = "") {
        this.includeIndividuals = filterString.indexOf("1") !== -1;
        this.includeCompanies = filterString.indexOf("2") !== -1;
        this.includeAddressLocations = filterString.indexOf("3") !== -1;
        this.includeRealProperties = filterString.indexOf("4") !== -1;
        this.includeVehicles = filterString.indexOf("5") !== -1;
    }

    toString(): string {
        let ret:string = "";
        ret += this.includeIndividuals ? "1" : "";
        ret += this.includeCompanies ? "2" : "";
        ret += this.includeAddressLocations ? "3" : "";
        ret += this.includeRealProperties ? "4" : "";
        ret += this.includeVehicles ? "5" : "";
        return ret;
    }
}
