
import {Component, Mixins} from 'vue-property-decorator';
import HomePage from "@/views/HomePage.vue";
import {MUTATION_POPUP_MESSAGE_STATUS} from "@/store/store";
import Utils from "@/mixins/utils";
import ApplicationStateSerializer from "@/mixins/application-state-serializer";
import StateHelper from "@/mixins/state-helper";

/**
 * This component represents the now obsolete page where a user was directed
 * when clicking on a verify email link. Now, it just displays a static error
 * message.
 */
@Component({
    components: {
        HomePage
    },
})
export default class VerifyEmailPage extends Mixins(ApplicationStateSerializer, StateHelper, Utils) {
    mounted() {
        this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "full-screen:errorMessage=Den angivna länken är inte längre aktiv");
    }
}
