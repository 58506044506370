import {ActionTree, Module} from "vuex";
import {HTTP} from "@/services/http-provider";
import {EventNotification} from "@/models/event-notification";
import {
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {FrontendMonitorConfig} from "@/models/frontend-monitor-config";
import {resetState, RootState} from "@/store/store";
import {Global} from "@/global";

export const MUTATION_EVENT_NOTIFICATIONS = "notifications";
export const MUTATION_EVENT_NOTIFICATION_ADD = "notificationsAdd"
export const MUTATION_EVENT_NOTIFICATION_COUNT = "notificationsCount"
export const MUTATION_EVENT_NOTIFICATION_DELETE = "notificationsDelete"
export const MUTATION_USER_CONFIG = "userConfig";
export const MUTATION_NOTIFICATION_TIME = "notificationTime";

/**
 * The string before the / is the namespace (as set in the root store) and the
 * names after the / must match the names of the functions in the ActionTree.
 */
export const ACTION_FETCH_NOTIFICATIONS = "notification/fetchNotificationsInit";
export const ACTION_FETCH_NOTIFICATIONS_PAGE = "notification/fetchNotificationsPaged";
export const ACTION_READ_NOTIFICATIONS = "notification/readNotifications";
export const ACTION_DELETE_NOTIFICATIONS = "notification/deleteNotifications";
export const ACTION_GET_USER_CONFIG = "notification/fetchUserConfig";
export const ACTION_UPDATE_USER_CONFIG = "notification/updateUserConfig";

export interface NotificationsState {
    wrappedNotifications: WrappedNotification[];
    unreadCount: number;
    totalCount: number;
    userConfig: FrontendMonitorConfig;
    latestTime: string;

    /**
     * The last time event notifications have been processed. Returned in each
     * response from the server. When the value changes to a later value, it is
     * time to fetch new event notifications from the server.
     */
    notificationTime: string;
}

export class WrappedNotification {
    notification: EventNotification;

    selected: boolean = false;

    expanded: boolean = false;


    constructor(notification: EventNotification, selected: boolean, expanded: boolean) {
        this.notification = notification;
        this.selected = selected;
        this.expanded = expanded;
    }
}

class EventNotificationRequest {
    subscriptionRefNo: string = "";

    latestTime: string = "";

    ids: number[] = [];


    constructor(subscriptionRefNo: string, latestTime: string, ids: number[]) {
        this.subscriptionRefNo = subscriptionRefNo;
        this.latestTime=latestTime;
        this.ids = ids;
    }
}

class EventNotificationResponse {
    unread: number = 0;

    count: number = 0;

    ids: number[] = [];


    constructor(unread: number, count: number, ids: number[]) {
        this.unread = unread;
        this.count = count;
        this.ids = ids;
    }
}

class EventNotificationPageRequest {
    start: number = 0;

    pageSize: number = 0;
    
    latestTime: string = "0";


    constructor(start: number, pageSize: number = 100, latestTime: string) {
        this.start = start;
        this.pageSize = pageSize;
        this.latestTime = latestTime;
    }
}

class EventNotificationPageResponse {
    start: number = 0;

    pageSize: number = 0;

    page: EventNotification[] = [];

    unread: number = 0;

    count: number = 0;
    
    latestTime: string = "0";


    constructor(start: number, pageSize: number, page: EventNotification[], unread: number, count: number, latestTime: string) {
        this.start = start;
        this.pageSize = pageSize;
        this.page = page;
        this.unread = unread;
        this.count = count;
        this.latestTime = latestTime;
    }
}

function doFetchPagedNotifications(as: SubscriptionParameters, req: EventNotificationPageRequest): Promise<EventNotificationPageResponse> {
    return HTTP.post<EventNotificationPageResponse>("/sapi/monitor-notification/get-notifications-paged/" + as.refNo, req);
}

export const actions: ActionTree<NotificationsState, RootState> = {
    readNotifications(context, ids: number[]): Promise<number[]> {
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as) {
                HTTP.post<EventNotificationResponse>("/sapi/monitor-notification/read-notifications", new EventNotificationRequest(as.refNo, context.state.latestTime, ids)).then((response: EventNotificationResponse) => {
                    context.commit(MUTATION_EVENT_NOTIFICATION_COUNT, response)
                    resolve(response.ids)
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve([])
            }
        })
    },
    deleteNotifications(context, ids: number[]): Promise<number[]> {
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as) {
                HTTP.post<EventNotificationResponse>("/sapi/monitor-notification/delete-notifications", new EventNotificationRequest(as.refNo, context.state.latestTime, ids)).then((response: EventNotificationResponse) => {
                    //context.commit(MUTATION_EVENT_NOTIFICATIONS, response);
                    context.commit(MUTATION_EVENT_NOTIFICATION_COUNT, response)
                    context.commit(MUTATION_EVENT_NOTIFICATION_DELETE, response)

                    resolve(response.ids)
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve([])
            }
        })
    },

    fetchNotificationsPaged(context): Promise<EventNotification[]> {
        const nextStart: number = context.state.wrappedNotifications.length;
        const pageSize: number = 250;
        const req: EventNotificationPageRequest = new EventNotificationPageRequest(nextStart, pageSize, context.state.latestTime);
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as) {
                doFetchPagedNotifications(as, req).then((response: EventNotificationPageResponse) => {
                    context.commit(MUTATION_EVENT_NOTIFICATION_ADD, response);
                    resolve(response.page)
                }).catch((error: any) => {
                    reject(error);
                });
            } else {
                resolve([])
            }
        })
    },

    fetchNotificationsInit(context): Promise<EventNotification[]> {
        const nextStart: number = 0;
        const pageSize: number = 50;

        const req: EventNotificationPageRequest = new EventNotificationPageRequest(nextStart, pageSize, "-1");
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as) {
                doFetchPagedNotifications(as, req).then((response: EventNotificationPageResponse) => {
                    context.commit(MUTATION_EVENT_NOTIFICATIONS, response);
                    resolve(response.page)
                }).catch((error) => {
                    context.commit(MUTATION_EVENT_NOTIFICATIONS, new EventNotificationPageResponse(0, 0, [], 0, 0, "0"));
                    reject(error);
                });
            } else {
                context.commit(MUTATION_EVENT_NOTIFICATIONS, new EventNotificationPageResponse(0, 0, [], 0, 0, "0"));
                resolve([]);
            }
        })
    },

    fetchUserConfig(context): Promise<FrontendMonitorConfig> {
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as) {
                HTTP.get<FrontendMonitorConfig>("/sapi/monitor-config/get-monitor-config").then((response: FrontendMonitorConfig) => {
                    context.commit(MUTATION_USER_CONFIG, response);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(undefined)
            }
        })
    },

    updateUserConfig(context, config: FrontendMonitorConfig): Promise<FrontendMonitorConfig> {
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as) {
                HTTP.post<FrontendMonitorConfig>("/sapi/monitor-config/update-monitor-config", config).then((response: FrontendMonitorConfig) => {
                    context.commit(MUTATION_USER_CONFIG, response);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(undefined)
            }
        })
    },
};

/**
 * Returns the initial state of this store.
 */
function initialState(): NotificationsState {
    return {
        wrappedNotifications: [],
        unreadCount: 0,
        totalCount: 0,
        userConfig: undefined,
        latestTime: "-1",
        notificationTime: null
    };
}

/**
 * This module handles the notifications list as well as the actions that are
 * possible to perform upon it.
 */
export const notificationsModule: Module<NotificationsState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        /**
         * Sets the complete list of event notifications.
         *
         * @param state The state.
         * @param freeriders The new set of notifications.
         */
        [MUTATION_EVENT_NOTIFICATIONS]: (state: NotificationsState, response: EventNotificationPageResponse) => {
            const notifications: EventNotification[] = response.page;
            state.totalCount = response.count;
            state.unreadCount = response.unread;
            state.latestTime = response.latestTime;
            state.wrappedNotifications = notifications.flatMap(not => {
                return new WrappedNotification(not, false, false)
            });
        },

        /**
         * Sets the complete list of event notifications.
         *
         * @param state The state.
         * @param freeriders The new set of notifications.
         */
        [MUTATION_EVENT_NOTIFICATION_COUNT]: (state: NotificationsState, response: EventNotificationResponse) => {
            state.totalCount = response.count;
            state.unreadCount = response.unread;
        },

        /**
         * Sets the complete list of event notifications.
         *
         * @param state The state.
         * @param freeriders The new set of notifications.
         */
        [MUTATION_EVENT_NOTIFICATION_DELETE]: (state: NotificationsState, response: EventNotificationResponse) => {
            let newArrWrapped: WrappedNotification[] = [];
            for (let not of state.wrappedNotifications) {
                if (!response.ids.includes(not.notification.id)) {
                    newArrWrapped.push(not);
                }
            }
            state.wrappedNotifications = newArrWrapped;
        },

        /**
         * Adds to the complete list of event notifications.
         *
         * @param state The state.
         * @param freeriders The new set of notifications.
         */
        [MUTATION_EVENT_NOTIFICATION_ADD]: (state: NotificationsState, response: EventNotificationPageResponse) => {
            const notifications: EventNotification[] = response.page;
            let newArrWrapped: WrappedNotification[] = [];
            for (let not of state.wrappedNotifications) {
                newArrWrapped.push(not);
            }
            for (let not of notifications) {
                // TODO Check for dupes here, and place in order of sentdate
                // and id.
                newArrWrapped.push(new WrappedNotification(not, false, false));
            }
            state.totalCount = response.count;
            state.unreadCount = response.unread;
            state.latestTime = response.latestTime;
            state.wrappedNotifications = newArrWrapped;
        },

        [MUTATION_USER_CONFIG]: (state: NotificationsState, response: FrontendMonitorConfig) => {
            state.userConfig = response;
        },

        /**
         * Sets the value of the notificationTime property.
         *
         * @param state The state.
         * @param pressed The new value of the notificationTime property.
         */
        [MUTATION_NOTIFICATION_TIME]: (state: NotificationsState, notificationTime: string) => state.notificationTime = notificationTime,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: NotificationsState) => resetState(state, initialState()),
    },
    actions: actions
};

