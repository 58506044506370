
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import {
    SearchResultOwnershipVehicle
} from "@/models/search-result-ownership-vehicle";
import Utils, {UTILS} from "@/mixins/utils";
import {ClickEventData} from "@/views/SearchPage.vue";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import SvgConstants from "@/mixins/svg-constants";

/**
     * This component represents a list of vehicles.
     */
    @Component({
        components: {SearchPageEntityInfoHeader, SearchPageEntityLink}
    })
    export default class SearchPageEntityVehicleList extends Mixins(StateHelper, SvgConstants, Utils) {
        /**
         * The list of vehicles.
         */
        @Prop()
        vehicles: SearchResultOwnershipVehicle[];

        /**
         * The zero based level we're on (0 = the leftmost detail view). This is used when
         * determining if the link is active or not by checking if the id in the detailIds
         * array on the level to the right of this one, is the same as our id.
         */
        @Prop()
        level: number;

        numToShow: number = UTILS.CHUNK_SIZE;


        /**
         * Gets the entries currently visible in the list. For large lists, we
         * don't show all entries since that may consume too much resources in
         * the browser.
         */
        get visibleEntries(): SearchResultOwnershipVehicle[] {
            return this.vehicles.slice(0, this.numToShow);
        }

        getVehicleName(vehicle: SearchResultOwnershipVehicle): string {
        	return vehicle.type + " " + this.formatRegistrationNumber(vehicle.registrationNumber);
        }

        showColor(vehicle: SearchResultOwnershipVehicle): boolean {
            if (vehicle.color.toLowerCase() == 'okänd') {
                return false;
            }
            let type = vehicle.type.toLowerCase();
            return type == 'personbil' || type == 'motorcykel';
        }

      /**
       * Re-emit click event.
       */
      @Emit()
          linkClick(event: ClickEventData<OpenEntityRequest>): ClickEventData<OpenEntityRequest> {
              return event;
          }
      }
