
    import {Component, Prop, Mixins} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    
    @Component
    export default class SidebarRightUserConfigFilterRadioGroup extends Mixins(SvgConstants) {
        
        @Prop()
        filterName: string;
        
        @Prop({default: null})
        displayName: string;
            
        @Prop()
        active: boolean;
        
        @Prop({default: false})
        invert: boolean;
        
        @Prop({default: "Ja"})
        activeLabel: string;
        
        @Prop({default: "Nej"})
        inactiveLabel: string;
        
        @Prop({default: false})
        disabled: boolean;
        
        get isActive(): boolean {
            return this.invert ? !this.active : this.active; 
        }
        
        handleEmitEvent(newValue: boolean) {
            this.$emit('filter-radio-click',{"filter":this.filterName, "value": this.invert ? !newValue : newValue});
        }
    }
