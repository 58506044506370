
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import StatusIcon from "@/components/StatusIcon.vue";

/**
 * This component represents a table cell with a status icon and some text.
 */
@Component({
    components: {StatusIcon}
})
export default class StatusIconCell extends Mixins(SvgConstants) {
    /**
     * The status. If set to "WARNING" or "INFO", the appropriate icon is
     * displayed. Using the value "NONE" will remove the icon html and any other
     * string will render an empty span, which can be useful in some layouts.
     */
    @Prop()
    status: string;

    /**
     * A class to add when displaying the info icon.
     */
    @Prop({default: "icon_gray"})
    infoClass: string;

    /**
     * Set to true in order to use a "light" info icon.
     */
    @Prop({default: true})
    light: boolean;
}
