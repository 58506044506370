
import {Component, Mixins, Watch} from 'vue-property-decorator';
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import SidebarRightMonitorListEntry from "@/components/SidebarRightMonitorListEntry.vue";
import SidebarRightMonitorListListFile from "@/components/SidebarRightMonitorListListFile.vue";
import SidebarRightMonitorUserConfig from "@/components/SidebarRightMonitorUserConfig.vue";
import {
    EndUserParameters,
    isSelfAutoMonitorEnabled,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {AutoSelfPriorityFields, FrontendMonitorConfig} from "@/models/frontend-monitor-config";
import {
    ACTION_FETCH_MONITORS,
    ACTION_IMPORT_MONITOR_LIST,
    ACTION_UPDATE_SELF_AUTO_MONITOR_ENABLED
} from "@/store/store-monitor";

import {ACTION_GET_USER_CONFIG} from "@/store/store-notification";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import SvgConstants from "@/mixins/svg-constants";
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import SidebarRightMonitorDropdownList from "@/components/SidebarRightMonitorDropdownList.vue";
import {MUTATION_SIDEBAR_RIGHT_STATUS} from "@/store/store";
import Dropdown from "@/components/Dropdown.vue";
import AutoSelfPriorityPicker from "@/components/AutoSelfPriorityPicker.vue";

/**
 * This component represents the list of monitors in the right sidebar.
 */
@Component({
    components: {
        AutoSelfPriorityPicker,
        Dropdown,
        SidebarRightMonitorDropdownList,
        ProgressBar,
        SidebarRightMonitorListEntry,
        SidebarRightMonitorListListFile,
        SidebarRightMonitorUserConfig,
        SidebarRightCloseButton,
        ErrorMessage
    },
})
export default class SidebarRightMonitorSettings extends Mixins(StateHelper, SvgConstants, Utils) {
    /**
     * Which type of monitor we're currently showing.
     */
    monitorType: string = "manual";

    selfMonitorEnabled: boolean = false;

    uploadErrorMessage: string = "";

    uploadInProgress: boolean = false;

    draggedFileOver: boolean = false;

    draggedEnterCount: number = 0;

    disableToggles: boolean = false;

    uploadPriority: string = "NORMAL";

    mounted() {
        this.tryGetMonitorConfigAndMaybeMonitors();
        this.selfMonitorEnabled = this.activeUser && isSelfAutoMonitorEnabled(this.activeUser);
    }

    tryGetMonitorConfigAndMaybeMonitors() {
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success && this.allowManageMonitor) {
                this.ignoreError(this.$store.dispatch(ACTION_GET_USER_CONFIG));
                if (this.monitors.manual.length === 0) {
                    this.ignoreError(this.$store.dispatch(ACTION_FETCH_MONITORS));
                }
            }
        });
    }

    selfAutoConfigOptions(): { description: string, field: AutoSelfPriorityFields }[] {
        return [
            {description: "Dig själv", field: "autoSelfPriority"},
            {description: "Andra i ditt hushåll", field: "autoSelfHouseholdPriority"},
            {description: "Din adressplats", field: "autoSelfAddressPriority"},
            {description: "Dina fastigheter", field: "autoSelfRealPropertyPriority"},
            {description: "Dina fordon", field: "autoSelfVehiclePriority"},
            {description: "Företag du är inblandad i", field: "autoSelfCompanyPriority"},
            {description: "Personer bakom dina fastigheter", field: "autoSelfBehindRealPropertyPriority"},
            {description: "Personer bakom dina företag", field: "autoSelfBehindCompanyPriority"},
        ]
    }

    /**
     * Make sure we update the monitor list whenever the active user changes.
     *
     * @param val The new active user. May be undefined.
     * @param oldVal The old active user. May be undefined.
     */
    @Watch("$store.state.activeUser")
    onActiveUserChange(val: EndUserParameters, oldVal: EndUserParameters) {
        if (!(val && val.refNo && oldVal && oldVal.refNo && val.refNo === oldVal.refNo) && this.allowManageMonitor) {
            this.ignoreError(this.$store.dispatch(ACTION_GET_USER_CONFIG));
            this.ignoreError(this.$store.dispatch(ACTION_FETCH_MONITORS));
        }
        this.selfMonitorEnabled = val && isSelfAutoMonitorEnabled(val);
    }

    /**
     * Makes sure we update the config when the subscription changes.
     * This also handles the initial loading of the config.
     */
    @Watch("$store.state.activeSubscription")
    onActiveSubscriptionExists(val: SubscriptionParameters, oldVal: SubscriptionParameters) {
        if (!oldVal && !!val || (!!val && val.refNo != oldVal.refNo)) {
            this.tryGetMonitorConfigAndMaybeMonitors();
        }
        this.selfMonitorEnabled = this.activeUser && isSelfAutoMonitorEnabled(this.activeUser);
    }

    /**
     * Make sure we remove error message and drag highligth, and restore active
     * tab whenever the sidebar panel is changed.
     */
    @Watch("$store.state.sidebarRightStatus")
    onSwitchSidebar() {
        this.uploadErrorMessage = "";
        this.uploadInProgress = false;
        if (this.sidebarRightStatus.startsWith("monitor-list")) {
            if (this.sidebarRightStatus.endsWith(":settings")) {
                this.monitorType = "settings";
            } else if (this.sidebarRightStatus.endsWith(":lists")) {
                this.monitorType = "lists";
            } else if (this.sidebarRightStatus.endsWith(":auto")) {
                this.monitorType = "auto";

            }

        }
        this.draggedFileOver = false;
        this.draggedEnterCount = 0;
    }

    /**
     * Make sure we remove error message and drag highlight whenever the tab is
     * switched.
     */
    @Watch("monitorType")
    onSwitchMonitorTab() {
        this.uploadErrorMessage = "";
        this.uploadInProgress = false;
        this.draggedFileOver = false;
        this.draggedEnterCount = 0;
        let action: string = "monitor-list:" + this.monitorType;

        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, action);
    }

    importList(files: any, htmlInputElementEvent: Event) {
        this.uploadErrorMessage = "";
        if (files && files.length === 1) {
            this.uploadInProgress = true;
            this.$store.dispatch(ACTION_IMPORT_MONITOR_LIST, {
                "priority": this.uploadPriority,
                "listFile": files[0]
            }).catch((response) => {
                /*
                  Add a default error message here, since the error message the
                  server produces may often get lost if the file is too large,
                  since the embedded Tomcat cuts the connection on too large
                  uploads.
                 */
                this.uploadErrorMessage = this.extractErrorMessage(response, "Något gick fel. Kan filen vara för stor? Max tillåten storlek är 32 MB.");
            }).finally(() => {
                // clear the value of the <input> element
                if (htmlInputElementEvent != null) {
                    (htmlInputElementEvent.target as HTMLInputElement).value = null;
                }
                this.uploadInProgress = false;
            });
        }
    }

    importClickedList(event: Event) {
        let files = (event.target as HTMLInputElement).files;
        this.importList(files, event);
    }

    importDraggedList(event: DragEvent) {
        this.draggedFileOver = false;
        this.draggedEnterCount = 0;
        let files = event.dataTransfer.files;
        this.importList(files, null);
    }

    updateSelfAutoMonitorEnabled() {
        let enabled = this.selfMonitorEnabled;
        this.ignoreError(this.$store.dispatch(ACTION_UPDATE_SELF_AUTO_MONITOR_ENABLED, enabled));
    }

    dragEnter() {
        this.draggedEnterCount++;
        this.draggedFileOver = true;
    }

    dragLeave() {
        this.draggedEnterCount--;
        if (this.draggedEnterCount <= 0) {
            this.draggedEnterCount = 0;
            this.draggedFileOver = false;
        }
    }

    clickAutoSelfPriorityChange(priorityField: AutoSelfPriorityFields) {
        let newValue = this.getNextMonitorPriorityValue(this.userMonitorConfig[priorityField], true);
        this.updateConfig((config: FrontendMonitorConfig) => config[priorityField] = newValue);
    }

    async updateConfig(callback: (config: FrontendMonitorConfig) => void): Promise<void> {
        if (this.disableToggles) {
            return;
        }
        this.disableToggles = true;

        return this.updateUserMonitorConfig(callback).then(() => {
            this.disableToggles = false;
        });
    }

    clickUploadPriority() {
        this.uploadPriority = this.getNextMonitorPriorityValue(this.uploadPriority);
    }

    get loaded(): boolean {
        return !!this.userMonitorConfig;
    }

    get monitorsLoading(): boolean {
        return this.$store.state.monitor.loading;
    }
}
