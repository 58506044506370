
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityCommitmentList
    from "@/components/SearchPageEntityCommitmentList.vue";
import {SearchResultCommitment} from "@/models/search-result-commitment";
import ProgressBar from "@/components/ProgressBar.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import {
    SearchResultHistoricalCommitmentCompany
} from "@/models/search-result-historical-commitment-company";
import SearchPageEntityHistoricalCommitmentsList
    from "@/components/SearchPageEntityHistoricalCommitmentsList.vue";

/**
 * This is a list component showing either current or historical commitments
 * depending on the state of the "showCurrent" variable.
 */
@Component({
    components: {
        SearchPageEntityHistoricalCommitmentsList,
        ProgressBar,
        ErrorMessage,
        SearchPageEntityCommitmentList
    }
})
export default class SearchPageEntityBoardCommitmentsLists extends Mixins(StateHelper, Utils) {
    /**
     * The list of current commitments.
     */
    @Prop()
    commitments: SearchResultCommitment[];

    /**
     * The list of historical commitments.
     */
    @Prop()
    historicalCommitments: SearchResultHistoricalCommitmentCompany[];

    /**
     * True if we're currently showing current commitments and false when
     * showing historical commitments.
     */
    @Prop()
    showCurrent: boolean;

    /**
     * Set to true if the table header should be shown.
     */
    @Prop()
    showHeader: boolean;

    /**
     * Set to true if the table footer should be shown.
     */
    @Prop({default: true})
    showFooter: boolean;

    /**
     * If there is an error message during fetching of historical commitments,
     * it should be stored here.
     */
    @Prop()
    errorMessage: string;

    @Prop()
    level: number;

    /**
     * Returns true if any of the individual's commitments has the
     * beneficial owner flag set.
     */
    get anyBeneficialOwner(): boolean {
        return this.commitments.find(c => c.beneficialOwner) !== undefined;
    }
}
