
import {Component, Mixins, Watch} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import {Global} from "@/global";
import SvgConstants from "@/mixins/svg-constants";
import V2CustomInput from "@/components_v2/V2CustomInput.vue";
import {SubscriptionParameters} from "@/models/end-user-subscription-parameters";
import Validation, {
    ValidationFunction,
    ValidationStatus
} from "@/mixins/validation";
import V2Header from "@/components_v2/V2Header.vue";
import CustomerSupportPhoneNumber
    from "@/components/CustomerSupportPhoneNumber.vue";
import CustomerSupportEmailLink
    from "@/components/CustomerSupportEmailLink.vue";
import {HTTP} from "@/services/http-provider";
import {EndUserSubscriptionOperationResponse} from "@/models/end-user-subscription-operation-response";
import {AxiosResponse} from "axios";
import Utils from "@/mixins/utils";
import {MUTATION_ACTIVE_SUBSCRIPTION} from "@/store/store";

@Component({
    components: {
        CustomerSupportEmailLink,
        CustomerSupportPhoneNumber,
        V2Header, V2CustomInput
    },
})
export default class Subscribe extends Mixins(StateHelper, SvgConstants, Utils, Validation) {
    params: SubscriptionParameters = new SubscriptionParameters();

    /**
     * Mapping from the name of the field in the SubscriptionParameters object
     * to the validation function for that field.
     */
    validation: any = {
        companyName: this.vAllOf(this.vLength(1, 250), this.externalValidation("companyName")),
        organisationNumber: this.vAllOf(this.vAllowEmpty(this.vModulo10CheckSum()), this.externalValidation("organisationNumber")),
        paymentEmail: this.vAllOf(this.vEmail(), this.vMaxLength(250), this.externalValidation("paymentEmail")),
        address1: this.vAllOf(this.vLength(1, 250), this.externalValidation("address1")),
        zip: this.vAllOf(this.vZipCode(), this.externalValidation("zip")),
        city: this.vAllOf(this.vLength(1, 250), this.externalValidation("city")),
    };

    /**
     * Mapping from the name of the field to a human readable string. See
     * Validation#runValidationFunctions for more info.
     */
    private fieldName: any = {
        companyName: "Företagsnamnet",
        organisationNumber: "Organisationsnumret",
        paymentEmail: "E-postadress för fakturering",
        address1: "Adressen",
        zip: "Postnumret",
        city: "Postorten",
    };

    /**
     * Mapping from the name of the field to an error message for that field.
     * These can be set both during frontend validation but also when receiving
     * a validation error message from the server. We also have a 'generic'
     * mapping to which other types of errors can be mapped. The error message
     * shown below the fields is composed of these messages. Notice that it is
     * important to initialize all mappings since Vue otherwise can't track when
     * they change.
     */
    private complaints: any = {
        generic: "",
        companyName: "",
        organisationNumber: "",
        paymentEmail: "",
        address1: "",
        zip: "",
        city: "",
    };

    step: number = 1;

    showDetailed: boolean = false;


    // noinspection JSMethodCanBeStatic
    mounted(): void {
        Global.setPrefixedTitle("Bli kund");
        if (this.activeSubscription) {
            this.setParamsFromActiveSubscription();
        } else {
            this.$store.state.appLoaded.then(() => {
                if (!this.activeSubscription) {
                    this.$router.push("/");
                } else {
                    this.setParamsFromActiveSubscription();
                }
            });
        }
    }

    /**
     * Copy parameters from the active subscription to our model object.
     */
    setParamsFromActiveSubscription(): void {
        this.params.organisationNumber = this.activeSubscription.organisationNumber;
        this.params.companyName = this.activeSubscription.companyName;
        this.params.address1 = this.activeSubscription.address1;
        this.params.address2 = this.activeSubscription.address2;
        this.params.address3 = this.activeSubscription.address3;
        this.params.zip = this.activeSubscription.zip;
        this.params.city = this.activeSubscription.city;
        this.params.paymentEmail = this.activeUser.email;
    }

    /**
     * Returns true if the given address is invalid.
     */
    get addressInvalid(): boolean {
        return !!this.complaints.address1;
    }

    /**
     * Compile an error message from our complaints map. Use the field names
     * in the fieldName map to produce a human readable text.
     */
    get errorMessage(): string {
        return this.createErrorMessage(this.complaints);
    }

    /**
     * Make sure we update the parameters if the user changes them in the right
     * side bar. Also call fieldChange in order to update validation status.
     *
     * @param val The new active subscription. May be undefined.
     * @param oldVal The old active subscription. May be undefined.
     */
    @Watch("$store.state.activeSubscription")
    onActiveSubscriptionChange(val: SubscriptionParameters, oldVal: SubscriptionParameters) {
        this.$nextTick(() => {
            this.setParamsFromActiveSubscription();
            this.fieldChange("companyName");
            this.fieldChange("organisationNumber");
            this.fieldChange("paymentEmail");
            this.fieldChange("address1");
            this.fieldChange("zip");
            this.fieldChange("city");
        });
    }

    /**
     * Toggles the show detailed flag and sets focus on the correct input field.
     */
    toggleShowDetailed(): void {
        this.showDetailed = !this.showDetailed;
        let focusId: string = this.showDetailed ? "subscribe-organisation-number" : "subscribe-company-name";
        this.$nextTick(() => this.setFocusOnId(focusId));
    }

    /**
     * Changes to step 2 and sets proper focus.
     */
    goToStep2(): void {
        this.step = 2;
        /*
          Set focus on first input field. Notice that we have to introduce a
          zero ms delay here since the input field does not seem to exist before
          that.
         */
        if (!Utils.isMobile()) {
            setTimeout(() => this.setFocusOnId("subscribe-company-name"), 0);
        }
    }

    /**
     * This method returns a validation function for the given field name
     * that will fail if there is a complaint for that field in the
     * complaints map. Thus, when we store complaints from the backend in
     * that map, the corresponding field will be marked as invalid as well.
     *
     * @param field The name of the field in the SubscriptionParameters object.
     */
    externalValidation(field: string): ValidationFunction {
        return () => this.complaints[field] ? ValidationStatus.FORCE_INVALID : ValidationStatus.VALID;
    }

    /**
     * Remove the error message for the given field (if it is still valid),
     * as well as any generic error message. This will be triggered on the
     * change event for an input field.
     *
     * @param fieldName The field in the SubscriptionParameters object.
     */
    fieldChange(fieldName: string) {
        this.onFieldChange(this.validation, this.complaints, fieldName, this.params);
    }

    validateAndSubmit(): void {
        this.complaints.generic = null;

        let actualValidation = this.validation;

        let requireAddress: boolean = this.params.paymentDeliveryMethod === "PRINT";
        if (!requireAddress) {
            actualValidation = {
                companyName: this.validation.companyName,
                organisationNumber: this.validation.organisationNumber
            };
            this.complaints.generic = "";
            this.complaints.paymentEmail = "";
            this.complaints.address1 = "";
            this.complaints.zip = "";
            this.complaints.city = "";
        }

        // Run all validation functions and store error messages for those that fail.
        this.runValidationFunctions(actualValidation, this.complaints, this.fieldName, this.params);

        if (!this.errorMessage) {
            this.step = 3;
            this.params.refNo = this.activeSubscriptionRefNo;
            HTTP.post<EndUserSubscriptionOperationResponse>("/sapi/subscription/subscribe-self-service?requireAddress=" + requireAddress, this.params).then((response) => {
                this.$store.commit(MUTATION_ACTIVE_SUBSCRIPTION, response.subscriptionParameters);
                setTimeout(() => {
                    this.$router.push({
                        name: "welcome",
                        params: {
                            activatedSelfService: "true"
                        }
                    });
                }, 0);
            }).catch((response: AxiosResponse) => {
                this.step = 2;
                this.handleErrorMessage(this.complaints, response.data);
                if (!this.errorMessage) {
                    this.complaints.generic = "Något gick fel. Vänligen försök igen eller kontakta kundtjänst."
                }
            });
        }
    }
}
