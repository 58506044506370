import {MonitoredEntity} from "@/models/monitored-entity";
import {MonitorList} from "@/models/monitor-list";

/**
 * See documentation for the corresponding java class.
 */
export class UserMonitors {
    manual: MonitoredEntity[];
    automatic: MonitoredEntity[];
    selfAutomatic: MonitoredEntity[];
    lists: MonitorList[];

    constructor(manual: MonitoredEntity[], automatic: MonitoredEntity[], selfAutomatic: MonitoredEntity[], excludedFromAuto: MonitoredEntity[], lists: MonitorList[]) {
        this.manual = manual;
        this.automatic = automatic;
        this.selfAutomatic = selfAutomatic;
        this.lists = lists;
    }
}
