
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";

/**
 * This component represents a tooltip. See property documentation for usage
 * instructions.
 */
@Component
export default class Tooltip extends Mixins(StateHelper) {
    /**
     * Decides which part of the tooltip box should be "attached" to the
     * element it belongs to. Valid values are
     *   topLeft
     *   topRight
     *   bottomLeft
     *   bottomRight
     */
    @Prop({default: "bottomLeft"})
    attachAt: string;

    /**
     * Setting this property to something falsy makes it possible to avoid
     * showing the tooltip even though the mouse is hovering above the relevant
     * component.
     */
    @Prop({default: true})
    onlyShowWhen: boolean;

    /**
     * Setting this property overrides the default behavior, and only shows the
     * tooltip when this property is true. May be reset to "undefined" if the
     * standard behavior is desired again.
     */
    @Prop({default: undefined})
    forceShowWhen: boolean;

    /**
     * True when the mouse is hovering above the tooltip component.
     */
    mouseOver: boolean = false;


    /**
     * Returns true if we should show the tooltip. If the user has specified the
     * forceShowWhen property, it overrides the normal behavior. It is possible
     * to reset it to "undefined" if the normal behavior is desired again.
     */
    get isActive(): boolean {
        if (this.forceShowWhen === undefined) {
            return this.mouseOver && this.onlyShowWhen;
        } else {
            return this.forceShowWhen;
        }
    }

    /**
     * Gets the list of dynamic classes to add.
     */
    get classes(): string[] {
        let ret: string[] = [this.attachAtToClass];
        if (this.isActive) {
            ret.push("active");
        }
        return ret;
    }

    /**
     * Maps the attatchAt string to the correct css class.
     */
    get attachAtToClass(): string {
        if (!this.attachAt) {
            return "";
        }
        switch (this.attachAt) {
            case "topLeft":
            case "tl":
                return "tooltipTopLeft";
            case "topRight":
            case "tr":
                return "tooltipTopRight";
            case "bottomLeft":
            case "bl":
                return "tooltipBottomLeft";
            case "bottomRight":
            case "br":
                return "tooltipBottomRight";
        }
        return "";
    }

    /**
     * Gets the style map. We have to have a changeable style map since we want
     * to increase the z-index when the mouse is hovering over the component. If
     * we don't do that, overlapping tooltip components may "bleed" into each
     * other, so that the trigger area of one component is visible through the
     * tooltip text of the one that the mouse is hovering over.
     */
    get styleMap(): any {
        let map: any = {};
        map["z-index"] = this.mouseOver ? 1000 : 0;
        return map;
    }
}
