
    import {Component, Mixins} from 'vue-property-decorator';
    import HomePage from "@/views/HomePage.vue";
    import {MUTATION_POPUP_MESSAGE_STATUS} from "@/store/store";
    import StateHelper from "@/mixins/state-helper";
    import {Global} from "@/global";

    /**
     * This is the page the user is redirected to after a successful login. It shows
     * a dialog in the middle of the screen with an appropriate message.
     */
    @Component({
        components: {
            HomePage
        },
    })
    export default class WelcomePage extends Mixins(StateHelper) {
        // noinspection JSMethodCanBeStatic
        mounted(): void {
            Global.setPrefixedTitle("Välkommen");
            let suffix: string = "";
            if (this.$route.params.activatedSelfService) {
                suffix = ":activatedSelfService";
            }
            this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "welcome" + suffix);
        }
    }
