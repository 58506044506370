
import {Component, Mixins} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import PopupMessageCloseButton
    from "@/components/PopupMessageCloseButton.vue";
import StateHelper from "@/mixins/state-helper";
import {MUTATION_ACTIVE_USER} from "@/store/store";
import Validation, {ValidationStatus} from "@/mixins/validation";
import {
    EndUserParameters,
    EndUserSubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {HTTP} from "@/services/http-provider";
import {EndUserSubscriptionOperationResponse} from "@/models/end-user-subscription-operation-response";
import {AxiosResponse} from "axios";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Utils from "@/mixins/utils";
import {Dictionary} from "vue-router/types/router";
import Logo from "@/components/Logo.vue";
import {signOutRedirect} from "@/services/auth-service";


/**
 * This component represents the popup for setting a user's personal number, if the user
 * is missing it, and their subscription does not allow that. It is shown when setting
 * the popup message status "personal-number". See App.vue for when this is triggered
 * to show.
 */
@Component({
    components: {Logo, ErrorMessage, PopupMessageCloseButton}
})
export default class PopupMessageWelcome extends Mixins(SvgConstants, StateHelper, Utils, Validation) {
    personalNumber: string = "";

    step: string = "1";

    errorMessage: string = "";

    mounted() {
        let params: Dictionary<string> = this.$route.params;
    }

    /**
     * Sets the active step in the registration process.
     *
     * @param step The new step. This must coincide with the show_step_* classes in
     *             the css.
     */
    setStep(step: string): void {
        this.step = step;
    }

    /**
     * Getter for the css class that determines which of the steps in the registration
     * process we're displaying. Thus, the step names must coincide with the css
     * classes.
     */
    get activeStepClass(): string {
        return "show_step" + this.step;
    }

    /**
     * Sets the personal number for the active user. Displays error message if the personal number
     * does not pass validation. Navigates to the next step on successful completion.
     */
     submit(): void {
        this.errorMessage = "";
        if (!this.personalNumber || this.vModulo10CheckSum()(this.personalNumber) !== ValidationStatus.VALID) {
            this.errorMessage = "Personnumret är inte korrekt.";
            return;
        }

        let userToSend: EndUserParameters = this.copy(this.activeUser);
        userToSend.personalNumber = this.personalNumber;
        let body: EndUserSubscriptionParameters = new EndUserSubscriptionParameters();
        body.endUserParameters = userToSend;
        body.subscriptionParameters = this.activeSubscription;
        HTTP.post<EndUserSubscriptionOperationResponse>("/sapi/subscription/set-user-personal-number", body).then((response: EndUserSubscriptionOperationResponse) => {
            this.$store.commit(MUTATION_ACTIVE_USER, response.endUserParameters);
            this.setStep("2");
        }).catch((response: AxiosResponse) => {
            let complaints: any = {personalNumber: false, generic: false};
            this.handleErrorMessage(complaints, response.data);
            if (complaints["personalNumber"]) {
                this.errorMessage = complaints["personalNumber"];
            } else if (complaints["generic"]) {
                this.errorMessage = complaints["generic"];
            }

            if (!this.errorMessage) {
                this.errorMessage = "Något gick fel. Försök igen eller sätt personnumret vid ett senare tillfälle."
            }
        });
    }

    /**
     * Closes the dialog. Shows the choose subscription sidebar if necessary.
     */
    close(): void {
        this.personalNumber = "";
        this.step = "1";
        this.errorMessage = "";
        this.setFocusOnSearchInputField(true);
        this.$emit("close");
    }

    /**
     * Signs the user out. Used if the user does not want to / cannot enter a personal number.
     */
    signOut(): void {
      signOutRedirect();
    }
}
