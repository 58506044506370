
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SelectOnClick from "@/components/SelectOnClick.vue";

/**
 * This component represents a vehicle property. If the property has a truthish
 * value, it is shown, but otherwise we may show a default "missing value"
 * string instead.
 */
@Component({
    components: {SelectOnClick}
})
export default class VehicleProperty extends Mixins(Utils) {
    /**
     * The label.
     */
    @Prop()
    label: string;

    /**
     * The value.
     */
    @Prop()
    value: number | string;

    /**
     * The value to display if the given value is missing. May be undefined if
     * no such value should be displayed.
     */
    @Prop()
    missingValue: string;
}
