
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import SvgConstants from "@/mixins/svg-constants";
    import {MUTATION_POPUP_MESSAGE_STATUS} from "@/store/store";
    import StateHelper from "@/mixins/state-helper";

    /**
     * This component represents the top right close button in a popup message modal. It
     * emits a "close" event when clicked, containing the popup message status before
     * closing.
     */
    @Component({
        components: {}
    })
    export default class PopupMessageCloseButton extends Mixins(StateHelper, SvgConstants) {
        /**
         * Set to true if the close button should trigger a "close" event instead of
         * directly closing the dialog.
         */
        @Prop()
        triggerEventInsteadOfClose: boolean;


        closePopup(): void {
            if (this.triggerEventInsteadOfClose) {
                this.$emit("close", this.popupMessageStatus);
            } else {
                this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "");
            }
        }
    }
