import {ActionTree, Module} from "vuex";
import {resetState, RootState} from "@/store/store";
import {PaymentParameters} from "@/models/end-user-subscription-parameters";
import {Global} from "@/global";

export const MUTATION_PAYMENTS = "payments";
export const ACTION_SET_PAYMENTS = "payments/setPayments";

export interface PaymentState {
    paymentList: PaymentParameters[];
}

export const actions: ActionTree<PaymentState, RootState> = {
    setPayments(context, payments: PaymentParameters[]): void {
        context.commit(MUTATION_PAYMENTS, payments);
    }
};

/**
 * Returns the initial state of this store.
 */
function initialState(): PaymentState {
    return {
        paymentList: []
    };
}


/**
 * This module handles the freerider list as well as the actions that are
 * possible to perform upon it.
 */
export const paymentsModule: Module<PaymentState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        /**
         * Sets the complete list of payments.
         *
         * @param state The state.
         * @param payments The new set of payments.
         */
        [MUTATION_PAYMENTS]: (state: PaymentState, payments: PaymentParameters[]) => state.paymentList = payments,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: PaymentState) => resetState(state, initialState()),
    },
    actions: actions
};

