
export class FrontendUserWebAppSettings {

    displayRPOwnershipAsFraction: boolean = null;
    
    displayBirthYearInResultList: boolean = null;
    
    showOutdatedCompanies: boolean = null;

    showOutdatedPersons: boolean = null;

    permanentExpertMode: boolean = null;
    
    constructor(settings: FrontendUserWebAppSettings){
        if(settings == null) {
            // do nothing            
        } else {
            this.displayRPOwnershipAsFraction = settings.displayRPOwnershipAsFraction;
            this.displayBirthYearInResultList = settings.displayBirthYearInResultList;
            this.showOutdatedCompanies = settings.showOutdatedCompanies;
            this.showOutdatedPersons = settings.showOutdatedPersons;
            this.permanentExpertMode = settings.permanentExpertMode;
        }
    }
 }