
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {ValidationStatus} from "@/mixins/validation";


    /**
     * This component represents a custom input field to which it is possible to attach a
     * validation function that marks the field as valid or invalid.
     */
    @Component
    export default class CustomInput extends Vue {
        /**
         * The model value backing the input field.
         */
        @Prop()
        value: string;

        /**
         * The label.
         */
        @Prop()
        label: string;

        /**
         * The input type, for example "text" or "password". Defaults to text.
         */
        @Prop({default: "text"})
        type: string;

        /**
         * Set to true if the input field should be disabled.
         */
        @Prop({default: false})
        disabled: boolean;

        /**
         * This property should be a ValidationFunction that thus returns true when the
         * value is valid, and false otherwise.
         */
        @Prop()
        validation: any;

        /**
         * Use this property to set the autocomplete value of the input field.
         */
        @Prop()
        autocomplete: string;

        /**
         * This flag is set to true on the first change event. Before this happens,
         * neither the valid nor invalid classes are set on the label and thus no
         * validation markers are shown. The user can thus type in the field before being
         * noticed about the validity.
         */
        touched: boolean = false;
        
        lastInput: number = null;

        /**
         * True if the input field has been touched and the value is valid.
         */
        get isValid(): boolean {
            return this.touched && this.validation && this.validation(this.value) === ValidationStatus.VALID;
        }

        /**
         * True if the input field has been touched and the value is invalid.
         */
        get isInvalid(): boolean {
            let status: ValidationStatus = this.validation ? this.validation(this.value) : undefined;
            return this.touched && this.validation && status === ValidationStatus.INVALID || status === ValidationStatus.FORCE_INVALID;
        }

        /**
         * Marks the input as touched and emits the original event to our parents.
         *
         * @param $event The original event.
         */
        onChange($event: any): void {
            this.touched = true;
            this.$emit('change', $event.target.value)
        }
        
        /**
         * Emits the original event to our parents.
         *
         * @param $event The original event.
         */
        onInput($event: any): void {
      	    this.$emit('input', $event.target.value)
        }
    }
