
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import {DATE_FILTER, GIVEN_NAME_FILTER} from "@/services/filters";
import {
    SearchResultHouseholdMember
} from "@/models/search-result-household-member";
import {
    MapAddress,
    MapPosition
} from "@/components/SearchPageEntityInfoMap.vue";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoExpandableMap
    from "@/components/SearchPageEntityInfoExpandableMap.vue";
import SearchPageEntityNeighborList
    from "@/components/SearchPageEntityNeighborList.vue";
import SearchPageEntityRealPropertiesList
    from "@/components/SearchPageEntityRealPropertiesList.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import SearchPageEntityVehicleList
    from "@/components/SearchPageEntityVehicleList.vue";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import SearchPageEntityHouseholdMemberListWithHeading
    from "@/components/SearchPageEntityHouseholdMemberListWithHeading.vue";
import SearchPageEntityEventList
    from "@/components/SearchPageEntityEventList.vue";
import {ClickEventData} from "@/views/SearchPage.vue";
import SearchPageEventFilterSettings
    from "@/components/SearchPageEventFilterSettings.vue";
import {DisplayableEvent} from "@/models/displayable-event";
import SearchPageEntitySimpleExpandable
    from "@/components/SearchPageEntitySimpleExpandable.vue";
import FormattedText from "@/components/FormattedText.vue";
import {
    SearchResultTradingProhibition
} from "@/models/search-result-trading-prohibition";
import StatusIconCell from "@/components/StatusIconCell.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import SearchPageEntityBoardCommitments
    from "@/components/SearchPageEntityBoardCommitments.vue";

/**
 * This component represents the info tab for individuals in a search result details
 * view.
 */
@Component({
    components: {
        SearchPageEntityBoardCommitments,
        StatusIcon,
        StatusIconCell,
        FormattedText,
        SearchPageEntitySimpleExpandable,
        SearchPageEntityEventList,
        SearchPageEntityHouseholdMemberListWithHeading,
        SearchPageEntityInfoHeader,
        SearchPageEntityExpandable,
        SearchPageEntityNeighborList,
        SearchPageEntityRealPropertiesList,
        SearchPageEntityInfoExpandableMap,
        SearchPageEntityLink,
        SelectOnClick,
        SearchPageEntityVehicleList,
        SearchPageEventFilterSettings
    }
})
export default class SearchPageEntityIndividualInfo extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    level: number;

    @Prop()
    individual: SearchResultEntryIndividualDetails;

    @Prop()
    eventFilters: string[];

    expandedMap: boolean = false;

    mapPosition: MapPosition = null;


    /**
     * Helper for formatting the name.
     */
    get firstNames(): string {
        let firstNames = GIVEN_NAME_FILTER(this.individual.firstNames, this.individual.givenNameCode);
        return `${firstNames}`;
    }

    /**
     * Gets the deregistration date.
     */
    get deregisteredDate(): string {
        return DATE_FILTER(this.individual.deregisteredDate);
    }

    /**
     * Gets addresses to be shown on the map.
     */
    get mapAddresses(): MapAddress[] {
        let ret: MapAddress[] = [];
        if (this.hasFbfAddress(this.individual)) {
            ret.push(new MapAddress("fbf", this.fbfAddressTag, this.individual.fbfCoord));
        }
        if (this.hasSpAddress(this.individual)) {
            ret.push(new MapAddress("sp", this.spAddressTag, this.individual.spCoord));
        }
        return ret;
    }

    /**
     * Gets the contents of the fbf address tag as html.
     */
    get fbfAddressTag(): string {
        if (this.individual.fbfStreetAddress) {
            return [this.individual.fbfStreetAddress, this.individual.fbfCity].join("<br>");
        } else if (this.individual.fbfCity) {
            return this.individual.fbfCity;
        } else {
            return this.individual.fbfRegion;
        }
    }

    /**
     * Gets the contents of the sp address tag as html.
     */
    get spAddressTag(): string {
        if (this.individual.spStreetAddress) {
            return [this.individual.spStreetAddress, this.individual.spCity].join("<br>");
        } else {
            return this.individual.spCity;
        }
    }

    /**
     * Produces an id string for the "Alla händelser" anchor including the level, in
     * order to be able to navigate to the anchor in the correct tab.
     */
    get allEventsId(): string {
        return "alla-handelser-" + this.level;
    }

    get filteredEvents(): DisplayableEvent[] {
        return this.nn(this.individual.events).filter((event: DisplayableEvent) => this.filterEvent(this.individual.id, event.type, this.eventFilters));
    }

    /**
     * Returns the header for the other family relations list, depending on if
     * we have ordinary household members or not.
     */
    get otherFamilyRelationsHeading(): string {
        return this.individual.householdMembers.length || this.individual.marriedTo ? "Andra familjemedlemmar" : "Familjemedlemmar"
    }

    /**
     * Returns deceased date and age when deceased if the necessary information
     * is available.
     */
    get deceasedDateAndTextAsHtml(): string {
        let ret: string = "Avliden";
        if (this.individual.ageWhenDeceased) {
            ret += " vid " + this.individual.ageWhenDeceased + " års ålder";
        }
        if (this.individual.deregisteredDate) {
            ret += " " + this.wrapToNoLineBreak("(" + this.individual.deregisteredDate + ")");
        }
        return ret;
    }

    /**
     * Helper for extracting the complete name of a household member.
     *
     * @param member The member.
     */
    hhMemberName(member: SearchResultHouseholdMember): string {
        let firstNames = GIVEN_NAME_FILTER(member.firstNames, member.givenNameCode, "extract-with-fallback");
        return `${firstNames} ${member.lastNames}`;
    }

    /**
     * Returns the heading for the fbf address section.
     */
    fbfAddressHeading(): string {
        return "Folkbokföringsadress" + (this.individual.fbfDate ? " sedan " + this.individual.fbfDate : "");
    }

    /**
     * Emits an "open-entity-view" event after setting the address location
     * filter if the address refers to a specific apartment.
     *
     * @param event Reference to object to open alongside with the event that caused the opening.
     * @param apartment The apartment number.
     */
    @Emit()
    openEntityView(event: ClickEventData<OpenEntityRequest>, apartment: string = ""): ClickEventData<OpenEntityRequest> {
        if (apartment) {
            event.data.addressLocationFilter = Number(apartment);
        }
        return event;
    }

    /**
     * Formats the PEP and sanction string.
     */
    get formatPEPAndSanction(): string {
        const pep: boolean = this.individual.pepType !== "NONE" && this.allowPEP;
        const sanction: boolean = this.individual.sanctionLists.filter((list) => list.property).length > 0 && this.allowSanctionIndividual;
        const pepPrefix: string = this.individual.pepType === "SECOND_ORDER" ? "relaterad till " : "";
        if (pep && sanction) {
            return "Ja, " + pepPrefix + "PEP och sanktion";
        } else if (pep) {
            return "Ja, " + pepPrefix + "PEP";
        } else if (sanction) {
            return "Ja, sanktion";
        } else {
            return this.EM_DASH;
        }
    }

    /**
     * Formats the trading prohibition string.
     */
    get formatTradingProhibition(): string {
        let tradingProhibition: SearchResultTradingProhibition = this.individual.tradingProhibition;
        if (tradingProhibition) {
            let dateSuffix: string = tradingProhibition.endDate ? " t.o.m. " + this.wrapToNoLineBreak(tradingProhibition.endDate) : "";

            if (tradingProhibition.temporary) {
                return "Ja, tillfälligt" + dateSuffix;
            } else {
                return "Ja" + (dateSuffix ? ", " + dateSuffix : "");
            }
        }

        // We should not end up here.
        return "";
    }

    /**
     * True if we should show the "Gift med" section.
     */
    get showMarriedTo(): boolean {
        return !!this.individual.marriedTo;
    }

    /**
     * True if we should show the "Hushåll" section with ordinary household
     * members.
     */
    get showHousehold(): boolean {
        return this.individual.householdMembers.length > 0;
    }

    /**
     * True if we should show the "Andra familjemedlemmar" section.
     */
    get showOtherFamilyMembers(): boolean {
        return this.individual.otherFamilyRelations.length > 0;
    }

    get noOtherHouseholdInfo(): boolean {
        return !this.showMarriedTo && !this.showHousehold && !this.showOtherFamilyMembers;
    }

    /**
     * Renders the header for pep and sanction info based on permissions.
     */
    get pepSanctionHeader(): string {
        if (this.allowPEP && this.allowSanctionIndividual) {
            return "PEP/Sanktion";
        } else if (this.allowPEP) {
            return "PEP";
        } else {
            return "Sanktion";
        }
    }
}
