
import {Component, Mixins, Watch} from 'vue-property-decorator';
import PageHeader from "@/views/PageHeader.vue";
import {Route} from "vue-router";
import SidebarRight from "@/components/SidebarRight.vue";
import Auth from "@/mixins/auth";
import PopupMessage from "@/components/PopupMessage.vue";
import PopupRegistration from "@/components/PopupRegistration.vue";
import StateHelper from "@/mixins/state-helper";
import {
    ACTION_GET_ACTIVE_USER,
    MUTATION_APP_LOADED,
    MUTATION_AUTH_INFO,
    MUTATION_CTRL_PRESSED,
    MUTATION_META_PRESSED,
    MUTATION_POPUP_MESSAGE_STATUS,
    MUTATION_SIGNED_OUT_IF_NECESSARY
} from "@/store/store";
import {ACTION_GET_USER_WEB_APP_SETTINGS} from "@/store/store-user-web-app-settings";
import Utils from "@/mixins/utils";
import {EndUserParameters} from "@/models/end-user-subscription-parameters";
import {signOut} from "@/services/auth-service";
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import {EntityViewRef} from "@/store/store-search";

@Component({
    components: {
        PopupRegistration,
        PopupMessage,
        SidebarRight,
        PageHeader
    }
})
export default class App extends Mixins(Auth, StateHelper, Utils) {
    /**
     * When the user presses any of these keys and the focus is not in an input
     * or textarea, we move the focus to the search input field.
     */
    private readonly FOCUS_ON_SEARCH_FIELD_KEYS: RegExp = /^[a-zA-ZåäöÅÄÖ ]$/;

    /**
     * When the user presses a number key and the focus is not in an input or
     * textarea, we open the corresponding hit from the hit list.
     */
    private readonly OPEN_DETAIL_VIEW_FOR_HIT_LIST_ENTRY_KEYS: RegExp = /^[0-9]$/;

    /**
     * Define global event handlers in this map.
     */
    private readonly EVENT_TO_HANDLER: Map<string, (e: Event) => void> = new Map([
        ["focus", this.removePressedState],
        ["blur", this.removePressedState],
        ["contextmenu", this.removePressedState],
        ["keydown", this.handleKeyDown],
        ["keyup", this.handleKeyUp],
        ["paste", this.handlePaste],
    ]);


    private signOutPaths: string[] = ["/engangskod", "/inbjudan", "/prova-gratis", "/prova-pa", "/teckna-abonnemang", "/verifiera-inloggning"];

    private pathsThatRequiresExtraSetup: string[] = ["/verifiera-inloggning"];

    private v2Paths: string[] = ["/prova-gratis", "/teckna-abonnemang"];

    showSearch: boolean = false;

    isLoaded: boolean = false;


    /**
     * Make sure we don't flag the application as loaded before we know if we're
     * signed in, and in that case, not until we have fetched the active
     * subscription from the server.
     */
    mounted() {
        let pSignOut: Promise<void>;
        let isOnSignOutPath: boolean = this.startsWithAnyOf(this.$route.path, this.signOutPaths);

        if (isOnSignOutPath) {
            // Make sure we sign out properly for certain paths.
            pSignOut = signOut();
        } else {
            pSignOut = Promise.resolve();
        }

        /*
          Ignore errors here since we're only really interested in a clean state
          with removed activeUser and activeSubscription etc.
         */
        pSignOut.catch(() => {
        }).finally(() => {
            // Make sure other components see that a potential sign-out is done.
            this.$store.commit(MUTATION_SIGNED_OUT_IF_NECESSARY, undefined);

            let pExtraSetupComplete: Promise<void>;

            /*
              Some pages may require extra setup before we can consider the
              application loaded. In those cases, we have to wait for that setup
              to be complete before continuing.
             */
            if (this.startsWithAnyOf(this.$route.path, this.pathsThatRequiresExtraSetup)) {
                pExtraSetupComplete = this.$store.state.extraSetupDone;
            } else {
                pExtraSetupComplete = Promise.resolve();
            }

            pExtraSetupComplete.then(() => {
                let pFetchActiveUser: Promise<EndUserSubscriptionOperationResponse>;
                if (!isOnSignOutPath) {
                    pFetchActiveUser = this.$store.dispatch(ACTION_GET_ACTIVE_USER);
                } else {
                    pFetchActiveUser = Promise.resolve(undefined);
                }

                /*
                  Fetch info about current user and its authorities. If it
                  fails, no authentication information will be received and the
                  sign-in page will show.
                 */
                pFetchActiveUser.then(() => {
                    this.$store.dispatch(ACTION_GET_USER_WEB_APP_SETTINGS);
                }).catch(() => {
                    this.$store.commit(MUTATION_AUTH_INFO, undefined);
                }).finally(() => {
                    this.$store.commit(MUTATION_APP_LOADED, true);
                });

                this.$store.state.appLoaded.then((success: boolean) => {
                    if (success) {
                        this.isLoaded = true;

                        // If we have no pnr, a subscription, and that doesn't allow users without pnr's,
                        // display an uncloseable popup asking for it
                        // Note: ONLY do this for SSO-managed users
                        if (this.activeUser && this.activeUser.ssoManaged) {
                            if (!this.hasPnr && this.activeSubscription && !this.activeSubscription.skipPnrRequirement) {
                                this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "personal-number");
                            }
                        }
                    } else {
                        this.$router.push("/");
                        this.$store.commit(MUTATION_POPUP_MESSAGE_STATUS, "error");
                    }
                });

                /*
                  This produces an event that the prerenderer can pick up in order
                  to know that it is time to start prerendering
                 */
                document.dispatchEvent(new Event("render-event"));
            });
        });
    }

    /**
     * Add event listeners modifying global state.
     */
    created() {
        for (const [eventType, handler] of this.EVENT_TO_HANDLER.entries()) {
            /*
              Really not sure if this is necessary, but I've seen duplicate
              handlers in the past and this seemed to fix it.
             */
            window.removeEventListener(eventType, handler);

            // Add event listeners,
            window.addEventListener(eventType, handler);
        }
    }

    /**
     * Remove event listeners modifying global state.
     */
    destroyed() {
        for (const [eventType, handler] of this.EVENT_TO_HANDLER.entries()) {
            window.removeEventListener(eventType, handler);
        }
    }

    /**
     * Event handler for keydown events.
     *
     * @param event The event.
     */
    handleKeyDown(event: KeyboardEvent): void {
        if (this.isCtrlClick(event.code)) {
            this.$store.commit(MUTATION_CTRL_PRESSED, true);
        }
        if (this.isMetaClick(event.code)) {
            this.$store.commit(MUTATION_META_PRESSED, true);
        }

        if (this.focusNotOnInputOrTextarea(event) && !event.ctrlKey && !event.metaKey && !event.altKey) {
            let isBackspace = event.key === "Backspace";
            if (event.key === "Escape") {
                this.$router.back();
            } else if (this.FOCUS_ON_SEARCH_FIELD_KEYS.test(event.key) || isBackspace) {
                /*
                  Set focus on the search input field as soon as the user starts
                  typing any letter, unless the event already originates from an
                  input field or a textarea.
                 */
                this.setFocusOnSearchInputField(true);
                if (!isBackspace) {
                    this.selectTextInSearchField();
                }
                this.storeLatestSearchString();
            } else if (this.OPEN_DETAIL_VIEW_FOR_HIT_LIST_ENTRY_KEYS.test(event.key)) {
                /*
                  If the user presses a number key, we open the corresponding
                  search hit as the only detail view, unless that hit is already
                  displayed in the leftmost detail view.
                 */
                let index: number = event.key === "0" ? 9 : Number(event.key) - 1;
                let enoughHits: boolean = this.searchResultResponse.hits.length > index;
                let chosenIsAlreadyLeftmost: boolean = this.details.length >= 1 && this.searchResultResponse.hits[index].id === this.details[0].publicId;
                if (enoughHits && !chosenIsAlreadyLeftmost) {
                    this.$router.push({
                        path: "sok",
                        name: "search",
                        query: {
                            vad: this.$route.query.vad,
                            detaljer: Utils.encode(new EntityViewRef(this.searchResultResponse.hits[index].id)),
                            filter: this.$route.query.filter
                        }
                    });
                }
            }
        }
    }

    /**
     * Event handler for keyup events.
     *
     * @param e The event.
     */
    handleKeyUp(e: KeyboardEvent): void {
        if (this.isCtrlClick(e.code)) {
            this.$store.commit(MUTATION_CTRL_PRESSED, false);
        }
        if (this.isMetaClick(e.code)) {
            this.$store.commit(MUTATION_META_PRESSED, false);
        }
    }

    /**
     * Event handler that makes sure we no longer consider the ctrl or meta key
     * pressed. Useful on focus, blur and when showing the context menu, in
     * order to not get stuck in a "pressed" state.
     *
     * @param e The event.
     */
    removePressedState(e: Event): void {
        this.$store.commit(MUTATION_CTRL_PRESSED, false);
        this.$store.commit(MUTATION_META_PRESSED, false);
    }

    /**
     * Moves the focus to the search input field, selects the text and stores it
     * as the latest search string, before continuing the paste operation. This
     * will have the effect that a pasted string always overwrites the current
     * search string, unless the focus is already in an input or textarea
     * element.
     *
     * @param event The event.
     */
    handlePaste(event: any): void {
        if (this.focusNotOnInputOrTextarea(event)) {
            this.setFocusOnSearchInputField(true);
            this.selectTextInSearchField();
            this.storeLatestSearchString();
        }
    }

    /**
     * True if home should be shown
     */
    get showHome(): boolean {
        return this.isFullScreen || this.homeScreenBackground;
    }

    /**
     *  True if on root page and signed in or on one of the specific home screen paths
     */
    get homeScreenBackground(): boolean {
        return (App.isRoot(this.$route) && this.signedIn) || this.hasHomeScreenBackgroundPath(this.$route.path);
    }

    /**
     * Reacts to route changes and if we are signedIn to determine if we are on
     * the sign-in page.
     */
    get isSignInPage(): boolean {
        return App.isRoot(this.$route) && !this.signedIn;
    }

    /**
     * Checks if v2 class should be added to the outmost container div
     */
    get isV2(): boolean {
        return this.v2Paths.indexOf(this.$route.path) !== -1 || this.isSignInPage;
    }

    get showEdgeHelperPhone(): boolean {
        if (this.showHome) {
            return !!this.sidebarRightStatus;
        }
        return !!this.sidebarRightStatus;
    }

    /**
     * Perform various tasks when the url changes, such as updating the authentication
     * status and checking which classes we should add to the outmost container div.
     */
    @Watch("$route", {immediate: true, deep: true})
    onUrlChange(to: Route) {
        this.updateState(to.path);
    }

    private updateState(path: string) {
        this.showSearch = path.startsWith("/sok");
    }

    private hasHomeScreenBackgroundPath(path: string): boolean {
        return this.startsWithAnyOf(path, ["/inbjudan", "/valkommen"]);
    }

    private static isRoot(route: Route) {
        return route.path == "/";
    }

    /**
     * Make sure we update stuff when a user is signed out.
     *
     * @param val The new active user. May be undefined.
     * @param oldVal The old active user. May be undefined.
     */
    @Watch("$store.state.activeUser")
    onActiveUserChange(val: EndUserParameters, oldVal: EndUserParameters) {
        this.updateState(this.$route.path);

        if (this.isLoaded && !(val && val.refNo && oldVal && oldVal.refNo && val.refNo === oldVal.refNo)) {
            this.$store.dispatch(ACTION_GET_USER_WEB_APP_SETTINGS);
        }
    }

    /**
     * Returns true if the element with focus is not an input or a textarea.
     * @param untypedEvent
     */
    focusNotOnInputOrTextarea(untypedEvent: any) {
        let tagName: string = untypedEvent.target.tagName.toLowerCase();
        return tagName !== "input" && tagName !== "textarea";
    }
}
