
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator';
import {
    EM_DASH_ON_FALSY_FILTER,
    GIVEN_NAME_FILTER,
    MILLIONS_FILTER,
    TRUNCATE_FILTER,
    COMPANY_FORM_ABBREV_FILTER
} from "@/services/filters";
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import {SearchResultEntry} from "@/models/search-result-entry";
import {
    SearchResultEntryIndividual
} from "@/models/search-result-entry-individual";
import {SearchResultEntryCompany} from "@/models/search-result-entry-company";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultEntryAddressLocation
} from "@/models/search-result-entry-address-location";
import {
    SearchResultEntryRealProperty
} from "@/models/search-result-entry-real-property";
import {SearchResultEntryVehicle} from "@/models/search-result-entry-vehicle";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import {ClickEventData} from "@/views/SearchPage.vue";
import FNum from "@/components/FNum.vue";

/**
 * This component represents a single entry in the search result list.
 */
@Component({
    components: {FNum}
})
export default class SearchPageResultListEntry extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    entry: SearchResultEntry;


    /**
     * Convenience getter for the individual.
     */
    get individual(): SearchResultEntryIndividual {
        return this.entry.individual;
    }

    /**
     * Convenience getter for the company.
     */
    get company(): SearchResultEntryCompany {
        return this.entry.company;
    }

    /**
     * Convenience getter for the address location.
     */
    get addressLocation(): SearchResultEntryAddressLocation {
        return this.entry.addressLocation;
    }

    /**
     * Convenience getter for the real property.
     */
    get realProperty(): SearchResultEntryRealProperty {
        return this.entry.realProperty;
    }

    /**
     * Convenience getter for the vehicle.
     */
    get vehicle(): SearchResultEntryVehicle {
        return this.entry.vehicle;
    }

    /**
     * Helper for formatting the name.
     */
    get individualName(): string {
        let individual: SearchResultEntryIndividual = this.entry.individual;
        if (individual.secretIdentity) {
            return "Person med skyddad identitet";
        }
        let firstNames = GIVEN_NAME_FILTER(individual.firstNames, individual.givenNameCode);
        let middleAndLastNames = TRUNCATE_FILTER(this.getMiddleAndLastNames(individual), 40, true);
        return `${firstNames} <b>${middleAndLastNames}</b>`
    }

    /**
     * Convenience getter for the company form.
     */
    get companyForm(): string {
        return this.company.formText ? COMPANY_FORM_ABBREV_FILTER(this.company.formText) : "";
    }

    /**
     * Convenience getter for turnover.
     */
    get turnover(): string {
        return this.company.turnover != null ? MILLIONS_FILTER(this.company.turnover) + " mkr" : EM_DASH_ON_FALSY_FILTER(false);
    }

    /**
     * Gets the formatted sp address.
     */
    get spAddress(): string {
        let i = this.individual;
        return [i.spCareOf, i.spStreetAddress, i.spCity].filter(s => !!s).join(", ");
    }

    /**
     * Gets the formatted foreign address.
     */
    get foreignAddress(): string {
        let i = this.individual;
        return [i.foreignAddress1, i.foreignAddress2, i.foreignAddress3].filter(s => !!s).join(", ");
    }

    /**
     * Returns true if we believe the individual has moved abroad.
     */
    get movedAbroad(): boolean {
        return this.individual.deregistered && this.hasFbfAddress(this.individual);
    }


    /**
     * Emits that the entry was clicked.
     */
    @Emit()
    entryClicked(id: string, pEvent: PointerEvent): ClickEventData<OpenEntityRequest> {
        const entity = new OpenEntityRequest(id, OpenEntityRequest.noParentLevel);
        return {data: entity, pEvent};
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Formats the address line.
     *
     * @param careOf The careOf address.
     * @param streetAddress The street address.
     * @param city The city.
     */
    address(careOf: string, streetAddress: string, city: string): string {
        return [careOf, streetAddress, city].filter(s => !!s).join(", ");
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Creates the city and A-region string. If the A-region description is missing or
     * if it is the same as the city, only the city is returned. Otherwise, we return
     * the both the name of the city and the A-region description.
     *
     * @param individual The individual.
     */
    fbfCityAndARegionDescription(individual: SearchResultEntryIndividual): string {
        if (!individual.fbfARegionDescription || individual.fbfCity === individual.fbfARegionDescription) {
            return individual.fbfCity
        } else {
            return [individual.fbfCity, individual.fbfARegionDescription].filter(s => !!s).join(", ");
        }
    }

    /**
     * Returns true if we should show housing info in the hit list.
     */
    showHousingInfo(): boolean {
        let hasHousingInfo: boolean = this.individual.housingInfo && !!this.individual.housingInfo.housingType;
        return hasHousingInfo || this.individual.householdHousingType !== 'UNKNOWN';
    }

    /**
     * Get definitions for which rows to show in the right column for individual hits.
     */
    get individualRightCols(): RightColRowDef[] {
        let ret: RightColRowDef[] = [];

        ret.push(new RightColRowDef(
            this.individual.gender === "MALE" ? this.svg.male : this.svg.female,
            this.getGenderAndAgeOrBirthdate(this.individual, this.alwaysBirthYearInResultList)));

        if (this.showHousingInfo()) {
            ret.push(new RightColRowDef(this.svg.home, this.getHousingType(this.individual)));
        }
        if (this.individual.householdCompositionType !== "UNKNOWN") {
            ret.push(new RightColRowDef(this.svg.userFriends, this.getHouseholdCompositionType(this.individual.householdCompositionType)));
        }

        // Only show the status with the highest priority.
        if (this.individual.deceased) {
            ret.push(new RightColRowDef(this.svg.warning, "Avliden", "icon_red"));
        } else if (this.individual.hasSanction) {
            ret.push(new RightColRowDef(this.svg.warning, "Sanktion", "icon_red"));
        } else if (this.individual.hasTradingProhibition) {
            ret.push(new RightColRowDef(this.svg.warning, "Näringsförbud", "icon_red"));
        } else if (this.individual.pepType === "FIRST_ORDER") {
            ret.push(new RightColRowDef(this.svg.infoCircleRegular, "PEP"));
        } else if (this.individual.pepType === "SECOND_ORDER") {
            ret.push(new RightColRowDef(this.svg.infoCircleRegular, "PEP-relaterad"));
        } else if (this.individual.deregistered) {
            ret.push(new RightColRowDef(this.svg.infoCircleRegular, "Avregistrerad"));
        }

        if (this.individual.nofCommitments) {
            ret.push(new RightColRowDef(this.svg.briefcase, this.individual.nofCommitments + " företag"))
        }

        // Never show more than four rows.
        return ret.slice(0, 4);
    }
}

class RightColRowDef {
    icon: string;

    html: string;

    extraClass: string;


    constructor(icon: string, html: string, extraClass: string = "") {
        this.icon = icon;
        this.html = html;
        this.extraClass = extraClass;
    }
}
