
import {Component, Mixins, Watch} from 'vue-property-decorator';
import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
import {
    ListUserParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import SvgConstants from "@/mixins/svg-constants";
import SidebarRightFreeriderListEntry
    from "@/components/SidebarRightFreeriderListEntry.vue";
import CustomInput from "@/components/CustomInput.vue";
import {
    ACTION_FETCH_USERS,
    ACTION_INVITE_FREERIDER
} from "@/store/store-freeriders";
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import VisibleAware from "@/mixins/visible-aware";
import Validation, {ValidationStatus} from "@/mixins/validation";
import ErrorMessage from "@/components/ErrorMessage.vue";

/**
 * This component represents the list of freeriders in the right sidebar.
 */
@Component({
    components: {
        ErrorMessage,
        CustomInput,
        SidebarRightFreeriderListEntry,
        SidebarRightCloseButton
    },
})
export default class SidebarRightFreeriderList extends Mixins(SvgConstants, StateHelper, Utils, Validation, VisibleAware) {
    freerider: ListUserParameters = new ListUserParameters();

    showSent: boolean = false;

    errorMessage: string = "";

    loading: boolean = false;


    mounted() {
        this.$store.state.appLoaded.then((success: boolean) => {
            if (success && this.users.length === 0) {
                this.fetchUsers(this.activeSubscription, null);
            }
        });
    }

    /**
     * Getter for the users list in the store.
     */
    get users(): ListUserParameters[] {
        return this.$store.state.freerider.freeriders;
    }

    /**
     * Make sure we fetch users whenever the active subscription changes. If the
     * active subscription is removed, we make sure to empty the users list.
     *
     * @param val The new active subscription. May be undefined.
     * @param oldVal The old active subscription. May be undefined.
     */
    @Watch("$store.state.activeSubscription")
    onActiveSubscriptionChange(val: SubscriptionParameters, oldVal: SubscriptionParameters) {
        this.fetchUsers(val, oldVal);
    }

    /**
     * Sends an invitation to the freerider specified in the form..
     */
    inviteFreerider(): void {
        this.errorMessage = "";
        if (this.vLength(1, 250)(this.freerider.name) !== ValidationStatus.VALID) {
            this.errorMessage += "Ett namn måste anges. ";
        }
        if (this.vEmail()(this.freerider.email) !== ValidationStatus.VALID) {
            this.errorMessage += "E-postadressen är ogiltig."
        }
        if (!this.errorMessage) {
            this.showSent = false;
            this.freerider.subscriptionRefNo = this.activeSubscription.refNo;
            this.freerider.status = "INVITED";
            this.freerider.role = "FREERIDER";
            this.loading = true;
            this.$store.dispatch(ACTION_INVITE_FREERIDER, this.freerider).then(() => {
                this.showSent = true;
                this.freerider = new ListUserParameters();
                setTimeout(() => this.showSent = false, 5000);
            }).catch(response => {
                this.errorMessage = this.extractErrorMessage(response);
            }).finally(() => this.loading = false);
        }
    }

    @Watch("isVisible")
    onVisibleChange(val: boolean): void {
        // Reset the form when we become visible
        if (val) {
            this.freerider = new ListUserParameters();
            this.showSent = false;
            this.errorMessage = "";
        }
    }

    fetchUsers(val: SubscriptionParameters, oldVal: SubscriptionParameters): void {
        if (this.differentRefNo(val, oldVal)) {
            this.$store.dispatch(ACTION_FETCH_USERS).catch(response => {
                this.errorMessage = this.extractErrorMessage(response);
            });
        }
    }
}
