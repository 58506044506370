
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import {MonitorList} from "@/models/monitor-list";
    import {ACTION_DELETE_MONITOR_LIST,ACTION_PRIORITY_MONITOR_LIST} from "@/store/store-monitor";
    import Utils from "@/mixins/utils";
    import SvgConstants from "@/mixins/svg-constants";
    import ErrorMessage from "@/components/ErrorMessage.vue";
    import StateHelper from "@/mixins/state-helper";
    import SidebarRightMonitorListListFileEntry
        from "@/components/SidebarRightMonitorListListFileEntry.vue";

    /**
     * This component represents a monitor list in the list of monitor lists,
     * hence the slightly ridiculous name.
     */
    @Component({
        components: {ErrorMessage, SidebarRightMonitorListListFileEntry}
    })
    export default class SidebarRightMonitorListListFile extends Mixins(StateHelper, SvgConstants, Utils) {
        @Prop()
        list: MonitorList;

        showDetails: boolean = false;

        showConfirmRemove: boolean = false;

        showRemoveAnimation: boolean = false;

        errorMessage: string = "";
        
        disableToggle = false;

        /**
         * Show the confirm dialog before removing the monitor list.
         */
        doShowConfirmRemove() {
            this.showConfirmRemove = true;
            this.errorMessage = "";
        }

        /**
         * Delete the monitor list.
         */
        deleteMonitorList() {
            this.showDetails = false;
            this.showRemoveAnimation = true;
            this.errorMessage = "";
            setTimeout(() => {
                this.$store.dispatch(ACTION_DELETE_MONITOR_LIST, this.list.id).catch((response) => {
                    this.errorMessage = this.extractErrorMessage(response);
                }).finally(() => {
                    this.showConfirmRemove = false;
                    this.showRemoveAnimation = false;
                })
            }, 500);
        }
        
        clickPriority() {
        	if(this.disableToggle) {
        		return;
        	}
        	this.disableToggle = true;
        	const nextValue: string = this.getNextMonitorPriorityValue(this.list.priority);
            this.$store.dispatch(ACTION_PRIORITY_MONITOR_LIST,{"listId" :this.list.id, "priority": nextValue}).catch(() => {
            	this.errorMessage = "Något gick fel."
            });
            this.disableToggle = false;
        }
        
        get headingIconText(): string {
            if (this.showDetails) {
                return "Dölj" + " &#9661;";
            } else {
                return "Visa" + " &#9655;";
            }
        }
    }
