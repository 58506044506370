
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import {
    SearchResultCompanyGroupTree
} from "@/models/search-result-company-group-tree";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import SearchPageEntityCompanyGroupTree
    from "@/components/SearchPageEntityCompanyGroupTree.vue";

/**
 * This component wraps the company group tree.
 */
@Component({
    components: {
        SearchPageEntityCompanyGroupTree,
        SearchPageEntityExpandable
    }
})
export default class SearchPageEntityCompanyGroup extends Mixins(SvgConstants, Utils) {
    @Prop()
    companyGroup: SearchResultCompanyGroupTree;

    @Prop()
    currentId: string;

    @Prop()
    level: number;

    showCompact: boolean = true;
}
