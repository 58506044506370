
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {DisplayableEntity} from "@/models/displayable-entity";
import {ACTION_DELETE_MONITOR_LIST_ENTRY} from "@/store/store-monitor";
import {MUTATION_SIDEBAR_RIGHT_STATUS} from "@/store/store";
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import {EntityViewRef} from "@/store/store-search";
import ErrorMessage from "@/components/ErrorMessage.vue";
import StateHelper from "@/mixins/state-helper";

/**
     * This component represents a single entry in a monitor list, in the list
     * of monitor lists. This has a slightly different display from
     * SidebarRightMonitorListEntry, and the endpoint that should be used to
     * delete a list monitor is different from the one used to delete manual
     * monitors.
     */
    @Component({
        components: {ErrorMessage}
    })
    export default class SidebarRightMonitorListListFileEntry extends Mixins(StateHelper, SvgConstants, Utils) {
        @Prop()
        monitor: DisplayableEntity;
        
        @Prop()
        list: number;

        errorMessage: string = "";


        /**
         * Delete the monitor from the list, without asking for confirmation.
         */
        deleteFromList() {
        	this.errorMessage="";
            this.$store.dispatch(ACTION_DELETE_MONITOR_LIST_ENTRY, {listId: this.list, publicId: this.monitor.id}).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response);
                setTimeout(() => {
                	this.errorMessage="";
                },4000);
            });
        }

        /**
         * Show the entity the monitor refers to.
         * Duplicated code from SidebarRightMonitorListEntry.
         */
        showEntity(): void {
            this.$router.push({
                path: "sok",
                query: {
                    vad: this.monitor.id,
                    detaljer: Utils.encode(new EntityViewRef(this.monitor.id)),
                }
            });
            if (!Utils.isMobile()) {
                this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, "monitor-list");
            }
        }
    }
