
import {Component, Mixins} from 'vue-property-decorator';
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";

/**
 * This component handles choosing the correct logo based on the global
 * state that tells us which site we're on.
 */
@Component({
    components: {}
})
export default class Logo extends Mixins(StateHelper, SvgConstants) {
}
