
    import {Component, Mixins} from 'vue-property-decorator';
    import SidebarRightCloseButton from "@/components/SidebarRightCloseButton.vue";
    import SvgConstants from "@/mixins/svg-constants";
    import {SubscriptionParameters} from "@/models/end-user-subscription-parameters";
    import Utils from "@/mixins/utils";
    import {
        MUTATION_ACTIVE_SUBSCRIPTION,
        MUTATION_SIDEBAR_RIGHT_STATUS
    } from "@/store/store";
    import StateHelper from "@/mixins/state-helper";
    import {MUTATION_FORCE_SEARCH} from "@/store/store-search";

    /**
     * This component represents the list where the user can choose the active
     * subscription.
     */
    @Component({
        components: {SidebarRightCloseButton},
    })
    export default class SidebarRightChooseSubscription extends Mixins(StateHelper, SvgConstants, Utils) {
        /**
         * Checks if we should close the sidebar entirely when closing or if we should go
         * back to the menu.
         */
        get closeEntirelyOnClose(): boolean {
            // Only the sign-in tab is allowed to close entirely.
            return this.sidebarRightStatus.endsWith(":sign-in")
        }

        /**
         * Make the given subscription the active subscription, and go back to the menu.
         * Also, if the subscription has actually changed, we must perform our current
         * search again, since the access rights may have changed. Notice that the force
         * search mutation will only have effect if the user is currently on the search
         * page.
         *
         * @param subscription The new active subscription.
         */
        chooseSubscription(subscription: SubscriptionParameters): void {
            let forceNewSearch = subscription.refNo != this.activeSubscriptionRefNo;
            this.$store.commit(MUTATION_ACTIVE_SUBSCRIPTION, subscription);
            this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, this.closeEntirelyOnClose ? "" : "menu");
            if (forceNewSearch) {
                this.$store.commit("search/" + MUTATION_FORCE_SEARCH);
            }
        }
    }
