
    import {Component, Prop, Vue} from 'vue-property-decorator';

    /**
     * This component represents a single faq entity.
     */
    @Component
    export default class FaqItem extends Vue {
        /**
         * The header.
         */
        @Prop()
        heading: string;

        /**
         * Whether to show the faq text or not.
         */
        active: boolean = false;


        /**
         * Toggles whether to show the faq text or not.
         */
        toggle(): void {
            this.active = !this.active;
        }
    }
