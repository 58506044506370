import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * This mixin provides the "isVisible" property that can be controlled by a parent
 * component and used in order to rerender parts of a component when it "becomes visible".
 * What it means to "become visible" may differ from case to case and is up to the parent
 * component to determine
 */
@Component
export default class VisibleAware extends Vue {
    @Prop({default: false})
    isVisible: boolean;
}
