
import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import {
    SearchResultEntryCompanyDetails
} from "@/models/search-result-entry-company-details";
import {EM_DASH_ON_FALSY_FILTER} from "@/services/filters";
import {SearchResultFinancial} from "@/models/search-result-financial";
import SearchPageEntityCompanyInfoChart
    from "@/components/SearchPageEntityCompanyInfoChart.vue";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {MapAddress} from "@/components/SearchPageEntityInfoMap.vue";
import SearchPageEntityInfoExpandableMap
    from "@/components/SearchPageEntityInfoExpandableMap.vue";
import SearchPageEntityEvent from "@/components/SearchPageEntityEvent.vue";
import {
    SearchResultLineOfBusiness
} from "@/models/search-result-line-of-business";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import SelectOnClick from "@/components/SelectOnClick.vue";
import SearchPageEntityCompanyGroupTree
    from "@/components/SearchPageEntityCompanyGroupTree.vue";
import SearchPageEntityCompanyInfoBoardMember
    from "@/components/SearchPageEntityCompanyInfoBoardMember.vue";
import SearchPageEntityRealPropertiesList
    from "@/components/SearchPageEntityRealPropertiesList.vue";
import SearchPageEntityVehicleList
    from "@/components/SearchPageEntityVehicleList.vue";
import SearchPageEntityEventList
    from "@/components/SearchPageEntityEventList.vue";
import SearchPageEntityBoardMemberList
    from "@/components/SearchPageEntityBoardMemberList.vue";
import SearchPageEventFilterSettings
    from "@/components/SearchPageEventFilterSettings.vue";
import {DisplayableEvent} from "@/models/displayable-event";
import {ClickEventData} from "@/views/SearchPage.vue";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import FNum from "@/components/FNum.vue";
import FMillions from "@/components/FMillions.vue";
import SearchPageEntityCompanyInfoBeneficialOwner
    from "@/components/SearchPageEntityCompanyInfoBeneficialOwner.vue";
import SearchPageEntityBeneficialOwnerList
    from "@/components/SearchPageEntityBeneficialOwnerList.vue";
import SearchPageEntitySimpleExpandable
    from "@/components/SearchPageEntitySimpleExpandable.vue";
import {CustomSelectOption} from "@/components/CustomSelect.vue";
import {HTTP} from "@/services/http-provider";
import {
    MUTATION_UPDATE_USER_COMPANY_FINANCIAL_CONFIG
} from "@/store/store-search";
import {FinancialPeriod} from "@/models/financial-period";
import AdditionalInfoRow from "@/components/AdditionalInfoRow.vue";
import {
    SearchResultHistoricalBoard
} from "@/models/search-result-historical-board";
import {
    SearchResultBasicBoardMember
} from "@/models/search-result-basic-board-member";
import SearchPageEntityRadioButtonListSettings
    from "@/components/SearchPageEntityRadioButtonListSettings.vue";
import TwoColTableRow from "@/components/TwoColTableRow.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import StatusIconCell from "@/components/StatusIconCell.vue";
import FormattedText from "@/components/FormattedText.vue";
import SearchPageEntityTabularData
    from "@/components/SearchPageEntityTabularData.vue";
import NumericalTableRow from "@/components/NumericalTableRow.vue";
import {BoardInfo} from "@/models/board-info";
import SearchPageEntityBoardCommitmentsLists
    from "@/components/SearchPageEntityBoardCommitmentsLists.vue";
import SearchPageEntityCompanyGroup
    from "@/components/SearchPageEntityCompanyGroup.vue";

/**
 * This component represents the info tab for companies in a search result details
 * view.
 */
@Component({
    components: {
        SearchPageEntityCompanyGroup,
        SearchPageEntityBoardCommitmentsLists,
        NumericalTableRow,
        SearchPageEntityTabularData,
        FormattedText, StatusIconCell,
        StatusIcon,
        TwoColTableRow,
        SearchPageEntityRadioButtonListSettings,
        AdditionalInfoRow,
        SearchPageEntitySimpleExpandable,
        SearchPageEntityBeneficialOwnerList,
        SearchPageEntityCompanyInfoBeneficialOwner,
        FMillions,
        FNum,
        SearchPageEntityBoardMemberList,
        SearchPageEntityEventList,
        SearchPageEntityCompanyInfoBoardMember,
        SearchPageEntityInfoHeader,
        SearchPageEntityExpandable,
        SearchPageEntityEvent,
        SearchPageEntityInfoExpandableMap,
        SearchPageEntityLink,
        SearchPageEntityCompanyInfoChart,
        SelectOnClick,
        SearchPageEntityCompanyGroupTree,
        SearchPageEntityRealPropertiesList,
        SearchPageEntityVehicleList,
        SearchPageEventFilterSettings
    }
})
export default class SearchPageEntityCompanyInfo extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    level: number;

    @Prop()
    company: SearchResultEntryCompanyDetails;

    @Prop()
    eventFilters: string[];

    /**
     * Controls whether to show all line of business codes.
     */
    showLob: boolean = false;

    /**
     * Controls whether to show all historical company names and secondary
     * business names.
     */
    showAllNames: boolean = false;

    /**
     * Controls whether to show the vacancy text.
     */
    showVacancyText: boolean = false;

    selectedBoard: string = null;

    /**
     * Controls which company group view we currently show.
     */
    companyGroupViewSelectValue: 'HIDE' | 'SIMPLE' | 'FULL' = 'HIDE';

    /**
     * This watcher is only needed in order to set the selected board the first
     * time that company.historicalBoards are updated. Since the historical
     * boards are fetched asynchronously we can't really trigger on something
     * else. If we don't do this, we will not get the current board selected in
     * the radio button list after successful fetching of historical boards.
     */
    @Watch("company.historicalBoards")
    updateSelectedBoard(newHistoricalBoards: SearchResultHistoricalBoard[]): void {
        if (newHistoricalBoards.length > 0 && this.selectedBoard === null) {
            this.selectedBoard = newHistoricalBoards[0].date;
        }
    }

    /**
     * Returns a financial for the latest period. If there is both company and
     * group financials for that period, we use the group financial.
     */
    get latestFinancial(): SearchResultFinancial {
        if (this.company.financialPeriods.length === 0) {
            return null;
        }
        let latest: FinancialPeriod = this.company.financialPeriods[0];
        return latest.group ? latest.group : latest.company;
    }

    /**
     * Gets the list of financials to show in tables, which is the three first
     * elements of the financialPeriods list, for the type that is currently
     * selected.
     */
    get tabularFinancials(): SearchResultFinancial[] {
        let ret: SearchResultFinancial[] = [];
        if (this.detailViewConfig.companyFinancialType === "CONCERN" && this.hasGroupFinancials) {
            this.company.financialPeriods.forEach(fp => {
                if (fp.group) {
                    ret.push(fp.group);
                } else {
                    // Use an empty object for periods we don't have data for.
                    let srf: SearchResultFinancial = new SearchResultFinancial();
                    srf.periodEnd = fp.period;
                    ret.push(srf);
                }
            })
        } else {
            this.company.financialPeriods.forEach(fp => ret.push(fp.company));
        }
        return this.truncateToTabularLength(ret);
    }

    /**
     * Gets the list corresponding to tabularFinancials but where we're
     * guaranteed to only have company info and not group info. Useful when
     * showing dividend, which should always be a company value.
     */
    get tabularFinancialsCompany(): SearchResultFinancial[] {
        return this.truncateToTabularLength(this.company.financialPeriods.map(fp => fp.company));
    }

    private truncateToTabularLength(financials: SearchResultFinancial[]) {
        return financials.slice(0, 3);
    }

    /**
     * Convenience getter for the turnover.
     */
    get turnover(): number {
        return this.latestFinancial ? this.latestFinancial.omsattning : null;
    }

    /**
     * Convenience getter for the earningsAfterNetInterestIncome.
     */
    get earningsAfterNetInterestIncome(): number {
        return this.latestFinancial ? this.latestFinancial.resultatEfterFinansnetto : null;
    }

    /**
     * Convenience getter for the numEmployees.
     */
    get numEmployees(): number {
        return this.latestFinancial ? this.latestFinancial.antalAnstallda : null;
    }

    /**
     * Convenience getter for the dividend. Notice that we always use the
     * dividend from the company and not the group.
     */
    get dividend(): number {
        return this.company.financialPeriods.length ? this.company.financialPeriods[0].company.utdelning : null;
    }

    /**
     * True if we're supposed to show the small boxes below the map, with
     * turnover etc. Only hide them if we're missing all values.
     */
    get showBoxesBelowMap(): boolean {
        return this.truthyOrZero(this.turnover) ||
            this.truthyOrZero(this.earningsAfterNetInterestIncome) ||
            this.truthyOrZero(this.numEmployees) ||
            this.truthyOrZero(this.dividend);
    }

    /**
     * Gets the markers to be shown on the small map.
     */
    get mapAddresses(): MapAddress[] {
        let ret: MapAddress[] = [];
        if (this.hasPostalAddress(this.company)) {
            ret.push(new MapAddress("pst", this.addressTag, this.company.coord));
        }
        return ret;
    }

    /**
     * Gets the contents of the address tag as html.
     */
    get addressTag(): string {
        let parts: string[] = [];
        if (this.company.streetAddress) {
            parts.push(this.company.streetAddress);
            parts.push(this.company.city);
        } else if (this.company.zip) {
            parts.push(this.company.zip + " " + this.company.city);
        }
        return parts.filter(s => s.length > 0).join("<br>");
    }

    get filteredEvents(): DisplayableEvent[] {
        return this.nn(this.company.events).filter((event: DisplayableEvent) => this.filterEvent(this.company.id, event.type, this.eventFilters));
    }

    get hasGroupFinancials(): boolean {
        return this.allowViewCompanyGroup && !!this.company.financialPeriods.find(f => !!f.group);
    }

    /**
     * Returns the options for populating the dropdown for selecting group or
     * company information. Will return null if we should hide the dropdown.
     */
    get groupToggleOptions(): CustomSelectOption[] {
        return this.hasGroupFinancials ? [new CustomSelectOption("CONCERN", "Koncern"), new CustomSelectOption("BUSINESS", "Moderbolag")] : null;
    }

    /**
     * Returns the options for the company group view, either hidden,
     * simple or full.
     */
    get companyGroupViewOptions(): CustomSelectOption[] {
        return [
            new CustomSelectOption("HIDE", "Dölj"),
            new CustomSelectOption("SIMPLE", "Kompakt vy"),
            new CustomSelectOption("FULL", "Fullständig vy"),
        ];
    }

    /**
     * Returns the options for populating the dropdown for selecting board
     * version. Will return null if we should hide the dropdown.
     */
    get boardToggleOptions(): CustomSelectOption[] {
        let numBoards: number = this.hasHistoricalBoard ? this.company.historicalBoards.length : 0;
        if (numBoards == 1) {
            return null;
        } else if (this.company.historicalBoards && numBoards) {
            let options: CustomSelectOption[] = [];
            let current: SearchResultHistoricalBoard = this.company.historicalBoards[0];
            options.push(new CustomSelectOption(current.date, "Nuvarande, sedan " + current.date));

            for (let i = 1; i < numBoards - 1; i++) {
                let previous: SearchResultHistoricalBoard = current;
                current = this.company.historicalBoards[i];
                options.push(new CustomSelectOption(current.date, current.date + " " + this.EN_DASH + " " + previous.date));
            }

            if (numBoards > 1) {
                let last: SearchResultHistoricalBoard = this.company.historicalBoards[numBoards - 1];
                options.push(new CustomSelectOption(last.date, "Fram till " + current.date));
            }
            return options;
        } else {
            return null;
        }
    }

    /**
     * Handles the event triggered when the user changes the group toggle
     * dropdown. Send the new value to the server and update our state when we
     * get a successful response.
     *
     * @param newValue The new selected value.
     */
    handleGroupToggleEvent(newValue: string): void {
        if (newValue !== this.detailViewConfig.companyFinancialType) {
            HTTP.post<void>("/sapi/user-config/store-user-company-financial-config", JSON.stringify(newValue)).then(() => {
                this.$store.commit("search/" + MUTATION_UPDATE_USER_COMPANY_FINANCIAL_CONFIG, newValue);
            }).catch(() => {
                // Ignore this error - it is not all that important.
            });
        }
    }

    /**
     * Handle the change event of the company group view toggle dropdown.
     */
    handleCompanyGroupViewChangeEvent(newValue: 'HIDE' | 'SIMPLE' | 'FULL'): void {
        // We allow the user to show e.g. the compact view
        // for one serach result, while showing the full for
        // another search result. Hence this is not stored in
        // the store like detailViewConfig above.
        this.companyGroupViewSelectValue = newValue;
    }

    /**
     * Handles the event triggered when the user changes the board dropdown.
     *
     * @param newValue The new selected value.
     */
    handleBoardToggleEvent(newValue: string): void {
        let newBoard = this.company.historicalBoards.find(board => board.date === newValue);
        if (newBoard != null && newBoard.boardMembers == null) {
            HTTP.get<SearchResultHistoricalBoard>("/sapi/search/historical-board-info/" + this.company.id + "/" + newValue, {subscriptionRefNo: this.activeSubscriptionRefNo}).then((value: SearchResultHistoricalBoard) => {
                let index: number = this.company.historicalBoards.findIndex(board => board.date === value.date);
                this.$set(this.company.historicalBoards, index, value);
                this.selectedBoard = newValue;
            }).catch((response: any) => {
                this.company.errors = {boardHistory: this.extractErrorMessage(response)};
            });
        } else {
            this.selectedBoard = newValue;
        }
    }

    /**
     * Maps the integer flag for f-tax, vat-reg and socSecContrib to a
     * human-readable string.
     *
     * @param flag The flag - between 0 and 3.
     * @param date The date coupled to the flag.
     */
    yesNoDate(flag: number, date: string): string {
        switch (flag) {
            case 1:
                return "Ja" + (date ? ", sedan " + date : "");
            case 2:
                return "Avregistrerad";
            case 3:
                return "Nej";
            default:
            case 0:
                return "Okänt";
        }
    }

    /**
     * Processes the procuration info string and replaces formatting from
     * Bolagsverket with more proper formatting for the web.
     */
    get procurationInfo(): string {
        return this.company.procurationInfo.replace(/\n/g, "<br>");
    }

    /**
     * Processes the description string and replaces formatting from Bolagsverket
     * with more proper formatting for the web.
     */
    description(): string {
        return this.company.description.replace(/\//g, "").trim();
    }

    formatCompanyForm(): string {
        let ret: string = this.company.formText;
        if (this.company.publicCompany) {
            ret += " (publikt)";
        }
        return ret;
    }

    getStockMarketSummary(): string {
        if (this.company.stockMarketInstruments.length == 0) {
            return '\u2014';
        } else {
            let markets: Set<string> = new Set<string>();
            for (let instrument of this.company.stockMarketInstruments) {
                markets.add(instrument.market)
            }
            if (markets.size == 1) {
                return this.company.stockMarketInstruments[0].market;
            } else {
                return this.company.stockMarketInstruments[0].market + " mm";
            }
        }
    }

    /**
     * Formats the header for the board members list.
     */
    boardMembersHeader(): string {
        return this.company.aktiebolag ? "Styrelse" : "Befattningshavare";
    }

    /**
     * Formats a line of business entry.
     *
     * @param lob The entry to format.
     */
    formatLob(lob: SearchResultLineOfBusiness): string {
        return lob.text + " (" + lob.code.substr(0, 2) + "." + lob.code.substr(2) + ")";
    }

    /**
     * Gets the label for the related company entry under the status.
     */
    relatedOrgNoLabel() {
        if (this.company && this.company.status.text === 'Upplöst genom fusion') {
            return "Övertaget av"
        }
        return "Se även";
    }

    /**
     * Gets the number of entries we expect to show in the Bolagsordning
     * part.
     */
    articlesOfAssociationLength(): number {
        let length: number = this.company.fiscalYear ? 1 : 0;
        length += this.company.articlesOfAssociation.length;
        let bi: BoardInfo = this.company.boardInfo;
        if (bi) {
            length += bi.minNumLed === -1 ? 0 : 1;
            length += bi.maxNumLed === -1 ? 0 : 1;
            length += bi.maxNumSup === -1 ? 0 : 1;
            length += bi.maxNumSup === -1 ? 0 : 1;
            length += bi.numChosenLed === -1 ? 0 : 1;
            length += bi.numChosenSup === -1 ? 0 : 1;
        }
        return length;
    }

    /**
     * Maps currency (SEK/EUR) to kr or euro respectively.
     *
     * @param currency The currency string.
     */
    currency(currency: string) {
        switch (currency) {
            case "SEK":
                return "kr";
            case "EUR":
                return "€";
            default:
                return "";
        }
    }

    /**
     * Re-emits the request to open a new entity.
     */
    @Emit()
    openEntityView(event: ClickEventData<OpenEntityRequest>): ClickEventData<OpenEntityRequest> {
        return event;
    }

    /**
     * Em dash surrounded by spaces, for use in date ranges.
     */
    get emDashSpacer(): string {
        return " " + EM_DASH_ON_FALSY_FILTER(false) + " ";
    }

    /**
     * True if the company has viewable historical names.
     */
    get hasHistoricalNames(): boolean {
        return this.allowViewAuxiliaryCompanyName && this.company.historicalNames && this.company.historicalNames.length > 1;
    }

    /**
     * True if the company has secondary business names.
     */
    get hasSecondaryBusinessNames(): boolean {
        return this.allowViewAuxiliaryCompanyName && this.company.secondaryBusinessNames.length > 0;
    }

    /**
     * True if we have at least one historical board entry.
     */
    get hasHistoricalBoard(): boolean {
        return this.company.historicalBoards && this.company.historicalBoards.length > 0;
    }

    /**
     * Gets the list of board members to show, honouring the historical board
     * radio buttons.
     */
    get selectedBoardMembers(): SearchResultBasicBoardMember[] {
        let theBoard: SearchResultHistoricalBoard = this.selectedHistoricalBoard;
        return theBoard == null ? this.company.boardMembers : theBoard.boardMembers;
    }

    /**
     * Gets the list of accountants to show, honouring the historical board
     * radio buttons.
     */
    get selectedAccountants(): SearchResultBasicBoardMember[] {
        let theBoard: SearchResultHistoricalBoard = this.selectedHistoricalBoard;
        return theBoard == null ? this.company.accountants : theBoard.accountants;
    }

    /**
     * Gets the list of external signatories to show, honouring the historical board
     * radio buttons.
     */
    get selectedExternalSignatories(): SearchResultBasicBoardMember[] {
        let theBoard: SearchResultHistoricalBoard = this.selectedHistoricalBoard;
        return theBoard == null ? this.company.externalSignatories : theBoard.externalSignatories;
    }

    /**
     * Gets the selected historical board, or null if there is none or if the
     * selected board is the current board.
     */
    get selectedHistoricalBoard(): SearchResultHistoricalBoard {
        if (!this.hasHistoricalBoard || this.selectedBoard == null || this.selectedBoard === this.company.historicalBoards[0].date) {
            return null;
        }
        for (let board of this.company.historicalBoards) {
            if (board.date === this.selectedBoard) {
                return board;
            }
        }
        return null;
    }

    /**
     * Gets the string describing the currently selected board.
     */
    get boardMembersSettingsDescription(): string {
        if (this.boardToggleOptions) {
            let selectedOption = this.boardToggleOptions.find(option => option.value === this.selectedBoard);
            if (selectedOption) {
                return this.EN_DASH + " " + selectedOption.text;
            }
        }
        return "";
    }

    /**
     * Adds the "historical" class if the selected board is historical, making
     * it possible to change the styling in that case.
     */
    get boardMemberClasses(): any {
        let classes: any = {
            "table_person": true,
        };
        if (this.selectedHistoricalBoard !== null) {
            classes["historical"] = true;
        }

        return classes;
    }

    /**
     * True if we should show the beneficial status line.
     */
    get showBeneficalStatusLine(): boolean {
        return !!this.beneficialStatusText;
    }

    /**
     * Formats the beneficial status.
     */
    get beneficialStatusText(): string {
        if (this.company.beneficialReportingObligationStatus === "NO") {
            return "Företaget är inte anmälningspliktigt för verklig huvudman";
        }
        switch (this.company.beneficialStatus) {
            case "NOT_REPORTED":
                return "Verklig huvudman är ej rapporterad";
            case "UNSPECIFIED":
                return "Verklig huvudman har ej gått att fastställa";
            case "MISSING":
                return "Verklig huvudman saknas";
            case "REMOVED":
                return "Information om verklig huvudman har gallrats då företaget avregistrerades för 5 år sedan eller mer";
            default:
                return "";
        }
    }

    /**
     * True if the "Bolagsordning/Stadgar" section should be shown.
     */
    get showArticlesOfAssociation(): boolean {
        return this.signedIn && this.hasActiveSubscription && (this.company.aktiebolag || this.articlesOfAssociationLength() > 0);
    }

    /**
     * Gets the heading for Bolagsordning/Stadgar based on the company's form.
     */
    get articlesOfAssociationHeading(): string {
        switch (this.company.form) {
            case "41": // Bankaktiebolag
            case "42": // Försäkringsaktiebolag
            case "49": // Aktiebolag
            case "92": // Ömsesidigt försäkringsbolag
            case "93": // Sparbank
                return "Bolagsordning";
            case "51": // Ekonomisk förening
            case "53": // Bostadsrättsförening
            case "54": // Kooperativ hyresrättsförening
            case "61": // Ideell förening
            case "94": // Understödsförening
                return "Stadgar";
        }
        return "Bolagsordning";
    }
}
