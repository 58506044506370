var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.financialsToShow.length)?_c('div',{staticClass:"popup_company_info_diagram"},[_c('div',{staticClass:"diagram_w1"},[_c('div',{staticClass:"diagram_w2"},[_c('div',{staticClass:"chart_w0"},[_c('div',{staticClass:"chart_w1"},[_vm._m(0),_c('div',{staticClass:"chart_w2"},_vm._l((_vm.financialsToShow),function(f){return _c('div',{key:f.index,staticClass:"chart_col_w1",attrs:{"onclick":""}},[_c('div',{staticClass:"chart_col_w2"},[_c('div',{staticClass:"chart_bar_w1 chart_bar_plus_w1"},[_c('div',{staticClass:"chart_bar_revenue bar_with_tooltip",class:{chartParentBlue: f.useBrighterColor},style:(_vm.positiveStyle(f.financial.omsattning))}),_c('div',{staticClass:"chart_bar_profit bar_with_tooltip",class:{chartParentGreen: f.useBrighterColor},style:(_vm.positiveStyle(f.financial.resultatEfterFinansnetto))})]),_c('div',{staticClass:"chart_bar_w1 chart_bar_minus_w1"},[_c('div',{staticClass:"chart_bar_revenue bar_with_tooltip",class:{chartParentRed: f.useBrighterColor},style:(_vm.negativeStyle(f.financial.omsattning))}),_c('div',{staticClass:"chart_bar_profit bar_with_tooltip",class:{chartParentRed: f.useBrighterColor},style:(_vm.negativeStyle(f.financial.resultatEfterFinansnetto))})]),_c('div',{staticClass:"chart_info_w1"},[_c('div',{staticClass:"chart_info_w2 bg_blue",class:{chartParentBlue: f.useBrighterColor}},[_c('div',{staticClass:"chart_info_revenue",domProps:{"innerHTML":_vm._s(_vm.formatMoney(f.financial.omsattning))}}),_c('div',{staticClass:"chart_info_heading"},[_vm._v("Omsättning")])]),_c('div',{staticClass:"chart_info_w2",class:{
                       bg_green: f.financial.resultatEfterFinansnetto >= 0,
                       bg_red: f.financial.resultatEfterFinansnetto < 0,
                       chartParentGreen: f.financial.resultatEfterFinansnetto >= 0 && f.useBrighterColor,
                       chartParentRed: f.financial.resultatEfterFinansnetto < 0 && f.useBrighterColor,
                       }},[_c('div',{staticClass:"chart_info_profit",domProps:{"innerHTML":_vm._s(_vm.formatMoney(f.financial.resultatEfterFinansnetto))}}),_c('div',{staticClass:"chart_info_heading"},[_vm._v("Resultat")])])])]),_c('div',{staticClass:"chart_year_w1"},[_c('div',[_vm._v(_vm._s(_vm._f("period")(f.financial.periodEnd)))])])])}),0),_vm._m(1),(_vm.onlyGroup)?_c('div',{staticClass:"chartFootnote"},[_vm._v(" Siffrorna avser koncernen ")]):(_vm.mixed)?_c('div',{staticClass:"chartFootnote"},[_c('div'),_c('div'),_c('div'),_c('div',[_vm._v("Koncern")]),_c('div'),_c('div'),_c('div'),_c('div',[_vm._v("Moderbolag")])]):_vm._e()])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chart_grid_w1"},[_c('div',{staticClass:"chart_grid_line"}),_c('div',{staticClass:"chart_grid_line"}),_c('div',{staticClass:"chart_grid_line color_none"}),_c('div',{staticClass:"chart_grid_line"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chart_grid_w1"},[_c('div',{staticClass:"chart_grid_line color_none"}),_c('div',{staticClass:"chart_grid_line color_none"}),_c('div',{staticClass:"chart_grid_line color_green"}),_c('div',{staticClass:"chart_grid_line color_none"})])
}]

export { render, staticRenderFns }