import {Component, Vue} from "vue-property-decorator";

/**
 * This mixin provides the isMounted flag, that will be set to true a short while after
 * the component gets mounted. The short delay makes it suitable for setting classes that
 * controls css animation of the component when it appears, something v-if will not be
 * able to handle without writing explicit Vue transitions. Thus - use this mixin when a
 * component should be animated when it appears, and set the animation controlling class
 * when the isMounted flag is true. Then the component can be controlled with v-if from
 * the parent component.
 */
@Component
export default class MountAware extends Vue {
    isMounted: boolean = false;

    mounted() {
        setTimeout(() => this.isMounted = true, 10);
    }
}
