
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import {FrontendTabularData} from "@/models/frontend-tabular-data";
import {FrontendTabularCell} from "@/models/frontend-tabular-cell";
import {FrontendTabularRow} from "@/models/frontend-tabular-row";
import SvgConstants from "@/mixins/svg-constants";
import SelectOnClick from "@/components/SelectOnClick.vue";


/**
 * This component represent tabular data.
 */
@Component({
    components: {SelectOnClick}
})
export default class SearchPageEntityTabularData extends Mixins(StateHelper, SvgConstants, Utils) {
    /**
     * The table data.
     */
    @Prop()
    data: FrontendTabularData;

    /**
     * The number of columns that should be visible, excluding a potential
     * header column.
     */
    @Prop()
    numVisibleCols: number

    /**
     * Set to true if the first element of each line should be considered a
     * header, and thus not be "scrollable".
     */
    @Prop()
    hasLineHeader: boolean;

    firstVisibleCol: number = -1;


    /**
     * Gets the cells of the given row excluding a potential header cell.
     *
     * @param row The row to get cells from.
     */
    nonHeaderCells(row: FrontendTabularRow): FrontendTabularCell[] {
        if (this.firstVisibleCol === -1) {
            this.firstVisibleCol = this.firstNonHeaderCol;
        }
        return row.cells.slice(this.firstVisibleCol, this.firstVisibleCol + this.numCols);
    }

    /**
     * Gets the index of the first cell of each row that is not a header cell.
     */
    get firstNonHeaderCol(): number {
        return this.hasLineHeader ? 1 : 0;
    }

    /**
     * Gets the number of columns that should be visible.
     */
    get numCols(): number {
        return this.numVisibleCols ? this.numVisibleCols : this.actualNumCols;
    }

    /**
     * Gets the actual number of columns in the data, which may be more than the
     * number of visible columns.
     */
    get actualNumCols(): number {
        if (this.data.header) {
            return this.data.header.cells.length;
        } else if (this.data.rows.length > 0) {
            return this.data.rows[0].cells.length;
        } else {
            return 0;
        }
    }

    /**
     * True if we should show the navigation footer, that is - if the table
     * contains more columns than we're currently viewing.
     */
    get showFooter(): boolean {
        return this.actualNumCols > this.numVisibleCols + this.firstNonHeaderCol;
    }

    /**
     * True if there are more columns to the left of the current columns.
     */
    canGoLeft(): boolean {
        return this.firstVisibleCol > this.firstNonHeaderCol;
    }

    /**
     * True if there are more columns to the right of the current columns.
     */
    canGoRight(): boolean {
        return this.firstVisibleCol + this.numVisibleCols < this.actualNumCols;
    }

    /**
     * Triggered when the user clicks the left button.
     */
    leftClicked(): void {
        if (this.canGoLeft()) {
            this.firstVisibleCol--;
        }
    }

    /**
     * Triggered when the user clicks the right button.
     */
    rightClicked(): void {
        if (this.canGoRight()) {
            this.firstVisibleCol++;
        }
    }
}
