
import {Component, Mixins} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import V2CustomInput from "@/components_v2/V2CustomInput.vue";
import V2Header from "@/components_v2/V2Header.vue";
import CustomerSupportPhoneNumber
    from "@/components/CustomerSupportPhoneNumber.vue";
import CustomerSupportEmailLink
    from "@/components/CustomerSupportEmailLink.vue";

@Component({
    components: {
        CustomerSupportEmailLink,
        CustomerSupportPhoneNumber,
        V2Header, V2CustomInput
    },
})
export default class Subscribe extends Mixins(StateHelper) {
}
