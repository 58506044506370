/*
 * Functions that provides authentication functions and does not have be in the
 * auth mixin. This is favorable since they are then also callable from normal
 * js (ts) functions.
 */

import store, {ACTION_SIGN_OUT, ACTION_SIGN_OUT_ALL} from "@/store/store";
import {Global} from "@/global";
import router, {
    fullOpenPaths,
    PATH_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_IN_KEEP_STATE_QUERY_NAME
} from "@/router";
import {UTILS} from "@/mixins/utils";

/**
 * Signs the user out and redirects to the base application url outside the SPA.
 *
 * @return Returns a promise that resolves when signing out is done.
 */
export function signOutRedirect(): Promise<void> {
    return signOut().finally(() => window.location.href = UTILS.appUrl());
}

/**
 * Signs user out and redirects to sign in page if we are on FaktaKontroll and
 * we are on a path/page that requires authorization. These paths not requiring
 * authorization are specified by {@link fullOpenPaths}. If on an "open" page
 * or not on faktakontroll then the user is just signed out but not redirected.
 *
 * State is optionally stored so that the sign in page can redirect to the
 * current page after sign in.
 *
 * @param keepState Specify if current state should be saved.
 * @return Returns a promise that resolves when signing out is done
 */
export function signOutRedirectIfSuitable(keepState: boolean): Promise<void> {
    return signOut().finally(() => {
            if (!inOpenPaths(window.location.pathname) && isFaktakontroll()) {
                redirectSignIn(keepState);
            }
        }
    );
}

/**
 * Returns true if the provided path does not have authentication requirements.
 *
 * @param path The path to check.
 * @return true if the provided path does not have authentication requirements.
 */
export function inOpenPaths(path: string) {
    for (let candidate of fullOpenPaths) {
        if (path.startsWith(candidate)) {
            return true;
        }
    }
    return false;
}

/**
 * Signs out all signed-in sessions and redirects to the base application url
 * outside the SPA.
 *
 * @return Returns a promise that resolves when signing out is done.
 */
export function signOutAllRedirect(): Promise<void> {
    return signOutAll().finally(() => window.location.href = UTILS.appUrl());
}

/**
 * Signs the user out, does no redirect afterwards.
 */
export function signOut(): Promise<void> {
    return store.dispatch(ACTION_SIGN_OUT);
}

function signOutAll(): Promise<void> {
    return store.dispatch(ACTION_SIGN_OUT_ALL)
}

function redirectSignIn(keepState: boolean): void {
    let query_param = {
        [REDIRECT_SIGN_IN_KEEP_STATE_QUERY_NAME]: keepState.toString()
    };
    UTILS.ignoreError(router.push({
        path: PATH_REDIRECT_SIGN_IN,
        query: query_param
    }));
}

function isFaktakontroll(): boolean {
    return Global.isFaktakontroll()
}
