/**
 * See the corresponding java class for documentation.
 */
export class DocumentAvailability {
    certificateOfRegistrationAvailable: boolean = false;

    statutesAvailable: boolean = false;

    financialPlanAvailable: boolean = false;

    articlesOfAssociationAvailable: boolean = false;
}
