
    import {Component, Mixins} from 'vue-property-decorator';
    import StateHelper from "@/mixins/state-helper";

    /**
     * This component represents the view the user sees when we're displaying a full
     * screen message.
     */
    @Component({
        components: {}
    })
    export default class PopupMessageFullScreen extends Mixins(StateHelper) {
        /**
         * Gets the message that is determined from the current global popupMessageStatus.
         * When the status starts with "full-screen" it is possible to also specify
         * arguments on the form
         *
         *   full-screen:<key>=<value>
         *
         * where "key" might be either "errorMessage" or "type". In the first case, the
         * value is the error message to be displayed. In the second case, the value is a
         * key that determines the error message. This mapping is preformed in the
         * messageFromType() method.
         */
        get message(): string {
            let index: number = this.popupMessageStatus.indexOf(":");
            if (index !== -1) {
                let parts: string[] = this.popupMessageStatus.substr(index + 1).split("=");
                let key: string = parts[0];
                let value: string = parts[1];
                switch (key) {
                    case "errorMessage":
                        return value;
                    case "type":
                        return PopupMessageFullScreen.messageFromType(value);
                }
            }
            return "Hoppsan! Något gick fel. Prova att gå tillbaka till startsidan";
        }

        private static messageFromType(messageKey: string) {
            if (messageKey === "signed-in-other-tab") {
                return "Du är inloggad i en annan flik. Det går bra att stänga den här fliken.";
            } else if (messageKey === "error") {
                return "Hoppsan! Något gick fel - vi ber om ursäkt för det. Prova gärna igen."
            }
            return "";
        }
    }
