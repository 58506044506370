
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import SvgConstants from "@/mixins/svg-constants";
import {
    SearchResultHistoricalCommitmentCompany
} from "@/models/search-result-historical-commitment-company";
import SearchPageEntityBoardCommitmentsLists
    from "@/components/SearchPageEntityBoardCommitmentsLists.vue";
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import Utils from "@/mixins/utils";
import SearchPageEntityHistoricalCommitmentsList
    from "@/components/SearchPageEntityHistoricalCommitmentsList.vue";

/**
 * This component renders board commitments, both current and historical, and a
 * dropdown to choose what to view. Notice that historical commitments may be
 * null initially, indicating that they are fetched asynchronously. Hence, we
 * wrap the list of historical commitments in a progress bar.
 */
@Component({
    components: {
        SearchPageEntityHistoricalCommitmentsList,
        SearchPageEntityExpandable,
        SearchPageEntityBoardCommitmentsLists,
    }
})
export default class SearchPageEntityBoardCommitments extends Mixins(SvgConstants, Utils) {
    @Prop()
    individual: SearchResultEntryIndividualDetails;

    @Prop()
    level: number;

    /**
     * True if we're currently showing current commitments and false when
     * showing historical commitments.
     */
    showCurrent: boolean = true;

    expanded: boolean = false;


    /**
     * Gets the length of the list currently visible.
     */
    get visibleListLength(): number {
        return this.showCurrent ? this.individual.commitments.length : this.historicalCommitments.length;
    }

    /**
     * The historical commitments to pass on to the rendering component. Null is
     * treated as an empty list.
     */
    get historicalCommitments(): SearchResultHistoricalCommitmentCompany[] {
        return this.individual.historicalCommitments ? this.individual.historicalCommitments : [];
    }

    /**
     * If an asynchronous fetch of historical commitments failed, this method
     * returns the error message.
     */
    get errorMessage(): string {
        return this.em(this.individual.errors).historicalCommitments;
    }

    /**
     * True if we have neither current nor historical commitments.
     */
    get neitherCurrentNorHistorical(): boolean {
        return this.individual.commitments.length === 0 && this.historicalCommitments.length === 0;
    }

    /**
     * The text to display when there are no current or historical commitments.
     */
    get nothingToShowText(): string {
        if (this.errorMessage) {
            // Don't hide the error message.
            return null;
        } else if (!this.showCurrent && this.individual.historicalCommitments === null) {
            /*
              Make sure the spinner is visible if we're showing historical
              commitments, and they have not finished loading yet.
             */
            return null;
        }
        let typeText;
        if (this.neitherCurrentNorHistorical) {
            typeText = "aktuella eller historiska";
        } else if (this.showCurrent) {
            typeText = "aktuella";
        } else {
            typeText = "historiska";
        }
        return "Inga " + typeText + " företagsengagemang";
    }
}
