import {Http} from "@/services/http";
import {HttpLocal} from "@/services/http-local";

/**
 * Checks the NODE_ENV environment variable and returns the correct Http implementation
 * accordingly. If the value is "local" we use the local mock implementation.
 */
function resolveHttp(): Http {
    switch (process.env.NODE_ENV) {
        case "local":
            return new HttpLocal();
        default:
            return new Http();
    }
}

/**
 * Our global http service.
 */
export const HTTP = resolveHttp();
