
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityInfoMap, {
    MapAddress
} from "@/components/SearchPageEntityInfoMap.vue";
import {
    SearchResultEntryRealPropertyDetails
} from "@/models/search-result-entry-real-property-details";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import SearchPageEntityInfoExpandableMap
    from "@/components/SearchPageEntityInfoExpandableMap.vue";
import SearchPageEntityEvent from "@/components/SearchPageEntityEvent.vue";
import SearchPageEntityRealPropertyTaxUnit
    from "@/components/SearchPageEntityRealPropertyTaxUnit.vue";
import SearchPageEntityRealPropertyOwner
    from "@/components/SearchPageEntityRealPropertyOwner.vue";
import {
    SearchResultRealPropertyTaxUnit
} from "@/models/search-result-real-property-tax-unit"
import SearchPageEntityExpandable
    from "@/components/SearchPageEntityExpandable.vue";
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";

import SelectOnClick from "@/components/SelectOnClick.vue";
import {
    SearchResultEntryAddressLocationWithCoordinate
} from "@/models/search-result-entry-address-location-with-coordinate";
import {
    SearchResultEntryAddressLocation
} from "@/models/search-result-entry-address-location";
import SearchPageEntityEventList
    from "@/components/SearchPageEntityEventList.vue";
import FNum from "@/components/FNum.vue";

/**
 * This component represents the info tab for individuals in a search result details
 * view.
 */
@Component({
    components: {
        FNum,
        SearchPageEntityEventList,
        SearchPageEntityInfoHeader,
        SearchPageEntityExpandable,
        SearchPageEntityEvent,
        SearchPageEntityRealPropertyTaxUnit,
        SearchPageEntityRealPropertyOwner,
        SearchPageEntityInfoExpandableMap,
        SearchPageEntityLink,
        SearchPageEntityInfoMap,
        SelectOnClick
    }
})
export default class SearchPageEntityRealPropertyInfo extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    level: number;

    @Prop()
    realProperty: SearchResultEntryRealPropertyDetails;

    expandAddressLocations: boolean = false;


    /**
     * Returns true if we have any addresses to display.
     */
    get hasDisplayableCoord(): boolean {
        if (this.realProperty.chosenAddress) {
            return true;
        }
        return !!this.realProperty.addressLocations.find((al) => !!al.coord);
    }

    /**
     * Gets addresses to be shown on the map.
     */
    get mapAddresses(): MapAddress[] {
        if (this.realProperty.chosenAddress) {
            return [this.toMapAddress(this.realProperty.chosenAddress, 0)];
        }
        let index: number = 0;
        return this.realProperty.addressLocations.map(al => this.toMapAddress(al, index++));
    }

    /**
     * Converts the given address location to a MapAddress.
     *
     * @param al The address location.
     * @param index The index. If other than 0, we use the "second" type.
     */
    toMapAddress(al: SearchResultEntryAddressLocationWithCoordinate, index: number): MapAddress {
        return new MapAddress(
            index == 0 ? "first" : "second",
            [al.streetAddress, al.city].join("<br>"),
            al.coord);
    }

    /**
     * Produces an id string for the "Alla händelser" anchor including the
     * level, in order to be able to navigate to the anchor in the correct tab.
     */
    get allEventsId(): string {
        return "alla-handelser-" + this.level;
    }

    get totalTaxationValue(): number {
        let totalValue: number = 0;
        const units: SearchResultRealPropertyTaxUnit[] = this.realProperty.taxUnits;
        for (let i = 0; i < units.length; i++) {
            totalValue += units[i].valueTotal;
        }
        return totalValue;
    }

    get uniqueTypes(): string[] {
        let addedTypes: number[] = [];
        let uniqueTypes: string[] = [];
        const units: SearchResultRealPropertyTaxUnit[] = this.realProperty.taxUnits;
        for (let i = 0; i < units.length; i++) {
            if (!addedTypes.includes(units[i].typeCode)) {
                uniqueTypes.push(units[i].typeCode + " - " + units[i].typeGroupDescription);
                addedTypes.push(units[i].typeCode);
            }
        }
        return uniqueTypes
    }

    get showPurchasePrice(): boolean {
        return this.realProperty.lastPurchasePriceDescriptionCode != "00" && this.realProperty.lastPurchasePriceDescriptionCode != "";
    }

    formatPurchasePriceCurrency(currency: string): string {
        if (currency.toLowerCase() === "sek") {
            return "kr";
        }
        return currency;
    }

    /**
     * Formats an address location - two lines.
     *
     * @param addressLocation The address location.
     */
    formatRealPropertyAddressLocation(addressLocation: SearchResultEntryAddressLocation): string {
        return addressLocation.streetAddress + "<br>" + addressLocation.zip + " " + addressLocation.city;
    }
}
