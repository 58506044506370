
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import Utils from "@/mixins/utils";
import {OpenEntityRequest} from "@/components/SearchPageEntity.vue";
import {ClickEventData} from "@/views/SearchPage.vue";

/**
 * This component represents a clickable link in a details view, that opens a new
 * entity to the right.
 */
@Component({
    components: {}
})
export default class SearchPageEntityLink extends Mixins(StateHelper, Utils) {
    /**
     * The id of the entity to open.
     */
    @Prop()
    id: string;

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;

    /**
     * A string that should identify this link to a group of links. This is necessary
     * since a link must be able to produce a consistent unique id in order for us to
     * know which link is the reason to that an entity is viewed in the detail view to
     * the right of the link's view. For example, if an entity with id x occurs both
     * in the household list and in the event list we must be able to separate those
     * two links so that only the clicked one becomes active.
     */
    @Prop()
    group: string;

    /**
     * Set this to true if this link is not supposed to be a link, for example for
     * board members where we don't have a public id.
     */
    @Prop()
    noLink: boolean;


    /**
     * True if this link is active. A link is active if its public id is shown in the
     * view to the right (that is - level + 1) and its unique id is stored at that
     * level in the details array.
     */
    get isActive(): boolean {
        let nextLevel = this.level + 1;
        return this.details.length > nextLevel && this.details[nextLevel].publicId === this.id && this.details[nextLevel].linkId === this.linkId;
    }

    /**
     * Gets a unique id for this link, so we make sure we only get one ref. This is
     * also the id used in order to track if this is the clicked link when showing the
     * entity with this link's id in the view to the right.
     */
    get linkId(): string {
        return String(this.hashCode(this.group + "-" + this.level + "-" + this.id));
    }

    /**
     * Checks if this link is located within a SearchPageEntity that is the rightmost
     * one allowed on the screen. The objects.css file defines the index of the
     * rightmost details view for different screen sizes and in order to not have to
     * duplicate that logic here, we use one of the css properties that is set on
     * links in the rightmost view. The property we use is the "cursor" property,
     * which is set to "text" in order to indicate that the links are no longer
     * clickable.
     *
     * This might seem a bit hacky (and it is...) but it is rather convenient and
     * without it we would still open more detail views even if they would not be
     * visible.
     */
    get isRightmost(): boolean {
        let element: Element = <Element>this.$refs[this.linkId];
        // The cursor property is set to "text" on links in the the rightmost view.
        return getComputedStyle(element).cursor === "text";
    }

    /**
     * Only emit the click event if we're not located in the rightmost details view.
     *
     * @param event The event to emit.
     */
    handleClick(event: PointerEvent): void {
        if (!this.isRightmost && this.id && !this.noLink) {
            let entityReference: OpenEntityRequest = new OpenEntityRequest(this.id, this.level, this.linkId);
            this.click(entityReference, event);
        }
    }

    /**
     * Returns true if the link should not be clickable.
     */
    get unclickable(): boolean {
        return this.noLink || !this.id;
    }

    /**
     * Emits a 'click' event. Signaling that the link was clicked
     */
    @Emit()
    click(entityReference: OpenEntityRequest, event: PointerEvent): ClickEventData<OpenEntityRequest> {
        return {data: entityReference, pEvent: event};
    }
}
