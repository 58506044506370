
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import FaqItem from "@/components/FaqItem.vue";
import Utils from "@/mixins/utils";
import {
    ACTION_DELETE_MONITOR,
    MonitorRequest
} from "@/store/store-monitor";
import {MUTATION_SIDEBAR_RIGHT_STATUS} from "@/store/store";
import {
    SearchResultEntryIndividualDetails
} from "@/models/search-result-entry-individual-details";
import {SearchResultEntryDetails} from "@/models/search-result-entry-details";
import {
    SearchResultEntryCompanyDetails
} from "@/models/search-result-entry-company-details";
import {
    SearchResultEntryRealPropertyDetails
} from "@/models/search-result-entry-real-property-details";
import {
    SearchResultEntryVehicleDetails
} from "@/models/search-result-entry-vehicle-details";
import ErrorMessage from "@/components/ErrorMessage.vue";
import SvgConstants from "@/mixins/svg-constants";
import {
    SearchResultEntryAddressLocation
} from "@/models/search-result-entry-address-location";
import {MonitoredEntity} from "@/models/monitored-entity";
import {MonitorList} from "@/models/monitor-list";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";

/**
 * This component represents the monitor tab in a search result details view.
 */
@Component({
    components: {SearchPageEntityLink, ErrorMessage, FaqItem}
})
export default class SearchPageEntityCommonMonitor extends Mixins(StateHelper, SvgConstants, Utils) {
    @Prop()
    entity: SearchResultEntryDetails;

    @Prop()
    level: number;

    errorMessage: string = "";

    get monitorListsForEntity(): MonitorList[] {
        return this.getMonitorListsFor(this.entity.id);
    }

    get onList(): boolean {
        return this.monitorListsForEntity.length > 0;
    }

    /**
     * Convenience getter for the individual.
     */
    get individual(): SearchResultEntryIndividualDetails {
        return this.entity.individual;
    }

    /**
     * Convenience getter for the company.
     */
    get company(): SearchResultEntryCompanyDetails {
        return this.entity.company;
    }

    /**
     * Convenience getter for the real property.
     */
    get realProperty(): SearchResultEntryRealPropertyDetails {
        return this.entity.realProperty;
    }

    /**
     * Convenience getter for the address location.
     */
    get addressLocation(): SearchResultEntryAddressLocation {
        return this.entity.addressLocation;
    }

    /**
     * Convenience getter for the vehicle.
     */
    get vehicle(): SearchResultEntryVehicleDetails {
        return this.entity.vehicle;
    }

    /**
     * Checks if we have a monitor on our entry.
     */
    get hasMonitor(): boolean {
        return this.hasMonitorOn(this.entity.id);
    }

    get autoMonitored(): boolean {
        return this.isAutoMonitored(this.entity.id);
    }

    get autoMonitor(): MonitoredEntity | undefined {
        return this.getAutoMonitor(this.entity.id);
    }

    get selfAutoMonitor(): MonitoredEntity | undefined {
        return this.getSelfAutoMonitor(this.entity.id);
    }

    get selfAutoMonitored(): boolean {
        return this.isSelfAutoMonitored(this.entity.id);
    }

    nameOfAutoMonitor(monitor: MonitoredEntity) {
        return this.formatDisplayableEntity(monitor);
    }

    get monitor(): MonitoredEntity {

        let monitor: MonitoredEntity = this.getMonitorFor(this.entity.id);
        if (monitor) {
            return monitor;
        }
        return this.getExclusionMonitorFor(this.entity.id);
    }

    get excludeFromAuto(): boolean {
        return (this.monitor ? this.monitor.excludedFromAuto : false);
    }

    get priority(): string {
        return (this.monitor ? this.monitor.priority : "OFF");
    }

    get lists(): MonitorList[] {
        return this.getMonitorListsFor(this.entity.id);
    }

    /**
     * Gets the complete name.
     */
    get completeName(): string {
        // TODO handle self monitoring and companies.
        if (this.individual) {
            return this.getGivenOrFirstOrPnrWithTruncation(this.individual, 20) + " " + this.getMiddleAndLastNames(this.entity.individual);
        } else if (this.company) {
            return this.company.name;
        } else if (this.addressLocation) {
            return this.formatAddressLocation(this.addressLocation);
        } else if (this.realProperty) {
            return this.realProperty.municipality + " " + this.realProperty.propertyDesignation;
        } else if (this.vehicle) {
            return this.vehicle.type + " " + this.formatRegistrationNumber(this.vehicle.registrationNumber);
        }
    }

    toggleMonitoring(priority: string) {
        if (this.priority === priority) {
            this.createMonitor("OFF");
        } else {
            this.createMonitor(priority);
        }
    }

    /**
     * Creates a monitor for this entity.
     */
    createMonitor(priority: string) {
        this.errorMessage = "";

        let monitorId = this.firstDefined(
            this.individual,
            this.company,
            this.addressLocation,
            this.realProperty,
            this.vehicle
        ).id;
        let request: MonitorRequest = new MonitorRequest();
        request.publicId = monitorId;
        request.priority = priority;
        request.excludeFromAuto = this.excludeFromAuto;

        if (priority === "OFF" && !this.excludeFromAuto) {
            this.ignoreError(this.deleteMonitor());
        } else {
            this.createMonitorFetchUpdates(request).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response);
            });
        }
    }

    handleExcludeFromAuto() {
        if(this.hasMonitor) {
            return;
        }
        this.errorMessage = "";
        let newValue: boolean = !this.excludeFromAuto;

        let monitorId = this.firstDefined(
            this.individual,
            this.company,
            this.addressLocation,
            this.realProperty,
            this.vehicle
        ).id;
        let request: MonitorRequest = new MonitorRequest();
        request.publicId = monitorId;
        request.priority = this.monitor ? this.monitor.priority : "OFF";
        request.excludeFromAuto = newValue;

        if (!newValue && request.priority === "OFF") {
            this.ignoreError(this.deleteMonitor());
        } else {
            this.createMonitorFetchUpdates(request).catch((response) => {
                this.errorMessage = this.extractErrorMessage(response);
            });
        }
    }

    /**
     * Delete the monitor.
     */
    deleteMonitor() {
        return this.$store.dispatch(ACTION_DELETE_MONITOR, this.entity.id);
    }

    handleListSettingsClick(): void {
        let action: string = "monitor-list:lists";

        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, action);
    }

    handlePrioSettingsClick(): void {
        let action: string = "monitor-list:settings";
        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, action);
    }

    handleAutoInfoClick(): void {
        let action: string = "monitor-list:auto";
        this.$store.commit(MUTATION_SIDEBAR_RIGHT_STATUS, action);
    }
}
