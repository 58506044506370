import {Component, Vue} from "vue-property-decorator";
import {ValidationErrorMessage} from "@/models/error-message";

/**
 * This enumeration contains the status that a validation function can return.
 */
export enum ValidationStatus {
    /**
     * The field is valid. Will show notification if touched.
     */
    VALID,

    /**
     * The field is invalid. Will show notification if touched.
     */
    INVALID,

    /**
     * The field is invalid. Will show notification even if untouched. Practical when we
     * want to force the warning flag when validation takes place without the user
     * interacting directly with the input field, such as during backend validation etc.
     */
    FORCE_INVALID
}

/**
 * A validation function evaluates the validation status for an input field. The value is
 * thus always a string. See above for different validation statuses.
 */
export type ValidationFunction = (value: string) => ValidationStatus;


/**
 * Computes the Luhn check digit for the given string. Pretty much a copy of the same
 * algorithm in the IdentificationNumber class.
 *
 * @param str The string.
 * @returns The control digit.
 */
function checkLuhn(str: string): number {
    let sum: number = 0;
    let length: number = str.length;
    let mul: number = 2;


    for (let i: number = (length - 1); i >= 0; i--) {
        let digit: number = parseInt(str[i]) | 0;
        if (mul === 1) {
            sum += digit;
            mul = 2;
        } else {
            // mul === 2
            sum += digit + digit;
            if (digit >= 5) {
                sum -= 9;
            }
            mul = 1;
        }
    }
    let result: number = sum % 10;
    result = (result > 0 ? 10 - result : 0);
    return result;
}

/**
 * Converts a boolean to the correct validation status.
 *
 * @param val The boolean to convert.
 */
function bToV(val: boolean) {
    return val ? ValidationStatus.VALID : ValidationStatus.INVALID;
}

/**
 * Mixin for standard validation functions.
 */
@Component
export default class Validation extends Vue {
    public static readonly TOO_FEW_DIGITS_PHONE: RegExp = new RegExp('^\\d{0,6}$');

    public static readonly ONLY_DIGITS: RegExp = new RegExp('^\\d+$');

    // Something followed by @ followed by something followed by a dot followed by 2 or more characters
    public static readonly EMAIL: RegExp = new RegExp('^.+@.+\\..{2,}');


    /**
     * Wraps another validation function so that empty values are considered
     * valid.
     *
     * @param toWrap The validation function to wrap.
     */
    vAllowEmpty(toWrap: ValidationFunction): ValidationFunction {
        return function (value: string) {
            if (!value) {
                return ValidationStatus.VALID;
            }
            return toWrap(value);
        }
    }

    /**
     * Value must contain at least 'len' characters.
     *
     * @param len The minimum length.
     */
    vMinLength(len: number): ValidationFunction {
        return function (value: string) {
            return bToV(value && value.length >= len);
        }
    }

    /**
     * Value must not contain more than 'len' characters.
     *
     * @param len The maximum length.
     */
    vMaxLength(len: number): ValidationFunction {
        return function (value: string) {
            return bToV(!value || value.length <= len);
        }
    }

    /**
     * Value must be at least minLen and at most maxLen characters.
     *
     * @param minLen The minimum number of characters.
     * @param maxLen The maximum number of characters.
     */
    vLength(minLen: number, maxLen: number): ValidationFunction {
        return this.vAllOf(this.vMinLength(minLen), this.vMaxLength(maxLen));
    }

    /**
     * Validates phone numbers.
     * Does the following checks:
     *
     * 1. Must be no more than 1 dash if any.
     * 2. Must be no more than 1 pair of parentheses. The pair must be valid and not empty if present.
     * 3. The value, with all whitespace, the parentheses and the dash removed, must be at least 7 digits long
     * 4. The value, with all whitespace, the parentheses, and the dash removed, must consist of only digits.
     * 5. There may be a plus sign at the start.
     * This validator uses the key "validPhone" on the error flag.
     */
    vPhone(): ValidationFunction {
        return function (value: string) {
            if (!value) {
                return ValidationStatus.INVALID;
            }

            // If we have more than 2 dashes its invalid
            if (value.split("-").length > 2) {
                return ValidationStatus.INVALID;
            }

            // If we have more than 1 pair of parentheses its invalid
            if (value.split("(").length > 2) {
                return ValidationStatus.INVALID;
            }
            if (value.split(")").length > 2) {
                return ValidationStatus.INVALID;
            }
            // Must also be a valid pair of parentheses if we have any.
            const indxOfStartParenthesis = value.indexOf("(");
            const indxOfEndParenthesis = value.indexOf(")");
            if (indxOfStartParenthesis >= 0 || indxOfEndParenthesis >= 0) {
                // We must have both start and end, not just one of them.
                if (indxOfStartParenthesis < 0 || indxOfEndParenthesis < 0) {
                    return ValidationStatus.INVALID;
                }
                // Start parenthesis must be before end parenthesis
                if (indxOfStartParenthesis >= indxOfEndParenthesis) {
                    return ValidationStatus.INVALID;
                }

                // There must be at least one character inside the parentheses
                if (indxOfEndParenthesis - indxOfStartParenthesis < 2) {
                    return ValidationStatus.INVALID;
                }

                // There must not only be whitespace inside the parentheses
                if (!/\S/.test(value.substring(indxOfStartParenthesis + 1, indxOfEndParenthesis))) {
                    return ValidationStatus.INVALID;
                }
            }

            // Remove whitespace, dashes, and parentheses and test if the number is long enough (0-6 characters is invalid)
            let valueCleaned = value.replace(/(\s+|-|\(|\))/g, '');

            // Remove any potential plus sign at the start
            valueCleaned = valueCleaned.replace(/^\+/, '');

            // Number is too short if this regex matches
            if (Validation.TOO_FEW_DIGITS_PHONE.test(valueCleaned)) {
                return ValidationStatus.INVALID;
            }

            // Does not contain only digits
            if (!Validation.ONLY_DIGITS.test(valueCleaned)) {
                return ValidationStatus.INVALID;
            }

            // All is good.
            return ValidationStatus.VALID;
        }
    }

    /**
     * Validates an email address.
     */
    vEmail(): ValidationFunction {
        return function (value: string) {
            if (!value) {
                return ValidationStatus.INVALID;
            }
            return bToV(Validation.EMAIL.test(value));
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Validates passwords. Currently, this includes the following checks:
     *
     * 1. Must not be one to three characters long. Ane empty password field is ok since
     *    that normally means "don't update the password".
     *
     * This validator uses the key "validPassword" on the error flag.
     */
    vPassword(): ValidationFunction {
        return function (value: string) {
            return bToV(!value || value.length === 0 || value.length > 3);
        }
    }

    /**
     * Validates zip codes. Currently, this involves the following checks:
     *
     * 1. Must be 5 characters long after removing all whitespace.
     * 2. All characters from step 1 must be digits
     *
     * This validator uses the key "validZipCode" on the error flag.
     */
    vZipCode(): ValidationFunction {
        return function (value: string) {
            if (!value || value.length == 0) {
                return ValidationStatus.INVALID;
            }

            // Remove whitespace and check if the result is exactly 5 digits
            let valueCleaned = value.replace(/\s+/g, '');
            if (valueCleaned.length != 5 || !Validation.ONLY_DIGITS.test(valueCleaned)) {
                return ValidationStatus.INVALID;
            }

            // All is good
            return ValidationStatus.VALID;
        }
    }

    /*
     * Validates that the given number has a valid Luhn control digit. All characters except
     * digits are ignored.
     *
     * This validator uses the key "mod10CheckSum" on the error flag.
     */
    vModulo10CheckSum(): ValidationFunction {
        return function (value: string) {
            if (!value) {
                return ValidationStatus.INVALID;
            }

            if (value.length == 0) {
                return ValidationStatus.VALID;
            }
            // Remove everything but digits
            let valueCleaned = value.replace(/\D+/g, '');
            if (valueCleaned.length == 12) {
                valueCleaned = valueCleaned.substr(2);
            }
            if (valueCleaned.length != 10) {
                return ValidationStatus.INVALID;
            }

            let checkDigit: number = checkLuhn(valueCleaned.substr(0, valueCleaned.length - 1));
            return bToV(checkDigit === parseInt(valueCleaned[9], 10));
        }
    }

    /**
     * All of the given validation functions must be valid. Returns the 'worst' validation
     * status, which is important in order to be able to trump the "untouched" state of an
     * input field with the ValidationStatus.FORCE_INVALID status.
     *
     * @param functions The functions.
     */
    vAllOf(...functions: ValidationFunction[]): ValidationFunction {
        return function (value: string) {
            // This is obviously how to find the max of an array...
            return Math.max.apply(null, functions.map((func) => Number(func(value))));
        }
    }

    /**
     * Creates an error message by concatenating all complaints in the given map.
     *
     * @param complaints A mapping from field name to a complaint for that field. Empty
     *                   messages are ignored.
     */
    createErrorMessage(complaints: any): string {
        let errorMessage = "";
        Object.keys(complaints)
            .filter((field) => complaints[field]) // Remove the empty ones.
            .forEach(field => errorMessage += complaints[field] + " ");
        return errorMessage;
    }

    /**
     * Runs all validation functions in the given mapping and store complaints in the
     * provided complaints mapping. Use the given fieldName mapping to produce human
     * readable strings for all field names.
     *
     * @param validation A mapping from field name to the validation function to run for
     *                   that field
     * @param complaints A mapping from field name to a complaint for that field. Will be
     *                   updated with new complaints.
     * @param fieldName  A mapping from field name to a human readable string that should
     *                   be able to replace "---" in the sentence "--- är inte korrekt."
     * @param object     The object to which the field names refer. Thus, the validation
     *                   functions are run for fields in this object.
     */
    runValidationFunctions(validation: any, complaints: any, fieldName: any, object: any): void {
        Object.keys(validation)
            .filter((field) => {
                let func: ValidationFunction = validation[field];
                let value: string = (<any>object)[field];
                return func(value) !== ValidationStatus.VALID;
            })
            .forEach(field => complaints[field] = fieldName[field] + " är inte korrekt. ");
    }

    // noinspection JSMethodCanBeStatic
    /**
     * If the given response is a ValidationErrorMessage, we put the error message for
     * each field in the complaints map, provided there is a key for that field in the
     * map. If the response is a UserErrorMessage, we put the error message under the
     * "generic" key in the complaints map.
     *
     * @param complaints The complaints map. Should contain entries for all fields for
     *                   which we're interested in validation errors.
     * @param errorResponse The response. We only handle ValidationErrorMessage and
     *                      UserErrorMessage responses. All other are no-ops.
     */
    handleErrorMessage(complaints: any, errorResponse: any): void {
        if (!errorResponse) {
            return;
        }
        if (errorResponse.type === "VALIDATION") {
            let msg: ValidationErrorMessage = errorResponse;
            for (let i = 0; i < msg.complaints.length; i++) {
                let complaint = msg.complaints[i];
                if (complaints[complaint.path] != null) {
                    complaints[complaint.path] = complaint.message;
                }
            }
        } else if (errorResponse.type === "USER_MESSAGE") {
            // We got some other error. Let's map it to the 'generic' field.
            complaints["generic"] = errorResponse.errorMessage;
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Remove the error message for the given field, if it is now valid. Also always
     * remove any generic error message. This should be triggered on the change event for
     * an input field in order to properly update the validation status.
     *
     * @param validation A mapping from field name to the validation function to run for
     *                   that field
     * @param complaints A mapping from field name to a complaint for that field. Will be
     *                   updated with new complaints.
     * @param fieldName  A mapping from field name to a human readable string that should
     *                   be able to replace "---" in the sentence "--- är inte korrekt."
     * @param object     The object to which the field names refer. Thus, the validation
     *                   functions are run for fields in this object.
     */
    onFieldChange(validation: any, complaints: any, fieldName: string, object: any) {
        // Always remove the generic complaint.
        complaints["generic"] = "";

        let validationFunction: ValidationFunction = validation[fieldName];

        if (validationFunction) {
            /*
              Store the old complaint and rerun the validation function. If it fails, we just
              reuse the old complaint.
             */
            let oldComplaint: string = complaints[fieldName];
            complaints[fieldName] = "";
            if (validationFunction(object[fieldName]) !== ValidationStatus.VALID) {
                complaints[fieldName] = oldComplaint;
            }
        }
    }
}


