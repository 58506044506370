
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SelectOnClick from "@/components/SelectOnClick.vue";

/**
 * This component represents a number formatted in a way that prevents line
 * breaks in the middle of the number. It is also possible to specify a unit
 * to be placed after the number.
 */
@Component({
    components: {SelectOnClick}
})
export default class FNum extends Mixins(Utils) {
    /**
     * The number to format.
     */
    @Prop()
    value: number | string;

    /**
     * Set this in order to format with a fixed number of decimals.
     */
    @Prop()
    numFixedDecimals: number;

    /**
     * This is the limit above which we ignore formatting decimals, should there
     * be any.
     */
    @Prop()
    maxValueForDecimals: number;

    /**
     * The unit, if any.
     */
    @Prop()
    unit: string;

    /**
     * Set to true in order to avoid wrapping the formatted number in a
     * select-on-click tag.
     */
    @Prop({default: false})
    skipSelect: boolean;


    /**
     * Renders the html.
     */
    get renderNumber(): string {
        let ret: string = this.formatNumberPlain(this.value, this.numFixedDecimals, this.maxValueForDecimals);

        // Don't show the unit if the value is unspecified.
        if (this.unit && ret !== this.EM_DASH) {
            ret += " " + this.unit;
        }
        return ret;
    }
}
