
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import Utils from "@/mixins/utils";
    import SvgConstants from "@/mixins/svg-constants";
    import {ListUserParameters} from "@/models/end-user-subscription-parameters";
    import {ACTION_DELETE_FREERIDER} from "@/store/store-freeriders";

    /**
     * This component represents a single entry in the list of freeriders in the right
     * sidebar.
     */
    @Component({})
    export default class SidebarRightFreeriderListEntry extends Mixins(SvgConstants, Utils) {
        @Prop()
        listUser: ListUserParameters;

        showConfirmRemove: boolean = false;

        showRemoveAnimation: boolean = false;

        showError: boolean = false;


        /**
         * Show the confirm dialog before removing the freerider.
         */
        doShowConfirmRemove() {
            this.showConfirmRemove = true;
            this.showError = false;
        }

        /**
         * Delete the freerider.
         */
        deleteFreerider() {
            this.showRemoveAnimation = true;
            this.showError = false;
            setTimeout(() => {
                this.$store.dispatch(ACTION_DELETE_FREERIDER, this.listUser).catch(() => {
                    this.showError = true;
                }).finally(() => {
                    this.showConfirmRemove = false;
                    this.showRemoveAnimation = false;
                })
            }, 500)
        }

        /**
         * Gets the human readable status string.
         */
        get userStatus(): string {
            if (this.listUser) {
                if (this.listUser.status === "ACTIVE") {
                    return "aktiv";
                } else if (this.listUser.status === "INVITED") {
                    return "inbjuden, väntar på registrering";
                }
                return "okänd";
            }
            return "";
        }
    }
