import {ActionTree, Module} from "vuex";
import {HTTP} from "@/services/http-provider";
import {resetState, RootState} from "@/store/store";
import {ListUserParameters} from "@/models/end-user-subscription-parameters";
import {Global} from "@/global";

export const MUTATION_FREERIDERS = "freeriders";

/**
 * The string before the / is the namespace (as set in the root store) and the
 * names after the / must match the names of the functions in the ActionTree.
 */
export const ACTION_INVITE_FREERIDER = "freerider/inviteFreerider";
export const ACTION_DELETE_FREERIDER = "freerider/deleteFreerider";
export const ACTION_FETCH_USERS = "freerider/fetchUsers";

export interface FreeriderState {
    freeriders: ListUserParameters[];
}

export const actions: ActionTree<FreeriderState, RootState> = {
    /**
     * Fetches the list of users for the currently active subscription from the
     * server. If there is no active subscription, or if the user is not the
     * owner of the active subscription, we empty the list.
     *
     * @param context The context.
     */
    fetchUsers(context): Promise<ListUserParameters[]> {
        return new Promise((resolve, reject) => {
            let as = context.rootState.activeSubscription;

            if (context.rootGetters.signedIn && as && as.owner) {
                HTTP.get<ListUserParameters[]>("/sapi/subscription/" + as.refNo + "/users").then((response: ListUserParameters[]) => {
                    context.commit(MUTATION_FREERIDERS, response);
                    resolve(response)
                }).catch((error) => {
                    context.commit(MUTATION_FREERIDERS, []);
                    reject(error);
                });
            } else {
                context.commit(MUTATION_FREERIDERS, []);
                resolve([])
            }
        })
    },

    /**
     * Sends an invite to the given freerider on the server and adds it as the
     * first element in our monitor list. The full list of monitors is not
     * retrieved from the server.
     *
     * @param context The context.
     * @param freerider The freerider entry to invite.
     */
    inviteFreerider(context, freerider: ListUserParameters): Promise<ListUserParameters[]> {
        return new Promise((resolve, reject) => {
            HTTP.post<ListUserParameters[]>("/sapi/subscription/invite-freerider", freerider).then((response: ListUserParameters[]) => {
                context.commit(MUTATION_FREERIDERS, response);
                resolve(response)
            }).catch((error) => {
                reject(error);
            });
        })
    },

    /**
     * Deletes the freerider with the given parameters.
     *
     * @param context The context.
     * @param freerider The freerider to delete.
     */
    deleteFreerider(context, freerider: ListUserParameters): Promise<ListUserParameters[]> {
        return new Promise((resolve, reject) => {
            HTTP.post<ListUserParameters[]>("/sapi/subscription/delete-freerider", freerider).then((response: ListUserParameters[]) => {
                context.commit(MUTATION_FREERIDERS, response);
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        })
    }
};

/**
 * Returns the initial state of this store.
 */
function initialState(): FreeriderState {
    return {
        freeriders: []
    };
}

/**
 * This module handles the freerider list as well as the actions that are
 * possible to perform upon it.
 */
export const freeriderModule: Module<FreeriderState, RootState> = {
    namespaced: true,
    state: initialState(),
    getters: {},
    mutations: {
        /**
         * Sets the complete list of freeriders.
         *
         * @param state The state.
         * @param freeriders The new set of freeriders.
         */
        [MUTATION_FREERIDERS]: (state: FreeriderState, freeriders: ListUserParameters[]) => state.freeriders = freeriders,

        /**
         * Resets the store to its initial state.
         *
         * @param state The current state. Will be reset to its initial values.
         */
        [Global.MUTATION_RESET_STORE]: (state: FreeriderState) => resetState(state, initialState()),

    },
    actions: actions,
};
