/**
 * This class contains info about the application state that we need to know when
 * restoring said state after an email login confirmed in the same browser as where it was
 * started.
 */
import {Dictionary} from "vue-router/types/router";
import {SubscriptionParameters} from "@/models/end-user-subscription-parameters";

export class EmailLoginApplicationState {
    /**
     * The current url path.
     */
    path: string;

    /**
     * The current query parameters.
     */
    query: Dictionary<string | string[]>;

    /**
     * If the email sign in took place during a subscription registration, and the user
     * verified the email address in the same browser, we must be able to create the
     * subscription from the new tab in which the verification takes place. Thus, we store
     * the subscription here in those cases.
     */
    subscription: SubscriptionParameters;

    /**
     * Store the user invite link id here if the sign in takes placed during processing of
     * an invite.
     */
    userInviteLinkId: string;
}