import {Http} from "@/services/http";
import {AuthRequest} from "@/models/auth-request.model";
import store from "@/store/store"

export class HttpLocal extends Http {
    unauthorized: any = JSON.parse('{"type":"USER_MESSAGE","errorType":"generic","errorHeader":"Otillåten operation.","errorMessage":"Du har inte tillräckliga rättigheter för att utföra den här åtgärden."}');

    private count: number = 0;


    public get<T>(path: string, params?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            if (path.startsWith("/sapi/search/individual/")) {
                this.handleFetchEntity(path, resolve, reject);
            }
            switch (path) {
                default:
                    // Might just use normal http request here.
                    reject("Local HTTP does not support GET on the path '" + path + "'");
            }
        });
    }

    public post<T>(path: string, data?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            if (path.startsWith("/sapi/monitor/delete/")) {
                resolve(undefined);
            }

            switch (path) {
                case "/api/auth/login/credentials":
                    this.handleAuthWithCredentials(data, resolve, reject);
                    break;
                case "/sapi/monitor/list":
                    this.handleMonitorList(resolve, reject);
                    break;
                case "/sapi/search":
                    this.handleSearch(data, resolve, reject);
                    break;

                default:
                    // Might just use normal http request here.
                    reject("Local HTTP does not support POST on the path '" + path + "'");
            }
        });
    }

    // noinspection JSMethodCanBeStatic
    private handleAuthWithCredentials(body: AuthRequest, resolve: any, reject: any): void {
        if (body.secret === "test") {
            resolve({
                "status": null,
                "authResponse": {
                    "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDAwMTUiLCJyb2xlcyI6WyJBVVRIRU5USUNBVEVEIl0sImlhdCI6MTU1MDEzOTE5MCwiZXhwIjoxNTUwMTQwOTkwfQ.X7FL3-YH6TjsDZkayI0O1XYqMwCIdQSx8H0wp-t5Qz7yAAT4s7PSRWASjBbwb9vrD2N2YzBPfX6UDxtqLKRw8A",
                    "refreshToken": "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJjN2FhZWY5OC0yMzRkLTQ1ZmYtODNjNS0wYTVjYTZlMDE1M2QiLCJzdWIiOiIxMDAwMTUiLCJpYXQiOjE1NTAxMzkxOTAsImV4cCI6MTU1MDIyNTU5MH0.CheAJHknTxfj-K-I4Ue3fMOOPtVvUqR-l4SmvBI0cZH-OwRG43PQ1UZlvXjyjBCstvqa9I7m1uCdRMd7_-iQ6Q"
                },
                "endUserParameters": {
                    "refNo": "100015",
                    "publicId": "1744508358068",
                    "personalNumber": "197004247636",
                    "firstName": "Medium",
                    "lastName": "Mediumsson",
                    "phoneNumber": "070-998889",
                    "email": "per.alexius@nodeus.se",
                    "password": null,
                    "numAllowedMonitors": 3
                },
                "subscriptionParameters": {
                    "refNo": "18.417.010.01",
                    "organisationNumber": "5565745600",
                    "companyName": "Racasse AB",
                    "paymentEmail": "per.alexius@nodeus.se",
                    "paymentReference": "",
                    "address1": "Sveavägen 66",
                    "address2": "",
                    "address3": "",
                    "zip": "111 34",
                    "city": "Stockholm",
                    "termsSigned": false,
                    "owner": true,
                    "availableCreditReports": false,
                    "numAllowedUsers": 6,
                    "demo": false,
                    "endDate": "2019-06-25"
                },
                "subscriptions": [{
                    "refNo": "18.417.010.01",
                    "organisationNumber": "5565745600",
                    "companyName": "Racasse AB",
                    "paymentEmail": "per.alexius@nodeus.se",
                    "paymentReference": "",
                    "address1": "Sveavägen 66",
                    "address2": "",
                    "address3": "",
                    "zip": "111 34",
                    "city": "Stockholm",
                    "termsSigned": false,
                    "owner": true,
                    "availableCreditReports": false,
                    "numAllowedUsers": 6,
                    "demo": false,
                    "endDate": "2019-06-25"
                }],
                "message": null
            });
        } else {
            reject(this.unauthorized);
        }
    }

    private handleMonitorList(resolve: any, reject: any): void {
        if (store.state.authorityInfo) {
            let monitors = [{
                "id": "1390169054890",
                "personalNumber": "19700107-3530",
                "firstNames": "Anders Mikael",
                "lastNames": "Svensson",
                "givenNameCode": "10",
                "city": "Simrishamn",
                "gender": "MALE",
                "age": 49,
                "secretIdentity": false
            }, {
                "id": "1620524619103",
                "personalNumber": "19700112-7831",
                "firstNames": "Hans-Erik Anders",
                "lastNames": "Löfström",
                "givenNameCode": "30",
                "city": "Sörberge",
                "gender": "MALE",
                "age": 49,
                "secretIdentity": false
            }, {
                "id": "1837532908813",
                "personalNumber": "19701216-8501",
                "firstNames": "Jenny Terese",
                "lastNames": "Karlsson",
                "givenNameCode": "10",
                "city": "Umeå",
                "gender": "FEMALE",
                "age": 48,
                "secretIdentity": false
            }];

            resolve(monitors);
        } else {
            reject(this.unauthorized);
        }
    }

    // noinspection JSMethodCanBeStatic
    private handleSearch(body: any, resolve: any, reject: any) {
        if (!body.searchString) {
            resolve({"totalNumHits": 0, "tooManyHits": false, "hits": []});
        }
        if (body.searchString.indexOf("banan") !== -1) {
            resolve({
                "totalNumHits": 1,
                "tooManyHits": false,
                "hits": [{
                    "id": "1616292477696",
                    "birthDate": "19700101",
                    "firstNames": "Sven Gunnar",
                    "middleNames": "Bengtsson",
                    "lastNames": "Banan",
                    "givenNameCode": "10",
                    "fbfStreetAddress": "Hjortstigen 14",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "29896",
                    "fbfCity": "Östra Sönnarslöv",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "Jönsson",
                    "spStreetAddress": "Särskilda gatan 3",
                    "spAddressPrefix": "Över gården",
                    "spZip": "33212",
                    "spCity": "Kräcklinge",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "MALE",
                    "age": 74,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "SINGLE_PERSON_HOUSEHOLD"
                }]
            });
        } else if (body.searchString.indexOf("anders") !== -1) {
            resolve({
                "totalNumHits": 6,
                "tooManyHits": false,
                "hits": [{
                    "id": "1616292477696",
                    "birthDate": "19730101",
                    "firstNames": "Johan Anders",
                    "middleNames": "Jönsson",
                    "lastNames": "Hademyr",
                    "givenNameCode": "10",
                    "fbfStreetAddress": "Norra Storgatan 42",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "26732",
                    "fbfCity": "Bjuv",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "",
                    "spStreetAddress": "",
                    "spAddressPrefix": "",
                    "spZip": "",
                    "spCity": "",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "MALE",
                    "age": 45,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "UNKNOWN"
                }, {
                    "id": "1041106806665",
                    "birthDate": "19830101",
                    "firstNames": "Anders Magnus",
                    "middleNames": "",
                    "lastNames": "Svensson",
                    "givenNameCode": "20",
                    "fbfStreetAddress": "Storgatan 10",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "26740",
                    "fbfCity": "Bjuv",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "",
                    "spStreetAddress": "",
                    "spAddressPrefix": "",
                    "spZip": "",
                    "spCity": "",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "MALE",
                    "age": 27,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "MULTIPLE_PERSON_HOUSEHOLD"
                }, {
                    "id": "1624769232021",
                    "birthDate": "19600101",
                    "firstNames": "Anders Nicanor",
                    "middleNames": "",
                    "lastNames": "Vräkelthunga",
                    "givenNameCode": "10",
                    "fbfStreetAddress": "von Übens gränd 10",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "26774",
                    "fbfCity": "Billesholm",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "",
                    "spStreetAddress": "",
                    "spAddressPrefix": "",
                    "spZip": "",
                    "spCity": "",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "MALE",
                    "age": 53,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "SINGLE_PERSON_HOUSEHOLD"
                }, {
                    "id": "1854048243321",
                    "birthDate": "19830101",
                    "firstNames": "Vedomar Anders Harkvål",
                    "middleNames": "",
                    "lastNames": "Torebjök",
                    "givenNameCode": "30",
                    "fbfStreetAddress": "Södergatan 1",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "26777",
                    "fbfCity": "Ekeby",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "",
                    "spStreetAddress": "",
                    "spAddressPrefix": "",
                    "spZip": "",
                    "spCity": "",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "MALE",
                    "age": 32,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "SINGLE_PERSON_HOUSEHOLD"
                }, {
                    "id": "1892688026594",
                    "birthDate": "19720501",
                    "firstNames": "Anders Lilleman",
                    "middleNames": "",
                    "lastNames": "Storehaal",
                    "givenNameCode": "20",
                    "fbfStreetAddress": "Bengtsgatan 4",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "26777",
                    "fbfCity": "Ekeby",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "",
                    "spStreetAddress": "",
                    "spAddressPrefix": "",
                    "spZip": "",
                    "spCity": "",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "MALE",
                    "age": 39,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "MULTIPLE_PERSON_HOUSEHOLD"
                }, {
                    "id": "1509609950191",
                    "birthDate": "19870303",
                    "firstNames": "Singoalla Ballerina",
                    "middleNames": "",
                    "lastNames": "Svensson",
                    "givenNameCode": "10",
                    "fbfStreetAddress": "Anderstorget 88",
                    "fbfAddressPrefix": "",
                    "fbfCareOf": "",
                    "fbfZip": "26777",
                    "fbfCity": "Ekeby",
                    "fbfRegion": "Skåne län",
                    "spCareOf": "",
                    "spStreetAddress": "",
                    "spAddressPrefix": "",
                    "spZip": "",
                    "spCity": "",
                    "foreignAddress1": "",
                    "foreignAddress2": "",
                    "foreignAddress3": "",
                    "foreignCountry": "",
                    "gender": "FEMALE",
                    "age": 31,
                    "secretIdentity": false,
                    "deceased": false,
                    "deregistered": false,
                    "deregisteredDate": null,
                    "householdHousingType": "SINGLE_FAMILY",
                    "householdCompositionType": "MULTIPLE_PERSON_HOUSEHOLD"
                }]
            });
        } else if (body.searchString.startsWith("ddd")) {
            // For testing that slow requests get cancelled by later posted quick requests.
            if (this.count % 2 == 0) {
                console.debug("Should be quick");
                setTimeout(() => resolve(JSON.parse('{"totalNumHits":1,"tooManyHits":false,"hits":[{"id":"1409797263748","birthDate":"19310424","firstNames":"Quick","middleNames":"","lastNames":"Jönsson","givenNameCode":"10","fbfStreetAddress":"Lärarvägen 4","fbfAddressPrefix":"","fbfCareOf":"","fbfZip":"95531","fbfCity":"Råneå","fbfRegion":"Norrbottens län","spCareOf":"","spStreetAddress":"","spAddressPrefix":"","spZip":"","spCity":"","foreignAddress1":"","foreignAddress2":"","foreignAddress3":"","foreignCountry":"","gender":"MALE","age":87,"secretIdentity":false,"deceased":false,"deregistered":false,"deregisteredDate":null,"householdHousingType":"SINGLE_FAMILY","householdCompositionType":"MULTIPLE_PERSON_HOUSEHOLD"}]}')), 100);
            } else {
                console.debug("Should be slow");
                setTimeout(() => resolve(JSON.parse('{"totalNumHits":1,"tooManyHits":false,"hits":[{"id":"1518620286289","birthDate":"19280826","firstNames":"Slow","middleNames":"","lastNames":"Jönsson","givenNameCode":"10","fbfStreetAddress":"Kartvägen 90","fbfAddressPrefix":"","fbfCareOf":"","fbfZip":"95441","fbfCity":"Södra Sunderbyn","fbfRegion":"Norrbottens län","spCareOf":"","spStreetAddress":"","spAddressPrefix":"","spZip":"","spCity":"","foreignAddress1":"","foreignAddress2":"","foreignAddress3":"","foreignCountry":"","gender":"MALE","age":90,"secretIdentity":false,"deceased":false,"deregistered":false,"deregisteredDate":null,"householdHousingType":"SINGLE_FAMILY","householdCompositionType":"MULTIPLE_PERSON_HOUSEHOLD"}]}')), 1000);
            }
            this.count++;
            return;
        } else if (body.searchString.startsWith("eee")) {
            // For testing that slow requests that fail get cancelled by later posted quick requests.
            if (this.count % 2 == 0) {
                console.debug("Should be quick");
                setTimeout(() => reject("Quick bad"), 100);
            } else {
                console.debug("Should be slow");
                setTimeout(() => reject("Slow bad"), 1000);
            }
            this.count++;
            return;
        } else if (body.searchString.indexOf("error") !== -1) {
            reject("Bad stuff happened.");
        } else {
            resolve({"totalNumHits": 0, "tooManyHits": false, "hits": []});
        }
    }

    // noinspection JSMethodCanBeStatic
    private handleFetchEntity(path: string, resolve: any, reject: any) {
        let id: string = path.substr(path.lastIndexOf("/") + 1);
        if (id === "1616292477696") {
            resolve({
                "id": "1616292477696",
                "birthDate": "19700101",
                "firstNames": "Sven Gunnar",
                "middleNames": "Bengtsson",
                "lastNames": "Banan",
                "givenNameCode": "00",
                "fbfStreetAddress": "Hjortstigen 14",
                "fbfAddressPrefix": "",
                "fbfCareOf": "",
                "fbfZip": "29896",
                "fbfCity": "Östra Sönnarslöv",
                "fbfRegion": "Skåne län",
                "spCareOf": "",
                "spStreetAddress": "",
                "spAddressPrefix": "",
                "spZip": "",
                "spCity": "",
                "foreignAddress1": "",
                "foreignAddress2": "",
                "foreignAddress3": "",
                "foreignCountry": "",
                "gender": "MALE",
                "age": 74,
                "secretIdentity": false,
                "deceased": false,
                "deregistered": false,
                "deregisteredDate": null,
                "householdHousingType": "SINGLE_FAMILY",
                "householdCompositionType": "SINGLE_PERSON_HOUSEHOLD",
                "personalNumber": "19440412-3392",
                "fbfDate": "1976-03-29",
                "fbfMunicipality": "Kristianstads kommun",
                "marriedTo": null,
                "householdMembers": [{
                    "id": "1041106806665",
                    "firstNames": "Wild Wendy",
                    "lastNames": "Watermelon",
                    "givenNameCode": "20",
                    "gender": "FEMALE",
                    "age": 69
                }, {
                    "id": "1624769232021",
                    "firstNames": "Maniac Mandy",
                    "lastNames": "Mango",
                    "givenNameCode": "20",
                    "gender": "FEMALE",
                    "age": 53
                }],
                "editionYear": 2019,
                "editionNum": 1,
                "publicationDate": "2018-12-06",
                "incomeYear": -1,
                "middleX": -1,
                "middleY": -1,
                "monitor": false
            });
        } else if (id === "1041106806665") {
            resolve({
                "id": "1041106806665",
                "birthDate": "19490304",
                "firstNames": "Wild Wendy",
                "middleNames": "",
                "lastNames": "Watermelon",
                "givenNameCode": "20",
                "fbfStreetAddress": "Tt-Banan 12",
                "fbfAddressPrefix": "",
                "fbfCareOf": "",
                "fbfZip": "77693",
                "fbfCity": "Hedemora",
                "fbfRegion": "Dalarnas län",
                "spCareOf": "",
                "spStreetAddress": "",
                "spAddressPrefix": "",
                "spZip": "",
                "spCity": "",
                "foreignAddress1": "",
                "foreignAddress2": "",
                "foreignAddress3": "",
                "foreignCountry": "",
                "gender": "FEMALE",
                "age": 69,
                "secretIdentity": false,
                "deceased": false,
                "deregistered": false,
                "deregisteredDate": null,
                "householdHousingType": "SINGLE_FAMILY",
                "householdCompositionType": "MULTIPLE_PERSON_HOUSEHOLD",
                "personalNumber": "",
                "fbfDate": null,
                "fbfMunicipality": null,
                "marriedTo": null,
                "householdMembers": [{
                    "id": "1624769232021",
                    "firstNames": "Sten Bertil Anders",
                    "lastNames": "Nilsson",
                    "givenNameCode": "10",
                    "gender": "MALE",
                    "age": 74
                }],
                "editionYear": 2019,
                "editionNum": 1,
                "publicationDate": "2018-12-06",
                "incomeYear": -1,
                "middleX": -1,
                "middleY": -1,
                "monitor": false
            });
        } else if (id === "1624769232021") {
            resolve({
                "id": "1624769232021",
                "birthDate": "19940304",
                "firstNames": "Maniac Mandy",
                "middleNames": "",
                "lastNames": "Mango",
                "givenNameCode": "20",
                "fbfStreetAddress": "Tt-Banan 12",
                "fbfAddressPrefix": "",
                "fbfCareOf": "",
                "fbfZip": "77693",
                "fbfCity": "Hedemora",
                "fbfRegion": "Dalarnas län",
                "spCareOf": "",
                "spStreetAddress": "",
                "spAddressPrefix": "",
                "spZip": "",
                "spCity": "",
                "foreignAddress1": "",
                "foreignAddress2": "",
                "foreignAddress3": "",
                "foreignCountry": "",
                "gender": "FEMALE",
                "age": 53,
                "secretIdentity": false,
                "deceased": false,
                "deregistered": false,
                "deregisteredDate": null,
                "householdHousingType": "SINGLE_FAMILY",
                "householdCompositionType": "MULTIPLE_PERSON_HOUSEHOLD",
                "personalNumber": "",
                "fbfDate": null,
                "fbfMunicipality": null,
                "marriedTo": null,
                "householdMembers": [{
                    "id": "1624769232021",
                    "firstNames": "Sten Bertil Anders",
                    "lastNames": "Nilsson",
                    "givenNameCode": "10",
                    "gender": "MALE",
                    "age": 74
                }],
                "editionYear": 2019,
                "editionNum": 1,
                "publicationDate": "2018-12-06",
                "incomeYear": -1,
                "middleX": -1,
                "middleY": -1,
                "monitor": false
            });
        } else {
            reject();
        }
    }
}
