
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

/**
 * This class represents a single option.
 */
export class CustomSelectOption {
    /**
     * The value that is selected and emitted as parameter to the clicked event.
     */
    value: string;

    /**
     * The text to be shown in the drop down.
     */
    text: string;

    constructor(value: string, text: string = value) {
        this.value = value;
        this.text = text;
    }
}

/**
 * This component represents a custom drop down menu component.
 */
@Component
export default class CustomSelect extends Vue {
    /**
     * The options array.
     */
    @Prop({required: true})
    options: CustomSelectOption[];

    /**
     * The default value. If this is changed from outside the component the
     * selected value will be updated accordingly.
     */
    @Prop()
    default: string;

    /**
     * Possible to set tabindex if necessary.
     */
    @Prop()
    tabIndex: Number;

    selected: string = "";

    open: boolean = false;


    mounted() {
        if (!this.default && this.options.length) {
            this.selected = this.options[0].value;
        } else {
            this.selected = this.default;
        }
        this.$emit("change", this.selected);
    }

    /**
     * It must be possible to change the selected value from outside this
     * component. This is accomplished by watching the default value, and
     * explicitly setting the selected element if the default value changes.
     *
     * @param newVal The new value.
     */
    @Watch("default")
    onDefaultChange(newVal: string) {
        this.clicked(newVal);
    }

    /**
     * Returns the text for the option that is currently selected.
     */
    get textForSelectedOption(): string {
        let option: CustomSelectOption = this.options.find(option => option.value === this.selected);
        return option ? option.text : this.options[0].text;
    }

    /**
     * Close the select and emit a change event if the value has changed.
     * @param value
     */
    clicked(value: string): void {
        this.open = false;
        if (this.selected !== value) {
            this.selected = value;
            this.$emit('change', value);
        }
    }
}
