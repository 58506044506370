
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import StateHelper from "../mixins/state-helper";
import SvgConstants from "../mixins/svg-constants";
import Utils from "../mixins/utils";
import {DisplayableDocument} from "@/models/displayable-document";
import SearchPageEntityDocumentListEntry
    from "@/components/SearchPageEntityDocumentListEntry.vue";
import {HTTP} from "@/services/http-provider";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import Expandable from "@/components/Expandable.vue";
import VisibleAware from "@/mixins/visible-aware";
import InlineModal from "@/mixins/inline-modal";


/**
 * This component represents the list of documents of a given type.
 */
@Component({
    components: {
        Expandable,
        ProgressBar, ErrorMessage, SearchPageEntityDocumentListEntry
    }
})
export default class SearchPageEntityDocumentList extends Mixins(InlineModal, StateHelper, SvgConstants, Utils, VisibleAware) {
    readonly CONFIRMATION_DIALOG_HEIGHT: number = 83;

    @Prop()
    documents: Array<DisplayableDocument>;

    @Prop()
    type: string;

    @Prop()
    price: number;

    @Prop()
    entityId: string;

    @Prop()
    urlPart: string;

    @Prop({default: true})
    availableToOrder: boolean;

    @Prop({default: "Ej tillgänglig"})
    unavailableToOrderText: string;

    @Prop({default: false})
    onlyShowList: boolean;

    @Prop({default: false})
    showDatePicker: boolean;

    /**
     * The minimum date value on the format yyyy-MM-dd.
     */
    @Prop()
    minDate: string;

    /**
     * The maximum date value on the format yyyy-MM-dd.
     */
    @Prop()
    maxDate: string;

    showAll: boolean = false;

    orderInProgress: boolean = false;

    showProgressBar: boolean = false;

    disableOrderButton: boolean = false;

    errorMessage: string = "";

    invalidDateMessage: string = "";

    newDocumentId: string = "";

    date: string = "";

    inputDate: string = "";


    mounted() {
        this.updateDates();
    }

    /**
     * Reload documents when we become visible.
     *
     * @param val The new visible value.
     */
    @Watch("isVisible")
    onVisibleChange(val: boolean): void {
        if (val) {
            this.updateDates();
        } else {
            this.closeDatePicker();
            this.errorMessage = "";
        }
    }

    /**
     * Sets the dates from the current max date.
     */
    updateDates(): void {
        if (this.showDatePicker) {
            this.date = this.maxDate;
            this.inputDate = this.maxDate;
        }
    }

    /**
     * We have to reset the date to the max value when we're not showing the
     * date input anymore.
     *
     * @param newVal The id of the new active inline modal.
     */
    @Watch("$store.state.activeInlineModal")
    onActiveInlineModalChange(newVal: string): void {
        if (newVal !== this.uniqueId) {
            setTimeout(() => this.setToLatest(), 500);
        }
    }

    @Watch("$store.state.documents.newDocumentId")
    onNewDocumentChange(newDocumentId: string): void {
        this.newDocumentId = newDocumentId;
    }

    get priceString(): string {
        if (this.price) {
            return "kostnad " + this.price + " kr + moms";
        } else {
            return "ingår i abonnemanget";
        }
    }

    getOrderText(): string {
        switch (this.type) {
            case "ARTICLES_OF_ASSOCIATION":
                return "Beställ ny bolagsordning";
            case "STATUTES":
                return "Beställ nya stadgar";
            case "FINANCIAL_PLAN":
                return "Beställ ny ekonomisk plan"
            case "CASE_LIST":
                return "Beställ ärendeförteckning från Bolagsverket";
            case "CERTIFICATE_OF_REGISTRATION":
                return "Beställ nytt registreringsbevis";
            case "CERTIFICATE_OF_REGISTRATION_ENGLISH":
                return "Beställ nytt registreringsbevis på engelska";
            case "REAL_PROPERTY_REPORT":
                return "Beställ ny fastighetsrapport";
            case "REAL_PROPERTY_TAXATION_REPORT":
                return "Beställ ny taxeringsrapport";
            case "TITLE_DEED_AND_HISTORICAL_OWNER_REPORT":
                return "Beställ ny rapport över lagfart och tidigare ägare";
            case "COMPANY_REPORT":
                return "Beställ ny företagsrapport";
            case "COMPANY_MORTGAGE_REPORT":
                return "Beställ ny rapport över företagsinteckningar";
            case "COMPANY_VEHICLE_REPORT":
                return "Beställ ny rapport över fordonsinnehav";
            case "COMPANY_CAPITAL_REPORT":
                return "Beställ ny kapitalrapport";
            case "INDIVIDUAL_REPORT":
                return "Beställ ny personrapport";
            case "INDIVIDUAL_COMMITMENT_REPORT":
                return "Beställ ny rapport över företagsengagemang";
            default:
                return "";
        }
    }

    /**
     * Called when the user clicks the icon and text. Does different things
     * depending on whether the date picker is visible or not.
     */
    expandClicked(): void {
        if (this.showDatePicker) {
            this.toggleInlineModal();
            return;
        }
        this.orderDocument();
    }

    /**
     * Orders the document and emits an event to the parent component that can
     * update the information about the downloadable document that was
     * created.
     */
    orderDocument(): void {
        if (this.disableOrderButton) {
            // Even though we disable the button, we can still trigger this
            // function through expandClicked, i.e., a click on the
            // document icon or loading bar (when the date picker is hidden),
            // so we have this conditional return statement
            return;
        }
        if (!this.availableToOrder) {
            return;
        }
        this.errorMessage = "";
        let url: string = "/sapi/documents/order/" + this.urlPart + "/" + this.entityId + "?subscription=" + this.activeSubscriptionRefNo;
        if (this.date) {
            url += "&date=" + this.date;
        }

        this.closeDatePicker();

        // Block requesting a new report until we get the response, so that
        // the user cannot accidentally order duplicate reports
        this.disableOrderButton = true;
        // Wait up to 300 ms after sending request before showing progress bar
        // The reasoning is that there are two cases: the request finishes
        // faster than 300 ms, in which case we don't show a progress bar at all, or,
        // the request takes more than 300 ms, in which case we show the progress bar
        // for an artifical extra delay of 500 ms, so that the UX looks a little nicer.
        let timeout = setTimeout(() => {
            this.showProgressBar = true;
            this.orderInProgress = true;
        }, 300);
        HTTP.post<DisplayableDocument>(url).then((downloadableDoc: DisplayableDocument) => {
            // Now we got back the document metadata from the server,
            // but haven't downloaded the doc itself
            if (this.orderInProgress) {
                // If still within 300 ms of starting request, then wait 500 ms
                // more before downloading all documents, so that the progress
                // bar doesn't finish "too quickly"
                setTimeout(() => {
                    this.$emit("ordered-document", downloadableDoc);
                    this.showProgressBar = false;
                }, 500);
            } else {
                // If more than 300 ms have elapsed already, download all documents directly,
                // skipping the progress bar
                this.$emit("ordered-document", downloadableDoc);
                this.showProgressBar = false;
            }
        }).catch((error) => {
            this.errorMessage = this.extractErrorMessage(error);
            this.showProgressBar = false;
        }).finally(() => {
            clearTimeout(timeout);
            this.orderInProgress = false;
            // Finally allow the user to click the button again (download more reports)
            this.disableOrderButton = false;
        });
    }

    deleteDocument(documentId: string) {
        /*
          Call the delete-endpoint and then propagate upwards so that we can
          delete it from the arrays.
         */
        let url: string = "/sapi/documents/delete/" + documentId;
        if (this.activeSubscriptionRefNo) {
            url = url + "?subscription=" + this.activeSubscriptionRefNo;
        }
        HTTP.post(url).then(() => {
            this.$emit("delete-document", documentId);
        }).catch((error) => {
            this.errorMessage = this.extractErrorMessage(error);
        })
    }

    scrollToShowConfirmation(index: number) {
        this.$nextTick(() => {
            this.scrollForDialogBox(this.getListEntryRef(index), this.CONFIRMATION_DIALOG_HEIGHT);
        });
    }

    scrollToCancelConfirmation(index: number) {
        this.$nextTick(() => {
            this.scrollForDialogBox(this.getListEntryRef(index), -this.CONFIRMATION_DIALOG_HEIGHT);
        });
    }

    private getListEntryRef(index: number): any {
        let refName = "document_list_entry_" + index;
        return this.$refs[refName] as any;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Takes the ref of a document list entry and scrolls the given height
     * by manipulating the scrollTop value of the scrollable div.
     *
     * @param refToScrollFor The ref to scroll for as returned by
     *                       getListEntryRef.
     * @param heightToScroll The height to add to the scrollTop. May be
     *                       negative to "scroll up" and positive to
     *                       "scroll down".
     */
    private scrollForDialogBox(refToScrollFor: any, heightToScroll: number) {
        /*
          The scroll bar is tied to the div with the class "object_w3" and we
          use the Element.closest() function to get to it and add a hardcoded
          value that represents enough height to show the dialog box.
        */
        let scrollable: any = refToScrollFor[0].$el.closest(".object_w3");
        if (scrollable && scrollable.scrollTop !== undefined) {
            scrollable.scrollTop += heightToScroll;
        }
    }

    /**
     * Closes the expandable date picker.
     */
    closeDatePicker(delayMs: number = 500): void {
        this.closeInlineModal();
        setTimeout(() => this.setToLatest(), delayMs);
    }

    /**
     * Sets the date to the max value.
     */
    setToLatest(): void {
        this.date = this.maxDate;
        this.inputDate = this.maxDate;
        this.invalidDateMessage = "";
    }

    /**
     * Always remove the error message when the user changes the input.
     */
    inputChanged(): void {
        this.invalidDateMessage = "";
    }

    /**
     * Make sure the new value is a valid date, and that it is between the min
     * and max values, before setting the actual value to a formatted version of
     * the entered value. Notice that we have both the "date", that is the
     * actual value we use, and the "inputDate", which is the model backing the
     * input field. This is necessary since we want to be able to reset the
     * value in the input field to the previous value if the new value is
     * invalid.
     *
     * @param event The event.
     */
    inputBlurred(event: Event): void {
        let target: any = event.target;
        let newValue: string = this.parseDate(target.value);

        if (newValue === null) {
            // Invalid.
            this.invalidDateMessage = "Ogiltigt datum."
        } else if (this.minDate && newValue < this.minDate) {
            // Too early.
            this.invalidDateMessage = "Tidigast tillgängliga datum är " + this.minDate;
            this.inputDate = newValue;
        } else if (this.maxDate && newValue > this.maxDate) {
            // Too late.
            this.invalidDateMessage = "Senast tillgängliga datum är " + this.maxDate;
            this.inputDate = newValue;
        } else {
            // The new value is ok.
            this.date = newValue;
            this.inputDate = newValue;
            this.invalidDateMessage = "";
        }
    }
}
