
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SearchPageEntityInfoHeader
  from "@/components/SearchPageEntityInfoHeader.vue";
import {SearchResultApartmentInfo} from "@/models/search-result-apartment-info";
import SearchPageEntityHouseholdMemberList
  from "@/components/SearchPageEntityHouseholdMemberList.vue";

/**
 * This component represents a list of apartments.
 */
@Component({
    components: {
        SearchPageEntityHouseholdMemberList,
        SearchPageEntityInfoHeader
    }
})
export default class SearchPageEntityApartmentList extends Mixins(Utils) {
    @Prop()
    apartments: SearchResultApartmentInfo[];

    @Prop()
    showApartmentInfo: boolean;

    @Prop()
    level: number;


    /**
     * Returns the apartment header string.
     *
     * @param apartment The apartment.
     */
    formatApartmentHeader(apartment: SearchResultApartmentInfo): string {
        return apartment.apartmentNumber === "" ? "Saknar lägenhetsnummer" : "Lägenhet " + apartment.apartmentNumber;
    }
}
