
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

/**
 * This component represents an expandable entity. If the externalTrigger is not
 * specified, the expanded state is handled entirely within this component.
 * However, sometimes it is necessary to be able to control the expanded state
 * from outside this component, and then the externalTrigger can be specified.
 */
@Component
export default class Expandable extends Vue {
    /**
     * Set to true in order to disable expand functionality.
     */
    @Prop({default: false})
    disabled: boolean;

    /**
     * Use this property if the expanded state should be manipulated from
     * outside this component.
     */
    @Prop()
    externalTrigger: boolean;


    /**
     * The expanded state.
     */
    expanded: boolean = false;

    private externallyTriggered: boolean = false;


    mounted(): void {
        if (this.externalTrigger !== undefined) {
            this.externallyTriggered = true;
        }
    }

    /**
     * Toggles the expanded state.
     */
    toggle(): void {
        if (this.disabled) {
            this.expanded = false;
        } else if (!this.externallyTriggered) {
            this.expanded = !this.expanded;
        }
    }

    /**
     * Make sure we respond to the external trigger if it is specified.
     *
     * @param newVal The new value of the expanded state.
     */
    @Watch("externalTrigger")
    onExternalTriggerChange(newVal: boolean): void {
        if (!this.disabled) {
            this.expanded = newVal;
        }
    }
}
