
import ProgressBar from "@/components/ProgressBar.vue"
import SidebarRightMonitorListEntry
    from "@/components/SidebarRightMonitorListEntry.vue"
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {MonitoredEntity} from "@/models/monitored-entity";
import Dropdown from "@/components/Dropdown.vue";


/**
 * Displays a dropdown header which when expanded shows a list of monitors.
 */
@Component({
    components: {
        Dropdown,
        ProgressBar,
        SidebarRightMonitorListEntry,
    },
})
export default class SidebarRightMonitorDropdownList extends Vue {
    @Prop()
    header: string;

    @Prop()
    monitorsLoading: boolean;

    @Prop()
    monitors: MonitoredEntity[];

    @Prop()
    explanation: string | undefined;

}
