
import {Component, Mixins} from 'vue-property-decorator';
import StateHelper from "@/mixins/state-helper";
import {Global} from "@/global";
import V2Header from "@/components_v2/V2Header.vue";
import SearchPage from "@/views/SearchPage.vue";
import SvgConstants from "@/mixins/svg-constants";
import {OneTimeCodeAuthRequest} from "@/models/auth-request.model";
import {
    ACTION_AUTHENTICATE_WITH_USERNAME_AND_PASSWORD,
    ACTION_CONFIRM_ONE_TIME_CODE
} from "@/store/store";
import {
    EndUserSubscriptionOperationResponse
} from "@/models/end-user-subscription-operation-response";
import ApplicationStateSerializer from "@/mixins/application-state-serializer";
import Utils from "@/mixins/utils";
import Routing from "@/mixins/routing";
import Validation from "@/mixins/validation";
import {HTTP} from "@/services/http-provider";
import {OneTimeCodeAuthResponse} from "@/models/one-time-code-auth-response";
import {
    OneTimeCodeConfirmRequest
} from "@/models/one-time-code-confirm-request";

@Component({
    components: {SearchPage, V2Header},
})
export default class SignIn extends Mixins(ApplicationStateSerializer, StateHelper, SvgConstants, Utils, Routing) {
    authRequest: OneTimeCodeAuthRequest = new OneTimeCodeAuthRequest("");

    step: number = 1;

    signInType: string = "";

    errorMessage: string = "";

    codeId: string = "";

    codeDestination: string = "";

    enteredCode: string = "";


    // noinspection JSMethodCanBeStatic
    mounted(): void {
        Global.setPrefixedTitle("");
        if (this.$route.params.codeId) {
            this.codeId = this.$route.params.codeId;
            this.goToStep2();
        }
    }

    /**
     * Show input field for entering one-time code.
     */
    goToStep2(): void {
        this.step = 2;
    }

    /**
     * Resets some basic values.
     */
    reset(): void {
        this.codeId = "";
        this.codeDestination = "";
        this.enteredCode = "";
    }

    /**
     * Gets the label for the input field for the id.
     */
    get idLabel(): string {
        switch (this.signInType) {
            case "password":
            case "email":
            case "sms":
                return "E-post / Personnr / Användarnamn:";
            case "sso":
                return "E-post";
            default:
                return "";
        }
    }

    /**
     * Gets the label for the submit button.
     */
    get submitButtonLabel(): string {
        switch (this.signInType) {
            case "password":
                return "Logga in";
            case "email":
            case "sms":
                return "Skicka kod för inloggning";
            case "sso":
                return "Fortsätt till SSO";
            default:
                return "";
        }
    }

    /**
     * Sets sign in type to the given string or to the empty string if it
     * already is the given string.
     */
    toggleSignInType(type: string): void {
        this.errorMessage = "";
        this.signInType = type === this.signInType ? "" : type;
    }

    /**
     * Return true if we should show the "accordion" button for the given
     * sign in type.
     */
    showAccordionButton(type: string): boolean {
        return this.signInType === type || this.signInType === "";
    }

    /**
     * Perform the correct sign in depending on which state we're in.
     */
    signIn(): void {
        this.errorMessage = "";
        this.authRequest.abort = undefined;

        if (this.signInType === 'password') {
            //=== Password sign in ===
            this.$store.dispatch(ACTION_AUTHENTICATE_WITH_USERNAME_AND_PASSWORD, this.authRequest).then((_: EndUserSubscriptionOperationResponse) => {
                // Reroutes to saved path or to home if path is saved
                this.rerouteToSavedPath();
            }).catch((error) => {
                this.errorMessage = this.extractErrorMessage(error, "Fel e-postadress eller lösenord. Försök igen eller logga in med e-postlänk eller sms.");
            });
        } else if (this.signInType === 'sso') {
            //=== SSO sign in, redirect to SSO endpoint ===
            // Due to the nature of SAML, we need to redirect the user to the IdP's sign-in page
            // so we can't really handle this case in any other way than a plain redirect.
            window.location.assign("/app/api/saml/login?email=" + encodeURIComponent(this.authRequest.id));
        } else {
            //=== Email or SMS sign in, using one-time code ===
            this.goToStep2();
            this.authRequest.type = this.signInType.toUpperCase();

            HTTP.post<OneTimeCodeAuthResponse>("/api/auth/login/one-time-code/create", this.authRequest).then((oneTimeCodeAuthResponse: OneTimeCodeAuthResponse) => {
                this.codeId = oneTimeCodeAuthResponse.codeId
                this.codeDestination = oneTimeCodeAuthResponse.identity;
            }).catch(error => {
                this.step = 1;
                this.errorMessage = this.extractErrorMessage(error, "Något gick fel. Försök igen eller logga in med lösenord.");
                this.reset();
            });
        }
    }

    /**
     * Sends the code the user entered along with the code id to the server, in
     * order to sign in. The user will be redirected to the Welcome screen on
     * successful sign-in.
     */
    confirmOneTimeCode(): void {
        const oneTimeCodeConfirmRequest = new OneTimeCodeConfirmRequest(this.codeId, this.enteredCode);
        this.$store.dispatch(ACTION_CONFIRM_ONE_TIME_CODE, oneTimeCodeConfirmRequest).then((response: EndUserSubscriptionOperationResponse) => {
            this.$router.push({
                name: "welcome",
                params: {
                    /*
                      If the user came to the sign-in page by trying to access
                      an url that requires being signed in, then that url state
                      (path and query parameters) will be saved on the
                      params.state by a router.beforeEach function in main.ts
                      before being redirected to sign in. Here we "forward" the
                      state so that WelcomePage has access to it and later can
                      restore the state.
                     */
                    state: this.getSerializedState(),
                    showPassword: String(Validation.EMAIL.test(response.endUserParameters.email)),
                    showSubscriptionSelection: "false"
                }
            });
            this.reset();
        }).catch(error => {
            this.errorMessage = this.extractErrorMessage(error, "Något gick fel. Försök igen eller logga in med lösenord.");
        });
    }

    /**
     * Aborts an ongoing one-time code sign-in.
     */
    abortOneTimeCodeSignIn(): void {
        this.errorMessage = "";
        this.step = 1;
        this.authRequest.abort = true;

        HTTP.post<void>("/api/auth/login/one-time-code/abort", JSON.stringify(this.codeId)).then(() => {
        }).catch(_ => {
            // Just ignore this. It is not really important.
        });
        this.reset();
    }
}
