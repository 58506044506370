/**
 * See the corresponding java model for documentation.
 */
export class OneTimeCodeConfirmRequest {
    constructor(codeId: string, code: string) {
        this.codeId = codeId;
        this.code = code;
    }

    codeId: string;
    code: string;
}