
    import {Component, Mixins, Prop} from 'vue-property-decorator';
    import {PaymentParameters} from "@/models/end-user-subscription-parameters";
    import Auth from "@/mixins/auth";
    import Utils from "@/mixins/utils";
    import SvgConstants from "../mixins/svg-constants";
    import {Global} from "@/global";
    import PreflightLink from "@/components/PreflightLink.vue";

    /**
     * This component represents a single entry in the list of payments in the right
     * sidebar.
     */
    @Component({
        components: {PreflightLink}
    })
    export default class SidebarRightPaymentListEntry extends Mixins(Auth, Utils, SvgConstants) {
        @Prop()
        payment: PaymentParameters;


        /**
         * Gets the url for opening or downloading a payment pdf.
         *
         * @param type The type of url - either "open" or "download".
         */
        getPaymentHref(type: string): string {
            return Global.url("/sapi/payment/" + type + "/" + this.payment.id + "/" + this.payment.ocrNo + ".pdf");
        }

        /**
         * Gets the text to show next to a payment.
         */
        getStatusAndDueDate(): string {
            return this.getPaymentStatusText(this.payment.status) + " - Fakturadatum " + this.payment.dueDate;
        }
    }
