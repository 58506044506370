import {Component, Vue} from "vue-property-decorator";

/**
 * This mixin provides the "uniqueId" property that simply is an id string that
 * is guaranteed to be unique across all instances of this component.
 */
@Component
export default class UniqueId extends Vue {
    static counter: number = 1;

    readonly uniqueId: string = "id-" + new Date().getTime() + "-" + UniqueId.counter++;
}
