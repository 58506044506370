
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchPageEntityInfoHeader
    from "@/components/SearchPageEntityInfoHeader.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

/**
 * This component represents a simple form of expandable part in the details
 * view, where for example content can't be loaded dynamically.
 */
@Component({
    components: {
        ErrorMessage,
        SearchPageEntityInfoHeader,
        SearchPageEntityCompanyInfoHeader: SearchPageEntityInfoHeader
    }
})
export default class SearchPageEntitySimpleExpandable extends Vue {
    /**
     * The header.
     */
    @Prop()
    heading: string;

    /**
     * An optional tag next to the heading, colored rounded box with white
     * text.
     */
    @Prop()
    tag: string;

    /**
     * A class to set on the heading.
     */
    @Prop()
    headingClass: string;

    /**
     * The text to show as the "expand" link at the right edge.
     */
    @Prop({default: "Visa alla"})
    expandText: string;

    /**
     * The text to show as the "contract" link at the right edge.
     */
    @Prop({default: "Visa färre"})
    contractText: string;

    /**
     * The text to show if there is nothing to show in the expandable part.
     */
    @Prop()
    nothingToShowText: string;

    /**
     * Set to some string to show as fixed icon text if non-expandable.
     * Useful when we only have one short line of text to display and that
     * line can fit on the same line as the header.
     */
    @Prop()
    fixedIconText: string;

    /**
     * Set to true to force showing the expanded part.
     */
    @Prop({default: false})
    forceExpand: boolean;

    /**
     * Show this error message instead of normal content, if it is set.
     */
    @Prop()
    errorMessage: string;

    /**
     * Whether to show the expanded part or not.
     */
    expanded: boolean = false;


    /**
     * Toggles whether to show the expanded part or not.
     */
    toggle(): void {
        this.expanded = !this.expanded;
        this.$emit("expand-change", this.expanded);
    }

    /**
     * Returns the map to pass to the :class attribute for the heading.
     */
    headingClassMap(): any {
        let classMap: any = {
            expandable: !this.forceExpand
        };
        if (this.headingClass) {
            classMap[this.headingClass] = true;
        }
        return classMap;
    }

    /**
     * Renders the text for the expand and contract link.
     */
    headingIconText(): string {
        if (this.fixedIconText) {
            return this.fixedIconText;
        } else if (this.forceExpand) {
            return ""
        } else if (this.expanded) {
            return this.contractText + " &#9661;";
        } else {
            return this.expandText + " &#9655;";
        }
    }

    get isExpanded(): boolean {
        return this.forceExpand || this.expanded;
    }
}
