
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import SvgConstants from "@/mixins/svg-constants";
import StateHelper from "@/mixins/state-helper";
import SearchPageEntityLink from "@/components/SearchPageEntityLink.vue";
import {
  SearchResultRealPropertyOwner
} from "@/models/search-result-real-property-owner";
import SelectOnClick from "@/components/SelectOnClick.vue";
import StatusIconRow from "@/components/StatusIconRow.vue";
import AdditionalInfoRow from "@/components/AdditionalInfoRow.vue";
import Fraction from "@/components/Fraction.vue";

/**
 * This component represents a real property owner.
 */
@Component({
    components: {
        AdditionalInfoRow,
        StatusIconRow,
        SearchPageEntityLink,
        SelectOnClick,
        Fraction
    }
})
export default class SearchPageEntityRealPropertyOwner extends Mixins(StateHelper, Utils, SvgConstants) {
    /**
     * The tax unit
     */
    @Prop()
    owner: SearchResultRealPropertyOwner;

    /**
     * The zero based level we're on (0 = the leftmost detail view). This is used when
     * determining if the link is active or not by checking if the id in the detailIds
     * array on the level to the right of this one, is the same as our id.
     */
    @Prop()
    level: number;

    /**
     * Formats address and country for the given owner, if the owner is not
     * clickable - that is - lacks id.
     *
     * @param owner The owner.
     */
    formatAddressAndCountry(owner: SearchResultRealPropertyOwner): string {
        if (owner.id) {
            return "";
        }
        let parts: string[] = [
            owner.streetAddress,
            (owner.zip + " " + owner.city).trim(),
            owner.country
        ];
        return parts.filter(part => !!part).join(", ").replace(/,,/, ",");
    }

    /**
     * Checks if an owner has an address that needs to be split into multiple
     * lines.
     *
     * @param owner The owner.
     */
    hasLongAddress(owner: SearchResultRealPropertyOwner): boolean {
        return this.approximateWidth(this.formatAddressAndCountry(owner)) > 50;
    }
    
    get fractionClass(): string {
    	if(this.owner.shareDenominator>999) {
    		return "fraction fraction_large";
    	}
    	return "fraction fraction_small";
    }
}
