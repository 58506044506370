
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import {
    SearchResultBeneficialOwner
} from "@/models/search-result-beneficial-owner";
import StatusIconRow from "@/components/StatusIconRow.vue";

/**
 * This component represents a beneficial owner in the list of beneficial
 * owners.
 */
@Component({
    components: {StatusIconRow}
})
export default class SearchPageEntityCompanyInfoBeneficialOwner extends Mixins(Utils) {
    @Prop()
    beneficialOwner: SearchResultBeneficialOwner;


    /**
     * Formats the name, age and possibly country, for a beneficial owner.
     */
    get formatBeneficialOwner(): string {
        let ret: string = this.nameAndPossiblyAgeOrBirthdate(this.beneficialOwner);
        if (ret.length === 0) {
            ret = "Registrerad utan personuppgifter";
        }
        return ret;
    }

    formatBirtDateToIdNumber(birtDate: string) {
        return birtDate.length === 8 ? birtDate + "-0000" : birtDate;
    }

    formatCountryOfResidence(country: string): string {
        switch (country) {
            case null:
            case "":
            case "Okänt":
                return "Okänt bosättningsland";
            default:
                return "Bosatt i " + country;
        }
    }

    formatCitizenshipCountry(country: string): string {
        switch (country) {
            case null:
            case "":
            case "Okänt":
                return "Okänt medborgarskap";
            case "Statslös":
                return "Medborgarskap: statslös"
            default:
                return "Medborgare i " + country;
        }
    }
}
