import {Component, Vue} from "vue-property-decorator";
import {
    EndUserParameters,
    SubscriptionParameters
} from "@/models/end-user-subscription-parameters";
import {AUTH} from "@/mixins/auth";
import {
    ACTION_UPDATE_USER_CONFIG,
    WrappedNotification
} from "@/store/store-notification";
import {MonitoredEntity} from "@/models/monitored-entity";
import {FrontendMonitorConfig} from "@/models/frontend-monitor-config";
import {UserMonitors} from "@/models/user-monitors";
import {MonitorList} from "@/models/monitor-list";
import {EventNotification} from "@/models/event-notification";
import {EntityViewRef} from "@/store/store-search";
import {Global} from "@/global";
import {
    SafenodeQuestionerParameters
} from "@/models/safenode-questioner-parameters";
import {DetailViewConfig} from "@/models/detail-view-config";
import {SearchResultResponse} from "@/models/search-response";
import {
    FrontendUserWebAppSettings
} from "@/models/frontend-user-web-app-settings";
import Utils from "@/mixins/utils";

/**
 * This class contains various helper methods for determining the state of the
 * application, such as which user is logged in and which subscription is
 * active etc.
 */
@Component({})
export default class StateHelper extends Vue {
    /**
     * Checks if we're logged in by ensuring the refresh token is still active.
     */
    get appLoaded(): boolean {
        return this.$store.state.appLoaded;
    }

    /**
     * Checks if we're logged in by checking if we have authentication info
     */
    get signedIn(): boolean {
        return this.$store.getters.signedIn;
    }

    /**
     * Gets the active user from the store.
     */
    get activeUser(): EndUserParameters {
        return this.$store.state.activeUser;
    }

    /**
     * Returns true if the active user has a personal number.
     */
    get hasPnr(): boolean {
        return this.activeUser && !!this.activeUser.personalNumber;
    }

    /**
     * Gets the list of subscriptions for the currently active user.
     */
    get subscriptions(): SubscriptionParameters[] {
        return this.$store.state.subscriptionList;
    }

    /**
     * Gets the active subscription.
     */
    get activeSubscription(): SubscriptionParameters {
        return this.$store.state.activeSubscription;
    }

    /**
     * Gets the refNo of the active subscription.
     */
    get activeSubscriptionRefNo(): string {
        return this.activeSubscription && this.activeSubscription.refNo;
    }

    /**
     * Check if we have signed the terms for the currently active subscription.
     */
    get signedTermsForActiveSubscription(): boolean {
        return this.activeSubscription && this.activeSubscription.termsSigned;
    }

    /**
     * Returns true if the active subscription has more available credit
     * reports.
     */
    get hasMoreAvailableCreditReports(): boolean {
        return this.activeSubscription && this.activeSubscription.availableCreditReports;
    }

    get eventNotifications(): EventNotification[] {
        return this.$store.state.notification.wrappedNotifications.flatMap((wn: WrappedNotification) => wn.notification);
    }

    /**
     * Getter for the monitor list in the store.
     */
    get monitors(): UserMonitors {
        return this.$store.state.monitor.monitors;
    }

    /**
     * Gets the normal manual monitors.
     */
    get manualMonitors(): MonitoredEntity[] {
        return this.$store.state.monitor.monitors.manual.filter((monitor: MonitoredEntity) => monitor.priority !== "OFF");
    }

    /**
     * Gets the manual monitors that exclude entities from being auto monitored.
     */
    get exclusionMonitors(): MonitoredEntity[] {
        return this.$store.state.monitor.monitors.manual.filter((monitor: MonitoredEntity) => monitor.priority === "OFF");
    }

    /**
     * Checks if we have a manual monitor on an entity. False is returned if
     * the priority of the monitor is OFF.
     */
    hasMonitorOn(entityId: string): boolean {
        return this.manualMonitors.filter(monitor => monitor.priority != "OFF").map(monitor => monitor.id).indexOf(entityId) !== -1;
    }

    hasAnyMonitorOn(entityId: string): boolean {
        const hasManual: boolean = this.manualMonitors.filter(monitor => monitor.priority != "OFF").map(monitor => monitor.id).indexOf(entityId) !== -1;
        const hasAuto: boolean = this.monitors.automatic.map(monitor => monitor.id).indexOf(entityId) !== -1;
        const hasSelfAuto: boolean = this.monitors.selfAutomatic.map(monitor => monitor.id).indexOf(entityId) !== -1;
        let hasList: boolean = false;
        for (const list of this.getMonitorListsFor(entityId)) {
            if (list.entries.map(entry => entry.id).indexOf(entityId) !== -1) {
                hasList = true;
                break;
            }
        }

        return hasManual || hasAuto || hasSelfAuto || hasList;
    }

    /**
     * Gets the manual monitor object for the entityId if one exists,
     * regardless of the priority.
     */
    getMonitorFor(entityId: string): MonitoredEntity {
        return this.manualMonitors[this.manualMonitors.map(monitor => monitor.id).indexOf(entityId)];
    }

    /**
     * Gets the exclusion monitor object for the entityId if one exists.
     */
    getExclusionMonitorFor(entityId: string): MonitoredEntity {
        return this.exclusionMonitors[this.exclusionMonitors.map(monitor => monitor.id).indexOf(entityId)];
    }

    /**
     * Gets the monitor lists an entity occurs in.
     */
    getMonitorListsFor(entityId: string): MonitorList[] {
        let lists: MonitorList[] = this.$store.state.monitor.monitors.lists;
        let ret: MonitorList[] = [];

        for (let list of lists) {
            const ids: string[] = list.entries.map(monitor => monitor.id);
            if (ids.indexOf(entityId) !== -1) {
                ret.push(list);
            }
        }

        return ret;
    }

    isAutoMonitored(entityId: string): boolean {
        return this.$store.state.monitor.monitors.automatic.map((monitor: MonitoredEntity) => monitor.id).indexOf(entityId) !== -1;
    }

    getAutoMonitor(entityId: string): MonitoredEntity | undefined {
        return this.$store.state.monitor.monitors.automatic.find((monitor: MonitoredEntity) => monitor.id === entityId);
    }

    getSelfAutoMonitor(entityId: string): MonitoredEntity | undefined {
        return this.$store.state.monitor.monitors.selfAutomatic.find((monitor: MonitoredEntity) => monitor.id === entityId);
    }

    isSelfAutoMonitored(entityId: string): boolean {
        return this.$store.state.monitor.monitors.selfAutomatic.map((monitor: MonitoredEntity) => monitor.id).indexOf(entityId) !== -1;
    }

    get realPropertyOwnershipAsFraction(): boolean {
        return this.userWebAppSettings.displayRPOwnershipAsFraction;
    }

    get alwaysBirthYearInResultList(): boolean {
        return this.userWebAppSettings.displayBirthYearInResultList;
    }

    /**
     * Gets the total number of unread notifications, including the ones not
     * fetched.
     */
    get numUnreadNotifications(): number {
        return this.$store.state.notification.unreadCount;
    }

    /**
     * Gets the total number of notifications, including the ones not fetched.
     */
    get numNotifications(): number {
        return this.$store.state.notification.totalCount;
    }

    /**
     * Convenience getter for getting the user monitor config.
     */
    get userMonitorConfig(): FrontendMonitorConfig {
        return this.$store.state.notification.userConfig;
    }

    /**
     * Helper function for updating the user monitor config. It takes a callback
     * as a parameter which supplies a deep copy of the current config which can
     * be modified within the callback. The new config is then persisted.
     * It returns a void promise.
     */
    async updateUserMonitorConfig(callback: (config: FrontendMonitorConfig) => void): Promise<void> {
        let config: FrontendMonitorConfig = new FrontendMonitorConfig(this.userMonitorConfig);
        callback(config);
        return this.$store.dispatch(ACTION_UPDATE_USER_CONFIG, config);
    }

    /**
     * Checks if we have payments fetched.
     */
    get hasPayments(): boolean {
        return this.$store.state.payments.paymentList.length > 0;
    }

    /**
     * Getter for the questioner list in the store.
     */
    get questioners(): SafenodeQuestionerParameters[] {
        return this.$store.state.questioner.questioners;
    }

    /**
     * Check if the given orgNo represents the currently selected questioner.
     *
     * @param orgNo The orgNo to check.
     */
    isQuestionerSelected(orgNo: string): boolean {
        return orgNo === this.$store.state.questioner.selected;
    }

    /**
     * Getter for the orgNo of the selected questioner.
     */
    get selectedQuestioner(): string {
        return this.$store.state.questioner.selected;
    }

    /**
     * Getter for the questioner that may be shown in terms.
     */
    get questionerShowInTerms(): SafenodeQuestionerParameters {
        return this.$store.state.questioner.showInTerms;
    }

    /**
     * Checks if we're currently logged in as the user with the given public id.
     *
     * @param publicId The public id.
     */
    signedInAs(publicId: string): boolean {
        return this.signedIn && this.activeUser && this.activeUser.publicId && this.activeUser.publicId === publicId;
    }

    /**
     * Returns true if active subscription is a demo subscription.
     *
     * @returns True if the active subscription is a demo subscription.
     */
    get isDemoSubscription() {
        return this.activeSubscription && this.activeSubscription.demo;
    }

    /**
     * Returns whether there is an active subscription.
     *
     * @returns True if there is an active subscription.
     */
    get hasActiveSubscription(): boolean {
        return !!this.activeSubscription;
    }

    /**
     * Returns true if the currently active user has more than one subscription.
     */
    get hasMultipleSubscriptions(): boolean {
        return this.subscriptions && this.subscriptions.length > 1;
    }

    /**
     * Returns true if the current user is allowed to view income using the
     * current subscription.
     */
    get allowViewIncome(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewIncome;
    }

    /**
     * Returns true if the current user is allowed to view the remark catalog
     * using the current subscription.
     */
    get allowViewRemarks(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewRemark;
    }

    /**
     * Returns true if the current user is allowed to create credit reports
     * using the current subscription.
     */
    get allowCreditReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.creditReport;
    }

    /**
     * Returns true if the current user is allowed to manage monitors using the
     * current subscription.
     */
    get allowManageMonitor(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.monitor;
    }

    /**
     * Returns true if the current user is allowed to use monitor lists.
     */
    get allowMonitorLists(): boolean {
        return this.allowManageMonitor && this.activeSubscription.permissions.monitorLists;
    }

    /**
     * Returns true if the current user is allowed to view housing register
     * information using the current subscription.
     */
    get allowViewHousingRegister(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewHousingRegister;
    }

    /**
     * Returns true if the current user is allowed to view company group
     * information using the current subscription.
     */
    get allowViewCompanyGroup(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewCompanyGroup;
    }

    /**
     * Returns true if the current user is allowed to view historical company
     * names and secondary business names using the current subscription.
     */
    get allowViewAuxiliaryCompanyName(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewAuxiliaryCompanyName;
    }

    /**
     * Returns true if the current user is allowed to view LEI codes using the
     * current subscription.
     */
    get allowViewLeiCode(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewLeiCode;
    }

    /**
     * Returns true if the current user is allowed to use automatic monitoring.
     * Note that we do not use permissions.autoMonitor, because that only
     * checks
     * if the user is permitted to use auto monitors by the current
     * subscription, where we want to check if the user is permitted to by any
     * subscription.
     */
    get allowAutoMonitor(): boolean {
        return this.activeUser && this.activeUser.autoMonitorAllowed;
    }

    /**
     * Returns true if the current user is allowed to fetch AB annual reports
     * using the current subscription.
     */
    get allowFetchAnnualReportAb(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchAnnualReportAb;
    }

    /**
     * Returns true if the current user is allowed to fetch non-AB annual
     * reports using the current subscription.
     */
    get allowFetchAnnualReportNonAb(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchAnnualReportNonAb;
    }

    /**
     * Returns true if the current user is allowed to fetch certificate of
     * registration using the current subscription.
     */
    get allowFetchCertificateOfRegistration(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCertificateOfRegistration;
    }

    /**
     * Returns true if the current user is allowed to fetch certificate of
     * registration in English using the current subscription.
     */
    get allowFetchCertificateOfRegistrationEnglish(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCertificateOfRegistrationEnglish;
    }

    /**
     * Returns true if the current user is allowed to fetch articles of
     * association using the current subscription.
     */
    get allowFetchArticlesOfAssociation(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchArticlesOfAssociation;
    }

    /**
     * Returns true if the current user is allowed to fetch BV case lists using
     * the current subscription.
     */
    get allowFetchCaseList(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCaseList;
    }

    /**
     * Returns true if the current user is allowed to fetch BV case info using
     * the current subscription.
     */
    get allowFetchCaseInfo(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCaseInfo;
    }

    /**
     * Returns true if the current user is allowed to fetch BV protocols using
     * the current subscription.
     */
    get allowFetchProtocol(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchProtocol;
    }

    /**
     * Returns true if the current user is allowed to fetch BV statutes using
     * the current subscription.
     */
    get allowFetchStatutes(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchStatutes;
    }

    /**
     * Returns true if the current user is allowed to fetch BV financial plans
     * using the current subscription.
     */
    get allowFetchFinancialPlan(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchFinancialPlan;
    }

    /**
     * Returns true if the current user is allowed to fetch company reports
     * using the current subscription.
     */
    get allowFetchCompanyReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCompanyReport;
    }

    /**
     * Returns true if the current user is allowed to fetch company mortgage
     * reports using the current subscription.
     */
    get allowFetchCompanyMortgageReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCompanyMortgageReport;
    }

    /**
     * Returns true if the current user is allowed to fetch company vehicle
     * reports using the current subscription.
     */
    get allowFetchCompanyVehicleReport(): boolean {
        return this.activeSubscription && this.allowVehicle && this.allowCompanyVehicleOwner && this.activeSubscription.permissions.fetchCompanyVehicleReport;
    }

    /**
     * Returns true if the current user is allowed to fetch company capital
     * reports using the current subscription.
     */
    get allowFetchCompanyCapitalReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchCompanyCapitalReport;
    }

    /**
     * Returns true if the current user is allowed to view address locations
     * using the current subscription.
     */
    get allowAddressLocation(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.addressLocation;
    }

    /**
     * Returns true if the current user is allowed to view vehicles using
     * the current subscription.
     */
    get allowVehicle(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.vehicle;
    }

    /**
     * Returns true if the current user is allowed to view links between
     * individuals and vehicles using the current subscription.
     */
    get allowIndividualVehicleOwner(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.individualVehicleOwner;
    }

    /**
     * Returns true if the current user is allowed to view links between
     * companies and vehicles using the current subscription.
     */
    get allowCompanyVehicleOwner(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.companyVehicleOwner;
    }

    /**
     * Returns true if the current user is allowed to view real properties using
     * the current subscription.
     */
    get allowRealProperty(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.realProperty;
    }

    /**
     * Returns true if the current user is allowed to view real properties using
     * the current subscription.
     */
    get allowRealPropertyPurchasePrice(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewRealPropertyPurchasePrice;
    }


    /**
     * Returns true if the current user is allowed to view real properties and
     * to fetch real property reports.
     */
    get allowFetchRealPropertyReport(): boolean {
        return this.allowRealProperty && this.activeSubscription.permissions.fetchRealPropertyReport;
    }

    /**
     * Returns true if the current user is allowed to view real properties and
     * to fetch real property taxation reports.
     */
    get allowFetchRealPropertyTaxationReport(): boolean {
        return this.allowRealProperty && this.activeSubscription.permissions.fetchRealPropertyTaxationReport;
    }

    /**
     * Returns true if the current user is allowed to view PEP data.
     */
    get allowPEP(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.pep;
    }

    /**
     * Returns true if the current user is allowed to fetch individual reports
     * using the current subscription.
     */
    get allowFetchIndividualReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchIndividualReport;
    }

    /**
     * Returns true if the current user is allowed to fetch individual
     * commitment reports using the current subscription.
     */
    get allowFetchIndividualCommitmentReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchIndividualCommitmentReport;
    }

    /**
     * Returns true if the current user is allowed to fetch title deed and
     * historical owner reports using the current subscription.
     */
    get allowFetchTitleDeedAndHistoricalOwnerReport(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.fetchTitleDeedAndHistoricalOwnerReport;
    }

    /**
     * Returns true if the current user is allowed to view board history using
     * the current subscription.
     */
    get allowBoardHistory(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.boardHistory;
    }

    /**
     * Returns true if the current user is allowed to view legal documents using
     * the current subscription.
     */
    get allowLegalDocuments(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.legalDocuments;
    }

    /**
     * Returns true if the current user is allowed to view payroll tax data
     * using the current subscription.
     */
    get allowPayrollTax(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.payrollTax;
    }

    /**
     * Returns true if the current user is allowed to view sanction data for
     * individuals.
     */
    get allowSanctionIndividual(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.sanctionIndividual;
    }

    /**
     * Returns true if the current user is allowed to view sanction data for
     * companies.
     */
    get allowSanctionCompany(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.sanctionCompany;
    }

    /**
     * Returns true if the current user is allowed to view documents for
     * individuals with secret identity.
     */
    get allowDocumentsForSecretIndividuals(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.documentsForSecretIndividuals;
    }

    /**
     * Checks if the current authentication token has the BankID role.
     */
    get authWithBankId(): boolean {
        if (!this.$store.getters.authorityInfo) {
            return false;
        }
        return AUTH.hasBankIdRole();
    }

    /**
     * Convenience getter for the popupMessageStatus in the store.
     */
    get popupMessageStatus(): string {
        return this.$store.state.popupMessageStatus;
    }

    /**
     * Convenience getter for the popupRegistrationStatus in the store.
     */
    get popupRegistrationStatus(): string {
        return this.$store.state.popupRegistrationState.status;
    }

    /**
     * Convenience getter for the sidebarRightStatus in the store.
     */
    get sidebarRightStatus(): string {
        return this.$store.state.sidebarRightStatus;
    }

    /**
     * Returns true if we should display the full screen dialog.
     */
    get isFullScreen(): boolean {
        return this.popupMessageStatus.startsWith("full-screen");
    }

    /**
     * Convenience getter for the search result response in the search store.
     */
    get searchResultResponse(): SearchResultResponse {
        return this.$store.state.search.searchResultResponse;
    }

    /**
     * Convenience getter for the details in the search store.
     */
    get details(): EntityViewRef[] {
        return this.$store.state.search.details;
    }

    /**
     * Convenience getter for the publicId strings from the details in the
     * search store.
     */
    get detailsPublicId(): string[] {
        return this.details.map(detail => detail.publicId);
    }

    /**
     * Returns true if we're on the Faktakontroll site.
     */
    get isKreditupplysning(): boolean {
        return Global.isKreditupplysning();
    }

    /**
     * Returns true if we're on the Faktakontroll site.
     */
    get isFaktakontroll(): boolean {
        return Global.isFaktakontroll();
    }

    /**
     * Returns the base url of the site.
     */
    get baseUrl(): string {
        return Global.baseUrl();
    }

    /**
     * Returns the name of the product we're using based on the url.
     */
    get productName(): string {
        return Global.productName();
    }

    /**
     * Returns the name of the site we're on (in the production environment).
     */
    get siteName(): string {
        return Global.siteName();
    }

    /**
     * Returns the url for the site we're on (in the production environment).
     */
    get siteUrl(): string {
        return Global.siteUrl();
    }

    /**
     * Returns the customer support email for the site we're on.
     */
    get siteCustomerSupport(): string {
        return Global.siteCustomerSupport();
    }

    /**
     * Returns the customer support phone for the site we're on.
     */
    get siteCustomerSupportPhone(): string {
        return Global.siteCustomerSupportPhone();
    }

    /**
     * True as long as any ctrl key is pressed.
     */
    get ctrlPressed(): boolean {
        return this.$store.state.ctrlPressed;
    }

    /**
     * True as long as any meta key is pressed.
     */
    get metaPressed(): boolean {
        return this.$store.state.metaPressed;
    }

    /**
     * True as long as any ctrl or meta key is pressed.
     */
    get ctrlOrMetaPressed(): boolean {
        return this.ctrlPressed || this.metaPressed;
    }

    /**
     * True if the user has enabled the setting and is not on mobile.
     */
    get permanentExpertMode(): boolean {
        if(this.userWebAppSettings && !Utils.isMobile()) {
            return this.userWebAppSettings.permanentExpertMode;
        }
        return false;
    }
    
    get expertModeOn(): boolean {
        return this.ctrlOrMetaPressed || this.permanentExpertMode;
    }

    /**
     * Gets the active inline modal, or the empty string if there is none.
     */
    get activeInlineModal(): string {
        return this.$store.state.activeInlineModal;
    }

    /**
     * Returns the time the current auth token expires at. May return null if
     * we don't have any auth info.
     */
    get expiresAt(): Date {
        if (this.$store.getters.authorityInfo) {
            return new Date(this.$store.getters.authorityInfo.expiresAt);
        }
        return null;
    }

    eventFilterForEntity(publicId: string): string[] {
        let m: Map<string, string[]> = this.$store.state.search.entityFilter;
        if (m.has(publicId)) {
            return m.get(publicId);
        } else {
            return m.get(null);
        }
    }

    /**
     * Gets all filter options for company events
     */
    get companyEventFilters(): Record<string, string> {
        let ret: Record<string, string> = {
            "COMPANY_GENERAL": "Allmänt",
            "COMPANY_BOARD_MEMBER": "Styrelse/Firmateckn/Huvudmän",
            "COMPANY_BV_CASE": "Ärenden Bolagsverket",
            "COMPANY_ANNUAL_REPORT": "Årsredovisning",
            "COMPANY_FINANCIALS": "Ekonomiska uppgifter",
            "COMPANY_REMARKS": "Betalningsanmärkningar",
        }
        if (this.allowRealProperty) {
            ret["COMPANY_REAL_PROPERTY"] = "Fastighetshändelser";
        }
        if (this.allowCompanyVehicleOwner && this.allowVehicle) {
            ret["COMPANY_VEHICLE"] = "Fordonshändelser";
        }
        return ret;
    }

    /**
     * Gets all filter options for individual events
     */
    get individualEventFilters(): Record<string, string> {
        let ret: Record<string, string> = {
            "INDIVIDUAL_GENERAL": "Allmänt",
            "INDIVIDUAL_BOARD_MEMBER": "Styrelse/Huvudman mm",
            "INDIVIDUAL_INCOME_CATALOG": "Inkomstkatalog",
        }
        if (this.allowRealProperty) {
            ret["INDIVIDUAL_REAL_PROPERTY"] = "Fastighetshändelser";
        }
        if (this.allowIndividualVehicleOwner && this.allowVehicle) {
            ret["INDIVIDUAL_VEHICLE"] = "Fordonshändelser";
        }
        if (this.allowPEP && this.allowSanctionIndividual) {
            ret["INDIVIDUAL_PEP_SANCTION_AND_TRADING_PROHIBITION"] = "PEP, sanktion och näringsförbud";
        } else if (this.allowPEP) {
            ret["INDIVIDUAL_PEP_SANCTION_AND_TRADING_PROHIBITION"] = "PEP och näringsförbud";
        } else if (this.allowSanctionIndividual) {
            ret["INDIVIDUAL_PEP_SANCTION_AND_TRADING_PROHIBITION"] = "Sanktion och näringsförbud";
        } else {
            ret["INDIVIDUAL_PEP_SANCTION_AND_TRADING_PROHIBITION"] = "Näringsförbud";
        }
        return ret;
    }

    /**
     * Gets the filter options for address location events
     */
    get addressLocationEventFilters() {
        return {
            "ADDRESS_LOCATION_COMPANY": "Företag",
            "ADDRESS_LOCATION_INDIVIDUAL": "Privatpersoner",
        };
    }

    /**
     * Gets the detail view config.
     */
    get detailViewConfig(): DetailViewConfig {
        return this.$store.state.search.detailViewConfig;
    }

    get userWebAppSettings(): FrontendUserWebAppSettings {
        return this.$store.state.userWebAppSettings.userWebAppSettings;
    }

    get allowViewOutdatedCompanies(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewOutdatedCompanies;
    }

    get allowViewOutdatedPersons(): boolean {
        return this.activeSubscription && this.activeSubscription.permissions.viewOutdatedPersons;
    }

    /**
     * Whether to display the web app settings option in the sidebar menu.
     * True if the user has the rights needed for any of the settings.
     */
    get showWebAppSettings(): boolean {
        // Nowadays, since we have a setting for displaying birth date in result
        // list that is available for everyone, this is always true. But in the
        // future, things might change so we keep this method.
        return true;
    }

    /**
     * Gets the current banner message.
     */
    get bannerMessage(): string {
        return this.$store.state.bannerMessage;
    }
}
